import { string } from "yup";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

export const PhoneNumber = string()
  .transform((value) => {
    try {
      return parsePhoneNumber(value || "")?.format("INTERNATIONAL");
    } catch (e) {
      return "";
    }
  })
  .test("phone-number", "Please enter a valid phone number", (value) => (value ? isValidPhoneNumber(value) : true));
