import classNames from "classnames";
import { PersistedContact } from "schemas";
import { Tag, flexStyles, tagStyles } from "ui";

interface Props {
  contact: PersistedContact;
  start?: boolean;
}
export function ContactTags({ contact, start }: Props) {
  const { pending, confirmed } = contact;
  if (pending) {
    return (
      <Tag
        className={classNames(tagStyles.neutral200, { [flexStyles.alignSelfStart]: start })}
        iconType="Pending"
        label="Pending"
        type="Square"
      />
    );
  }

  if (!confirmed) {
    return (
      <Tag
        className={classNames(tagStyles.neutral200, { [flexStyles.alignSelfStart]: start })}
        iconType="EmailNewsletter"
        label="Unconfirmed"
        type="Square"
      />
    );
  }
}
