import { ChangeEvent } from "react";
import classNames from "classnames";
import { PropsWithClassName } from "./types";
import { Icon } from "./Icon";
import { Link } from "./Link";
import {
  bgStyles,
  borderStyles,
  displayStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  marginStyles,
  outlineStyles,
  paddingStyles,
  pointerStyles,
  positionStyles,
  textStyles,
  transitionStyles,
  widthStyles,
} from "./styles";

interface Props extends PropsWithClassName {
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onCancel?: () => void;
  value: string;
}

export function SearchInput({ className, placeholder, onChange, onFocus, onBlur, onCancel, value }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.horiz125,
        flexStyles.justifyStretch,
        flexStyles.alignEnd,
        positionStyles.relative,
        iconStyles.neutral500,
        transitionStyles.all200,
        className,
      )}
    >
      <Icon
        className={classNames(
          positionStyles.absolute,
          positionStyles.left087,
          positionStyles.top087,
          pointerStyles.eventsNone,
        )}
        iconType="SearchSymbol"
      />
      <input
        className={classNames(
          textStyles.fontPoppins,
          textStyles.size087,
          textStyles.neutral600,
          textStyles.placeholderNeutral300,
          displayStyles.block,
          heightStyles.height312,
          widthStyles.full,
          paddingStyles.p0875,
          paddingStyles.ps250,
          bgStyles.neutral0,
          borderStyles.neutral400,
          borderStyles.radius025,
          borderStyles.secondary300Active,
          outlineStyles.none,
        )}
        onChange={onChange}
        type="search"
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
      />
      {onCancel && (
        <Link className={marginStyles.mb087} onClick={onCancel}>
          Cancel
        </Link>
      )}
    </div>
  );
}
