import classNames from "classnames";
import { LinkItem } from "./LinkItem";
import {
  PropsWithClassName,
  ResponsiveWrapper,
  bgStyles,
  borderStyles,
  flexStyles,
  overflowStyles,
  paddingStyles,
} from "ui";
import { Item } from "./Item";
import { useState } from "react";

interface Props extends PropsWithClassName {
  items: LinkItem[];
}
export function Tabs({ items, className }: Props) {
  const [activeItem, setActiveItem] = useState<LinkItem>(items[0]);
  return (
    <>
      <ResponsiveWrapper
        className={classNames(
          flexStyles.horiz,
          flexStyles.justifyCenter,
          paddingStyles.px050,
          overflowStyles.autoX,
          overflowStyles.overscrollNone,
          className,
        )}
      >
        {items.map((item) => (
          <Item
            key={`tab-i-${item.label}`}
            item={item}
            active={item.label === activeItem.label}
            onClick={() => setActiveItem(item)}
          />
        ))}
      </ResponsiveWrapper>
      <div className={classNames(bgStyles.neutral100, borderStyles.topNeutral200, flexStyles.grow)}>
        <ResponsiveWrapper>{activeItem.content}</ResponsiveWrapper>
      </div>
    </>
  );
}
