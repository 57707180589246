import {
  List as ListContactGroups,
  New as NewContactGroup,
  Edit as EditContactGroup,
  Show as ShowContactGroup,
  Select,
  AddToList,
  AddNewContactGroupDrawer,
} from "../components/contactGroups";
import {
  CandidatesDrawer,
  ChooseLists,
  DuplicatesDrawer,
  GooglePrimer,
  ImportSuccessful,
  Summary,
} from "../components/contactImports";
import {
  AddMenu,
  Edit as EditContact,
  New as NewContact,
  Note,
  Search,
  Show as ShowContact,
} from "../components/contacts";
import { PrivateOutlet } from "./PrivateOutlet";

export const ContactRoutes = [
  {
    path: "contacts",
    Component: PrivateOutlet,
    children: [
      {
        path: "",
        Component: ListContactGroups,
      },
    ],
  },
  {
    path: "contactGroups",
    Component: PrivateOutlet,
    children: [
      {
        path: "new",
        Component: NewContactGroup,
      },
      {
        path: ":contactGroupId",
        Component: ShowContactGroup,
        children: [
          {
            path: "add",
            Component: AddMenu,
          },
          {
            path: "addToList",
            Component: AddToList,
          },
        ],
      },
      {
        path: ":contactGroupId/contacts/new",
        Component: NewContact,
      },
      {
        path: ":contactGroupId/search?/select?/contacts/:contactId",
        Component: ShowContact,
      },
      {
        path: ":contactGroupId/search?/select?/contacts/:contactId/edit",
        Component: EditContact,
      },
      {
        path: ":contactGroupId/search?/select?/contacts/:contactId/note",
        Component: Note,
      },
      {
        path: ":contactGroupId/search",
        Component: Search,
        children: [
          {
            path: "addToList",
            Component: AddToList,
          },
        ],
      },
      {
        path: ":contactGroupId/select",
        Component: Select,
        children: [
          {
            path: "addToList",
            Component: AddToList,
          },
        ],
      },
      {
        path: ":contactGroupId/edit",
        Component: EditContactGroup,
      },
    ],
  },
  {
    path: "contactImports",
    Component: PrivateOutlet,
    children: [
      {
        path: ":contactImportId/lists",
        Component: ChooseLists,
        children: [{ path: "addNewList", Component: AddNewContactGroupDrawer }],
      },
      {
        path: ":contactImportId/primer",
        Component: GooglePrimer,
      },
      {
        path: ":contactImportId/summary",
        Component: Summary,
        children: [
          { path: "candidates", Component: CandidatesDrawer },
          { path: "duplicates", Component: DuplicatesDrawer },
        ],
      },
      {
        path: ":contactImportId/success",
        Component: ImportSuccessful,
      },
    ],
  },
];
