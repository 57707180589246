import classNames from "classnames";
import { ThemePayload } from "schemas";
import { RawThemedWrapper, bgStyles, borderStyles, flexStyles, marginStyles, squareStyles, transitionStyles } from "ui";

interface Props {
  theme: ThemePayload;
}
export function ThemeCircles({ theme }: Props) {
  return (
    <RawThemedWrapper theme={theme} className={classNames(flexStyles.horiz)}>
      <div
        className={classNames(
          squareStyles.square200,
          borderStyles.radiusRound,
          transitionStyles.all200,
          bgStyles.businessLight,
        )}
      />
      <div
        className={classNames(
          squareStyles.square200,
          borderStyles.radiusRound,
          marginStyles.msNegative050,
          transitionStyles.all200,
          bgStyles.businessDark,
        )}
      />
      <div
        className={classNames(
          squareStyles.square200,
          borderStyles.radiusRound,
          marginStyles.msNegative050,
          transitionStyles.all200,
          bgStyles.textColor,
        )}
      />
      <div
        className={classNames(
          squareStyles.square200,
          borderStyles.radiusRound,
          marginStyles.msNegative050,
          transitionStyles.all200,
          bgStyles.businessBackground,
        )}
      />
    </RawThemedWrapper>
  );
}
