import { SVGProps } from "react";

const SvgHeroLines = (props: SVGProps<SVGSVGElement>) => (
  <svg width={162} height={12} viewBox="0 0 162 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M160 3.364s-48.859.853-76.093-.79C58.781 1.056 2.027 3.771 2.027 3.771M7.505 8.32l39.28 1.483c5.804.219 11.544-.375 17.251-.16 4.417.167 9.079.75 13.46.329 3.31-.318 6.74.455 9.872.102 17.156-1.934 69.434-.17 52.057-.827"
      stroke="#8FC9FA"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgHeroLines;
