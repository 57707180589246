import { Asserts, addMethod, array, boolean, object } from "yup";
import { MinutesNumberValidation } from "./shared";

const TimesSchema = object({
  startMinutes: MinutesNumberValidation,
  endMinutes: MinutesNumberValidation,
});
export type TimesSchemaPayload = Asserts<typeof TimesSchema>;

const isIntersecting = (times: TimesSchemaPayload[], current: TimesSchemaPayload, excludeIndex: number) =>
  times.some(
    (time, index) =>
      current.startMinutes <= time.endMinutes && time.startMinutes <= current.endMinutes && excludeIndex !== index,
  );

const validateRanges = (times: TimesSchemaPayload[]) => {
  let valid = times.length === 0;
  let error = "";

  times.forEach((time: TimesSchemaPayload, index: number) => {
    if (time.startMinutes >= time.endMinutes) {
      error = "Starting hours must be less than ending hours";
      valid = false;
      return;
    }
    if (isIntersecting(times, time, index)) {
      error = "Hour ranges must not intersect";
      valid = false;
      return;
    }
    valid = true;
  });

  return {
    valid,
    error,
  };
};

addMethod(array, "noRangeOverlap", function noRangeOverlap() {
  return this.test("noRangeOverlap", "Ranges should not overlap", function test(value) {
    const { path, createError } = this;

    const { valid, error } = validateRanges(value as TimesSchemaPayload[]);
    return valid || createError({ path, message: error });
  });
});

const ScheduleItem = object({
  on: boolean().required(),
  times: array()
    // @ts-expect-error no noRangeOverlap method
    .noRangeOverlap()
    .of(TimesSchema)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .when("on", { is: true, then: (schema: any) => schema.min(1) }),
});
export type ScheduleItemPayload = Asserts<typeof ScheduleItem>;

export const ScheduleSchema = array().of(ScheduleItem).length(7);
export type SchedulePayload = Asserts<typeof ScheduleSchema>;
