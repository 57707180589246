import type { SVGProps } from "react";
const SvgSocialPinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M1.667 10.667a8.34 8.34 0 0 0 5.368 7.79c-.077-.65-.158-1.724.017-2.477.15-.647.973-4.123.973-4.123s-.248-.497-.248-1.232c0-1.153.668-2.014 1.5-2.014.71 0 1.05.531 1.05 1.168 0 .712-.453 1.776-.687 2.763-.195.825.415 1.499 1.229 1.499 1.474 0 2.608-1.555 2.608-3.8 0-1.986-1.428-3.374-3.466-3.374-2.36 0-3.745 1.77-3.745 3.6 0 .713.274 1.477.617 1.893a.25.25 0 0 1 .058.238c-.063.262-.204.825-.23.94-.037.152-.12.185-.278.111-1.037-.482-1.684-1.996-1.684-3.213 0-2.617 1.901-5.02 5.48-5.02 2.879 0 5.115 2.05 5.115 4.79 0 2.86-1.802 5.161-4.304 5.161-.841 0-1.631-.437-1.902-.953l-.517 1.972c-.195.753-.738 1.706-1.075 2.247a8.334 8.334 0 1 0-5.88-7.966"
    />
  </svg>
);
export default SvgSocialPinterest;
