"use client";

import { DateTime } from "luxon";
import { useMemo, useRef } from "react";
import classNames from "classnames";
import { Card } from "../Card";
import { Description, HeaderMain, TextMain } from "../typography";
import { bgStyles, borderStyles, flexStyles, paddingStyles, pointerStyles, textStyles, widthStyles } from "../styles";
import { useHorizontalScrollIntoView } from "../../hooks";

interface Props {
  onClick: (day: DateTime) => void;
  day: DateTime;
  active: boolean;
  timeZone: string;
}

export function CarouselCard({ timeZone, onClick, day, active }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  useHorizontalScrollIntoView({ ref, trigger: active, offsetAdjustment: -100 });

  const now = DateTime.now().setZone(timeZone);
  const isToday = now.hasSame(day, "day");
  const dayLabel = useMemo(() => {
    if (isToday) {
      return "Today";
    }
    return now.plus({ day: 1 }).hasSame(day, "day") ? "Tomorrow" : day.toLocaleString({ weekday: "long" });
  }, [now, isToday, day]);

  return (
    <Card
      ref={ref}
      className={classNames(
        flexStyles.alignCenter,
        widthStyles.minWidth550,
        paddingStyles.py100,
        borderStyles.none,
        pointerStyles.cursor,
        {
          [textStyles.neutral600]: !isToday && !active,
          [bgStyles.neutral600]: isToday,
          [textStyles.neutral0]: isToday,
          [bgStyles.businessBackground]: active,
          [textStyles.businessTextColor]: active,
        },
      )}
      onClick={() => onClick(day)}
    >
      <Description>{dayLabel}</Description>
      <HeaderMain>{day.day}</HeaderMain>
      <TextMain>{day.toLocaleString({ month: "short" })}</TextMain>
    </Card>
  );
}
