import { PersistedBlock } from "schemas";
import { useLiteSiteStore } from "../store";

export function setBlock(block: PersistedBlock): void {
  switch (block.kind) {
    case "AppointmentBlock":
      useLiteSiteStore.setState(({ blockAppointmentBlocks }) => ({
        blockAppointmentBlocks: blockAppointmentBlocks.set(block._id, block),
      }));
      break;
    case "AppointmentLinkBlock":
      useLiteSiteStore.setState(({ blockAppointmentLinkBlocks }) => ({
        blockAppointmentLinkBlocks: blockAppointmentLinkBlocks.set(block._id, block),
      }));
      break;
    case "EmailBlock":
      useLiteSiteStore.setState(({ blockEmailBlocks }) => ({
        blockEmailBlocks: blockEmailBlocks.set(block._id, block),
      }));
      break;
    case "FormLinkBlock":
      useLiteSiteStore.setState(({ blockFormLinkBlocks }) => ({
        blockFormLinkBlocks: blockFormLinkBlocks.set(block._id, block),
      }));
      break;
    case "ImageBlock":
      useLiteSiteStore.setState(({ blockImageBlocks }) => ({
        blockImageBlocks: blockImageBlocks.set(block._id, block),
      }));
      break;
    case "LinkBlock":
      useLiteSiteStore.setState(({ blockLinkBlocks }) => ({
        blockLinkBlocks: blockLinkBlocks.set(block._id, block),
      }));
      break;
    case "PageBlock":
      useLiteSiteStore.setState(({ blockPageBlocks }) => ({
        blockPageBlocks: blockPageBlocks.set(block._id, block),
      }));
      break;
    case "PhoneBlock":
      useLiteSiteStore.setState(({ blockPhoneBlocks }) => ({
        blockPhoneBlocks: blockPhoneBlocks.set(block._id, block),
      }));
      break;
    case "SocialLinksBlock":
      useLiteSiteStore.setState(({ blockSocialLinksBlocks }) => ({
        blockSocialLinksBlocks: blockSocialLinksBlocks.set(block._id, block),
      }));
      break;
    case "TextBlock":
      useLiteSiteStore.setState(({ blockTextBlocks }) => ({
        blockTextBlocks: blockTextBlocks.set(block._id, block),
      }));
      break;
    case "VideoBlock":
      useLiteSiteStore.setState(({ blockVideoBlocks }) => ({
        blockVideoBlocks: blockVideoBlocks.set(block._id, block),
      }));
      break;
  }
}
