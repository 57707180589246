import classNames from "classnames";
import { Button, TextAccent, bgStyles, flexStyles, paddingStyles } from "ui";
import { stopMasquerading, useCurrentUser } from "../../store";

export function Masquerade() {
  const currentUser = useCurrentUser();

  if (!currentUser?.impersonated) {
    return null;
  }

  return (
    <div className={classNames(bgStyles.warningMedium, paddingStyles.p125, flexStyles.horizApart)}>
      <TextAccent>Admin impersonation in progress</TextAccent>
      <Button onClick={stopMasquerading}>Stop Masquerading</Button>
    </div>
  );
}
