import { apiRequest } from "../apiRequest";

type EntityTypes = "page" | "form" | "appointment" | "business";
const EntityName: Record<EntityTypes, string> = {
  page: "pages",
  form: "forms",
  appointment: "appointments",
  business: "business",
};

export async function checkEntityLink(
  params: {
    type: EntityTypes;
    link: string;
    id?: string;
  },
  abortController?: AbortController,
) {
  const urlParams = new URLSearchParams(params);
  try {
    const { available } = await apiRequest<{ available: boolean }>({
      url: `${EntityName[params.type]}/link?${urlParams.toString()}`,
      signal: abortController?.signal,
    });

    return available;
  } catch (error) {
    if ((error as Error).name !== "CanceledError") {
      throw error;
    }

    return true;
  }
}
