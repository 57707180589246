import { PersistedFormAnswer } from "schemas";
import { Description, DescriptionItalic, textStyles } from "ui";
import { answerToComponent } from "../../helpers/answerToComponent";

interface Props {
  formAnswer: PersistedFormAnswer;
}
export function FormAnswer({ formAnswer }: Props) {
  return formAnswer.answers?.map((answer, index) => (
    <div key={`answer_${index}`}>
      <Description>{answer.question}</Description>
      {answer.answer ? (
        answerToComponent(answer.questionType, answer.answer as string)
      ) : (
        <DescriptionItalic className={textStyles.neutral300}>No answer provided</DescriptionItalic>
      )}
    </div>
  ));
}
