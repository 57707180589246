"use client";

import { useFormContext, useWatch } from "react-hook-form";
import { Label } from "../Label";
import { flexStyles } from "../../styles";
import classNames from "classnames";
import { SelectOption } from "./SelectOption";
import { AvailableValues } from "./AvailableValues";
import { Props } from "./Props";
import { BaseSelect } from "./BaseSelect";

export function Select<T extends AvailableValues>({ className, options, label, name, raw, placeholder }: Props<T>) {
  const { setValue } = useFormContext();
  const value = useWatch({ name });

  const onChange = (value: SelectOption<T> | null) => {
    setValue(name, value?.value, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <div className={classNames(flexStyles.vert025, className)}>
      {label && <Label name={name}>{label}</Label>}
      <BaseSelect value={value} options={options} placeholder={placeholder} onChange={onChange} raw={raw} />
    </div>
  );
}
