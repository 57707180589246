import { ReactElement } from "react";
import {
  Loader,
  Offsetino,
  SimpleTable,
  SmallTextMain,
  SubheaderMain,
  TextAccent,
  flexStyles,
  paddingStyles,
  textStyles,
} from "ui";
import classNames from "classnames";
import { Button } from "./Button";
import { HomeWrapper } from "./HomeWrapper";

interface Props {
  header: string;
  description: ReactElement;
  why: string;
  tableRows: string[];
  minutes: number;
  cta: string;
  continueTo?: string;
  cancelTo: string;
}

export function Primer({ header, description, why, tableRows, minutes, cta, continueTo, cancelTo }: Props) {
  return (
    <HomeWrapper>
      <div className={classNames(flexStyles.vert050, flexStyles.grow, paddingStyles.py200, paddingStyles.px125)}>
        <SubheaderMain>{header}</SubheaderMain>
        {description}
      </div>
      <TextAccent className={classNames(paddingStyles.px125, paddingStyles.pb100)}>{why}</TextAccent>
      <SimpleTable rows={tableRows} />
      <Offsetino />
      <div className={classNames(flexStyles.vert100, paddingStyles.py200, paddingStyles.px125)}>
        <SmallTextMain className={textStyles.center}>
          {minutes < 6 ? `It takes about ${minutes} minutes.` : `It shouldn't take more than ${minutes} minutes.`}
        </SmallTextMain>
        {continueTo ? <Button to={continueTo}>{cta}</Button> : <Loader />}
        <Button buttonType="secondary" to={cancelTo}>
          Cancel
        </Button>
      </div>
    </HomeWrapper>
  );
}
