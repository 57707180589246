"use client";

import { yupResolver } from "@hookform/resolvers/yup";
import { PropsWithChildren } from "react";
import { DefaultValues, FormProvider as HookFormProvider, useForm } from "react-hook-form";

interface Props<T> extends PropsWithChildren {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any;
  defaultValues: DefaultValues<T>;
}

export function FormProvider<T extends object>({ children, schema, defaultValues }: Props<T>) {
  const form = useForm<T>({
    defaultValues,
    resolver: schema && yupResolver(schema),
    mode: "onTouched",
  });

  return <HookFormProvider {...form}>{children}</HookFormProvider>;
}
