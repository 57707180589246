"use client";

import { ThemePayload, businessThemeObject } from "schemas";
import { PropsWithClassNameAndChildren } from "../types";
import classNames from "classnames";
import { bgStyles } from "../styles";
import { CSSProperties } from "react";

interface Props extends PropsWithClassNameAndChildren {
  theme: ThemePayload;
  onClick?: (theme: ThemePayload) => void;
}
export function ClickableThemedWrapper({ theme, children, className, onClick }: Props) {
  return (
    <div
      onClick={() => onClick && onClick(theme)}
      className={classNames(bgStyles.businessBackground, className)}
      style={businessThemeObject(theme) as CSSProperties}
    >
      {children}
    </div>
  );
}
