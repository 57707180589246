import { SVGProps } from "react";

const SvgReminder = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.701 6.634a.8.8 0 0 1 1.131 0L23.898 7.7l.001.001 1.067 1.067a.8.8 0 1 1-1.132 1.131l-.497-.497-.854.865a9.333 9.333 0 0 1-7.15 15.332 9.333 9.333 0 1 1 6.02-16.465l.853-.864-.505-.505a.8.8 0 0 1 0-1.131Zm-14.06 8.833H8.4a.8.8 0 1 1 0 1.6h-.76a7.736 7.736 0 0 0 6.893 6.892v-.76a.8.8 0 0 1 1.6 0v.76a7.736 7.736 0 0 0 6.892-6.892h-.759a.8.8 0 0 1 0-1.6h.76a7.708 7.708 0 0 0-2.213-4.657 7.706 7.706 0 0 0-4.68-2.236v.759a.8.8 0 0 1-1.6 0v-.76a7.736 7.736 0 0 0-6.892 6.893Z"
      fill="#F0A056"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.756 12.401a.8.8 0 0 1 1.11.222l2.133 3.2a.8.8 0 0 1-1.331.887l-2.134-3.2a.8.8 0 0 1 .222-1.109Z"
      fill="#F0A056"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.334 10.667a.8.8 0 0 1 .8.8v4.8a.8.8 0 0 1-1.6 0v-4.8a.8.8 0 0 1 .8-.8Z"
      fill="#F0A056"
    />
  </svg>
);
export default SvgReminder;
