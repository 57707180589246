import { useCurrentUser } from "../../store";
import { DesktopLink } from "./DesktopLink";

export function User() {
  const user = useCurrentUser();

  if (!user) {
    return null;
  }

  return <DesktopLink animationType="DesktopMenuAccount" href="/more" label={user.name || user.email} />;
}
