import { Asserts, object, string } from "yup";
import { PromptTypeValues } from "../../enums";

export const BasePromptSchema = object({
  type: string().oneOf(PromptTypeValues).required(),
});
export type BasePromptPayload = Asserts<typeof BasePromptSchema>;

export type PromptMessage = {
  role: "system" | "user" | "assistant";
  content: string;
  parsedContent?: object;
};
