import { useEffect } from "react";
import { useLiteSiteStore } from "..";
import { apiRequest } from "../apiRequest";

export function useZoomUrl() {
  useEffect(() => {
    getZoomUrl();
  }, []);

  return useLiteSiteStore((state) => state.zoomUrl);
}

const getZoomUrl = async () => {
  const { url: zoomUrl } = await apiRequest<{ url: string }>({ url: "zoom/url" });

  useLiteSiteStore.setState({
    zoomUrl,
  });
};
