import type { SVGProps } from "react";
const SvgSocialVimeo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <g clipPath="url(#SocialVimeo_svg__a)">
      <path
        fill="#fff"
        d="M19.99 5.922q-.133 2.917-4.077 7.99-4.08 5.305-6.903 5.305-1.75 0-2.959-3.23L4.437 10.07q-.9-3.229-1.928-3.228-.222.002-1.568.942L0 6.572q1.468-1.29 2.918-2.602 1.976-1.705 2.966-1.796 2.33-.23 2.872 3.191.582 3.69.808 4.583.676 3.058 1.482 3.056.626 0 1.885-1.984t1.347-3.018q.18-1.713-1.347-1.713a3.8 3.8 0 0 0-1.481.326q1.479-4.823 5.635-4.695 3.083.095 2.905 4z"
      />
    </g>
    <defs>
      <clipPath id="SocialVimeo_svg__a">
        <path fill="#fff" d="M0 .667h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSocialVimeo;
