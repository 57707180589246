import { PropsWithChildren } from "react";
import { flexStyles, marginStyles, paddingStyles, widthStyles } from "../styles";
import { PropsWithClassName } from "../types";
import classNames from "classnames";

interface Props extends PropsWithClassName, PropsWithChildren {}
export function ContentWrapper({ children, className }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.vert,
        widthStyles.maxWidth4225,
        widthStyles.full,
        marginStyles.centered,
        paddingStyles.p125,
        paddingStyles.pb200,
        className,
      )}
    >
      {children}
    </div>
  );
}
