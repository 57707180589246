import { Asserts, object, string } from "yup";
import { LinkValidation } from "./Link";
import { PersistedBlock } from "./blocks";

const BasePageSchema = object({
  name: string().required("Page name is required"),
  description: string().nullable(),
}).noUnknown();

export const HomePageSchema = BasePageSchema.shape({
  handle: string().required().oneOf(["/"]),
});

export const PageSchema = BasePageSchema.shape({
  handle: LinkValidation.required("Link is required"),
});

export type PagePayload = Asserts<typeof PageSchema>;

export type PersistedPage = PagePayload & {
  id: string;
  url: string;
  themeId: string;
};

export type PageWithBlocks = Omit<Omit<PersistedPage, "blocks">, "themeId"> & {
  blocks: PersistedBlock[];
};

export const EmptyPage: PagePayload = {
  handle: "",
  description: "",
  name: "",
};
