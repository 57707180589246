import { Loader } from "ui";
import { useContactBookings } from "../../../store";
import { BookingsList } from "./BookingsList";

interface Props {
  id: string;
}
export function Bookings({ id }: Props) {
  const contactBookings = useContactBookings(id);
  if (!contactBookings) {
    return <Loader />;
  }

  return (
    <>
      <BookingsList bookings={contactBookings.futureBookings} label="Upcoming Appointments" />
      <BookingsList bookings={contactBookings.pastBookings} label="Past Appointments" />
    </>
  );
}
