import { useEffect, useState } from "react";
import { Loader } from "ui";
import { PersistedContact, PersistedContactGroup } from "schemas";
import { ContactsList } from "./ContactsList";
import { searchContacts } from "../../store";
import { BulkContactActions } from "./BulkContactActions";

interface Props {
  query: string;
  name?: string;
  contactGroup: PersistedContactGroup;
  hideInitials?: boolean;
  disabledIds?: string[];
}
export function SearchContacts({ query, name, contactGroup, hideInitials, disabledIds = [] }: Props) {
  const [contacts, setContacts] = useState<PersistedContact[]>([]);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (query) {
      setLoading(true);
      searchContacts({ contactGroupId: contactGroup.id, query })
        .then(setContacts)
        .finally(() => setLoading(false));
    }
  }, [contactGroup.id, query, refresh]);

  if (!query) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <BulkContactActions contactIds={contacts.map((c) => c.id)} onDone={() => setRefresh(refresh + 1)} />
      <ContactsList
        newContactTo="../contacts/new"
        disabledIds={disabledIds}
        hideInitials={hideInitials}
        contacts={contacts}
        name={name}
        query={query}
      />
    </>
  );
}
