import { Asserts, array, boolean, object, string } from "yup";
import { FormQuestionType } from "../enums";
import { FormQuestionTypeSchema } from "./shared";

export const FormQuestionSchema = object({
  question: string().required("Question is required"),
  required: boolean(),
  possibleAnswers: array()
    .of(string().required("Fill in an option"))
    .when("questionType", {
      is: "SingleChoice",
      then: (schema) => schema.required("Possible answers are required").min(2),
      otherwise: (schema) => schema.optional(),
    }),
  otherOption: boolean().default(false).optional(),
  questionType: FormQuestionTypeSchema,
});
export type FormQuestionPayload = Asserts<typeof FormQuestionSchema>;
export type FormQuestionWithId = FormQuestionPayload & {
  _id: string;
};

export function getEmptyQuestion(questionType: FormQuestionType): FormQuestionPayload {
  const baseQuestion = { questionType, question: "", required: false, otherOption: false };
  switch (questionType) {
    case "SingleChoice":
    case "MultipleChoice":
      return { ...baseQuestion, possibleAnswers: ["", ""] };
    default:
      return baseQuestion;
  }
}
