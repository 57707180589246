import { SVGProps } from "react";
const SvgFormFileField = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="FormFileField_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#FormFileField_svg__a)">
      <path
        d="M9.125 14.167h1.75v-3.333l1.292 1.291 1.23-1.229L10 7.5l-3.396 3.396 1.23 1.23 1.291-1.292v3.333Zm-5.708 2.5c-.481 0-.893-.172-1.236-.514a1.685 1.685 0 0 1-.514-1.236V5.083c0-.48.171-.893.514-1.235a1.685 1.685 0 0 1 1.236-.514H8.25L10 5.083h6.584c.48 0 .893.17 1.236.514.342.342.514.754.514 1.236v8.083c0 .481-.172.893-.514 1.236a1.685 1.685 0 0 1-1.236.514H3.417Zm0-11.584v9.834h13.167V6.833H9.27l-1.75-1.75H3.417Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgFormFileField;
