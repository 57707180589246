import { PersistedForm } from "schemas";
import { useLiteSiteStore } from "../store";
import { setForm } from "./setForm";
import { useEffect, useState } from "react";
import { apiRequest } from "../apiRequest";
import { useIdParam } from "../../hooks";
import { HookLoading } from "../types";

export async function getForm(id: string) {
  try {
    const form = await apiRequest<PersistedForm>({ url: `forms/${id}` });
    setForm(form);
  } catch {
    // not found
  }
}

export function useForm(passedId?: string): HookLoading<{ form?: PersistedForm }> {
  const [loading, setLoading] = useState<boolean>(true);
  const pathId = useIdParam("formId");
  const id = passedId || pathId;

  useEffect(() => {
    if (id) {
      if (loading) {
        getForm(id).finally(() => setLoading(false));
      }
    } else {
      setLoading(false);
    }
  }, [id, loading]);

  const formsMap = useLiteSiteStore(({ forms }) => forms);
  const form = id ? formsMap.get(id) : undefined;
  return { loading, form };
}
