import { useLocalStorage } from "react-use";

export function useZoomSetupRedirect() {
  const [zoomSetupRedirect, setZoomSetupRedirect] = useLocalStorage<string>("zoom-setup-redirect");

  return {
    zoomSetupRedirect,
    setZoomSetupRedirect,
  };
}
