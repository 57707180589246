import { Business, Calendar, CalendarAvailability, CalendarSettings, Home } from "../components/settings";
import { TimezoneForm } from "../components/business";
import { PrivateOutlet } from "./PrivateOutlet";

export const SettingsRoutes = [
  {
    path: "more",
    Component: PrivateOutlet,
    children: [
      {
        path: "",
        Component: Home,
      },
    ],
  },
  {
    path: "settings",
    Component: PrivateOutlet,
    children: [
      {
        path: "business",
        Component: Business,
      },
      {
        path: "business/timezone",
        Component: TimezoneForm,
      },
      {
        path: "calendars",
        Component: CalendarSettings,
      },
      {
        path: "calendars/synced",
        Component: Calendar,
      },
      {
        path: "calendars/availability",
        Component: CalendarAvailability,
      },
    ],
  },
];
