import { TimesSchemaPayload } from "schemas";
import { IconButton, SelectOption, TimeSelect, flexStyles } from "ui";
import classNames from "classnames";
import { ChangeType } from "./ChangeType";

interface Props {
  time: TimesSchemaPayload;
  index: number;
  onDelete: (() => Promise<void>) | null;
  onAdd: () => Promise<void>;
  onChange: (field: ChangeType, value: number) => void;
}

export function Time({ index, time: { startMinutes, endMinutes }, onDelete, onAdd, onChange }: Props) {
  const onChangeStartMinutes = (value: SelectOption | null) => {
    onChange("startMinutes", value!.value);
  };
  const onChangeEndMinutes = (value: SelectOption | null) => {
    onChange("endMinutes", value!.value);
  };
  return (
    <div className={classNames(flexStyles.horiz050, flexStyles.alignCenter)}>
      <TimeSelect onChange={onChangeStartMinutes} value={startMinutes} />
      -
      <TimeSelect onChange={onChangeEndMinutes} value={endMinutes} />
      <IconButton iconType="Add" onClick={onAdd} />
      <IconButton
        style={{ visibility: !!onDelete && index > 0 ? "visible" : "hidden" }}
        iconType="Remove"
        onClick={onDelete || (async () => {})}
      />
    </div>
  );
}
