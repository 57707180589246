import { SVGProps } from "react";

const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Mail_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Mail_svg__a)">
      <path
        d="M3.5 16c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 2 14.5v-9c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 3.5 4h13c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v9c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 16.5 16h-13Zm6.5-5L3.5 7.271V14.5h13V7.271L10 11Zm0-1.771L16.5 5.5h-13L10 9.229ZM3.5 7.271V5.5v9-7.229Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgMail;
