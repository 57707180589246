import { Asserts, object, string } from "yup";

export const LinkBlockSchema = object({
  label: string().required("Label is required"),
  url: string()
    .required("Button has to link somewhere")
    .matches(/^(?=\S*\.)\S*$/, "Must be a valid url"),
  kind: string().oneOf(["LinkBlock"]).required(),
}).noUnknown();

export type LinkBlockPayload = Asserts<typeof LinkBlockSchema> & {
  kind: "LinkBlock";
};
export type PersistedLinkBlock = LinkBlockPayload & {
  _id: string;
  buttonUrl: string;
};
