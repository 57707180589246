import classNames from "classnames";
import { OnClickHandler, PropsWithClassNameAndChildren } from "./types";
import { IconButton } from "./IconButton";
import { SmallTextMain } from "./typography";
import { bgStyles, flexStyles, heightStyles, positionStyles, textStyles, widthStyles } from "./styles";

interface Props extends PropsWithClassNameAndChildren {
  onClose: OnClickHandler;
  header?: string;
}
export function Modal({ className, children, onClose, header }: Props) {
  return (
    <div
      className={classNames(
        bgStyles.neutral100,
        flexStyles.vert200,
        flexStyles.alignCenter,
        textStyles.center,
        heightStyles.minHeightFull,
        className,
      )}
    >
      <div
        className={classNames(
          positionStyles.relative,
          flexStyles.vert,
          flexStyles.justifyCenter,
          heightStyles.height325,
          widthStyles.full,
          widthStyles.maxWidth4225,
        )}
      >
        {header && <SmallTextMain>{header}</SmallTextMain>}
        <IconButton
          className={classNames(positionStyles.absolute, positionStyles.zIndex1, positionStyles.right125)}
          iconType="X"
          onClick={onClose}
        />
      </div>
      {children}
    </div>
  );
}
