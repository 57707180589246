"use client";

import { ReactNode, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import classNames from "classnames";
import { Label } from "./Label";
import { Icon, IconType } from "../Icon";
import {
  bgStyles,
  borderStyles,
  displayStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  paddingStyles,
  pointerStyles,
  textStyles,
  widthStyles,
} from "../styles";

type AvailableValues = string | boolean;
export interface RadioOption<T> {
  label: string;
  value: T;
  contents?: ReactNode;
  iconType?: IconType;
}

interface Props<T> {
  name: string;
  options: RadioOption<T>[];
  label?: string;
  className?: string;
}

export function Radio<T extends AvailableValues>({ name, options, label, className }: Props<T>) {
  const { register, setValue } = useFormContext();
  const value = useWatch({ name });

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <div className={className}>
      {label && <Label name={name}>{label}</Label>}
      <div
        className={classNames(
          flexStyles.horiz025,
          flexStyles.justifyStretch,
          paddingStyles.p025,
          heightStyles.height300,
          borderStyles.neutral200,
          borderStyles.radius025,
          bgStyles.neutral0,
        )}
      >
        {options.map(({ label, value: optionValue, iconType }: RadioOption<T>) => {
          const checked = value === optionValue;
          const onChange = () => {
            setValue(name, optionValue);
          };

          return (
            <label
              className={classNames(
                flexStyles.grow,
                flexStyles.horiz025,
                flexStyles.alignCenter,
                flexStyles.justifyCenter,
                textStyles.size087,
                pointerStyles.cursor,
                textStyles.weight600,
                textStyles.lsColor,
                borderStyles.radius010,
                {
                  [iconStyles.neutral600]: !checked,
                  [textStyles.neutral600]: !checked,
                  [iconStyles.neutral0]: checked,
                  [bgStyles.secondary300]: checked,
                  [textStyles.neutral0]: checked,
                },
              )}
              key={label}
            >
              <input
                className={classNames(displayStyles.visibilityHidden, widthStyles.width0, heightStyles.height0)}
                type="radio"
                value={optionValue.toString()}
                checked={checked}
                onChange={onChange}
              />
              {iconType && <Icon iconType={iconType} />}
              {label}
            </label>
          );
        })}
      </div>
    </div>
  );
}
