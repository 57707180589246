import type { SVGProps } from "react";
const SvgSocialLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M6.113 4.563c0 .863-.694 1.562-1.55 1.562s-1.55-.7-1.55-1.562S3.706 3 4.563 3c.856 0 1.55.7 1.55 1.563m.012 2.812H3v10h3.125zm4.989 0H8.009v10h3.105v-5.25c0-2.918 3.768-3.157 3.768 0v5.25H18v-6.332c0-4.925-5.576-4.745-6.886-2.321z"
    />
  </svg>
);
export default SvgSocialLinkedin;
