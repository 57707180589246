import type { SVGProps } from "react";
const SvgSocialSlack = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M7.778 2a1.668 1.668 0 0 0-1.18 2.85 1.67 1.67 0 0 0 1.18.49h1.666V3.67A1.67 1.67 0 0 0 7.778 2m0 4.453H3.333c-.92 0-1.666.748-1.666 1.67s.746 1.67 1.666 1.67h4.445c.92 0 1.666-.748 1.666-1.67s-.746-1.67-1.666-1.67M18.333 8.123c0-.922-.746-1.67-1.666-1.67-.921 0-1.667.748-1.667 1.67v1.67h1.666a1.67 1.67 0 0 0 1.667-1.67m-4.444 0V3.67A1.67 1.67 0 0 0 12.222 2c-.92 0-1.666.748-1.666 1.67v4.453a1.668 1.668 0 0 0 2.845 1.18 1.67 1.67 0 0 0 .488-1.18M12.222 18.7a1.669 1.669 0 0 0 0-3.34h-1.666v1.67c0 .922.746 1.67 1.666 1.67m0-4.453h4.445a1.669 1.669 0 0 0 1.179-2.85 1.67 1.67 0 0 0-1.18-.49h-4.444a1.668 1.668 0 0 0-1.18 2.85 1.67 1.67 0 0 0 1.18.49M1.667 12.576a1.67 1.67 0 0 0 1.666 1.67A1.67 1.67 0 0 0 5 12.576v-1.67H3.333c-.92 0-1.666.748-1.666 1.67m4.444 0v4.453a1.668 1.668 0 0 0 2.846 1.18c.312-.312.488-.737.487-1.18v-4.453a1.67 1.67 0 0 0-1.666-1.67c-.92 0-1.667.748-1.667 1.67"
    />
  </svg>
);
export default SvgSocialSlack;
