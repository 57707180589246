import { useEffect, useState } from "react";
import { apiRequest } from "../apiRequest";

async function fetchContactsLimit(quantity?: number): Promise<boolean> {
  const params = new URLSearchParams(quantity ? { quantity: quantity.toString() } : {});
  const { overLimit } = await apiRequest<{ overLimit: boolean }>({ url: `contacts/limit?${params.toString()}` });

  return overLimit;
}

export function useOverContactsLimit(quantity?: number): boolean | undefined {
  const [overLimit, setOverLimit] = useState<boolean>();

  useEffect(() => {
    if (quantity !== undefined) {
      fetchContactsLimit(quantity).then(setOverLimit);
    }
  }, [quantity]);

  return overLimit;
}
