import { BlockDestination, BlockPayload, PersistedBlock } from "schemas";
import { setBlock } from "./setBlock";
import { apiRequest } from "../apiRequest";
import { refetchParent } from "./refetchParent";
import { withHttp } from "ui";

interface Args {
  destinationIndex: number;
  destination: BlockDestination;
  payload: BlockPayload;
}
export async function addBlock({ destinationIndex, destination, payload }: Args) {
  const destinationId = destination.entity.id;

  const extendedPayload = payload.kind === "LinkBlock" ? { ...payload, url: withHttp(payload.url) } : payload;
  const block = await apiRequest<PersistedBlock>({
    url: "blocks",
    method: "POST",
    body: JSON.stringify({
      block: extendedPayload,
      destination: {
        destinationIndex,
        destinationType: destination.type,
        destinationId: destinationId,
      },
    }),
  });
  setBlock(block);
  refetchParent(destination);
}
