import { Content, Edit, New } from "../components/pages";
import { OutletBlockRoutes, StandaloneBlockRoutes } from "./Blocks";
import { PrivateOutlet } from "./PrivateOutlet";

export const PagesRoutes = {
  path: "pages",
  Component: PrivateOutlet,
  children: [
    { path: "new/:destinationIndex", Component: New },
    {
      path: ":pageId",
      children: [
        { path: "", Component: Content, children: [OutletBlockRoutes] },
        { path: "", children: [StandaloneBlockRoutes] },
        { path: "edit", Component: Edit },
      ],
    },
  ],
};
