import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ScheduleItemPayload, SchedulePayload, TimesSchemaPayload } from "schemas";
import { DayNames, flexStyles } from "ui";
import classNames from "classnames";
import { Link } from "../Link";
import { Day } from "./Day";
import { uniqWith } from "es-toolkit";

interface Props {
  name: string;
}
export function Days({ name }: Props) {
  const { setValue } = useFormContext();
  const values = useWatch();
  const value = values[name] as SchedulePayload;
  const firstOn = value!.filter((item: ScheduleItemPayload) => item.on)[0];
  const reducedTimes = value
    ? uniqWith(
        value?.map((item: ScheduleItemPayload) =>
          item.times
            .map((time: TimesSchemaPayload) => [time.startMinutes, time.endMinutes])
            .flat()
            .sort((a: number, b: number) => b > a),
        ),
        (a, b) => a === b,
      )
    : [];

  const [expanded, setExpanded] = useState<boolean>(reducedTimes.length > 1);
  const expandedLabel = expanded ? "Reset" : "Customize each day";

  const collapse = () => {
    setValue(
      name,
      value!.map((item: ScheduleItemPayload) => ({
        ...item,
        times: firstOn.times,
      })),
    );
    setExpanded(false);
  };
  const expandHandler = expanded ? collapse : () => setExpanded(true);

  return (
    <>
      <div className={classNames(flexStyles.vert100, flexStyles.alignStart)}>
        {expanded ? (
          value!.map((item: ScheduleItemPayload, index: number) =>
            item.on ? <Day key={`Day-${index}`} label={DayNames[index]} name={name} item={item} index={index} /> : null,
          )
        ) : (
          <Day label="Available hours" name={name} item={firstOn} />
        )}
      </div>
      <Link onClick={expandHandler}>{expandedLabel}</Link>
    </>
  );
}
