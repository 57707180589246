import { SharedBusiness } from "schemas";
import classNames from "classnames";
import { TextMain } from "./typography";
import { Icon } from "./Icon";
import { Button } from "./Button";
import { PopoverLinkItem, PopoverMenu } from "./PopoverMenu";
import { OnClickHandler, PropsWithClassName, PropsWithOptionalClick } from "./types";
import styles from "./GetYourLiteSite.module.css";
import {
  bgStyles,
  flexStyles,
  iconStyles,
  imageStyles,
  overflowStyles,
  paddingStyles,
  positionStyles,
  textStyles,
} from "./styles";

interface Props extends PropsWithClassName, PropsWithOptionalClick {
  business: SharedBusiness;
  onHide?: OnClickHandler;
  disabled?: boolean;
  logoPath: string;
}
export function GetYourLiteSite({ className, business, onClick, logoPath, onHide, disabled = false }: Props) {
  if (business.paidFeatures.noBranding) {
    return null;
  }

  const link: PopoverLinkItem = {
    onClick: onHide || (async () => {}),
    label: "Hide",
    iconType: "Hide",
  };

  return (
    <div
      className={classNames(
        bgStyles.callout,
        paddingStyles.p200,
        paddingStyles.px100,
        positionStyles.relative,
        overflowStyles.hidden,
        textStyles.callout,
        textStyles.center,
        flexStyles.alignSelfStretch,
        flexStyles.vert100,
        flexStyles.alignCenter,
        className,
      )}
    >
      <Icon
        className={classNames(positionStyles.absolute, iconStyles.callout, styles.stars)}
        iconType="GetYoursStars"
      />
      <Icon
        className={classNames(positionStyles.absolute, iconStyles.callout, styles.twirl)}
        iconType="GetYoursTwirl"
      />
      <img alt="PopSend Logo" width="80" height="24" className={imageStyles.fitContain} src={logoPath} />
      <TextMain>Really simple email newsletters</TextMain>
      {onClick && (
        <Button disabled={disabled} onClick={onClick}>
          Get started for free
        </Button>
      )}
      {onHide && <PopoverMenu open links={[link]} />}
    </div>
  );
}
