import {
  BlockPayload,
  EmailBlockPayload,
  EmailBlockSchema,
  ImageBlockPayload,
  ImageBlockSchema,
  InlineEditableBlockKindValues,
  InlineEditableBlockKinds,
  LinkBlockPayload,
  LinkBlockSchema,
  PhoneBlockPayload,
  PhoneBlockSchema,
  Schema,
  TextBlockPayload,
  TextBlockSchema,
  VideoBlockPayload,
  VideoBlockSchema,
} from "schemas";
import { FC } from "react";
import { TextEditor } from "./TextEditor";
import { ImageEditor } from "./ImageEditor";
import { VideoLinkEditor } from "./VideoLinkEditor";
import { LinkBlockEditor } from "./LinkBlockEditor";
import { PhoneBlockEditor } from "./PhoneBlockEditor";
import { EmailBlockEditor } from "./EmailBlockEditor";
import { Form } from "./Form";
import { PropsWithDirtyBlock } from "../../../helpers";
import { DirtyBlockLock } from "./DirtyBlockLock";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Components: Record<InlineEditableBlockKinds, FC<any>> = {
  TextBlock: TextEditor,
  ImageBlock: ImageEditor,
  VideoLinkBlock: VideoLinkEditor,
  VideoBlock: VideoLinkEditor,
  LinkBlock: LinkBlockEditor,
  PhoneBlock: PhoneBlockEditor,
  EmailBlock: EmailBlockEditor,
};
const Schemas: Record<InlineEditableBlockKinds, Schema> = {
  TextBlock: TextBlockSchema,
  ImageBlock: ImageBlockSchema,
  VideoLinkBlock: VideoBlockSchema,
  VideoBlock: VideoBlockSchema,
  LinkBlock: LinkBlockSchema,
  PhoneBlock: PhoneBlockSchema,
  EmailBlock: EmailBlockSchema,
};
type TypeMap = {
  TextBlock: TextBlockPayload;
  ImageBlock: ImageBlockPayload;
  VideoLinkBlock: VideoBlockPayload;
  VideoBlock: VideoBlockPayload;
  LinkBlock: LinkBlockPayload;
  PhoneBlock: PhoneBlockPayload;
  EmailBlock: EmailBlockPayload;
};

interface Props extends PropsWithDirtyBlock {
  block: BlockPayload;
  onSubmit: (payload: BlockPayload) => Promise<void>;
  onDelete?: () => Promise<void>;
  onCancel: () => void;
}
export function InlineEditor({ block, onSubmit, onDelete, onCancel, dirtyBlock, setDirtyBlock }: Props) {
  if (!InlineEditableBlockKindValues.includes(block.kind)) {
    return null;
  }

  const kind = block.kind as InlineEditableBlockKinds;

  const schema = Schemas[kind];
  const Editor = Components[kind as InlineEditableBlockKinds];

  return (
    <Form<TypeMap[typeof kind]>
      schema={schema}
      defaultValues={block as TypeMap[typeof kind]}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onDelete={onDelete}
    >
      <DirtyBlockLock dirtyBlock={dirtyBlock} setDirtyBlock={setDirtyBlock} />
      <Editor onSubmit={onSubmit} />
    </Form>
  );
}
