import { PersistedCampaign } from "schemas";
import { toast } from "react-toastify";
import { apiRequest } from "../apiRequest";

export async function sendTestCampaign(id: string) {
  const campaign = await apiRequest<PersistedCampaign>({ url: `campaigns/${id}/sendTest`, method: "PUT" });

  toast.success("Test campaign sent, check your email.");

  return campaign;
}
