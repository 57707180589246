import classNames from "classnames";
import { PersistedContact } from "schemas";
import {
  Icon,
  ResponsiveWrapper,
  SmallTextMain,
  bgStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  useConfirm,
} from "ui";
import { Button } from "../ui";
import { resendConfirmationEmail } from "../../store";
import { toast } from "react-toastify";
import { pluralize } from "../../helpers";

interface Props {
  contacts: PersistedContact[];
}
export function ConfirmCallout({ contacts }: Props) {
  const { confirm, Confirm } = useConfirm();
  const contactCount = contacts.length;

  if (contacts.length === 0) {
    return null;
  }

  const onClick = async () => {
    confirm({
      label: `Send ${contactCount} emails`,
      text: `You are about to re-send a confirmation email to ${contactCount} people. Are you sure?`,
      onConfirm: async () => {
        await resendConfirmationEmail(contacts.map((c) => c.id));
        toast.success("Confirmation emails sent successfully");
      },
    });
  };

  return (
    <div className={classNames(bgStyles.secondary200)}>
      <Confirm />
      <ResponsiveWrapper
        className={classNames(flexStyles.vert125, flexStyles.alignStart, paddingStyles.p125, iconStyles.secondary400)}
      >
        <Icon iconType="EmailUnsubscribe" />
        <SmallTextMain>
          {contacts.length > 1 ? "Contacts on this list have" : "This contact has"} not approved to receive emails yet.
          Re-send a confirmation email.
        </SmallTextMain>
        <Button onClick={onClick}>Send confirmation {pluralize("email", contacts.length)}</Button>
      </ResponsiveWrapper>
    </div>
  );
}
