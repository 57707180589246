import classNames from "classnames";
import { Description } from "../typography";
import { IconButton } from "../IconButton";
import { flexStyles, paddingStyles } from "../styles";
import { OnClickHandler } from "../types";

interface Props {
  title: string;
  onClose: OnClickHandler;
}
export function Header({ title, onClose }: Props) {
  return (
    <div className={classNames(paddingStyles.p125, paddingStyles.pb100, flexStyles.horizApart)}>
      <Description>{title}</Description>
      <IconButton iconType="X" onClick={onClose} />
    </div>
  );
}
