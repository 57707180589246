import { HugeAccent, SimpleTable, SmallTextMain, TextMain, flexStyles } from "ui";
import { toast } from "react-toastify";
import classNames from "classnames";
import { Button } from "../ui";
import { postCheckoutSession } from "../../store";

const ProFeatures = [
  "Up to 2500 contacts, $10 per each next 2500",
  "Brand Customization",
  "Payment Up-front",
  "No PopSend Badge",
];
const MoreFeatures = ["SMS Automations", "32 Themes", "Email Automations"];

const { REACT_APP_BUSINESS_MONTHLY_STRIPE_PRICE_ID } = import.meta.env;

export function PlanSelector() {
  const onSubmit = async () => {
    try {
      const url = await postCheckoutSession(REACT_APP_BUSINESS_MONTHLY_STRIPE_PRICE_ID);
      window.location.href = url;
    } catch {
      toast.error("Something went wrong. Please try again.");
    }
  };
  return (
    <div className={flexStyles.vert125}>
      <div className={classNames(flexStyles.vert025)}>
        <HugeAccent>$16</HugeAccent>
        <TextMain>per month</TextMain>
      </div>
      <SimpleTable more={MoreFeatures} moreLabel="Other PopSend Features" rows={ProFeatures} />
      <SmallTextMain>Cancel anytime</SmallTextMain>
      <Button onClick={onSubmit}>Continue</Button>
    </div>
  );
}
