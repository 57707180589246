import { SVGProps } from "react";
const SvgFormNumberField = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="FormNumberField_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#FormNumberField_svg__a)">
      <path
        d="m5.5 16 .625-2.5H3l.375-1.5H6.5l.875-3.5H4L4.375 7H7.75l.75-3H10l-.75 3h3L13 4h1.5l-.75 3H17l-.375 1.5h-3.25L12.5 12H16l-.375 1.5h-3.5L11.5 16H10l.625-2.5h-3L7 16H5.5ZM8 12h3l.875-3.5h-3L8 12Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgFormNumberField;
