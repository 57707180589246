import { LoginPayload, UserPayload } from "schemas";
import { apiRequest } from "../apiRequest";

interface Args {
  payload: LoginPayload;
}
export async function login({ payload }: Args): Promise<UserPayload | null> {
  try {
    const user = await apiRequest<UserPayload>({ url: "auth/login", method: "POST", body: JSON.stringify(payload) });
    return user;
  } catch (e) {
    const error = new Error();
    // @ts-expect-error errors
    error.message = e.response.data.error;
    throw error;
  }
}
