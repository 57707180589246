import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Loader } from "ui";

import { useParamsToast } from "../hooks";
import { getCurrentUser, useAuthorized } from "../store";

export function PrivateOutlet() {
  const { pathname } = useLocation();
  const authorized = useAuthorized();

  useParamsToast();

  const preAuth = authorized === undefined;

  useEffect(() => {
    if (preAuth) {
      getCurrentUser();
    }
  }, [pathname, preAuth]);

  if (preAuth) {
    return <Loader full />;
  }

  if (!authorized) {
    return <Navigate to="/auth/login" />;
  }

  return <Outlet />;
}
