import { SVGProps } from "react";
const SvgFormTextField = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="FormTextField_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#FormTextField_svg__a)" fill="#F0A056">
      <path d="M8.7 11.946v-1.5h7v1.5h-7Zm-4.35 0v-1.5h3v1.5h-3Zm0-3.25v-1.5h7v1.5h-7ZM12.7 7.196v1.5h3v-1.5h-3Z" />
    </g>
  </svg>
);
export default SvgFormTextField;
