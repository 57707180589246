import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Loader } from "ui";
import { getCurrentUser, useAuthorized } from "../store";

export function PublicOutlet() {
  const authorized = useAuthorized();

  const preAuth = authorized === undefined;

  useEffect(() => {
    if (preAuth) {
      getCurrentUser();
    }
  }, [preAuth]);

  if (preAuth) {
    return <Loader full />;
  }

  if (authorized) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
