export function withHttp(candidate: string) {
  if (!candidate) {
    return candidate;
  }
  if (
    candidate.startsWith("h") ||
    candidate.startsWith("ht") ||
    candidate.startsWith("htt") ||
    candidate.startsWith("http") ||
    candidate.startsWith("{") ||
    candidate.startsWith("{{")
  ) {
    return candidate;
  }
  return `https://${candidate}`;
}
