import type { SVGProps } from "react";
const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#D1D4D5"
      d="m12.558 9.375-.75.767c-.6.6-.975 1.108-.975 2.358H9.167v-.417c0-.925.375-1.758.975-2.358l1.033-1.05c.308-.3.492-.717.492-1.175a1.666 1.666 0 1 0-3.334 0H6.667a3.333 3.333 0 0 1 6.666 0c0 .733-.3 1.392-.775 1.875Zm-1.725 6.458H9.167v-1.667h1.666v1.667ZM10 1.667A8.333 8.333 0 1 0 18.333 10c0-4.609-3.75-8.333-8.333-8.333Z"
    />
  </svg>
);
export default SvgHelp;
