import { ThemePayload } from "../../schemas/Theme";
import { ThemeSolidColor, ThemeSolids } from "../colors";
import { Gradients } from "../gradients";
import { isGradientTheme } from "./isGradientTheme";

export function getThemeName(theme: ThemePayload) {
  return isGradientTheme(theme)
    ? Gradients[theme.gradient].name
    : ThemeSolids.find((solid: ThemeSolidColor) => solid.color === theme.primaryColor)?.name || theme.primaryColor;
}
