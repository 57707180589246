import { SVGProps } from "react";
const SvgFormsSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="FormsSymbol_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#FormsSymbol_svg__a)">
      <path
        d="M4.5 17c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 3 15.5v-11c0-.412.147-.766.44-1.06.294-.293.648-.44 1.06-.44h3.563a1.98 1.98 0 0 1 .697-1.073A1.92 1.92 0 0 1 10 1.5c.472 0 .885.142 1.24.427.354.285.586.642.697 1.073H15.5c.412 0 .766.147 1.06.44.293.294.44.648.44 1.06v11c0 .412-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-11Zm0-1.5h11v-11h-11v11ZM6 14h6v-1.5H6V14Zm0-3.25h8v-1.5H6v1.5ZM6 7.5h8V6H6v1.5ZM10 4a.486.486 0 0 0 .5-.5.485.485 0 0 0-.5-.5.486.486 0 0 0-.5.5.486.486 0 0 0 .5.5Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgFormsSymbol;
