import { Asserts, boolean, number, object, string } from "yup";
import { ReminderDeliveryMethod, ScheduledMessageType } from "../enums";
import { ReturnPromptDaysValues } from "../consts";

export interface TemplatePair {
  sms: string;
  email: string;
}

export interface DefaultTemplates {
  UpcomingBooking: TemplatePair;
  ThankYou: TemplatePair;
  ReturnPrompt: TemplatePair;
}

const BaseReminder = object({
  emailEnabled: boolean(),
  smsEnabled: boolean(),
});

export const UpcomingBookingSchema = BaseReminder.shape({
  templates: object({
    sms: string().transform((value) =>
      value === DefaultScheduledMessageTemplates.UpcomingBooking.sms ? undefined : value,
    ),
    email: string().transform((value) =>
      value === DefaultScheduledMessageTemplates.UpcomingBooking.email ? undefined : value,
    ),
  }).optional(),
}).nullable();

export const ThankYouSchema = BaseReminder.shape({
  templates: object({
    sms: string().transform((value) => (value === DefaultScheduledMessageTemplates.ThankYou.sms ? undefined : value)),
    email: string().transform((value) =>
      value === DefaultScheduledMessageTemplates.ThankYou.email ? undefined : value,
    ),
  }).optional(),
}).nullable();

export const ReturnPromptSchema = BaseReminder.shape({
  days: number().default(7).oneOf(ReturnPromptDaysValues),
  templates: object({
    sms: string().transform((value) =>
      value === DefaultScheduledMessageTemplates.ReturnPrompt.sms ? undefined : value,
    ),
    email: string().transform((value) =>
      value === DefaultScheduledMessageTemplates.ReturnPrompt.email ? undefined : value,
    ),
  }).optional(),
}).nullable();

export const ReminderSchema = {
  ReturnPrompt: ReturnPromptSchema,
  ThankYou: ThankYouSchema,
  UpcomingBooking: UpcomingBookingSchema,
};

export type ReminderPayload =
  | Asserts<typeof ThankYouSchema>
  | Asserts<typeof UpcomingBookingSchema>
  | Asserts<typeof ReturnPromptSchema>;

export const RemindersSchema = object({
  ReturnPrompt: ReturnPromptSchema.required(),
  ThankYou: ThankYouSchema.required(),
  UpcomingBooking: UpcomingBookingSchema.required(),
});
export type RemindersPayload = Asserts<typeof RemindersSchema>;

export const DefaultScheduledMessageTemplates: Record<ScheduledMessageType, Record<ReminderDeliveryMethod, string>> = {
  UpcomingBooking: {
    sms: "Hey! Just a reminder of your {{event}} with {{business}} tomorrow at {{time}}.",
    email: `Just a quick reminder of your booking with {{business}} on {{date}} at {{time}} ({{timezone}}).

Your event location: {{location}}

To cancel, click [here]({{cancelURL}}).

Or book a different time [here]({{businessURL}})`,
  },
  ThankYou: {
    sms: "Thank you for booking {{event}} with me. {{business}}.",
    email: `Thanks for attending {{event}} with {{business}}. It was a pleasure.`,
  },
  ReturnPrompt: {
    sms: "Ready to book your next {{event}} with me? {{eventURL}}",
    email: `Ready for your next {{event}} with {{business}}?

Book [here]({{eventURL}}).`,
  },
};
