import { PersistedPage } from "schemas";
import { useLiteSiteStore } from "../store";
import { apiRequest } from "../apiRequest";
import { getPageElements } from "../pages/getPageElements";

export async function deleteAppointment(destinationPage: PersistedPage, id: string) {
  const params = new URLSearchParams({ destinationType: "Page", destinationId: destinationPage.id, id });
  await apiRequest({ url: `appointments/${id}?${params.toString()}`, method: "DELETE" });
  getPageElements(destinationPage.id);
  useLiteSiteStore.setState(({ appointments }) => {
    appointments.delete(id);
    return { appointments: new Map(appointments) };
  });
}
