import { SocialNetwork, findNetworkForUrl } from "schemas";
import { IconType } from "../components/Icon";

const SocialNetworkIcons: Record<SocialNetwork, IconType> = {
  Amazon: "SocialAmazon",
  Bandcamp: "SocialBandcamp",
  Behance: "SocialBehance",
  DeviantArt: "SocialDeviantArt",
  Discord: "SocialDiscord",
  Dribbble: "SocialDribbble",
  Facebook: "SocialFacebook",
  Figma: "SocialFigma",
  Github: "SocialGithub",
  Google: "SocialGoogle",
  Instagram: "SocialInstagram",
  Line: "SocialLine",
  Linkedin: "SocialLinkedin",
  Medium: "SocialMedium",
  Messenger: "SocialMessenger",
  MySpace: "SocialMySpace",
  Pinterest: "SocialPinterest",
  QQ: "SocialQQ",
  Reddit: "SocialReddit",
  Signal: "SocialSignal",
  Skype: "SocialSkype",
  Slack: "SocialSlack",
  Snapchat: "SocialSnapchat",
  Spotify: "SocialSpotify",
  Teams: "SocialTeams",
  Telegram: "SocialTelegram",
  Threads: "SocialThreads",
  Tumblr: "SocialTumblr",
  Tiktok: "SocialTiktok",
  Tinder: "SocialTinder",
  Twitch: "SocialTwitch",
  Twitter: "SocialTwitter",
  Viber: "SocialViber",
  Vimeo: "SocialVimeo",
  VK: "SocialVK",
  WeChat: "SocialWeChat",
  Whatsapp: "SocialWhatsapp",
  Youtube: "SocialYoutube",
};

export function iconForSocialNetwork(url: string): IconType {
  const network = findNetworkForUrl(url);

  return network ? SocialNetworkIcons[network as SocialNetwork] : "Timezone";
}
