import type { SVGProps } from "react";
const SvgFormatStrikethrough = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <path
      fill="#191B20"
      d="M10.625 15.917q-1.5 0-2.625-.876-1.125-.875-1.5-2.312l1.688-.687q.333 1.062.968 1.604.636.54 1.51.541.939 0 1.49-.448.552-.447.552-1.198 0-.333-.125-.614a1.5 1.5 0 0 0-.375-.51h2.146q.105.228.136.49.03.26.03.614 0 1.5-1.082 2.448-1.084.947-2.813.947m-8.125-6v-1.5h16v1.5zm8-6q1.334 0 2.208.562.876.562 1.417 1.792l-1.625.708a2.12 2.12 0 0 0-.76-1.01 2 2 0 0 0-1.198-.386q-.77 0-1.282.375-.51.376-.552.958H6.896q.042-1.312 1.062-2.156 1.02-.843 2.542-.843"
    />
  </svg>
);
export default SvgFormatStrikethrough;
