import { SVGProps } from "react";

const SvgChevronSmallLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m7.84 9.72 3.151-2.974a.9.9 0 0 1 .452-.228.932.932 0 0 1 .51.047c.162.063.3.17.396.307a.8.8 0 0 1 .15.463v6.33a.8.8 0 0 1-.15.463.874.874 0 0 1-.396.307.93.93 0 0 1-.51.047.899.899 0 0 1-.452-.228L7.84 11.279a1.073 1.073 0 0 1-.341-.78c0-.291.123-.572.341-.778Z"
      fill="#465357"
    />
  </svg>
);
export default SvgChevronSmallLeft;
