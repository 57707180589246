import classNames from "classnames";
import { PropsWithClassNameAndChildren } from "./types";
import { bgStyles, borderStyles, overflowStyles, paddingStyles, textStyles } from "./styles";

export function Code({ children, className }: PropsWithClassNameAndChildren) {
  return (
    <div
      className={classNames(
        borderStyles.neutral400,
        borderStyles.radius025,
        bgStyles.neutral200,
        paddingStyles.p050,
        textStyles.fontCode,
        textStyles.size075,
        overflowStyles.scrollX,
        className,
      )}
    >
      {children}
    </div>
  );
}
