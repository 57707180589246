import classNames from "classnames";
import { Props, RawInitials } from "./RawInitials";
import { squareStyles, textStyles } from "../styles";

export function MediumContactInitials({ contact, onClick, className }: Props) {
  return (
    <RawInitials
      className={classNames(squareStyles.square200, textStyles.size100, className)}
      contact={contact}
      onClick={onClick}
    />
  );
}
