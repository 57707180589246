import { SVGProps } from "react";

const SvgThanks = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.91 26.603a.934.934 0 0 1-.537-.166C8.045 21.457 4.23 15.23 5.869 10.949c.717-1.863 3.027-4.75 6.188-4.538 2.49.166 4.084 2.214 4.653 3.078a.967.967 0 0 1-.275 1.332.967.967 0 0 1-1.331-.275c-.41-.628-1.549-2.106-3.18-2.221-1.998-.135-3.726 1.894-4.27 3.309-1.273 3.321 2.426 8.883 8.788 13.21.441.3.55.895.256 1.33a.927.927 0 0 1-.788.43Z"
      fill="#F0A056"
    />
    <path
      d="M16.09 26.603a.954.954 0 0 1-.794-.422.951.951 0 0 1 .256-1.332c6.368-4.332 10.06-9.888 8.787-13.21-.544-1.413-2.272-3.442-4.268-3.308-1.632.109-2.765 1.587-3.181 2.22a.959.959 0 0 1-1.331.276.959.959 0 0 1-.276-1.331c.57-.87 2.164-2.912 4.653-3.079 3.149-.217 5.472 2.67 6.19 4.538 1.637 4.282-2.177 10.502-9.505 15.488a.928.928 0 0 1-.531.16Z"
      fill="#F0A056"
    />
  </svg>
);
export default SvgThanks;
