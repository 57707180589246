import type { SVGProps } from "react";
const SvgSocialSignal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="m7.6.625.224.91a9 9 0 0 0-2.512 1.04l-.48-.805A9.9 9.9 0 0 1 7.599.625m4.8 0-.224.91a9 9 0 0 1 2.512 1.04l.486-.805A10 10 0 0 0 12.4.625M1.438 5.163a10 10 0 0 0-1.145 2.77l.91.224A9 9 0 0 1 2.24 5.646zm-.5 5.17q0-.682.102-1.358l-.926-.142a10 10 0 0 0 0 2.998l.926-.14a9 9 0 0 1-.102-1.358m14.231 8.56-.48-.8a9 9 0 0 1-2.509 1.04l.225.908a10 10 0 0 0 2.764-1.147m3.895-8.56q0 .684-.102 1.358l.926.14c.15-.994.15-2.004 0-2.998l-.926.142q.102.676.102 1.358m.645 2.398-.91-.224a9 9 0 0 1-1.039 2.514l.804.483c.52-.86.906-1.796 1.145-2.773m-8.35 6.563c-.9.137-1.816.137-2.716 0l-.14.926a10 10 0 0 0 2.995 0zm5.938-3.585a9 9 0 0 1-1.92 1.918l.556.755a10 10 0 0 0 2.12-2.11zm-1.92-12.672a9 9 0 0 1 1.92 1.921l.756-.563a10 10 0 0 0-2.114-2.113zM2.703 4.958a9 9 0 0 1 1.92-1.92l-.562-.756a10 10 0 0 0-2.114 2.113zm15.86.205-.805.483a9 9 0 0 1 1.04 2.508l.91-.224a9.9 9.9 0 0 0-1.146-2.767m-9.922-3.79a9 9 0 0 1 2.716 0l.14-.927a10 10 0 0 0-2.995 0zm-5.458 17.26-1.935.448.452-1.935-.912-.213-.452 1.935a.937.937 0 0 0 1.125 1.128l1.935-.446zM.982 16.097l.915.21.313-1.34A9 9 0 0 1 1.2 12.506l-.91.224c.205.829.515 1.628.921 2.378zm4.376 2.029-1.341.312.213.915.986-.23c.75.408 1.549.717 2.378.92l.224-.909a9 9 0 0 1-2.452-1.014zM10 2.207a8.13 8.13 0 0 0-8.123 8.13 8.15 8.15 0 0 0 1.248 4.318l-.782 3.335 3.333-.781c3.798 2.39 8.816 1.25 11.205-2.546a8.13 8.13 0 0 0-2.543-11.205A8.15 8.15 0 0 0 10 2.208"
    />
  </svg>
);
export default SvgSocialSignal;
