import { useLocation, useNavigate } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";
import { AutoCompleteInput, Icon, SearchItem, TextMain, svgStyles } from "ui";
import { useAfterImportRedirect, useUrlWithBack } from "../../hooks";
import { SelectableContactGroup } from "../contactGroups";
import { Submit, SuperCard } from "../ui";
import { addContactByEmail, useContactGroups, useContacts } from "../../store";
import { compact } from "es-toolkit";
import { RecipientsContactGroupsExcludes } from "./RecipientsContactGroupsExcludes";

export function RecipientsForm() {
  const contacts = useContacts();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setAfterImportRedirect } = useAfterImportRedirect();

  const { setValue } = useFormContext();

  const selectedGroupIds = useWatch({ name: "contactGroupIds" });
  const unselectGroupHandler = (id: string) => async () => {
    if (selectedGroupIds.includes(id)) {
      const newSelection = selectedGroupIds.filter((item: string) => item !== id);
      setValue("contactGroupIds", newSelection, { shouldValidate: true });
    }
  };

  const { contactGroups } = useContactGroups(["Unconfirmed"]);
  const allContactsGroup = contactGroups.find((contactGroup) => contactGroup.autogeneratedType === "AllContacts");
  const allSelected = selectedGroupIds.includes(allContactsGroup?.id);
  const addContactsLink = useUrlWithBack(`/contactGroups/${allContactsGroup?.id}/add`);
  const groupTags = (allSelected && allContactsGroup ? [allContactsGroup] : contactGroups)
    .filter((contactGroup) => selectedGroupIds.includes(contactGroup.id))
    .map((contactGroup) => ({
      label: `${contactGroup.name} (${contactGroup.contactCount})`,
      onClick: unselectGroupHandler(contactGroup.id),
    }));

  const onAddNew = async (email: string): Promise<string> => {
    const contact = await addContactByEmail(email);
    return contact.id;
  };

  const autocompleteCollection: SearchItem[] = contacts
    .filter(({ sendable }) => sendable)
    .map((contact) => ({
      id: contact.id,
      label: contact.displayName,
      subLabel: contact.name && contact.email,
      searchable: compact([contact.name?.toLowerCase(), contact.email.toLowerCase()]),
    }));

  return (
    <>
      <AutoCompleteInput
        type="inner"
        onAddNew={onAddNew}
        collection={autocompleteCollection}
        name="contactIds"
        extraSelections={groupTags}
        shortPlaceholder="Add more"
        placeholder="Search for contacts or add email adresses"
      />
      <RecipientsContactGroupsExcludes contactGroups={contactGroups} />
      {contactGroups.map((contactGroup) => (
        <SelectableContactGroup
          key={contactGroup.id}
          contactGroup={contactGroup}
          disabled={contactGroup.id !== allContactsGroup?.id && allSelected}
          name="contactGroupIds"
        />
      ))}
      <SuperCard
        onClick={async () => {
          setAfterImportRedirect(pathname);
          navigate(addContactsLink);
        }}
        left={<Icon className={svgStyles.square} iconType="ContactGroup" />}
        title={<TextMain>Import contacts to send to</TextMain>}
      />
      <Submit label="Save and continue" />
    </>
  );
}
