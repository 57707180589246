import classNames from "classnames";
import { PersistedContact, PersistedContactGroup } from "schemas";
import {
  Card,
  ContactInitials,
  Description,
  DescriptionAccent,
  DescriptionItalic,
  DotMenu,
  DotMenuLink,
  Strong,
  Summary,
  TextAccent,
  bgStyles,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  positionStyles,
  textStyles,
} from "ui";
import { ContactTags } from "./ContactTags";
import { IconLink, Link } from "../ui";

interface Props {
  links: DotMenuLink[];
  contact: PersistedContact;
  contactGroups: PersistedContactGroup[];
}
export function BigContactCard({ links, contact, contactGroups }: Props) {
  const { name, email, phone, blocked, note, context } = contact;

  return (
    <Card>
      <div
        className={classNames(flexStyles.vert075, flexStyles.alignCenter, positionStyles.relative, paddingStyles.p125)}
      >
        <div
          className={classNames(
            positionStyles.absolute,
            positionStyles.top125,
            positionStyles.right125,
            flexStyles.horiz125,
          )}
        >
          <IconLink to="edit" iconType="Edit" />
          <DotMenu vertical links={links} />
        </div>
        <ContactInitials contact={contact} />
        <div className={classNames(flexStyles.vert075, flexStyles.alignCenter)}>
          <TextAccent>{name}</TextAccent>
          {blocked && <DescriptionItalic className={textStyles.neutral300}>Blocked</DescriptionItalic>}
          <Link className={textStyles.decorationNone} href={`mailto:${contact.email}`}>
            <Description>{email}</Description>
          </Link>
          {phone && (
            <Link className={textStyles.decorationNone} href={`tel:${phone}`}>
              <Description>{phone}</Description>
            </Link>
          )}
          <ContactTags contact={contact} />
          {contactGroups.length > 0 && (
            <div className={classNames(flexStyles.horiz050, flexStyles.wrap, flexStyles.justifyCenter)}>
              {contactGroups.map((group: PersistedContactGroup) => (
                <Summary
                  key={group.id}
                  className={classNames(textStyles.neutral600, iconStyles.neutral500, bgStyles.neutral100)}
                  iconType="ContactGroup"
                  label={group.name}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames(
          flexStyles.vert050,
          bgStyles.neutral100,
          borderStyles.topNeutral200,
          paddingStyles.p125,
          textStyles.center,
        )}
      >
        <Link to="note" className={textStyles.decorationNone}>
          <Description>{note ? note : "You can add a note here."}</Description>
        </Link>
        {Object.entries(context).length === 0 && (
          <div className={textStyles.left}>
            <DescriptionAccent>Additional info:</DescriptionAccent>
            {Object.entries(context).map(([key, value]) => (
              <Description key={key}>
                {key}: <Strong>{value}</Strong>
              </Description>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
}
