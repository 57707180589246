import { Upload } from "./Upload";
import { getUploadUrl } from "./getUploadUrl";

export async function uploadImage(file: File): Promise<Upload & { fileUrl: string }> {
  const { key, bucket, uploadUrl, fileUrl } = await getUploadUrl();
  const form = new FormData();
  form.append("file", file);
  await fetch(uploadUrl, { method: "POST", body: form });
  return { key, bucket, fileUrl };
}
