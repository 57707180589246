import { DateTime } from "luxon";

export const dateToDayDescription = (day: DateTime, timeZone?: string): string => {
  const now = timeZone ? DateTime.now().setZone(timeZone) : DateTime.now();
  if (now.hasSame(day, "day")) {
    return "Today";
  }
  if (now.plus({ day: 1 }).hasSame(day, "day")) {
    return "Tomorrow";
  }
  return day.toLocaleString({ weekday: "long" });
};
