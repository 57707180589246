import { SubheaderMain, TextMain } from "ui";
import { SuperCard } from "../ui";

interface Props {
  label: string;
  count: number;
}

export function CampaignStat({ label, count }: Props) {
  return <SuperCard title={<TextMain>{label}</TextMain>} right={<SubheaderMain>{count}</SubheaderMain>}></SuperCard>;
}
