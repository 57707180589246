import type { SVGProps } from "react";
const SvgSocialFigma = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.8 0h6.4a3.601 3.601 0 0 1 1.65 6.8 3.6 3.6 0 1 1-4.45 5.462V16.4a3.601 3.601 0 0 1-7.2 0c0-1.393.792-2.601 1.95-3.2a3.6 3.6 0 0 1 0-6.4A3.6 3.6 0 0 1 6.8 0M4 3.6C4 2.055 5.255.8 6.8.8h2.8v5.6H6.79A2.8 2.8 0 0 1 4 3.6m5.6 6.362V12.8H6.8a2.801 2.801 0 0 1-.009-5.6H9.6zm.8.072v-.068A2.8 2.8 0 0 1 13.188 7.2h.021a2.801 2.801 0 0 1-.01 5.6 2.8 2.8 0 0 1-2.799-2.767m.538-2.834q-.297.24-.538.538V7.2zm2.27-.8H10.4V.8h2.8a2.801 2.801 0 0 1 .009 5.6M6.8 13.6a2.801 2.801 0 0 0 0 5.6c1.545 0 2.8-1.255 2.8-2.8v-2.8z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialFigma;
