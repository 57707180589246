import { useNavigate } from "react-router-dom";
import { FormProvider, paddingStyles } from "ui";
import { ContactGroupPayload, ContactGroupSchema, EmptyContactGroup } from "schemas";
import { useBackLocation } from "../../hooks";
import { Form } from "./Form";
import { addContactGroup } from "../../store";
import { Overlay } from "../ui";

export function New() {
  const navigate = useNavigate();
  const backLocation = useBackLocation("/contacts");

  const onSubmit = async (payload: ContactGroupPayload) => {
    const group = await addContactGroup(payload);
    navigate(`/contactGroups/${group.id}`);
  };

  return (
    <FormProvider defaultValues={EmptyContactGroup} schema={ContactGroupSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: backLocation, label: "Contacts" }}
        header="Add a new contact list"
        title="List Basics"
      >
        <Form onSubmit={onSubmit} cancelTo={backLocation} />
      </Overlay>
    </FormProvider>
  );
}
