import type { SVGProps } from "react";
const SvgZoomLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={1000} height={224.723} viewBox="0 0 1000 224" {...props}>
    <path
      d="M814.186 67.389c3.822 6.595 5.073 14.098 5.486 22.533l.542 11.244v78.62l.555 11.255c1.109 18.38 14.664 31.972 33.187 33.128l11.196.554V101.166l.554-11.244c.46-8.34 1.7-15.986 5.58-22.628a44.902 44.902 0 0 1 77.748.142c3.822 6.595 5.014 14.24 5.474 22.486l.554 11.208v78.656l.555 11.255c1.156 18.475 14.606 32.066 33.187 33.128l11.196.554v-134.8a89.899 89.899 0 0 0-89.863-89.9 89.663 89.663 0 0 0-67.424 30.45A89.78 89.78 0 0 0 775.288.013c-18.664 0-35.983 5.663-50.329 15.443C716.205 5.687 696.621.012 685.378.012v224.711l11.243-.554c18.806-1.239 32.397-14.464 33.128-33.128l.602-11.255v-78.62l.554-11.244c.472-8.482 1.652-15.938 5.486-22.58a45.02 45.02 0 0 1 38.897-22.393 44.95 44.95 0 0 1 38.898 22.44zM44.938 224.18l11.243.542h168.53l-.554-11.207c-1.522-18.476-14.606-31.972-33.14-33.176l-11.243-.554H78.667L213.468 44.938l-.554-11.196c-.873-18.664-14.511-32.15-33.14-33.176L168.531.06 0 .012l.554 11.243c1.475 18.298 14.748 32.078 33.129 33.14l11.255.554h101.106l-134.8 134.849.554 11.243c1.109 18.522 14.476 31.936 33.14 33.128zM641.266 32.904a112.35 112.35 0 0 1 0 158.904 112.432 112.432 0 0 1-158.939 0c-43.876-43.876-43.876-115.028 0-158.904A112.29 112.29 0 0 1 561.726 0a112.373 112.373 0 0 1 79.54 32.916Zm-31.795 31.818a67.448 67.448 0 0 1 0 95.35 67.448 67.448 0 0 1-95.35 0 67.448 67.448 0 0 1 0-95.35 67.448 67.448 0 0 1 95.35 0zM325.913 0a112.29 112.29 0 0 1 79.398 32.916c43.888 43.864 43.888 115.028 0 158.892a112.432 112.432 0 0 1-158.939 0c-43.876-43.876-43.876-115.028 0-158.904A112.29 112.29 0 0 1 325.771 0Zm47.603 64.699a67.448 67.448 0 0 1 0 95.361 67.448 67.448 0 0 1-95.349 0 67.448 67.448 0 0 1 0-95.35 67.448 67.448 0 0 1 95.35 0z"
      style={{
        fill: "#2d8cff",
        fillRule: "evenodd",
        strokeWidth: 11.79773998,
      }}
    />
  </svg>
);
export default SvgZoomLogo;
