import { Asserts, boolean, object, string } from "yup";
import { PersistedBlock } from "./blocks";
import { CampaignStatus } from "../enums";

export const CampaignSchema = object({
  name: string().required("Campaign name is required"),
  subject: string(),
  showLogo: boolean(),
});
export const CampaignWithSubjectSchema = object({
  subject: string().required("Subject is required"),
});

export type CampaignPayload = Asserts<typeof CampaignSchema>;
export type PersistedCampaign = CampaignPayload & {
  id: string;
  status: CampaignStatus;
  updatedAt: string;
  insertedAt: string;
  recipients: {
    totalCount: number;
    contactGroupIds: string[];
    excludedContactGroupIds: string[];
    contactIds: string[];
  };
  url: string;
  sentAt?: string;
  themeId: string;
};
export type PopulatedCampaign = PersistedCampaign & {
  blocks: PersistedBlock[];
};

export type PublicPopuledCampaign = {
  id: string;
  blocks: PersistedBlock[];
  showLogo: boolean;
  url: string;
};

export const EmptyCampaign: CampaignPayload = {
  name: "",
  subject: "",
};
