import type { SVGProps } from "react";
const SvgFormatBold = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={46} height={36} fill="none" {...props}>
    <mask
      id="FormatBold_svg__a"
      width={21}
      height={20}
      x={13}
      y={8}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M13.072 8h20v20h-20z" />
    </mask>
    <g mask="url(#FormatBold_svg__a)">
      <path
        fill="#191B20"
        d="M18.613 24V12h4.75c.93 0 1.788.287 2.573.86.785.575 1.177 1.371 1.177 2.39 0 .708-.156 1.26-.469 1.656-.312.396-.607.685-.885.866.361.166.75.464 1.167.895.416.43.625 1.062.625 1.895 0 1.264-.466 2.153-1.397 2.667-.93.514-1.805.771-2.62.771h-4.92Zm2.646-2.458h2.18c.672 0 1.077-.17 1.216-.51.139-.341.208-.588.208-.74 0-.153-.073-.4-.219-.74-.145-.34-.572-.51-1.28-.51h-2.105v2.5Zm0-4.834h1.938c.458 0 .795-.121 1.01-.364.215-.243.323-.504.323-.782a1.03 1.03 0 0 0-.357-.812c-.237-.208-.545-.313-.922-.313h-1.992v2.271Z"
      />
    </g>
  </svg>
);
export default SvgFormatBold;
