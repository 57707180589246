import type { SVGProps } from "react";
const SvgSocialGithub = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 1.667A8.33 8.33 0 0 0 1.667 10a8.33 8.33 0 0 0 5.698 7.906c.416.073.572-.177.572-.396 0-.198-.01-.854-.01-1.552-2.094.386-2.635-.51-2.802-.979-.094-.24-.5-.979-.854-1.177-.292-.156-.709-.542-.01-.552.656-.01 1.124.604 1.28.854.75 1.26 1.949.906 2.428.688.073-.542.291-.907.531-1.115-1.854-.208-3.792-.927-3.792-4.115 0-.906.323-1.656.854-2.24-.083-.207-.375-1.062.084-2.207 0 0 .698-.22 2.291.854a7.7 7.7 0 0 1 2.084-.282c.708 0 1.416.094 2.083.282 1.594-1.084 2.292-.854 2.292-.854.458 1.145.166 2 .083 2.208.531.583.854 1.323.854 2.24 0 3.197-1.948 3.906-3.802 4.114.302.26.563.76.563 1.542 0 1.114-.01 2.01-.01 2.291 0 .22.155.48.572.396A8.35 8.35 0 0 0 18.333 10 8.33 8.33 0 0 0 10 1.667"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialGithub;
