export type FormQuestionType =
  | "TextField"
  | "NumberField"
  | "DateField"
  | "SingleChoice"
  | "MultipleChoice"
  | "FileField";

export const FormQuestionTypeValues: FormQuestionType[] = [
  "TextField",
  "NumberField",
  "DateField",
  "SingleChoice",
  "MultipleChoice",
  "FileField",
];
