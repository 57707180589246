import type { SVGProps } from "react";
const SvgSocialDeviantArt = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M15.003 1.667h-2.869l-.306.304-1.46 2.786-.425.246H5v4.163h2.64l.276.275L5 15.007v3.326h2.871l.307-.306 1.463-2.787.417-.24h4.945v-4.162h-2.649l-.268-.268L15.003 5"
    />
  </svg>
);
export default SvgSocialDeviantArt;
