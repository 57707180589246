import { Asserts, object, string } from "yup";
import { EmailValidation } from "../shared";

export const EmailBlockSchema = object({
  label: string().required("Label is required"),
  email: EmailValidation.required("Email is required"),
  kind: string().oneOf(["EmailBlock"]).required(),
}).noUnknown();
export type EmailBlockPayload = Asserts<typeof EmailBlockSchema> & {
  kind: "EmailBlock";
};
export type PersistedEmailBlock = EmailBlockPayload & {
  _id: string;
  buttonUrl: string;
};
