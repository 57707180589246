import { PersistedAppointment } from "schemas";
import { HookLoading } from "../types";
import { useEffect, useState } from "react";
import { setAppointment } from "./setAppointment";
import { useLiteSiteStore } from "../store";
import { apiRequest } from "../apiRequest";

async function getAppointments() {
  const appointments = await apiRequest<PersistedAppointment[]>({ url: `appointments` });

  return appointments;
}

export function useAppointments(): HookLoading<{ appointments: PersistedAppointment[] }> {
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    getAppointments().then((appointments) => {
      appointments.map(setAppointment);
      setLoading(false);
    });
  }, []);

  const appointmentsMap = useLiteSiteStore(({ appointments }) => appointments);
  const appointments = Array.from(appointmentsMap.values());

  return { loading, appointments };
}
