import type { SVGProps } from "react";
const SvgSocialInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 2.333c-2.263 0-2.547.01-3.436.05-.887.04-1.493.182-2.023.388a4.1 4.1 0 0 0-1.476.961c-.424.416-.752.92-.961 1.476-.205.53-.347 1.136-.387 2.024s-.05 1.17-.05 3.435c0 2.264.01 2.546.05 3.435.04.887.181 1.493.387 2.023.21.556.537 1.06.962 1.477.416.423.92.751 1.475.96.53.206 1.136.347 2.023.388.89.04 1.173.05 3.436.05s2.547-.01 3.436-.05c.887-.04 1.493-.182 2.023-.388a4.1 4.1 0 0 0 1.476-.961c.424-.416.752-.92.961-1.476.206-.53.347-1.136.387-2.023.04-.889.05-1.172.05-3.435s-.01-2.547-.05-3.436c-.04-.887-.181-1.493-.387-2.023a4.1 4.1 0 0 0-.962-1.476 4.1 4.1 0 0 0-1.475-.962c-.53-.205-1.137-.346-2.024-.387-.888-.04-1.171-.05-3.435-.05m0 1.502c2.225 0 2.489.008 3.368.048.812.037 1.253.172 1.547.287.389.151.667.332.958.623.292.292.473.57.623.959.115.293.25.735.287 1.547.04.879.049 1.143.049 3.368s-.009 2.489-.049 3.367c-.037.813-.172 1.254-.287 1.548a2.6 2.6 0 0 1-.623.958 2.6 2.6 0 0 1-.958.623c-.294.114-.735.25-1.547.287-.88.04-1.142.048-3.368.048s-2.489-.008-3.368-.048c-.812-.037-1.253-.172-1.547-.287a2.6 2.6 0 0 1-.958-.623 2.6 2.6 0 0 1-.623-.958c-.115-.294-.25-.735-.287-1.548-.04-.879-.049-1.142-.049-3.367s.009-2.49.049-3.368c.037-.812.172-1.254.287-1.547a2.6 2.6 0 0 1 .623-.959 2.6 2.6 0 0 1 .958-.623c.294-.114.735-.25 1.547-.287.88-.04 1.143-.048 3.368-.048"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 13.447a2.781 2.781 0 1 1 0-5.562 2.781 2.781 0 0 1 0 5.562m0-7.064a4.283 4.283 0 1 0 0 8.567 4.283 4.283 0 0 0 0-8.567m5.53-.078a1.012 1.012 0 1 1-2.025 0 1.012 1.012 0 0 1 2.024 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialInstagram;
