import type { SVGProps } from "react";
const SvgFormatUnderline = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <mask
      id="FormatUnderline_svg__a"
      width={21}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M.5 0h20v20H.5z" />
    </mask>
    <g mask="url(#FormatUnderline_svg__a)">
      <path
        fill="#191B20"
        d="M5.5 17v-1.5h10V17zm5-3q-2 0-3.094-1.238t-1.093-3.28V3h2.025v6.606q0 1.1.55 1.79.55.687 1.613.687t1.612-.688q.55-.688.55-1.789V3h2.024v6.482q0 2.044-1.093 3.28Q12.5 14 10.5 14"
      />
    </g>
  </svg>
);
export default SvgFormatUnderline;
