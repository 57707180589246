import classNames from "classnames";
import { PropsWithClassName, bgStyles, borderStyles, squareStyles } from "ui";

export function Dot({ className }: PropsWithClassName) {
  return (
    <div
      className={classNames(squareStyles.square050, borderStyles.radiusRound, bgStyles.secondary300, className)}
    ></div>
  );
}
