"use client";

import { useFormContext } from "react-hook-form";
import classNames from "classnames";

import { Description } from "../typography";
import { PropsWithClassNameAndChildren } from "../types";
import { textStyles } from "../styles";
import { pick } from "es-toolkit";

export function SimpleError({ children, className }: PropsWithClassNameAndChildren) {
  if (!children) {
    return null;
  }
  return <Description className={classNames(textStyles.errorDark, className)}>{children}</Description>;
}

interface Props {
  name: string;
}

export function Error({ name }: Props) {
  const {
    formState: { errors },
  } = useFormContext();
  const error = pick(errors, [name])[name];

  if (!error) {
    return null;
  }

  return <SimpleError>{error.message?.toString()}</SimpleError>;
}
