import { ImageBlockPayload } from "schemas";
import { Help, ImageInput, InlineTextInput, iconStyles } from "ui";
import { uploadImage } from "../../../store";

interface Props {
  onSubmit: (payload: ImageBlockPayload) => Promise<void>;
}

export function ImageEditor({ onSubmit }: Props) {
  const altHelp = (
    <Help className={iconStyles.size100}>Alt text is used by screen readers and when the image fails to load.</Help>
  );
  return (
    <>
      <ImageInput widthName="pixelWidth" urlValue uploadImage={uploadImage} name="image" />
      <InlineTextInput<ImageBlockPayload>
        label="Image link URL"
        size="Text"
        name="url"
        placeholder="URL"
        onSubmit={onSubmit}
      />
      <InlineTextInput<ImageBlockPayload>
        label="Image alt text"
        size="Text"
        name="alt"
        placeholder="Image's alt text"
        onSubmit={onSubmit}
        extra={altHelp}
      />
    </>
  );
}
