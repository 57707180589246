import { Loader, SubheaderMain, flexStyles, paddingStyles } from "ui";
import { FormResponseCard } from "../../forms";
import classNames from "classnames";
import { useContactFormAnswers } from "../../../store";

interface Props {
  contactId: string;
}
export function Forms({ contactId }: Props) {
  const formAnswers = useContactFormAnswers(contactId);

  if (!formAnswers) {
    return <Loader />;
  }

  if (formAnswers.length === 0) {
    return (
      <div className={classNames(paddingStyles.p125, flexStyles.vert050)}>
        <SubheaderMain>No form responses yet</SubheaderMain>
      </div>
    );
  }

  return (
    <div className={classNames(paddingStyles.p125, flexStyles.vert050)}>
      {formAnswers.map((formAnswer) => (
        <FormResponseCard key={formAnswer._id} formAnswer={formAnswer} />
      ))}
    </div>
  );
}
