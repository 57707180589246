import { SharedBusiness } from "schemas";
import classNames from "classnames";
import { SmallTextMain } from "../typography";
import { PropsWithClassName, PropsWithOptionalClick } from "../types";
import { Link } from "../Link";
import { borderStyles, flexStyles, textStyles } from "../styles";

interface Props extends PropsWithClassName, PropsWithOptionalClick {
  business: SharedBusiness;
}
export function BusinessDetailsCard({ business, className, onClick }: Props) {
  const { address } = business;
  return (
    <>
      {business.logo && (
        <img
          className={classNames(flexStyles.alignSelfCenter, borderStyles.radiusRound, className)}
          alt={`${business.businessName} logo`}
          src={`${business.logo}/logo`}
          width="32"
          height="32"
        />
      )}
      <div className={textStyles.textCenter}>
        <SmallTextMain>{business.businessName}</SmallTextMain>
        {address && <SmallTextMain>{address}</SmallTextMain>}
        {!address && onClick && (
          <>
            <SmallTextMain>Your company&apos;s physical address is required</SmallTextMain>
            <Link onClick={onClick}>Add address</Link>
          </>
        )}
      </div>
    </>
  );
}
