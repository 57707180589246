import type { SVGProps } from "react";
const SvgPending = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#465357"
      d="M12.502 4.25a.97.97 0 0 1-.71-.29.96.96 0 0 1-.292-.708q0-.419.29-.71a.96.96 0 0 1 .708-.292.97.97 0 0 1 .71.29.96.96 0 0 1 .292.708q0 .419-.29.71a.96.96 0 0 1-.708.292m0 13.5a.97.97 0 0 1-.71-.29.96.96 0 0 1-.292-.708.97.97 0 0 1 .29-.71.96.96 0 0 1 .708-.292.97.97 0 0 1 .71.29.96.96 0 0 1 .292.708.97.97 0 0 1-.29.71.96.96 0 0 1-.708.292m3.5-10.5a.97.97 0 0 1-.71-.29.96.96 0 0 1-.292-.708q0-.419.29-.71a.96.96 0 0 1 .708-.292.97.97 0 0 1 .71.29.96.96 0 0 1 .292.708q0 .419-.29.71a.96.96 0 0 1-.708.292m0 7.5a.97.97 0 0 1-.71-.29.96.96 0 0 1-.292-.708.97.97 0 0 1 .29-.71.96.96 0 0 1 .708-.292.97.97 0 0 1 .71.29.96.96 0 0 1 .292.708.97.97 0 0 1-.29.71.96.96 0 0 1-.708.292m1-3.75a.97.97 0 0 1-.71-.29.96.96 0 0 1-.292-.708q0-.419.29-.71A.96.96 0 0 1 16.997 9a.97.97 0 0 1 .71.29.96.96 0 0 1 .292.708.97.97 0 0 1-.29.71.96.96 0 0 1-.708.292M10 18a7.8 7.8 0 0 1-3.108-.625 8.1 8.1 0 0 1-2.548-1.719 8.1 8.1 0 0 1-1.719-2.551A7.8 7.8 0 0 1 2 9.99q0-1.657.625-3.105a8.076 8.076 0 0 1 4.267-4.26A7.8 7.8 0 0 1 10 2v1.5q-2.709 0-4.604 1.896T3.5 10t1.896 4.604T10 16.5zm-.004-6.5q-.621 0-1.059-.44A1.45 1.45 0 0 1 8.5 10v-.198q0-.093.042-.198L7 8.063 8.063 7l1.541 1.542A1.4 1.4 0 0 1 10 8.5q.619 0 1.06.442.44.442.44 1.062t-.442 1.059a1.46 1.46 0 0 1-1.062.437"
    />
  </svg>
);
export default SvgPending;
