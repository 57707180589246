import type { SVGProps } from "react";
const SvgSocialSpotify = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21" {...props}>
    <path
      fill="#fff"
      d="M2.167 10.284c0 4.563 3.77 8.333 8.333 8.333s8.333-3.77 8.333-8.333S15.063 2 10.5 2s-8.333 3.72-8.333 8.284m11.408 3.869c-1.934-1.19-4.414-1.488-7.291-.844-.695.1-.794-.892-.199-.991 3.175-.745 5.853-.397 8.036.942.546.347 0 1.19-.546.893m.844-2.232c-2.232-1.39-5.655-1.786-8.284-.992-.843.248-1.141-1.042-.347-1.24 3.025-.893 6.746-.447 9.325 1.14.694.447 0 1.538-.694 1.092M5.738 8.399c-.843.297-1.389-1.141-.446-1.488 2.926-.893 7.887-.744 10.962 1.091.843.446.05 1.835-.843 1.34-2.679-1.588-7.143-1.737-9.673-.943"
    />
  </svg>
);
export default SvgSocialSpotify;
