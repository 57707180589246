import { Asserts, object, string } from "yup";
import { PromptType } from "../../enums";
import { BasePromptSchema, PromptMessage } from "./BasePromptSchema";

export const CampaignPromptSchema = BasePromptSchema.shape({
  type: string().oneOf(["Campaign"]).required(),
  who: string().required("You have to describe yourself"),
  what: string().required("You have to describe what campaign you want us to create"),
  audience: string(),
});

export type CampaignPromptPayload = Asserts<typeof CampaignPromptSchema> & {
  type: "Campaign";
};

export const UpdatePromptSchema = object({
  content: string().required(),
});
export type UpdatePromptPayload = Asserts<typeof UpdatePromptSchema>;

export type ExtendedCampaignPromptPayload = CampaignPromptPayload & {
  type: PromptType;
  messages: PromptMessage[];
};

export type PersistedPrompt = {
  id: string;
  who?: string;
  what?: string;
  audience?: string;
  type: PromptType;
};

export const EmptyCampaignPrompt: CampaignPromptPayload = {
  who: "",
  what: "",
  type: "Campaign",
};
