import classNames from "classnames";
import { PropsWithClassNameAndChildren, PropsWithDir } from "../types";
import { textStyles } from "../styles";

interface Props extends PropsWithClassNameAndChildren, PropsWithDir {}
export function SubheaderMain({ dir, children, className }: Props) {
  return (
    <h1 dir={dir} className={classNames(textStyles.lsColor, textStyles.size125, textStyles.weight600, className)}>
      {children}
    </h1>
  );
}
