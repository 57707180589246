import classNames from "classnames";
import { Card } from "../Card";
import { Icon } from "../Icon";
import { PropsWithClassName, PropsWithIconType, PropsWithLabel, PropsWithOptionalClick } from "../types";
import { TextAccent } from "../typography";
import {
  bgStyles,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  pointerStyles,
  textStyles,
  transitionStyles,
} from "../styles";

interface Props extends PropsWithIconType, PropsWithOptionalClick, PropsWithLabel, PropsWithClassName {}
export function CardButton({ onClick, iconType, label, className }: Props) {
  return (
    <Card
      noBackground
      className={classNames(
        bgStyles.customButton,
        textStyles.customButtonText,
        iconStyles.customButtonText,
        transitionStyles.all200,
        flexStyles.horiz,
        flexStyles.justifyButtonTheme,
        paddingStyles.p125,
        borderStyles.radiusTheme,
        {
          [pointerStyles.cursor]: !!onClick,
        },
        className,
      )}
      onClick={onClick}
    >
      <TextAccent>{label}</TextAccent>
      <Icon iconType={iconType} />
    </Card>
  );
}
