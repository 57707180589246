import classNames from "classnames";
import { Card } from "./Card";
import { bgStyles, borderStyles, flexStyles, iconStyles, paddingStyles, squareStyles, widthStyles } from "./styles";
import { Icon, IconType } from "./Icon";
import { PropsWithClassName, PropsWithOnClick } from "./types";
import { PropsWithChildren } from "react";

interface Args extends PropsWithOnClick, PropsWithClassName, PropsWithChildren {
  iconType: IconType;
}
export function SquareCard({ iconType, onClick, className, children }: Args) {
  return (
    <Card
      onClick={onClick}
      className={classNames(
        paddingStyles.p125,
        flexStyles.vert075,
        widthStyles.min800,
        flexStyles.alignCenter,
        className,
      )}
    >
      <Icon
        iconType={iconType}
        className={classNames(
          flexStyles.alignCenter,
          squareStyles.square250,
          borderStyles.radiusRound,
          bgStyles.secondary100,
          iconStyles.secondary500,
          iconStyles.size150,
        )}
      />
      {children}
    </Card>
  );
}
