import { useFormContext, useWatch } from "react-hook-form";
import { TextAlignment } from "schemas";
import { Description, flexStyles, textStyles } from "ui";
import { RadioCard } from "../ui";

export function AlignmentRadio() {
  const name = "buttonTextAlignment";
  const value: TextAlignment = useWatch({ name });
  const { setValue } = useFormContext();
  const onClick = (newValue: TextAlignment) => {
    setValue(name, newValue, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  return (
    <div className={flexStyles.vert050}>
      <Description className={textStyles.neutral400}>Corner Radius</Description>
      <RadioCard checked={value === "Default"} title="Default (LRT or RTL)" onClick={() => onClick("Default")} />
      <RadioCard checked={value === "Center"} title="Centered" onClick={() => onClick("Center")} />
    </div>
  );
}
