import { apiRequest } from "../apiRequest";

export async function postCheckoutSession(priceId: string): Promise<string> {
  const { url } = await apiRequest<{ url: string }>({
    url: "stripe/session",
    method: "POST",
    body: JSON.stringify({ priceId }),
  });
  return url;
}
