import { useEffect } from "react";
import { useLiteSiteStore } from "../store";
import { getAppointment } from "./getAppointment";

export function useAppointment(id: string) {
  const appointment = useLiteSiteStore((state) => state.appointments.get(id));
  useEffect(() => {
    if (id && !appointment) {
      getAppointment(id);
    }
  }, [appointment, id]);

  return appointment;
}
