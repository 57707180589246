import type { SVGProps } from "react";
const SvgEmailNewsletter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#465357"
      d="M3.5 14a1.63 1.63 0 0 1-1.062-.469A1.39 1.39 0 0 1 2 12.5V5.958q0-.437.24-.802a1.53 1.53 0 0 1 .635-.552L8.5 2l5.625 2.604q.292.125.5.365t.292.531H12.5l-4-1.854-5 2.312zm3 3a1.44 1.44 0 0 1-1.052-.448A1.44 1.44 0 0 1 5 15.5v-7q0-.625.448-1.062Q5.897 7 6.5 7h10q.604 0 1.052.438Q18 7.875 18 8.5v7q0 .604-.448 1.052A1.44 1.44 0 0 1 16.5 17zm5-4-5-2.771V15.5h10v-5.271zm0-1.729 5-2.771h-10z"
    />
  </svg>
);
export default SvgEmailNewsletter;
