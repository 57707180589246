import classNames from "classnames";
import { CheckboxItem, PropsWithName, Toggler, flexStyles, opacityStyles, widthStyles } from "ui";
import { useToggle } from "./useToggle";
import { ReactNode } from "react";

interface Props extends PropsWithName {
  id: string;
  disabled?: boolean;
  children: (toggle: Toggler) => ReactNode;
}
export function OuterSelectable({ id, name, children, disabled }: Props) {
  const { toggle } = useToggle({ name, id });
  const maybeToggle = disabled ? () => {} : toggle;

  return (
    <div
      className={classNames(flexStyles.horiz125, flexStyles.alignCenter, flexStyles.justifyStretch, widthStyles.full, {
        [opacityStyles.transparentDisabled]: disabled,
      })}
    >
      <CheckboxItem onClick={maybeToggle} name={name} id={id} />
      {children(maybeToggle)}
    </div>
  );
}
