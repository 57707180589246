import { PropsWithChildren, ReactNode } from "react";
import { SuperCard } from "./SuperCard";
import { PropsWithOnClick, RawIcon } from "ui";

interface Props extends PropsWithChildren, PropsWithOnClick {
  left?: ReactNode;
  right?: ReactNode;
  title: ReactNode;
  checked: boolean;
}

export function RadioCard({ children, left, right, checked, title, onClick }: Props) {
  const checkIcon = checked ? "RadioChecked" : "RadioUnchecked";
  const check = <RawIcon iconType={checkIcon} />;

  const localLeft = right ? check : left;
  const localRight = right || check;
  return (
    <SuperCard left={localLeft} onClick={onClick} title={title} right={localRight}>
      {children}
    </SuperCard>
  );
}
