import { useWatch } from "react-hook-form";
import { ThemePayload, isGradientTheme } from "schemas";
import { TextInput } from "ui";

export function HexInput() {
  const theme = useWatch() as ThemePayload;

  if (isGradientTheme(theme)) {
    return null;
  }

  return <TextInput label="Or use your custom color" name="primaryColor" />;
}
