import type { SVGProps } from "react";
const SvgOnline = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <mask
      id="Online_svg__a"
      width={21}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M.25 0h20v20h-20z" />
    </mask>
    <g fill="#fff" mask="url(#Online_svg__a)">
      <path d="M7.25 17v-2h-3.5c-.413 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06V4.493c0-.413.147-.765.44-1.056A1.45 1.45 0 0 1 3.75 3h13c.413 0 .766.147 1.06.44.293.295.44.648.44 1.06v9.006c0 .413-.147.765-.44 1.056a1.45 1.45 0 0 1-1.06.438h-3.5v2h-6Zm-3.5-3.5h13v-9h-13v9Z" />
      <path d="M4.75 11.465V12h4.714v-.535c0-.238-.065-.45-.196-.64a1.233 1.233 0 0 0-.524-.43 4.561 4.561 0 0 0-.8-.25 3.96 3.96 0 0 0-.837-.09 4.29 4.29 0 0 0-.835.082 3.65 3.65 0 0 0-.802.259 1.106 1.106 0 0 0-.524.423 1.138 1.138 0 0 0-.196.646ZM11.036 11.465V12h4.714v-.535c0-.238-.066-.45-.196-.64a1.234 1.234 0 0 0-.524-.43 4.56 4.56 0 0 0-.8-.25 3.96 3.96 0 0 0-.837-.09 4.29 4.29 0 0 0-.835.082 3.65 3.65 0 0 0-.802.259 1.107 1.107 0 0 0-.524.423c-.13.196-.196.411-.196.646ZM6.272 9.323c.23.229.507.344.832.344.325 0 .603-.114.834-.34a1.11 1.11 0 0 0 .348-.824c0-.321-.115-.597-.344-.826a1.133 1.133 0 0 0-.831-.344 1.15 1.15 0 0 0-.835.34 1.11 1.11 0 0 0-.347.823c0 .322.114.598.343.827ZM12.558 9.323c.23.229.506.344.831.344.325 0 .604-.114.835-.34a1.11 1.11 0 0 0 .347-.824c0-.321-.114-.597-.343-.826a1.133 1.133 0 0 0-.832-.344 1.15 1.15 0 0 0-.835.34 1.11 1.11 0 0 0-.347.823c0 .322.115.598.344.827Z" />
    </g>
  </svg>
);
export default SvgOnline;
