import { FormProvider, FormTag, PasswordInput, ReCaptcha, SubheaderMain, TextInput, textStyles } from "ui";
import { EmptyLogin, LoginPayload, LoginSchema } from "schemas";
import { toast } from "react-toastify";
import { GoogleButton } from "../ui/GoogleButton";
import { AuthScreen } from "./AuthScreen";
import { TheOtherMode } from "./TheOtherMode";
import { Link, Or, Submit } from "../ui";
import { login } from "../../store";

export function Login() {
  const onLogin = async (payload: LoginPayload) => {
    try {
      const user = await login({ payload });
      if (user) {
        window.location.href = "/";
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <FormProvider<LoginPayload> defaultValues={EmptyLogin} schema={LoginSchema}>
      <AuthScreen>
        <SubheaderMain>Welcome back!</SubheaderMain>
        <GoogleButton type="login" label="Continue with Google" />
        <Or />
        <FormTag<LoginPayload> onSubmit={onLogin}>
          <TextInput type="email" name="email" label="Email" />
          <PasswordInput name="password" label="Password" />
          <Link className={textStyles.center} to="/auth/passwordReset">
            Forgot password?
          </Link>
          <ReCaptcha action="login" />
          <Submit label="Login" disableClean disableInvalid />
        </FormTag>
        <TheOtherMode destination="signup" />
      </AuthScreen>
    </FormProvider>
  );
}
