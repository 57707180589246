import { Description, bgStyles, borderStyles, flexStyles, heightStyles, textStyles, widthStyles } from "ui";
import { RadioCard } from "../ui";
import { useFormContext, useWatch } from "react-hook-form";
import { BorderRadius } from "schemas";
import classNames from "classnames";

export function RadiusRadio() {
  const name = "buttonBorderRadius";
  const value: BorderRadius = useWatch({ name });
  const { setValue } = useFormContext();
  const onClick = (newValue: BorderRadius) => {
    setValue(name, newValue, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  return (
    <div className={flexStyles.vert050}>
      <Description className={textStyles.neutral400}>Corner Radius</Description>
      <RadioCard
        left={
          <div
            className={classNames(
              borderStyles.radius10000,
              widthStyles.width300,
              heightStyles.height150,
              bgStyles.neutral200,
              borderStyles.neutral300,
            )}
          />
        }
        checked={value === "Rounded"}
        title="Rounded"
        onClick={() => onClick("Rounded")}
      />
      <RadioCard
        left={
          <div
            className={classNames(
              borderStyles.radius025,
              widthStyles.width300,
              heightStyles.height150,
              bgStyles.neutral200,
              borderStyles.neutral300,
            )}
          />
        }
        checked={value === "Smooth"}
        title="Smooth"
        onClick={() => onClick("Smooth")}
      />
      <RadioCard
        left={
          <div
            className={classNames(
              widthStyles.width300,
              heightStyles.height150,
              bgStyles.neutral200,
              borderStyles.neutral300,
            )}
          />
        }
        checked={value === "Square"}
        title="Square"
        onClick={() => onClick("Square")}
      />
    </div>
  );
}
