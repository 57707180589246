import {
  Icon,
  PropsWithClassName,
  PropsWithIconType,
  RawIcon,
  bgStyles,
  borderStyles,
  flexStyles,
  squareStyles,
} from "ui";
import { RawLink } from "./Link";
import classNames from "classnames";

interface Props extends PropsWithClassName, PropsWithIconType {
  to: string;
  raw?: boolean;
  preventScrollReset?: boolean;
}
export function IconLink({ className, iconType, to, preventScrollReset, raw = false }: Props) {
  const IconComponent = raw ? RawIcon : Icon;

  return (
    <RawLink
      to={to}
      preventScrollReset={preventScrollReset}
      className={classNames(
        flexStyles.horiz,
        flexStyles.justifyCenter,
        flexStyles.alignCenter,
        bgStyles.initialNone,
        borderStyles.initialNone,
        borderStyles.radiusRound,
        squareStyles.square125,
        className,
      )}
    >
      <IconComponent iconType={iconType} />
    </RawLink>
  );
}
