import { useLocation } from "react-router-dom";

export function getUrlWithBack(url: string, pathname: string) {
  return `${url}?backTo=${encodeURIComponent(pathname)}`;
}

export function useUrlWithBack(url: string) {
  const { pathname } = useLocation();
  return getUrlWithBack(url, pathname);
}
