import classNames from "classnames";
import { ReactNode } from "react";
import { borderStyles, flexStyles, gridStyles, heightStyles, iconStyles, paddingStyles } from "ui";

interface Props {
  title: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  noBorder?: boolean;
}

export function Header({ left, title, right, noBorder }: Props) {
  return (
    <div
      className={classNames(
        iconStyles.secondary300,
        gridStyles.gap050,
        flexStyles.alignCenter,
        heightStyles.min375,
        paddingStyles.py100,
        paddingStyles.px125,
        { [gridStyles.header2]: !left },
        { [gridStyles.header3]: left },
        { [borderStyles.bottomNeutral100]: !noBorder },
      )}
    >
      {left && <div className={gridStyles.areaL}>{left}</div>}
      <div className={gridStyles.areaT}>{title}</div>
      {right && <div className={classNames(iconStyles.primary, gridStyles.areaR)}>{right}</div>}
    </div>
  );
}
