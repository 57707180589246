import { PersistedPage } from "schemas";
import classNames from "classnames";
import { HeaderMain, Markdown, PopoverLinkItem, PopoverMenu, flexStyles, textStyles, widthStyles } from "ui";
import { EditWrapper } from "../blocks";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface Props {
  page: PersistedPage;
}
export function EditPageDetails({ page }: Props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const links: PopoverLinkItem[] = [
    {
      label: "Edit Details",
      onClick: () => navigate("edit"),
      iconType: "Edit",
    },
  ];

  const contents = (
    <>
      <HeaderMain>{page.name}</HeaderMain>
      {page.description && <Markdown className={widthStyles.full}>{page.description}</Markdown>}
    </>
  );

  const editElement = open && (
    <>
      {contents}
      <PopoverMenu open links={links} />
    </>
  );

  return (
    <EditWrapper
      editElement={editElement}
      className={classNames(textStyles.left, flexStyles.gap100, flexStyles.alignStart)}
      open={open}
      onClick={() => setOpen(!open)}
    >
      {contents}
    </EditWrapper>
  );
}
