import classNames from "classnames";
import { PropsWithChildren } from "react";
import { PropsWithClassName, bgStyles, flexStyles, paddingStyles, positionStyles } from "ui";

interface Props extends PropsWithClassName, PropsWithChildren {
  sticky?: boolean;
}
export function TransparentBottomWrapper({ children, className, sticky }: Props) {
  return (
    <div
      className={classNames(flexStyles.vert100, paddingStyles.p125, className, {
        [positionStyles.stickyBottom]: sticky,
      })}
    >
      {children}
    </div>
  );
}

export function BottomWrapper({ children, className, sticky }: Props) {
  return (
    <TransparentBottomWrapper sticky={sticky} className={classNames(bgStyles.neutral100, className)}>
      {children}
    </TransparentBottomWrapper>
  );
}
