import { PersistedCalendar } from "schemas";
import { useLiteSiteStore } from "..";
import { useEffect, useState } from "react";
import { HookLoading } from "../types";
import { apiRequest } from "../apiRequest";

async function getCalendars() {
  const calendars = await apiRequest<PersistedCalendar[]>({ url: "calendars" });
  useLiteSiteStore.setState(() => ({ calendars }));
}

export function useCalendars(): HookLoading<{ calendars: PersistedCalendar[] }> {
  const [loading, setLoading] = useState<boolean>(true);
  const calendars = useLiteSiteStore((state) => state.calendars);

  useEffect(() => {
    if (loading) {
      getCalendars().then(() => setLoading(false));
    }
  }, [loading]);

  return { loading, calendars };
}
