import type { SVGProps } from "react";
const SvgCalendarSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <mask
      id="CalendarSettings_svg__a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g fill="#F0A056" mask="url(#CalendarSettings_svg__a)">
      <path
        fillRule="evenodd"
        d="m14.125 19-.27-1.188a2.832 2.832 0 0 1-.584-.25 2.139 2.139 0 0 1-.438-.333l-1.166.354L11 16.438l.875-.834a3.127 3.127 0 0 1-.063-.614c0-.202.021-.4.063-.594L11 13.583l.667-1.146 1.146.334c.166-.153.333-.275.5-.365.166-.09.347-.17.541-.24L14.125 11h1.333l.271 1.167c.195.055.386.132.573.229.188.097.358.222.51.375l1.105-.313.687 1.146-.854.792a2.623 2.623 0 0 1-.02 1.23l.874.812-.687 1.145-1.146-.333c-.125.139-.271.253-.438.344-.166.09-.368.17-.604.24L15.438 19h-1.313Zm.688-2.5c.416 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.063a1.446 1.446 0 0 0-1.063-.437c-.416 0-.77.146-1.062.438A1.446 1.446 0 0 0 13.312 15c0 .417.146.77.438 1.063.292.291.646.437 1.063.437Z"
        clipRule="evenodd"
      />
      <path d="M4.5 16c-.403.014-.753-.132-1.052-.438A1.475 1.475 0 0 1 3 14.5v-9c0-.412.15-.766.448-1.06.299-.293.65-.44 1.052-.44h1V2H7v2h4V2h1.5v2h1c.403 0 .757.146 1.063.438.305.291.45.645.437 1.062V10c-.25 0-.5.02-.75.063-.25.041-.5.104-.75.187V9h-9v5.5h5.542c-.028.236-.038.48-.032.73.007.25.039.506.094.77H4.5Zm0-8.5h9v-2h-9v2Z" />
    </g>
  </svg>
);
export default SvgCalendarSettings;
