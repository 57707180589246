"use client";

import { useState } from "react";
import classNames from "classnames";
import { PropsWithClassName } from "../types";
import { TableRow } from "./TableRow";
import { MoreRow } from "./MoreRow";
import { flexStyles } from "../styles";

interface Props extends PropsWithClassName {
  rows: string[];
  more?: string[];
  moreLabel?: string;
}
export function SimpleTable({ className, more = [], moreLabel, rows }: Props) {
  const [moreOpen, setMoreOpen] = useState(false);

  return (
    <div className={classNames(flexStyles.vert, flexStyles.alignStretch, className)}>
      {rows.map((label) => (
        <TableRow key={label} label={label} />
      ))}
      {moreLabel && <MoreRow open={moreOpen} label={moreLabel} onClick={setMoreOpen} />}
      {moreOpen && more.map((label) => <TableRow key={label} label={label} />)}
    </div>
  );
}
