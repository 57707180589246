import type { SVGProps } from "react";
const SvgShuffle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#191B20"
      d="M12 16v-1.5h1.438l-2.376-2.375 1.063-1.062 2.375 2.374V12H16v4zm-6.937 0L4 14.938 13.438 5.5H12V4h4v4h-1.5V6.563zm2.812-7.062L4 5.062 5.063 4l3.875 3.875z"
    />
  </svg>
);
export default SvgShuffle;
