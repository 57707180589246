import { SVGProps } from "react";

const SvgFeedback = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Feedback_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Feedback_svg__a)">
      <path
        d="M9.65 11.458h5.142v-1.333h-3.81L9.65 11.458Zm-4.281 0h1.697l4.779-4.695a.349.349 0 0 0 .137-.273.412.412 0 0 0-.137-.293l-1.153-1.173a.349.349 0 0 0-.272-.138.412.412 0 0 0-.293.138L5.369 9.782v1.676Zm-3.286 6.074V3.673c0-.444.154-.82.462-1.128a1.534 1.534 0 0 1 1.128-.462h12.654c.444 0 .82.154 1.128.462.308.308.462.684.462 1.128v9.32c0 .444-.154.82-.462 1.128a1.534 1.534 0 0 1-1.128.462H5.032l-2.949 2.949Zm1.334-3.227L4.47 13.25h11.856a.25.25 0 0 0 .184-.072.25.25 0 0 0 .072-.184v-9.32a.25.25 0 0 0-.072-.185.25.25 0 0 0-.184-.072H3.673a.25.25 0 0 0-.184.072.25.25 0 0 0-.072.184v10.632Zm0-10.632v-.256 10.888V3.673Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgFeedback;
