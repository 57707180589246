import { PropsWithChildren, ReactNode } from "react";
import classNames from "classnames";
import { MobileOverlayHeader } from "./MobileOverlayHeader";
import {
  PropsWithClassName,
  PropsWithOptionalDotMenu,
  bgStyles,
  flexStyles,
  marginStyles,
  paddingStyles,
  positionStyles,
  widthStyles,
} from "ui";
import { useBreakpoint } from "../../../hooks";
import { BackTo, OverlayHeader } from "./OverlayHeader";

interface Props extends PropsWithClassName, PropsWithChildren, PropsWithOptionalDotMenu {
  backTo?: BackTo;
  header: string;
  title?: string;
  subtitle?: ReactNode;
  subHeaderElement?: ReactNode;
  full?: boolean;
  transparent?: boolean;
}

export function Overlay({
  className,
  backTo,
  header,
  title,
  subtitle,
  children,
  subHeaderElement,
  links,
  full = false,
  transparent = false,
}: Props) {
  const isBig = useBreakpoint("mobile");
  return (
    <div
      className={classNames(flexStyles.vert, widthStyles.full, flexStyles.grow, {
        [bgStyles.neutral100]: !transparent,
      })}
    >
      {isBig ? (
        <OverlayHeader
          subHeaderElement={subHeaderElement}
          title={title}
          header={header}
          backTo={backTo}
          links={links}
        />
      ) : (
        <MobileOverlayHeader
          subHeaderElement={subHeaderElement}
          title={title}
          header={header}
          backTo={backTo}
          links={links}
        />
      )}
      {subtitle && (
        <div className={classNames(flexStyles.vert, flexStyles.alignCenter, { [bgStyles.neutral0]: !isBig })}>
          <div
            className={classNames(
              flexStyles.vert050,
              paddingStyles.px125,
              paddingStyles.pb050,
              widthStyles.full,
              widthStyles.maxWidth4225,
            )}
          >
            {subtitle}
          </div>
        </div>
      )}
      <div
        className={classNames(
          flexStyles.vert,
          flexStyles.grow,
          positionStyles.relative,
          {
            [widthStyles.full]: !full,
            [widthStyles.maxWidth4225]: !full,
            [marginStyles.centered]: !full,
          },
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
}
