import { ThemePayload } from "../schemas/Theme";

export function themeButtonAlignment(theme: ThemePayload) {
  switch (theme.buttonTextAlignment) {
    case "Default":
      return "space-between";
    case "Center":
      return "center";
  }
}
