import { BusinessLinkInput } from "../ui";
import { BusinessForm } from "./BusinessForm";

export function LinkForm() {
  return (
    <BusinessForm
      toastText="Your business link updated successfully"
      header="Edit Link"
      closeUrl="/content"
      submitText="Apply changes"
    >
      <BusinessLinkInput />
    </BusinessForm>
  );
}
