export type SocialNetwork =
  | "Amazon"
  | "Bandcamp"
  | "Behance"
  | "DeviantArt"
  | "Discord"
  | "Dribbble"
  | "Facebook"
  | "Figma"
  | "Github"
  | "Google"
  | "Instagram"
  | "Line"
  | "Linkedin"
  | "Medium"
  | "Messenger"
  | "MySpace"
  | "Pinterest"
  | "QQ"
  | "Reddit"
  | "Signal"
  | "Skype"
  | "Slack"
  | "Snapchat"
  | "Spotify"
  | "Teams"
  | "Telegram"
  | "Threads"
  | "Tumblr"
  | "Tiktok"
  | "Tinder"
  | "Twitch"
  | "Twitter"
  | "Viber"
  | "Vimeo"
  | "VK"
  | "WeChat"
  | "Whatsapp"
  | "Youtube";

export const SocialNetworkDomains: Record<string, SocialNetwork> = {
  "amazon.com": "Amazon",
  "bandcamp.com": "Bandcamp",
  "behance.net": "Behance",
  "deviantart.com": "DeviantArt",
  "discord.com": "Discord",
  "dribbble.com": "Dribbble",
  "facebook.com": "Facebook",
  "fb.com": "Facebook",
  "figma.com": "Figma",
  "github.com": "Github",
  "google.com": "Google",
  "instagram.com": "Instagram",
  "instagr.am": "Instagram",
  "line.me": "Line",
  "linkedin.com": "Linkedin",
  "medium.com": "Medium",
  "messenger.com": "Messenger",
  "fb.com/msg": "Messenger",
  "myspace.com": "MySpace",
  "pinterest.com": "Pinterest",
  "pin.it": "Pinterest",
  "qq.com": "QQ",
  "reddit.com": "Reddit",
  "redd.it": "Reddit",
  "signal.org": "Signal",
  "skype.com": "Skype",
  "slack.com": "Slack",
  "snapchat.com": "Snapchat",
  "snap.com": "Snapchat",
  "spotify.com": "Spotify",
  "teams.microsoft.com": "Teams",
  "telegram.org": "Telegram",
  "t.me": "Telegram",
  "tumblr.com": "Tumblr",
  "tiktok.com": "Tiktok",
  "tinder.com": "Tinder",
  "twitch.tv": "Twitch",
  "twitter.com": "Twitter",
  "t.co": "Twitter",
  "viber.com": "Viber",
  "vimeo.com": "Vimeo",
  "vk.com": "VK",
  "wechat.com": "WeChat",
  "whatsapp.com": "Whatsapp",
  "wa.me": "Whatsapp",
  "youtube.com": "Youtube",
  "youtu.be": "Youtube",
};

export function findNetworkForUrl(url: string): SocialNetwork | undefined {
  const { href } = new URL(url);
  const tuple = Object.entries(SocialNetworkDomains).find(([domain]) => href.includes(domain));
  return tuple ? tuple[1] : undefined;
}
