import { Asserts, object, string } from "yup";
import { FontFamily, FontFamilyValues, TextAlignmentValues } from "../../themes";

export const TextBlockSchema = object({
  text: string().required("Text is required"),
  fontFamily: string().oneOf(FontFamilyValues).nullable(),
  textAlignment: string().oneOf(TextAlignmentValues).required(),
  kind: string().oneOf(["TextBlock"]).required(),
}).noUnknown();
export type TextBlockPayload = Asserts<typeof TextBlockSchema> & {
  kind: "TextBlock";
};
export type PersistedTextBlock = TextBlockPayload & {
  _id: string;
  fontFamily: FontFamily;
};
