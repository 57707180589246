import { ChangeEvent } from "react";
import { PropsWithClassName } from "../../types";
import { FileInputType } from "./FileInputType";

interface Props extends PropsWithClassName {
  onSelectFile: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  type: FileInputType;
}

const fileTypes: Record<FileInputType, string> = {
  image: ".png,.gif,.jpeg,.jpg,.webp,.heic,.heif",
  csv: ".csv",
};

export function RawFileInput({ onSelectFile, disabled = false, type, className }: Props) {
  return (
    <input className={className} disabled={disabled} type="file" onChange={onSelectFile} accept={fileTypes[type]} />
  );
}
