import { PersistedCalendar } from "schemas";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

export async function deleteCalendar(id: string) {
  await apiRequest<PersistedCalendar>({ url: `calendars/${id}`, method: "DELETE" });
  useLiteSiteStore.setState(({ calendars }) => {
    const filtered = calendars.filter((calendar) => calendar.id !== id);
    return { calendars: filtered };
  });
}
