import { ImageInput, flexStyles } from "ui";
import { BusinessForm } from "./BusinessForm";
import { uploadImage } from "../../store";

export function LogoForm() {
  return (
    <BusinessForm
      toastText="Your image updated successfully"
      header="Edit Logo"
      closeUrl="/content"
      submitText="Apply changes"
    >
      <ImageInput
        className={flexStyles.alignSelfCenter}
        urlValue
        cropAspect="Round"
        name="logo"
        uploadImage={uploadImage}
      />
    </BusinessForm>
  );
}
