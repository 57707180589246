import { SVGProps } from "react";
const SvgFormDateFieldBox = (props: SVGProps<SVGSVGElement>) => (
  <svg width={295} height={53} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={294} height={52} rx={3.5} fill="#fff" />
    <path
      d="M20.116 21.242c1.064 0 1.983.2 2.758.602a4.09 4.09 0 0 1 1.792 1.694c.42.737.63 1.605.63 2.604 0 .999-.21 1.867-.63 2.604-.41.728-1.008 1.288-1.792 1.68-.775.383-1.694.574-2.758.574h-3.038v-9.758h3.038Zm0 8.708c1.26 0 2.221-.331 2.884-.994.663-.672.994-1.61.994-2.814 0-1.213-.336-2.16-1.008-2.842-.663-.681-1.62-1.022-2.87-1.022h-1.764v7.672h1.764Zm9.898-8.708c1.064 0 1.984.2 2.758.602a4.09 4.09 0 0 1 1.792 1.694c.42.737.63 1.605.63 2.604 0 .999-.21 1.867-.63 2.604-.41.728-1.008 1.288-1.792 1.68-.774.383-1.694.574-2.758.574h-3.038v-9.758h3.038Zm0 8.708c1.26 0 2.222-.331 2.884-.994.663-.672.994-1.61.994-2.814 0-1.213-.336-2.16-1.008-2.842-.662-.681-1.619-1.022-2.87-1.022H28.25v7.672h1.764Zm11.62-12.138L37.8 33.38h-1.26l3.822-15.568h1.274Zm11.797 3.5V31h-1.274v-7.224L48.937 31h-.896l-3.234-7.238V31h-1.274v-9.688h1.372l3.584 8.008 3.584-8.008h1.358Zm12.059 0V31h-1.274v-7.224L60.996 31H60.1l-3.234-7.238V31h-1.274v-9.688h1.372l3.584 8.008 3.584-8.008h1.358Zm6.92-3.5L68.574 33.38h-1.26l3.822-15.568h1.274Zm8.73 3.43-3.177 6.076V31h-1.274v-3.682l-3.192-6.076h1.413l2.409 4.942 2.407-4.942h1.415Zm8.176 0-3.178 6.076V31h-1.274v-3.682l-3.192-6.076h1.414l2.408 4.942 2.408-4.942h1.414Zm8.176 0-3.178 6.076V31H93.04v-3.682l-3.192-6.076h1.414l2.408 4.942 2.408-4.942h1.414Zm8.176 0-3.178 6.076V31h-1.274v-3.682l-3.192-6.076h1.414l2.408 4.942 2.408-4.942h1.414Z"
      fill="#D1D4D5"
    />
    <mask
      id="FormDateFieldBox_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={259}
      y={16}
      width={20}
      height={21}
    >
      <path fill="#D9D9D9" d="M259 16.5h20v20h-20z" />
    </mask>
    <g mask="url(#FormDateFieldBox_svg__a)">
      <path
        d="M263.5 34.5c-.417 0-.771-.15-1.062-.448A1.456 1.456 0 0 1 262 33V22c0-.403.146-.753.438-1.052a1.427 1.427 0 0 1 1.062-.448h1.5v-2h1.5v2h5v-2h1.5v2h1.5c.417 0 .771.15 1.062.448.292.299.438.65.438 1.052v11c0 .403-.146.753-.438 1.052a1.427 1.427 0 0 1-1.062.448h-11Zm0-1.5h11v-7.5h-11V33Zm0-9h11v-2h-11v2Z"
        fill="#191B20"
      />
    </g>
    <rect x={0.5} y={0.5} width={294} height={52} rx={3.5} stroke="#848C8F" />
  </svg>
);
export default SvgFormDateFieldBox;
