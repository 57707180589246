import { Asserts, number, object, string } from "yup";
import { LinkValidation } from "./Link";
import { BusinessPlan, FeatureFlagsRecord } from "../enums";
import { BookingIncrementValues, SoonestBookingHoursValues } from "../consts";
import { EmailValidation } from "./shared";

export const BusinessNameString = string().required("Business name is required");

export const BusinessSchema = object({
  businessName: BusinessNameString,
  link: LinkValidation,
  externalUrl: string().url().nullable(),
  description: string().max(300, "Description can not be longer than 300 characters"),
  logo: string().url().nullable(),
  timeZone: string(),
  address: string(),
  emailSettings: object({ fromEmail: string(), fromName: string(), replyToEmail: EmailValidation }),
}).noUnknown();

export const PgBusinessSchema = object({
  soonestBookingHours: number().oneOf(SoonestBookingHoursValues),
  bookingIncrementMinutes: number().oneOf(BookingIncrementValues),
}).noUnknown();

export type SharedBusiness = {
  _id: string;
  businessName: string;
  address?: string;
  description?: string;
  link?: string;
  logo?: string;
  externalUrl?: string;
  timeZone: string;
  relativeUrl: string;
  resolvedLink: string;
  paidFeatures: {
    multipleCalendars: boolean;
    noBranding: boolean;
    smsReminders: boolean;
  };
  customerPayments: {
    stripeAccountId?: string;
  };
};
export type BusinessPayload = Asserts<typeof BusinessSchema>;

export type PgBusinessPayload = Asserts<typeof PgBusinessSchema>;
export type BusinessKey = keyof PgBusinessPayload;

export type PersistedBusiness = SharedBusiness &
  BusinessPayload &
  PgBusinessPayload & {
    url: string;
    plan: BusinessPlan;
    planName: string;
    customerPayments: {
      enabled: boolean;
      currency?: string;
    };
    featureFlags: FeatureFlagsRecord;
    hasZoom: boolean;
    hasAppointments: boolean;
    allContactsGroupId: string;
    emailSettings: {
      fromEmail?: string;
      fromName?: string;
      replyToEmail?: string;
      resolvedFromEmail: string;
      resolvedFromName: string;
      resolvedReplyToEmail: string;
    };
  };

export const EmptyBusiness: BusinessPayload = {
  businessName: "",
  description: "",
  logo: undefined,
  timeZone: undefined,
  emailSettings: {},
};
