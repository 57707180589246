import { ContactGroupPayload } from "schemas";
import { FormTag, TextInput } from "ui";
import { Submit } from "../ui";

interface Props {
  onSubmit: (payload: ContactGroupPayload) => Promise<void>;
  cancelTo: string;
}

export function Form({ cancelTo, onSubmit }: Props) {
  return (
    <FormTag<ContactGroupPayload> onSubmit={onSubmit}>
      <TextInput name="name" label="Name" maxCharacterCount={80} />
      <TextInput name="description" label="Description (optional)" />
      <Submit cancelTo={cancelTo} />
    </FormTag>
  );
}
