import { ReactNode, useRef } from "react";
import classNames from "classnames";
import {
  DotMenu,
  PropsWithOptionalDotMenu,
  SmallTextMain,
  SubheaderMain,
  bgStyles,
  flexStyles,
  paddingStyles,
  pointerStyles,
  positionStyles,
  shadowStyles,
  transitionStyles,
  widthStyles,
} from "ui";
import { useIntersection } from "react-use";
import { BackTo } from "./OverlayHeader";
import { IconLink } from "../IconLink";

interface Props extends PropsWithOptionalDotMenu {
  header: string;
  title?: string;
  subHeaderElement?: ReactNode;
  backTo?: BackTo;
}

export function MobileOverlayHeader({ backTo, header, links, title, subHeaderElement }: Props) {
  const intersectionRef = useRef(null);
  const topIntersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "-76px",
    threshold: 1,
  });

  return (
    <>
      <div ref={intersectionRef} />
      <div
        className={classNames(
          transitionStyles.all200,
          positionStyles.stickyTop,
          positionStyles.zIndex5,
          flexStyles.vert,
          bgStyles.neutral0,
          flexStyles.alignCenter,
          {
            [shadowStyles.shadow30]: !topIntersection?.isIntersecting,
          },
        )}
      >
        <div
          className={classNames(
            flexStyles.vert050,
            positionStyles.relative,
            paddingStyles.p125,
            paddingStyles.pb100,
            widthStyles.full,
          )}
        >
          <div className={classNames(flexStyles.horiz, flexStyles.alignCenter, flexStyles.justifyCenter)}>
            {backTo && (
              <IconLink
                to={backTo.to}
                iconType="BackArrow"
                className={classNames(positionStyles.absolute, positionStyles.left075, pointerStyles.cursor)}
              />
            )}
            <SmallTextMain>{header}</SmallTextMain>
            {links && (
              <DotMenu className={classNames(positionStyles.absolute, positionStyles.right075)} links={links} />
            )}
          </div>
          {subHeaderElement}
          {title && <SubheaderMain>{title}</SubheaderMain>}
        </div>
      </div>
    </>
  );
}
