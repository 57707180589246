import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import {
  Animation,
  AnimationType,
  TextAccent,
  TextMain,
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  paddingStyles,
  positionStyles,
  squareStyles,
  textStyles,
  transitionStyles,
  widthStyles,
} from "ui";
import { Dot } from "./Dot";

interface Args {
  animationType: AnimationType;
  label: string;
  href: string;
  smallIcon?: boolean;
  hasDot?: boolean;
}

export function DesktopLink({ label, href, animationType, smallIcon, hasDot }: Args) {
  const { pathname } = useLocation();
  const active = pathname === href;

  const Text = active ? TextAccent : TextMain;

  return (
    <Link
      to={href}
      className={classNames(
        textStyles.primary,
        textStyles.decorationNone,
        iconStyles.primary,
        flexStyles.horiz050,
        flexStyles.alignCenter,
        paddingStyles.px050,
        paddingStyles.py075,
        paddingStyles.pe300,
        positionStyles.relative,
        borderStyles.leftRadius025,
        borderStyles.right250Neutral200,
        heightStyles.height400,
        transitionStyles.all200,
        {
          [bgStyles.hoverSecondary100]: !active,
          [bgStyles.secondary200]: active,
          [borderStyles.right250Secondary300]: active,
        },
      )}
    >
      {hasDot && <Dot className={classNames(positionStyles.absolute, positionStyles.top087, positionStyles.left087)} />}
      <Animation
        animationType={animationType}
        noAutoplay
        keepLastFrame
        oneWay
        togglePlay={active}
        className={classNames({
          [squareStyles.square250]: !smallIcon,
          [widthStyles.width250]: smallIcon,
          [heightStyles.height150]: smallIcon,
        })}
      />
      <Text>{label}</Text>
    </Link>
  );
}
