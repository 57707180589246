import { Drawer, FormProvider, FormTag, TextInput, TextMain, flexStyles } from "ui";
import { useNavigate } from "react-router-dom";
import { BusinessPayload, BusinessSchema } from "schemas";
import { Submit } from "../ui";
import { updateBusiness, useCurrentBusiness } from "../../store";

export function EditBusinessAddress() {
  const business = useCurrentBusiness();
  const navigate = useNavigate();
  const { address } = business;
  const title = address ? "Edit Business Address" : "Add Business Address";
  const goBack = () => navigate("..");
  const onSubmit = async (payload: BusinessPayload) => {
    await updateBusiness(payload);
    goBack();
  };
  return (
    <Drawer open title={title} onClose={goBack}>
      <FormProvider defaultValues={business} schema={BusinessSchema}>
        <FormTag className={flexStyles.vert200} onSubmit={onSubmit}>
          <TextMain>
            To comply with email marketing regulations and provide transparency to your recipients, enter your
            company&apos;s physical address below.
          </TextMain>
          <TextInput autoFocus name="address" label="Company Address" />
          <Submit label="Save" cancelTo=".." />
        </FormTag>
      </FormProvider>
    </Drawer>
  );
}
