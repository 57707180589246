import { VideoBlockPayload } from "schemas";
import { InlineTextInput } from "ui";

interface Props {
  onSubmit: (payload: VideoBlockPayload) => Promise<void>;
}
export function VideoLinkEditor({ onSubmit }: Props) {
  return (
    <InlineTextInput size="Text" label="Youtube video link" name="video" placeholder="Video URL" onSubmit={onSubmit} />
  );
}
