import { LinkBlockPayload } from "schemas";
import { InlineTextInput } from "ui";
import { BlockPreview } from "./BlockPreview";

interface Props {
  onSubmit: (payload: LinkBlockPayload) => Promise<void>;
}
export function LinkBlockEditor({ onSubmit }: Props) {
  return (
    <>
      <BlockPreview />
      <InlineTextInput
        autofocus
        size="Text"
        label="Button label"
        name="label"
        placeholder="Button Label"
        onSubmit={onSubmit}
      />
      <InlineTextInput size="Text" label="Button link" name="url" placeholder="https://..." onSubmit={onSubmit} />
    </>
  );
}
