import { SVGProps } from "react";

const SvgCutOutRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={44} viewBox="0 0 20 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#CutOutRight_svg__a)">
      <mask id="CutOutRight_svg__b" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-315-208a4 4 0 0 1 4-4H16a4 4 0 0 1 4 4V6c0 2.21-1.84 3.935-3.928 4.658C11.374 12.284 8 16.748 8 22c0 5.252 3.374 9.716 8.072 11.343C18.16 34.065 20 35.79 20 38v142a4 4 0 0 1-4 4h-327a4 4 0 0 1-4-4V38c0-2.21 1.84-3.935 3.928-4.657C-306.374 31.716-303 27.252-303 22s-3.374-9.716-8.072-11.342C-313.16 9.934-315 8.209-315 6v-214Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-315-208a4 4 0 0 1 4-4H16a4 4 0 0 1 4 4V6c0 2.21-1.84 3.935-3.928 4.658C11.374 12.284 8 16.748 8 22c0 5.252 3.374 9.716 8.072 11.343C18.16 34.065 20 35.79 20 38v142a4 4 0 0 1-4 4h-327a4 4 0 0 1-4-4V38c0-2.21 1.84-3.935 3.928-4.657C-306.374 31.716-303 27.252-303 22s-3.374-9.716-8.072-11.342C-313.16 9.934-315 8.209-315 6v-214Z"
        fill="#FFF1FC"
      />
      <path
        d="m-311.072 10.658.327-.945-.327.945Zm0 22.685-.328-.945.328.944Zm327.144 0 .328-.946-.328.946Zm0-22.686.328.945-.328-.944ZM-311-213a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3v-2Zm327 0h-327v2H16v-2Zm5 5a5 5 0 0 0-5-5v2a3 3 0 0 1 3 3h2ZM21 6v-214h-2V6h2ZM9 22c0-4.813 3.091-8.906 7.4-10.398l-.655-1.89C10.656 11.475 7 16.31 7 22h2Zm7.4 10.398C12.09 30.905 9 26.813 9 22H7c0 5.691 3.656 10.526 8.745 12.288l.655-1.89ZM21 180V38h-2v142h2Zm-5 5a5 5 0 0 0 5-5h-2a3 3 0 0 1-3 3v2Zm-327 0H16v-2h-327v2Zm-5-5a5 5 0 0 0 5 5v-2a3 3 0 0 1-3-3h-2Zm0-142v142h2V38h-2Zm12-16c0 4.813-3.091 8.906-7.4 10.398l.655 1.89C-305.656 32.526-302 27.69-302 22h-2Zm-7.4-10.398c4.309 1.492 7.4 5.585 7.4 10.398h2c0-5.691-3.656-10.526-8.745-12.287l-.655 1.89ZM-316-208V6h2v-214h-2Zm5.255 217.713C-312.624 9.063-314 7.609-314 6h-2c0 2.81 2.304 4.807 4.6 5.602l.655-1.89ZM-314 38c0-1.609 1.376-3.062 3.255-3.712l-.655-1.89c-2.296.795-4.6 2.793-4.6 5.602h2Zm329.745-3.712C17.624 34.938 19 36.39 19 38h2c0-2.81-2.304-4.807-4.6-5.602l-.655 1.89ZM19 6c0 1.609-1.376 3.062-3.255 3.713l.655 1.89C18.696 10.806 21 8.808 21 6h-2Z"
        fill="#DF349B"
        mask="url(#CutOutRight_svg__b)"
      />
    </g>
    <defs>
      <clipPath id="CutOutRight_svg__a">
        <path fill="#fff" d="M0 0h20v44H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCutOutRight;
