import { useWindowSize } from "react-use";

type Breakpoint = "desktop" | "tablet" | "mobile";
const Pixels: Record<Breakpoint, number> = {
  desktop: 1000,
  tablet: 768,
  mobile: 576,
};

export function useBreakpoint(breakpoint: Breakpoint) {
  const { width } = useWindowSize();
  return width > Pixels[breakpoint];
}
