import { PersistedImageBlock } from "schemas";
import { Card } from "../Card";
import classNames from "classnames";
import {
  displayStyles,
  imageStyles,
  marginStyles,
  overflowStyles,
  paddingStyles,
  pointerStyles,
  widthStyles,
} from "../styles";

interface Props {
  block: PersistedImageBlock;
  disabled: boolean;
}

export function ImageCard({ block, disabled }: Props) {
  const onClick =
    disabled || !block.url
      ? undefined
      : () => {
          block.url && window.open(block.url, "_blank");
        };
  return (
    <Card
      noBackground
      onClick={onClick}
      className={classNames(widthStyles.full, overflowStyles.hidden, paddingStyles.p0, {
        [pointerStyles.cursor]: block.url,
      })}
    >
      <picture className={marginStyles.centered}>
        <source srcSet={`${block.image}/feed`} type="image/webp" />
        <img
          style={{ maxWidth: block.pixelWidth || undefined }}
          className={classNames(displayStyles.block, widthStyles.maxWidthFull, imageStyles.fitContain)}
          src={`${block.image}/feed`}
          alt={block.alt ? block.alt : undefined}
        />
      </picture>
    </Card>
  );
}
