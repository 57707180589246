import type { SVGProps } from "react";
const SvgZoom = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g clipPath="url(#Brands_svg__a)">
      <path fill="#2196F3" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z" />
      <path
        data-fill="non-fill"
        fill="#fff"
        fillRule="evenodd"
        d="M5.868 12.858h6.614v-4.85c0-.73-.592-1.322-1.323-1.322H4.545v4.85c0 .73.592 1.322 1.323 1.322Zm7.495-1.763 2.646 1.763V6.686l-2.645 1.763v2.646Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Brands_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgZoom;
