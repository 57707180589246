import { createImage } from "./createImage";

export interface Point {
  x: number;
  y: number;
}

export interface CroppedArea extends Point {
  width: number;
  height: number;
}

export async function getCroppedImg(imageSrc: string, pixelCrop: CroppedArea): Promise<Blob> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;

  /* setting canvas width & height allows us to
    resize from the original image resolution */
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, canvas.width, canvas.height);

  return new Promise((resolve, _reject) => {
    canvas.toBlob((file) => {
      file && resolve(file);
    }, "image/webp");
  });
}
