import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import classNames from "classnames";
import { PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import { useClickAway } from "react-use";
import { AddDivider } from "./AddDivider";
import styles from "./EditWrapper.module.css";
import { PropsWithClassName, PropsWithOnClick, flexStyles } from "ui";
import useTransition from "react-transition-state";
import { BlockKind } from "schemas";

interface Props extends PropsWithClassName, PropsWithChildren, PropsWithOnClick {
  last?: boolean;
  open: boolean;
  blockId?: string;
  kind?: BlockKind;
  index?: number;
  editElement: ReactNode;
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null;
}
export function EditWrapper({
  className,
  children,
  index,
  blockId,
  kind,
  dragHandleProps,
  last = false,
  open,
  onClick,
  editElement,
}: Props) {
  const ref = useRef(null);
  const events = open ? undefined : [];

  const [{ status }, toggle] = useTransition({
    timeout: 10,
    preEnter: true,
  });

  useEffect(() => {
    toggle(open);
  }, [open]);

  useClickAway(
    ref,
    () => {
      onClick();
    },
    events,
  );
  const hasIndex = index !== undefined;
  const maybeDrag = open ? undefined : dragHandleProps;

  return (
    <div ref={ref} dir="auto" className={classNames(flexStyles.vert100, className)} {...maybeDrag}>
      {hasIndex && blockId && kind && (
        <AddDivider blockId={blockId} kind={kind} visible={open && hasIndex} destinationIndex={index} />
      )}
      <div className={classNames(flexStyles.vert050, styles.wrapper, status)} onClick={open ? undefined : onClick}>
        {editElement || children}
      </div>
      {hasIndex && blockId && kind && (
        <AddDivider blockId={blockId} kind={kind} visible={(open || last) && !!hasIndex} destinationIndex={index + 1} />
      )}
    </div>
  );
}
