import { FormPayload, PersistedForm } from "schemas";
import { setForm } from "./setForm";
import { apiRequest } from "../apiRequest";

export async function updateForm(id: string, payload: FormPayload) {
  const form = await apiRequest<PersistedForm>({ url: `forms/${id}`, method: "PATCH", body: JSON.stringify(payload) });
  setForm(form);

  return form;
}
