import type { SVGProps } from "react";
const SvgFormatNumbered = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <mask
      id="FormatNumbered_svg__a"
      width={21}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M.357 0h20v20h-20z" />
    </mask>
    <g mask="url(#FormatNumbered_svg__a)">
      <path
        fill="#191B20"
        d="M3.357 17v-1h2v-.5h-1v-1h1V14h-2v-1h2.5c.142 0 .26.048.356.144a.484.484 0 0 1 .144.356v1c0 .142-.048.26-.144.356a.484.484 0 0 1-.356.144c.142 0 .26.048.356.144a.484.484 0 0 1 .144.356v1c0 .142-.048.26-.144.356a.484.484 0 0 1-.356.144h-2.5Zm0-5v-2c0-.142.048-.26.144-.356a.484.484 0 0 1 .356-.144h1.5V9h-2V8h2.5c.142 0 .26.048.356.144a.484.484 0 0 1 .144.356V10c0 .142-.048.26-.144.356a.484.484 0 0 1-.356.144h-1.5v.5h2v1h-3Zm1-5V4h-1V3h2v4h-1Zm3.5 8v-1.5h9.5V15h-9.5Zm0-4.25v-1.5h9.5v1.5h-9.5Zm0-4.25V5h9.5v1.5h-9.5Z"
      />
    </g>
  </svg>
);
export default SvgFormatNumbered;
