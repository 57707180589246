import type { SVGProps } from "react";
const SvgContactGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#F0A056"
      d="M0 15v-1.23c0-.707.313-1.263.938-1.666S2.417 11.5 3.5 11.5a6.287 6.287 0 0 1 1.25.125 3.18 3.18 0 0 0-.552.938A2.944 2.944 0 0 0 4 13.616V15H0Zm5 0v-1.27c0-.388.1-.741.302-1.062.201-.32.469-.564.802-.73A9.019 9.019 0 0 1 8 11.24a8.426 8.426 0 0 1 4 0c.653.16 1.285.392 1.896.697.333.167.6.41.802.731.201.32.302.674.302 1.061V15H5Zm11 0v-1.404c0-.374-.066-.727-.198-1.058a3.276 3.276 0 0 0-.552-.913c.236-.042.455-.073.656-.094.202-.02.4-.031.594-.031 1.083 0 1.938.201 2.563.604.625.403.937.959.937 1.667V15h-4Zm-9.458-1.5h6.916c-.097-.236-.51-.462-1.24-.677a7.797 7.797 0 0 0-4.437 0c-.729.215-1.142.44-1.24.677Zm-3.038-3a1.44 1.44 0 0 0 1.058-.438C4.854 9.77 5 9.419 5 9.009c0-.423-.146-.78-.438-1.072A1.437 1.437 0 0 0 3.509 7.5c-.423 0-.78.144-1.071.433A1.447 1.447 0 0 0 2 9.004c0 .414.144.767.433 1.059.288.291.645.437 1.071.437Zm13 0a1.44 1.44 0 0 0 1.058-.438c.292-.292.438-.643.438-1.053 0-.423-.146-.78-.438-1.072a1.437 1.437 0 0 0-1.053-.437c-.423 0-.78.144-1.072.433A1.447 1.447 0 0 0 15 9.004c0 .414.144.767.432 1.059.289.291.646.437 1.072.437ZM9.99 10a2.399 2.399 0 0 1-1.76-.73A2.41 2.41 0 0 1 7.5 7.5c0-.694.243-1.285.73-1.77A2.41 2.41 0 0 1 10 5a2.41 2.41 0 0 1 1.77.73c.487.485.73 1.079.73 1.78 0 .688-.243 1.275-.73 1.76-.485.487-1.079.73-1.78.73ZM10 8.5c.283 0 .52-.096.713-.287A.967.967 0 0 0 11 7.5a.967.967 0 0 0-.287-.713A.968.968 0 0 0 10 6.5a.968.968 0 0 0-.713.287A.968.968 0 0 0 9 7.5c0 .283.096.52.287.713.192.191.43.287.713.287Z"
    />
  </svg>
);
export default SvgContactGroup;
