import { PersistedContact } from "schemas";
import { apiRequest } from "../apiRequest";
import { setContact } from "./setContact";

export async function batchBlockContacts(ids: string[]) {
  const contacts = await apiRequest<PersistedContact[]>({
    url: "contacts/block",
    body: JSON.stringify({ ids }),
    method: "PATCH",
  });

  contacts.map(setContact);
}
