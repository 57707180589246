import { useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";

interface Props {
  loadMore: () => Promise<void>;
}

export function LoadMore({ loadMore }: Props) {
  const [processing, setProcessing] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "200px",
    threshold: 1,
  });

  useEffect(() => {
    if (!processing && intersection?.isIntersecting) {
      setProcessing(true);
      loadMore().then(() => setProcessing(false));
    }
  }, [intersection?.isIntersecting, processing, loadMore]);

  return <div ref={intersectionRef} />;
}
