import type { SVGProps } from "react";
const SvgEmailUnsubscribe = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#232A2C"
      d="M10 11 3.5 7.27v7.23h7.02q0 .354.011.76t.073.74H3.5a1.44 1.44 0 0 1-1.052-.448A1.44 1.44 0 0 1 2 14.5v-9q0-.618.448-1.06Q2.897 4 3.5 4h13q.604 0 1.052.44Q18 4.883 18 5.5v4.396a3.5 3.5 0 0 0-.733-.25 7 7 0 0 0-.767-.125V7.27zm0-1.77 6.5-3.73h-13zM15.995 19q-1.662 0-2.828-1.172Q12 16.658 12 14.995q0-1.662 1.172-2.828Q14.342 11 16.005 11q1.662 0 2.828 1.172Q20 13.342 20 15.005q0 1.662-1.172 2.828Q17.658 19 15.995 19M14 15.5h4v-1h-4z"
    />
  </svg>
);
export default SvgEmailUnsubscribe;
