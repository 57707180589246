import { PropsWithChildren } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import { PropsWithClassName, pointerStyles } from "ui";

interface Props extends PropsWithChildren, PropsWithClassName {
  onDragEnd: (args: object) => Promise<void> | void;
  droppableId?: string;
}
export function Drop({ onDragEnd, className, children, droppableId = "droppable" }: Props) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div
            className={classNames(pointerStyles.noUserSelect, className)}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
