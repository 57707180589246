import { PropsWithChildren, forwardRef } from "react";
import classNames from "classnames";
import { PropsWithClassName, PropsWithOptionalClick } from "./types";
import { bgStyles, borderStyles, flexStyles, pointerStyles } from "./styles";

interface Props extends PropsWithClassName, PropsWithChildren, PropsWithOptionalClick {
  noBackground?: boolean;
}
export const Card = forwardRef<HTMLDivElement, Props>(({ className, onClick, children, noBackground }: Props, ref) => (
  <div
    dir="auto"
    className={classNames(flexStyles.vert050, flexStyles.justifyStretch, borderStyles.radius025, className, {
      [bgStyles.neutral0]: !noBackground,
      [borderStyles.neutral200]: !noBackground,
      [pointerStyles.cursor]: !!onClick,
    })}
    onClick={onClick}
    ref={ref}
  >
    {children}
  </div>
));
