import type { SVGProps } from "react";
const SvgFormatBulleted = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <mask
      id="FormatBulleted_svg__a"
      width={21}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M.929 0h20v20h-20z" />
    </mask>
    <g mask="url(#FormatBulleted_svg__a)">
      <path
        fill="#191B20"
        d="M8.429 15v-1.5h9.5V15h-9.5Zm0-4.25v-1.5h9.5v1.5h-9.5Zm0-4.25V5h9.5v1.5h-9.5Zm-3.005 9.25c-.414 0-.766-.147-1.058-.442a1.456 1.456 0 0 1-.437-1.062c0-.414.147-.767.442-1.059a1.456 1.456 0 0 1 1.062-.437c.414 0 .766.147 1.058.442.292.294.438.649.438 1.062 0 .414-.148.767-.442 1.059a1.456 1.456 0 0 1-1.063.437Zm0-4.25c-.414 0-.766-.147-1.058-.442a1.456 1.456 0 0 1-.437-1.062c0-.414.147-.767.442-1.059A1.456 1.456 0 0 1 5.433 8.5c.414 0 .766.147 1.058.442.292.295.438.649.438 1.062 0 .414-.148.767-.442 1.059a1.456 1.456 0 0 1-1.063.437Zm0-4.25c-.414 0-.766-.147-1.058-.442a1.456 1.456 0 0 1-.437-1.062c0-.414.147-.767.442-1.059a1.456 1.456 0 0 1 1.062-.437c.414 0 .766.147 1.058.442.292.295.438.649.438 1.062 0 .414-.148.767-.442 1.059a1.456 1.456 0 0 1-1.063.437Z"
      />
    </g>
  </svg>
);
export default SvgFormatBulleted;
