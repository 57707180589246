import { boolean, Asserts, object, string, number } from "yup";
import { LinkValidation } from "./Link";
import { DefaultTemplates, RemindersSchema } from "./reminder";
import { DaysAheadAllowedValues, NullableNumber, SessionDurationValidation, Tag } from "./shared";
import { DefaultSchedule, TimeBetweenAppointmentsValues } from "../consts";
import { ScheduleSchema } from "./Schedule";
import { ConferenceType, ConferenceTypeValues } from "../enums";

const AppointmentLinkValidation = LinkValidation.required("Appointment link is required");

export const AppointmentSchema = object({
  eventName: string().required("Appointment name is required"),
  public: boolean(),
  description: string().max(200, "Description can't be longer than 200 characters"),
  location: string().when("isVirtual", { is: false, then: (schema) => schema.required("Location is required") }),
  isVirtual: boolean().required(),
  schedule: ScheduleSchema,
  link: AppointmentLinkValidation,
  timeBetween: number().oneOf(TimeBetweenAppointmentsValues),
  sessionDuration: SessionDurationValidation.required("Appointment duration is required"),
  daysAheadAllowed: number().oneOf(DaysAheadAllowedValues),
  reminders: RemindersSchema,
  image: string().url(),
  price: NullableNumber("Price"),
  form: string().nullable(),
  conferenceType: string().required().oneOf(ConferenceTypeValues),
}).noUnknown();
export type AppointmentPayload = Asserts<typeof AppointmentSchema> & {
  link: string;
  form?: string | null;
};
export type PersistedAppointment = AppointmentPayload & {
  _id: string;
  business: string;
  tags: Tag[];
  pathname: string;
  url: string;
  defaultTemplates: DefaultTemplates;
  formattedPrice?: string;
  form?: string;
  conferenceType: ConferenceType;
};

export const EmptyAppointment: AppointmentPayload = {
  eventName: "",
  description: "",
  location: "",
  link: "",
  isVirtual: true,
  schedule: DefaultSchedule,
  conferenceType: "GoogleMeet",
  timeBetween: 0,
  sessionDuration: 30,
  public: true,
  daysAheadAllowed: 30,
  image: undefined,
  reminders: {
    ReturnPrompt: { emailEnabled: false, smsEnabled: false, days: 7 },
    ThankYou: { emailEnabled: false, smsEnabled: false },
    UpcomingBooking: { emailEnabled: false, smsEnabled: false },
  },
};
