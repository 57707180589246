import { SVGProps } from "react";

const SvgGoogleMeet = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.484 4.14v3.516H2.97" fill="#EA4335" />
    <path d="M6.484 4.14v3.516h4.688v2.422l3.515-2.851V5.312c0-.78-.39-1.171-1.171-1.171" fill="#FFBA00" />
    <path d="M6.406 15.86v-3.516h4.766V10l3.515 2.773v1.915c0 .78-.39 1.171-1.171 1.171" fill="#00AC47" />
    <path d="m11.172 10 3.515-2.852v5.704" fill="#00832D" />
    <path
      d="m14.688 7.148 1.64-1.328c.352-.273.703-.273.703.274v7.812c0 .547-.351.547-.703.274l-1.64-1.328"
      fill="#00AC47"
    />
    <path d="M2.969 12.266v2.421c0 .782.39 1.172 1.172 1.172h2.343v-3.593" fill="#0066DA" />
    <path d="M2.969 7.656h3.515v4.688H2.97" fill="#2684FC" />
  </svg>
);
export default SvgGoogleMeet;
