import { SVGProps } from "react";

const SvgCustomersLowerLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={55} height={54} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.9 2.102c0 8.113-.625 16.594-1.265 24.676-.494 6.25.062 13.189-1.451 19.287-.473 1.907 1.735-8.676 1.873-10.78.519-7.91 0-15.306 0-23.144 0-2.482-.673-4.576-.843-6.975-.235-3.316-2.01 6.478-2.107 7.028C2.262 22.606 2 33.356 2 43.951v6.182c0 3.205.7 1.63 2.903 1.216C19.748 48.556 65 47.399 49.99 48.654c-14.103 1.179-28.602.053-42.747.053-4.085 0-1.193.95-5.197.95m1.358 0h36.52"
      stroke="#F5A343"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgCustomersLowerLeft;
