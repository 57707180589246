import type { SVGProps } from "react";
const SvgMagicWand01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#465357"
      fillRule="evenodd"
      d="m8.862 9.095-.047.04a7 7 0 0 0-.26.253 7 7 0 0 0-.254.26l-.039.047a.14.14 0 0 0 0 .115l.04.047c.05.058.125.133.253.26l1.26 1.26.73-.73-1.26-1.259a7 7 0 0 0-.26-.254l-.048-.039a.14.14 0 0 0-.115 0m2.743 2.614-.73.73 4.84 4.84c.129.128.203.201.26.253l.048.04a.15.15 0 0 0 .115 0l.047-.04c.058-.052.132-.125.26-.253a8 8 0 0 0 .254-.26l.039-.047a.14.14 0 0 0 0-.116l-.04-.047a7 7 0 0 0-.253-.26zM8.189 7.754c.46-.228 1-.228 1.461 0 .247.123.455.333.643.522l.052.051 7.161 7.161.052.052c.189.188.399.397.52.643.229.46.229 1.001 0 1.461-.121.247-.331.456-.52.644l-.052.051-.052.052c-.187.189-.396.399-.643.521-.46.228-1 .228-1.46 0-.248-.122-.457-.332-.644-.521l-.052-.052-7.161-7.16-.052-.052c-.189-.188-.399-.397-.52-.644-.229-.46-.229-1 0-1.46.121-.248.331-.456.52-.644l.052-.052.052-.051c.187-.19.396-.4.643-.522M14.167.917a.75.75 0 0 1 .703.49l.246.663c.345.935.453 1.18.627 1.353.175.175.418.282 1.353.628l.664.246a.75.75 0 0 1 0 1.406l-.664.246c-.934.346-1.178.453-1.353.628-.174.174-.282.418-.627 1.353l-.246.664a.75.75 0 0 1-1.407 0l-.245-.664c-.346-.935-.454-1.18-.628-1.353-.174-.175-.418-.282-1.353-.628l-.664-.246a.75.75 0 0 1 0-1.406l.664-.246c.935-.346 1.179-.453 1.353-.628.174-.174.282-.418.628-1.353l.245-.664a.75.75 0 0 1 .704-.49m0 2.816c-.141.287-.302.537-.516.751A2.7 2.7 0 0 1 12.9 5c.287.14.537.302.75.516.215.214.376.464.517.75.14-.286.302-.536.516-.75a2.7 2.7 0 0 1 .75-.516 2.7 2.7 0 0 1-.75-.516 2.7 2.7 0 0 1-.516-.751M5 2.583a.75.75 0 0 1 .703.49l.185.498c.265.717.332.852.427.947s.23.162.947.428l.498.184a.75.75 0 0 1 0 1.407l-.498.184c-.717.265-.852.332-.947.427s-.162.23-.427.948l-.185.498a.75.75 0 0 1-1.406 0l-.185-.498c-.265-.717-.332-.853-.427-.948s-.23-.162-.947-.427l-.498-.184a.75.75 0 0 1 0-1.407l.498-.184c.717-.266.852-.333.947-.428s.162-.23.427-.947l.185-.498A.75.75 0 0 1 5 2.583m0 2.68a2 2 0 0 1-.254.316q-.146.144-.316.254.17.111.316.255.144.145.254.315.11-.17.254-.315.146-.144.316-.255a2 2 0 0 1-.316-.254A2 2 0 0 1 5 5.263"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMagicWand01;
