import { Asserts, object, string } from "yup";
import { AutogeneratedType } from "../enums";

const MAX_NAME_LENGTH = 80;
export const ContactGroupSchema = object({
  name: string()
    .max(MAX_NAME_LENGTH, `Group name can't be longer than ${MAX_NAME_LENGTH} characters`)
    .required("Contact group name is required"),
  description: string().nullable(),
});

export type InitialsSelection = { selection: string[]; total: number };
export type ContactGroupPayload = Asserts<typeof ContactGroupSchema>;
export type PersistedContactGroup = ContactGroupPayload & {
  id: string;
  contactCount: number;
  autogeneratedType?: AutogeneratedType;
  contacts: string[];
  initials: InitialsSelection;
};

export const EmptyContactGroup: ContactGroupPayload = {
  name: "",
  description: "",
};
