import { PersistedContact } from "schemas";
import { apiRequest } from "../apiRequest";

interface Args {
  contactGroupId: string;
  query: string;
}
export async function searchContacts({ contactGroupId, query }: Args): Promise<PersistedContact[]> {
  const contacts = await apiRequest<PersistedContact[]>({ url: `contacts/search/${contactGroupId}/${query}` });

  return contacts;
}
