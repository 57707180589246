import { SVGProps } from "react";

const SvgTimer = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Timer_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Timer_svg__a)">
      <path
        d="M7.5 3V1.5h5V3h-5Zm1.75 9h1.5V7h-1.5v5Zm.75 6a6.776 6.776 0 0 1-2.729-.552 7.11 7.11 0 0 1-2.219-1.5 7.111 7.111 0 0 1-1.5-2.219A6.776 6.776 0 0 1 3 11c0-.972.184-1.882.552-2.729a7.11 7.11 0 0 1 1.5-2.219 7.11 7.11 0 0 1 2.219-1.5A6.776 6.776 0 0 1 10 4c.805 0 1.58.135 2.323.406.743.27 1.434.65 2.073 1.136l1.083-1.063 1.042 1.042-1.063 1.083a7.461 7.461 0 0 1 1.136 2.063c.27.75.406 1.528.406 2.333 0 .972-.184 1.882-.552 2.729a7.111 7.111 0 0 1-1.5 2.219 7.111 7.111 0 0 1-2.219 1.5A6.776 6.776 0 0 1 10 18Zm0-1.5c1.528 0 2.827-.535 3.896-1.604 1.07-1.07 1.604-2.368 1.604-3.896s-.535-2.827-1.604-3.896C12.826 6.034 11.528 5.5 10 5.5s-2.827.535-3.896 1.604C5.034 8.174 4.5 9.472 4.5 11s.535 2.827 1.604 3.896C7.174 15.966 8.472 16.5 10 16.5Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgTimer;
