import type { SVGProps } from "react";
const SvgSocialTiktok = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M14.018 4.279a3.96 3.96 0 0 1-.978-2.612h-.766a3.97 3.97 0 0 0 1.744 2.612M6.935 9.92a2.41 2.41 0 0 0-2.405 2.408c0 .924.526 1.73 1.291 2.132a2.4 2.4 0 0 1-.456-1.405 2.41 2.41 0 0 1 2.405-2.407c.247 0 .487.043.711.112V7.835a5 5 0 0 0-.711-.05c-.043 0-.081.003-.124.003v2.245a2.4 2.4 0 0 0-.711-.112"
    />
    <path
      fill="#fff"
      d="M16.187 5.563v2.225a6.8 6.8 0 0 1-3.982-1.28v5.823a5.28 5.28 0 0 1-5.27 5.275 5.25 5.25 0 0 1-3.02-.956 5.26 5.26 0 0 0 3.855 1.683 5.28 5.28 0 0 0 5.27-5.274V7.235a6.8 6.8 0 0 0 3.982 1.28V5.653q-.435 0-.835-.089"
    />
    <path
      fill="#fff"
      d="M12.205 12.331V6.508a6.8 6.8 0 0 0 3.982 1.28V5.563a3.98 3.98 0 0 1-2.169-1.284 4 4 0 0 1-1.748-2.612h-2.095l-.004 11.48a2.41 2.41 0 0 1-2.401 2.319 2.42 2.42 0 0 1-1.953-1.002 2.42 2.42 0 0 1-1.291-2.133 2.41 2.41 0 0 1 2.405-2.407 2.4 2.4 0 0 1 .711.113V7.792a5.269 5.269 0 0 0-3.727 8.858c.855.6 1.899.956 3.02.956a5.28 5.28 0 0 0 5.27-5.275"
    />
  </svg>
);
export default SvgSocialTiktok;
