import { SVGProps } from "react";

const SvgFinalBigBottomSquiggle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={253} height={123} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M250.752 109.264c-22.032 14.112-60.319 16.446-82.857 1.5-10.916-7.239-22.417-21.383-23.033-35.2-.73-16.39 8.708-27.969 20.61-37.937 6.339-5.309 20.942-16.94 29.583-8.36 6.051 6.01 2.345 13.8-1.792 19.983-7.752 11.584-20.89 21.463-32.484 28.89-12.545 8.035-25.474 18.145-40.928 18.616-19.733.603-36.816-11.641-47.763-27.363C61.96 54.847 43.536 32.155 61.125 16.84 72.267 7.141 82.112-.473 97.474 3.097c9.893 2.3 12.673 7.464 11.473 16.901-2.376 18.693-27.314 31.244-44.466 33.089-10.747 1.156-25.836 3.271-35.435-2.927-10.21-6.594-19.924-12.883-26.633-23.357"
      stroke="#8FC9FA"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgFinalBigBottomSquiggle;
