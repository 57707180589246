import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import classNames from "classnames";
import styles from "./Markdown.module.css";
import { PropsWithChildren } from "react";
import { textStyles } from "../styles";
import { FontFamily, fontFamilyCSS } from "schemas";
import { PropsWithClassName } from "../types";

type MarkdownSize = "small" | "regular";
interface Props extends PropsWithClassName, PropsWithChildren {
  size?: MarkdownSize;
  fontFamily?: FontFamily;
}

export function Markdown({ children, fontFamily, className, size = "regular" }: Props) {
  const fontStyles = fontFamily ? fontFamilyCSS(fontFamily) : {};
  const components = {
    a(props: PropsWithChildren) {
      return (
        <a {...props} target="_blank">
          {props.children}
        </a>
      );
    },
  };

  return (
    <div style={fontStyles} dir="auto">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={components}
        className={classNames(
          textStyles.lsColor,
          textStyles.weight600Strong,
          styles.wrapper,
          { [styles.small]: size === "small" },
          className,
        )}
      >
        {children as string}
      </ReactMarkdown>
    </div>
  );
}
