import { useNavigate } from "react-router-dom";
import { Drawer, FormProvider, FormTag, TextInput } from "ui";
import { ContactGroupPayload, ContactGroupSchema, EmptyContactGroup } from "schemas";
import { Submit } from "../ui";
import { addContactGroup } from "../../store";

export function AddNewContactGroupDrawer() {
  const navigate = useNavigate();
  const onClose = () => navigate("..", { relative: "path" });

  const onSubmit = async (payload: ContactGroupPayload) => {
    await addContactGroup(payload);
    onClose();
  };

  return (
    <Drawer title="Add a new list" open onClose={onClose}>
      <FormProvider<ContactGroupPayload> defaultValues={EmptyContactGroup} schema={ContactGroupSchema}>
        <FormTag<ContactGroupPayload> onSubmit={onSubmit}>
          <TextInput name="name" placeholder="List name" />
          <Submit label="Create list" cancelTo=".." />
        </FormTag>
      </FormProvider>
    </Drawer>
  );
}
