"use client";

import { useWatch } from "react-hook-form";
import { RawCheckbox } from "./RawCheckbox";
import { PropsWithName, PropsWithOptionalClick } from "../../types";

interface Props extends PropsWithOptionalClick, PropsWithName {
  id: string | number;
}

export function CheckboxItem({ name, id, onClick }: Props) {
  const list = useWatch({ name });

  const on = list.includes(id);

  return <RawCheckbox on={on} name={name} onClick={onClick} />;
}
