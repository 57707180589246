import classNames from "classnames";
import { bgStyles, flexStyles, heightStyles, iconStyles, squareStyles, transformStyles, transitionStyles } from "ui";
import useTransition from "react-transition-state";
import { useEffect } from "react";
import { AddMenu } from "./AddMenu";
import { IconLink } from "../ui";
import { useDestinationIndex } from "../../hooks";
import { BlockKind } from "schemas";

interface Props {
  visible?: boolean;
  destinationIndex: number;
  kind: BlockKind;
  blockId: string;
}
export function AddDivider({ kind, blockId, visible = true, destinationIndex }: Props) {
  const addDestinationIndex = useDestinationIndex("addDestinationIndex");
  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 202,
    preEnter: true,
    mountOnEnter: true,
    unmountOnExit: true,
  });

  useEffect(() => {
    toggle(visible);
  }, [visible]);

  if (!isMounted) {
    return null;
  }

  const open = addDestinationIndex === destinationIndex;

  return (
    <>
      <div
        className={classNames(
          flexStyles.horiz125,
          flexStyles.alignCenter,
          transitionStyles.all200,
          transitionStyles.fadeIn,
          iconStyles.buttonDimensions250,
          status,
        )}
      >
        <div className={classNames(bgStyles.editorInterface, flexStyles.grow, heightStyles.height012)} />
        <IconLink
          className={classNames(
            bgStyles.editorInterface,
            iconStyles.editorInterfaceText,
            transitionStyles.all200,
            squareStyles.square250,
            { [transformStyles.rotate45]: open },
          )}
          to={`blocks/${kind}/${blockId}${open ? "" : `/${destinationIndex}`}`}
          preventScrollReset
          iconType="Plus"
        />
        <div className={classNames(bgStyles.editorInterface, flexStyles.grow, heightStyles.height012)} />
      </div>
      <AddMenu currentKind={kind} blockId={blockId} open={open && visible} destinationIndex={destinationIndex} />
    </>
  );
}
