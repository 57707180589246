import classNames from "classnames";
import { PropsWithChildren } from "react";
import { RawIcon, displayStyles, iconStyles, positionStyles } from "ui";

export function AuthUnderline({ children }: PropsWithChildren) {
  return (
    <span className={classNames(displayStyles.inlineBlock, positionStyles.relative)}>
      <RawIcon
        className={classNames(
          positionStyles.absolute,
          positionStyles.left0,
          positionStyles.bottom0,
          positionStyles.right0,
          iconStyles.horizStretch,
        )}
        iconType="AuthUnderline"
      />
      {children}
    </span>
  );
}
