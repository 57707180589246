import type { SVGProps } from "react";
const SvgSocialViber = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M16.12 3.958c-.414-.381-2.087-1.595-5.817-1.612 0 0-4.398-.263-6.54 1.703-1.191 1.191-1.611 2.94-1.657 5.104-.045 2.165-.1 6.22 3.809 7.32h.003l-.003 1.68s-.026.68.423.818c.54.169.86-.349 1.377-.905.283-.306.674-.755.97-1.097 2.676.224 4.73-.29 4.964-.365.54-.176 3.597-.566 4.092-4.622.514-4.187-.247-6.83-1.621-8.024m.452 7.718c-.42 3.385-2.897 3.6-3.353 3.746-.195.062-2.002.511-4.27.365 0 0-1.693 2.041-2.22 2.572-.173.172-.362.156-.359-.186 0-.224.013-2.79.013-2.79q-.004 0 0 0c-3.313-.918-3.118-4.371-3.082-6.178s.377-3.288 1.386-4.284c1.814-1.644 5.547-1.4 5.547-1.4 3.155.013 4.665.964 5.017 1.283 1.162.996 1.754 3.379 1.321 6.872m-4.524-2.63c.012.28-.407.299-.42.019-.036-.716-.371-1.064-1.062-1.103-.28-.017-.254-.437.023-.42.908.048 1.413.57 1.458 1.503m.66.367c.033-1.38-.83-2.46-2.467-2.581-.277-.02-.248-.44.03-.42 1.887.137 2.893 1.436 2.857 3.011-.003.28-.426.267-.42-.01m1.53.437c.004.28-.42.283-.42.003-.02-2.653-1.787-4.099-3.932-4.115-.277-.003-.277-.42 0-.42 2.4.017 4.33 1.673 4.352 4.532m-.368 3.193v.006c-.351.619-1.009 1.303-1.686 1.084l-.006-.01c-.687-.191-2.305-1.025-3.327-1.839a8.5 8.5 0 0 1-1.38-1.38c-.336-.42-.674-.918-1.003-1.517-.693-1.253-.846-1.813-.846-1.813-.218-.677.462-1.335 1.084-1.686h.006c.3-.156.586-.104.778.127 0 0 .404.482.576.72.163.22.381.575.495.774.199.355.075.716-.12.866l-.39.312c-.2.16-.173.456-.173.456s.579 2.19 2.744 2.744c0 0 .296.026.456-.172l.312-.39c.15-.196.511-.32.866-.121.478.27 1.087.69 1.49 1.07.229.186.28.47.124.769"
    />
  </svg>
);
export default SvgSocialViber;
