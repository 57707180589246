import classNames from "classnames";
import { PropsWithChildren } from "react";
import { bgStyles, borderStyles, flexStyles, paddingStyles, positionStyles, widthStyles } from "ui";

export function BottomBar({ children }: PropsWithChildren) {
  return (
    <div
      className={classNames(
        borderStyles.topNeutral200Thick,
        positionStyles.fixed,
        positionStyles.bottom0,
        positionStyles.zIndex3,
        widthStyles.full,
        bgStyles.neutral0,
        flexStyles.horiz,
        flexStyles.justifyEvenly,
        paddingStyles.pb100,
      )}
    >
      {children}
    </div>
  );
}
