import { SVGProps } from "react";

const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Video_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Video_svg__a)">
      <path
        d="M3.5 14c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 2 12.5v-8c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 3.5 3h13c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v8c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 16.5 14h-13Zm0-1.5h13v-8h-13v8Zm5-.75 5-3.25-5-3.25v6.5ZM4 17v-1.5h12V17H4Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgVideo;
