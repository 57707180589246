import { PriceInput, TextAccent, flexStyles } from "ui";
import { useCurrentBusiness } from "../../store";

export function PaymentsInputs() {
  const {
    customerPayments: { currency },
  } = useCurrentBusiness();
  return (
    <div className={flexStyles.vert100}>
      <TextAccent>Payments</TextAccent>
      <PriceInput placeholder="0.00" name="price" currency={currency || ""} label="Price" />
    </div>
  );
}
