import { MouseEvent } from "react";
import classNames from "classnames";
import { PropsWithClassNameAndChildren } from "./types";
import { bgStyles, borderStyles, iconStyles, outlineStyles, paddingStyles, pointerStyles, textStyles } from "./styles";

interface Props extends PropsWithClassNameAndChildren {
  onClick: () => void;
  stopPropagation?: boolean;
}

export function Link({ children, onClick, className, stopPropagation }: Props) {
  const neuterClick = (e: MouseEvent) => {
    if (onClick) {
      if (stopPropagation) {
        e.stopPropagation();
      }
      e.preventDefault();
      onClick();
    }
  };

  return (
    <button
      className={classNames(
        textStyles.size087,
        textStyles.weight600,
        textStyles.left,
        textStyles.lsColor,
        textStyles.underline,
        textStyles.neutral400Hover,
        iconStyles.textColor,
        borderStyles.none,
        bgStyles.initialNone,
        paddingStyles.p0,
        pointerStyles.cursor,
        outlineStyles.primary,
        className,
      )}
      type="button"
      onClick={neuterClick}
    >
      {children}
    </button>
  );
}
