import classNames from "classnames";
import { flexStyles, heightStyles, paddingStyles, textStyles } from "../../styles";
import { Icon } from "../../Icon";
import { TextAccent } from "../../typography";
import { PropsWithClassName } from "../../types";

interface Props extends PropsWithClassName {
  prompt: string;
}
export function FilePrompt({ prompt, className }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.vert050,
        flexStyles.alignCenter,
        flexStyles.justifyCenter,
        heightStyles.full,
        paddingStyles.p125,
        textStyles.secondary300,
        textStyles.editorInterface,
        className,
      )}
    >
      <Icon iconType="UploadCloud" />
      <TextAccent>{prompt}</TextAccent>
    </div>
  );
}
