import { PropsWithChildren } from "react";
import {
  PropsWithOptionalClick,
  PropsWithOptionalTo,
  displayStyles,
  flexStyles,
  pointerStyles,
  textStyles,
  widthStyles,
} from "ui";
import { RawLink } from "../Link";
import classNames from "classnames";

interface Props extends PropsWithOptionalTo, PropsWithOptionalClick, PropsWithChildren {}
export function MaybeLink({ to, children, onClick }: Props) {
  if (to) {
    return (
      <RawLink
        to={to}
        className={classNames(
          flexStyles.grow,
          widthStyles.fullMinus200,
          textStyles.decorationNone,
          displayStyles.block,
        )}
      >
        {children}
      </RawLink>
    );
  } else {
    return (
      <div
        onClick={onClick}
        className={classNames(flexStyles.grow, widthStyles.fullMinus200, { [pointerStyles.cursor]: onClick })}
      >
        {children}
      </div>
    );
  }
}
