import { useState } from "react";
import { OnClickHandler, PropsWithName, RawTextInput, flexStyles } from "ui";
import { Button } from "../../ui";
import { addContactGroup } from "../../../store";
import { useFormContext, useWatch } from "react-hook-form";

interface Props extends PropsWithName {
  onDone: OnClickHandler;
}
export function NewGroupForm({ name, onDone }: Props) {
  const list = useWatch({ name });
  const { setValue } = useFormContext();
  const [candidate, setCandidate] = useState<string>("");
  const createGroup = async () => {
    const group = await addContactGroup({ name: candidate });
    setValue(name, [...list, group.id], { shouldValidate: true, shouldDirty: true });
    onDone();
  };

  return (
    <div className={flexStyles.vert125}>
      <RawTextInput placeholder="List name" onChange={(value) => setCandidate(value)} />
      <Button disabled={candidate.length === 0} onClick={createGroup}>
        Add list
      </Button>
      <Button buttonType="secondary" onClick={onDone}>
        Cancel
      </Button>
    </div>
  );
}
