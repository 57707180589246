import { Home } from "../components/dashboard/Home";
import { PrivateOutlet } from "./PrivateOutlet";

export const DashboardRoutes = {
  path: "/dashboard",
  Component: PrivateOutlet,
  children: [
    {
      path: "",
      Component: Home,
    },
  ],
};
