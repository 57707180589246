import { BlockDestination } from "schemas";
import { getPageElements } from "../pages/getPageElements";
import { getCampaignElements } from "../campaigns/getCampaignElements";

export async function refetchParent(destination: BlockDestination) {
  switch (destination.type) {
    case "Page":
      getPageElements(destination.entity.id);
      break;
    case "Campaign":
      getCampaignElements(destination.entity.id);
      break;
  }
}
