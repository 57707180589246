export const DefaultSchedule = [
  {
    on: false,
    times: [{ startMinutes: 9 * 60, endMinutes: 17 * 60 }],
  },
  {
    on: true,
    times: [{ startMinutes: 9 * 60, endMinutes: 17 * 60 }],
  },
  {
    on: true,
    times: [{ startMinutes: 9 * 60, endMinutes: 17 * 60 }],
  },
  {
    on: true,
    times: [{ startMinutes: 9 * 60, endMinutes: 17 * 60 }],
  },
  {
    on: true,
    times: [{ startMinutes: 9 * 60, endMinutes: 17 * 60 }],
  },
  {
    on: true,
    times: [{ startMinutes: 9 * 60, endMinutes: 17 * 60 }],
  },
  {
    on: false,
    times: [{ startMinutes: 9 * 60, endMinutes: 17 * 60 }],
  },
];
