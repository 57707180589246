import { SVGProps } from "react";

const SvgOpenInNew = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="OpenInNew_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#OpenInNew_svg__a)">
      <path
        d="M4.5 17c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 3 15.5v-11c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 4.5 3H10v1.5H4.5v11h11V10H17v5.5c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 15.5 17h-11Zm3.562-4L7 11.938 14.438 4.5H12V3h5v5h-1.5V5.562L8.062 13Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgOpenInNew;
