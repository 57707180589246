import { useEffect } from "react";
import { useLiteSiteStore } from "../store";
import { getPageElements } from "./getPageElements";

export function usePageElements(id?: string) {
  const pageElements = useLiteSiteStore(({ pageElements }) => pageElements.get(id));

  useEffect(() => {
    if (id) {
      getPageElements(id);
    }
  }, [id]);

  return pageElements?.sort((elA, elB) => elA.position - elB.position);
}
