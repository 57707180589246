import { Asserts, array, boolean, object, string } from "yup";
import { PersistedBusiness } from "./Business";
import { FormQuestionSchema, FormQuestionWithId } from "./FormQuestion";
import { PersistedFormAnswer } from "./FormAnswer";
import { LinkValidation } from "./Link";

export const FormSchema = object({
  name: string().required("Form name is required"),
  description: string(),
  handle: LinkValidation,
  destinationContactGroup: string(),
  subscribesContacts: boolean(),
  questions: array().of(FormQuestionSchema).required(),
});
export type FormPayload = Asserts<typeof FormSchema>;

export type SimpleForm = FormPayload & {
  _id: string;
  business: PersistedBusiness;
  questions: FormQuestionWithId[];
  url: string;
};
export type PersistedForm = SimpleForm & {
  usedInAppointments: string[];
  formAnswers: PersistedFormAnswer[];
  undeletable: boolean;
};

export const PublicFormFields = ["name", "description", "subscribesContacts", "questions", "_id"];
export type PublicForm = Pick<PersistedForm, "name" | "description" | "subscribesContacts" | "questions" | "_id">;

export const EmptyForm: FormPayload = {
  name: "",
  description: "",
  questions: [],
};
