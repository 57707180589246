import { SVGProps } from "react";

const SvgTaskAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 16a7.796 7.796 0 0 1-3.104-.625 8.064 8.064 0 0 1-2.552-1.719 8.063 8.063 0 0 1-1.719-2.552A7.797 7.797 0 0 1 0 8c0-1.111.208-2.15.625-3.115a8.064 8.064 0 0 1 4.27-4.26A7.797 7.797 0 0 1 8 0c.875 0 1.708.132 2.5.396s1.52.639 2.188 1.125l-1.084 1.083a5.952 5.952 0 0 0-1.687-.823A6.498 6.498 0 0 0 8 1.5c-1.806 0-3.34.632-4.604 1.896C2.132 4.66 1.5 6.194 1.5 8s.632 3.34 1.896 4.604C4.66 13.868 6.194 14.5 8 14.5s3.34-.632 4.604-1.896C13.868 11.34 14.5 9.806 14.5 8c0-.292-.02-.58-.063-.865a8.62 8.62 0 0 0-.166-.843l1.187-1.188c.18.445.316.91.407 1.396.09.486.135.986.135 1.5a7.796 7.796 0 0 1-.625 3.104 8.063 8.063 0 0 1-4.26 4.271A7.774 7.774 0 0 1 8 16Zm-1.146-4.396L3.5 8.25l1.083-1.083 2.292 2.291 8.063-8.062L16 2.458l-9.146 9.146Z"
      fill="#6ABB7B"
    />
  </svg>
);
export default SvgTaskAlt;
