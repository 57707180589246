import { PropsWithChildren } from "react";
import { PropsWithClassName } from "../types";
import { bgStyles, flexStyles } from "../styles";
import classNames from "classnames";

interface Props extends PropsWithClassName, PropsWithChildren {}

export function GrayGrow({ className, children }: Props) {
  return <div className={classNames(flexStyles.grow, bgStyles.neutral100, className)}>{children}</div>;
}
