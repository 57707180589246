import type { SVGProps } from "react";
const SvgGetYoursTwirl = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={177} height={207} fill="none" {...props}>
    <path
      stroke="#54B9D1"
      strokeLinecap="round"
      strokeWidth={3}
      d="M37.697-28.584c-6.425 12.427-7.646 24.811-8.101 39.525-.77 24.903 45.475 27.784 56.696 19.968 6.605-4.602 17.449-7.932 20.035-16.41 3.063-10.036-2.829-12.98-11.863-7.85-19.616 11.142-40.298 40.594-21.83 70.82 14.756 24.148 70.288 35.715 83.14 20.398 3.067-3.654 14.917-4.311 13.804-10.642-1.606-9.14-19.779 17.763-22.674 21.214-13.682 16.305-24.187 31.337-6.978 60.588 10.071 17.117 21.965 20.302 35.849 31.952"
      opacity={0.1}
    />
  </svg>
);
export default SvgGetYoursTwirl;
