import type { SVGProps } from "react";
const SvgFormatHeadingH3 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <path
      fill="#1C1B1F"
      d="M3.5 14V6H5v3.25h3.5V6H10v8H8.5v-3.25H5V14zm8 0v-1.5H16v-1.75h-3.5v-1.5H16V7.5h-4.5V6h4.505q.62 0 1.058.44.437.442.437 1.06v5q0 .619-.44 1.06-.44.44-1.06.44z"
    />
  </svg>
);
export default SvgFormatHeadingH3;
