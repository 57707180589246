import type { SVGProps } from "react";
const SvgSocialAmazon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M15.853 15.387c-6.88 3.273-11.149.534-13.882-1.13-.169-.104-.456.025-.207.312.91 1.103 3.894 3.764 7.79 3.764s6.215-2.126 6.505-2.497c.288-.368.085-.571-.207-.45m1.932-1.067c-.185-.241-1.124-.286-1.714-.213-.592.07-1.48.432-1.403.649.04.081.12.045.527.008.408-.04 1.55-.185 1.787.126.24.313-.364 1.805-.474 2.046-.107.24.04.302.24.142.197-.16.554-.575.794-1.162.238-.59.383-1.413.242-1.596"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.227 8.706c0 .86.022 1.576-.412 2.339-.35.62-.906 1.001-1.526 1.001-.847 0-1.34-.645-1.34-1.597 0-1.88 1.684-2.22 3.278-2.22zm2.224 5.375a.46.46 0 0 1-.521.052c-.732-.607-.862-.89-1.266-1.47-1.209 1.235-2.065 1.604-3.634 1.604-1.855 0-3.3-1.145-3.3-3.436 0-1.79.97-3.009 2.35-3.604 1.198-.527 2.87-.62 4.147-.766v-.285c0-.524.04-1.145-.267-1.597-.27-.407-.784-.574-1.237-.574-.84 0-1.59.431-1.774 1.324-.037.199-.183.394-.381.403l-2.14-.23c-.18-.04-.378-.185-.329-.461.493-2.593 2.835-3.374 4.931-3.374 1.073 0 2.475.285 3.321 1.097 1.073 1.002.971 2.339.971 3.793v3.436c0 1.033.428 1.486.831 2.044.143.198.174.437-.006.586a93 93 0 0 0-1.69 1.464z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M15.853 15.387c-6.88 3.273-11.149.534-13.882-1.13-.169-.104-.456.025-.207.312.91 1.103 3.894 3.764 7.79 3.764s6.215-2.126 6.505-2.497c.288-.368.085-.571-.207-.45m1.932-1.067c-.185-.241-1.124-.286-1.714-.213-.592.07-1.48.432-1.403.649.04.081.12.045.527.008.408-.04 1.55-.185 1.787.126.24.313-.364 1.805-.474 2.046-.107.24.04.302.24.142.197-.16.554-.575.794-1.162.238-.59.383-1.413.242-1.596"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.227 8.706c0 .86.022 1.576-.412 2.339-.35.62-.906 1.001-1.526 1.001-.847 0-1.34-.645-1.34-1.597 0-1.88 1.684-2.22 3.278-2.22zm2.224 5.375a.46.46 0 0 1-.521.052c-.732-.607-.862-.89-1.266-1.47-1.209 1.235-2.065 1.604-3.634 1.604-1.855 0-3.3-1.145-3.3-3.436 0-1.79.97-3.009 2.35-3.604 1.198-.527 2.87-.62 4.147-.766v-.285c0-.524.04-1.145-.267-1.597-.27-.407-.784-.574-1.237-.574-.84 0-1.59.431-1.774 1.324-.037.199-.183.394-.381.403l-2.14-.23c-.18-.04-.378-.185-.329-.461.493-2.593 2.835-3.374 4.931-3.374 1.073 0 2.475.285 3.321 1.097 1.073 1.002.971 2.339.971 3.793v3.436c0 1.033.428 1.486.831 2.044.143.198.174.437-.006.586a93 93 0 0 0-1.69 1.464z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialAmazon;
