import { Success, Upgrade } from "../components/billing";
import { Discovered } from "../components/billing/Discovered";
import { PrivateOutlet } from "./PrivateOutlet";

export const BillingRoutes = {
  path: "billing",
  Component: PrivateOutlet,
  children: [
    {
      path: "upgrade",
      Component: Upgrade,
    },
    {
      path: "success",
      Component: Success,
    },
    {
      path: "discovered/:source",
      Component: Discovered,
    },
  ],
};
