import { RawIcon, TextMain, flexStyles, paddingStyles } from "ui";
import { PropsWithChildren } from "react";
import classNames from "classnames";
import { AddQuestionCard } from "./AddQuestionCard";
import { Overlay } from "../ui";

function RadioWrapper({ children }: PropsWithChildren) {
  return <div className={classNames(flexStyles.horiz100, flexStyles.alignCenter)}>{children}</div>;
}

export function AddQuestion() {
  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap100)}
      header="Question Types"
      backTo={{ to: "..", label: "Form" }}
    >
      <AddQuestionCard to="TextField" iconType="FormTextField" title="Text Field" contentIconType="FormTextFieldBox" />
      <AddQuestionCard to="SingleChoice" iconType="FormSingleChoiceField" title="Single Choice">
        <RadioWrapper>
          <RawIcon iconType="RadioChecked" />
          <TextMain>Option</TextMain>
        </RadioWrapper>
        <RadioWrapper>
          <RawIcon iconType="RadioUnchecked" />
          <TextMain>Option</TextMain>
        </RadioWrapper>
      </AddQuestionCard>
      <AddQuestionCard to="MultipleChoice" iconType="FormMultipleChoiceField" title="Multiple Choice">
        <RadioWrapper>
          <RawIcon iconType="CheckboxChecked" />
          <TextMain>Option</TextMain>
        </RadioWrapper>
        <RadioWrapper>
          <RawIcon iconType="CheckboxUnchecked" />
          <TextMain>Option</TextMain>
        </RadioWrapper>
      </AddQuestionCard>
      <AddQuestionCard
        to="NumberField"
        iconType="FormNumberField"
        title="Number Field"
        contentIconType="FormNumberFieldBox"
      />
      <AddQuestionCard to="DateField" iconType="FormDateField" title="Date Picker" contentIconType="FormDateFieldBox" />
      <AddQuestionCard to="FileField" iconType="FormFileField" title="File Upload" contentIconType="FormFileFieldBox" />
    </Overlay>
  );
}
