import type { SVGProps } from "react";
const SvgSocialTeams = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M16.781 7.88a1.744 1.744 0 1 0 0-3.489 1.744 1.744 0 0 0 0 3.489M15.851 15.998q.168.021.343.021h.012a2.707 2.707 0 0 0 2.707-2.706V9.39a.736.736 0 0 0-.736-.736h-2.099c.119.225.184.481.18.753v4.455c.016.757-.13 1.48-.407 2.135M14.341 15.286a2.7 2.7 0 0 1-.854-1.973V9.488h1.104v4.404c.012.493-.078.964-.25 1.394M11.607 7.86a2.52 2.52 0 1 0-2.768-2.364h1.224c.852 0 1.544.691 1.544 1.544zM9.94 7.438v-.275h-.338q.156.151.338.275M6.896 14.023l.001-.15v-.893h.395V9.23H8.75v-.575h1.19v5.368z"
    />
    <path
      fill="#fff"
      d="M7.246 15.69h2.817c.852 0 1.544-.691 1.544-1.544V8.655h3.107c.402.01.72.343.71.745v4.473a4.37 4.37 0 0 1-4.263 4.472 4.37 4.37 0 0 1-3.915-2.655"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10.063 6.33H2.957a.71.71 0 0 0-.71.71v7.106c0 .392.317.71.71.71h7.106a.71.71 0 0 0 .71-.71V7.04a.71.71 0 0 0-.71-.71m-5.48 2.9h1.459v3.75h.833V9.23h1.458v-.833h-3.75z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialTeams;
