import { CSSProperties } from "react";
import { Description } from "./typography";
import styles from "./Progress.module.css";
import { flexStyles, heightStyles, widthStyles } from "./styles";
import classNames from "classnames";

interface Props {
  current: number;
  total: number;
}
export function Progress({ current, total }: Props) {
  return (
    <div className={flexStyles.vert075}>
      <div
        className={classNames(heightStyles.height025, widthStyles.full, styles.progress)}
        style={{ "--progress-percentage": `${(100 * current) / total}%` } as CSSProperties}
      />
      <Description>
        Step {current}/{total}
      </Description>
    </div>
  );
}
