"use client";

import { ReactNode, useEffect } from "react";

import { Path, PathValue, useFormContext, useWatch } from "react-hook-form";
import { Label, LabelWithCount } from "./Label";
import { Error } from "./Error";
import { PropsWithOptionalLabel } from "../types";
import { RichEditorType, Wysiwyg } from "./Wysiwyg";
import { flexStyles } from "../styles";

interface Props<T> extends PropsWithOptionalLabel {
  name: Path<T>;
  type: RichEditorType;
  placeholder?: string;
  maxCharacterCount?: number;
  onSubmit?: (payload: T) => Promise<void>;
  extra?: ReactNode;
  autoFocus?: boolean;
  fontSelectable?: boolean;
  textAlignmentSelectable?: boolean;
}

export function VisualTextArea<T extends object>({
  name,
  label,
  type,
  placeholder,
  onSubmit,
  extra,
  autoFocus = false,
  maxCharacterCount = 0,
  fontSelectable = false,
  textAlignmentSelectable = false,
}: Props<T>) {
  const { register, setValue, handleSubmit } = useFormContext<T>();
  const value: string = useWatch({ name });
  const fontFamily = useWatch({ name: "fontFamily" });
  const textAlignment = useWatch({ name: "textAlignment" });

  useEffect(() => {
    register(name);
  }, [register, name]);

  const hasCharacterCount = maxCharacterCount > 0;
  return (
    <div className={flexStyles.vert025}>
      {hasCharacterCount ? (
        <LabelWithCount name={name} maxCharacterCount={maxCharacterCount}>
          {label}
        </LabelWithCount>
      ) : (
        label && (
          <Label name={name} extra={extra}>
            {label}
          </Label>
        )
      )}
      <Wysiwyg
        onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}
        type={type}
        autoFocus={autoFocus}
        name={name}
        value={value}
        setValue={(newValue: string) =>
          setValue(name, newValue as PathValue<T, Path<T>>, { shouldDirty: true, shouldTouch: true })
        }
        placeholder={placeholder}
        fontFamily={fontFamily}
        fontSelectable={fontSelectable}
        textAlignmentSelectable={textAlignmentSelectable}
        textAlignment={textAlignment || "Default"}
      />
      <Error name={name} />
    </div>
  );
}
