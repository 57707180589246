import { MeUser } from "schemas";
import { useLiteSiteStore } from "../store";
import { setUser } from "./setUser";
import { apiRequest } from "../apiRequest";
import { getBusiness } from "../business/getBusiness";

function unauthorize() {
  useLiteSiteStore.setState(() => ({ user: undefined, authorized: false }));
}

export async function getCurrentUser() {
  try {
    const user = await apiRequest<MeUser>({ url: "users/me" });
    await getBusiness();
    setUser(user);
  } catch (e) {
    unauthorize();
  }
}
