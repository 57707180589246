import {
  Card,
  RawIcon,
  SecuredByStripe,
  SmallTextMain,
  flexStyles,
  iconStyles,
  paddingStyles,
  textStyles,
  widthStyles,
} from "ui";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Button } from "./Button";
import { Link } from "./Link";
import { useCustomerPaymentsRedirect, useUrlWithBack } from "../../hooks";

export function SetupPaymentsBox() {
  const navigate = useNavigate();
  const connectTo = useUrlWithBack("/payments/connect");
  const { setCustomerPaymentsRedirect } = useCustomerPaymentsRedirect();

  const onClick = () => {
    setCustomerPaymentsRedirect();
    navigate(connectTo);
  };

  return (
    <Card className={classNames(flexStyles.gap125, flexStyles.alignCenter, paddingStyles.p125, textStyles.center)}>
      <RawIcon className={iconStyles.width500} iconType="Dollars" />
      <SmallTextMain>Connect payment method, easily process client transactions.</SmallTextMain>
      <Button className={widthStyles.full} buttonType="secondary" onClick={onClick}>
        Setup Payments
      </Button>
      <Link target="_blank" href="#">
        Fees
      </Link>
      <SecuredByStripe />
    </Card>
  );
}
