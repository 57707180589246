import { TextMain } from "ui";
import { useContactGroup } from "../../store";

interface Props {
  id: string;
}
export function SummaryContactGroup({ id }: Props) {
  const contactGroup = useContactGroup(id);

  if (!contactGroup) {
    return null;
  }

  return <TextMain>- {contactGroup.name}</TextMain>;
}
