import type { SVGProps } from "react";
const SvgSocialSkype = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.533 2c.915 0 1.805.257 2.57.732a7.73 7.73 0 0 1 6.271 2.206 7.68 7.68 0 0 1 2.258 5.431q0 .539-.075 1.07a4.843 4.843 0 0 1-2.381 6.858 4.88 4.88 0 0 1-4.332-.3 7.74 7.74 0 0 1-3.869-.53 7.7 7.7 0 0 1-4.171-4.158A7.66 7.66 0 0 1 2.3 9.243a4.84 4.84 0 0 1 .792-5.822A4.87 4.87 0 0 1 6.532 2m.465 7.302c.188.322.448.596.76.8q.62.406 1.309.681.769.321 1.192.545c.239.117.456.275.641.466a.8.8 0 0 1 .218.55.91.91 0 0 1-.348.754 1.64 1.64 0 0 1-1.032.275 2.7 2.7 0 0 1-1.091-.215q-.203-.087-.554-.263a1.34 1.34 0 0 0-.611-.157.75.75 0 0 0-.523.184.66.66 0 0 0-.203.511.83.83 0 0 0 .155.503c.126.16.288.286.474.368a5.3 5.3 0 0 0 2.305.437 4.7 4.7 0 0 0 1.691-.276c.45-.16.842-.45 1.125-.835.278-.406.42-.89.402-1.382.011-.402-.09-.799-.29-1.147a2.6 2.6 0 0 0-.805-.825 7.5 7.5 0 0 0-1.306-.672 1 1 0 0 0-.151-.06q-.702-.292-1.056-.466a1.9 1.9 0 0 1-.563-.403.76.76 0 0 1-.209-.53.84.84 0 0 1 .403-.752c.304-.181.654-.271 1.007-.258q.41-.005.805.1.428.123.835.306a.85.85 0 0 0 .35.078.62.62 0 0 0 .472-.196.72.72 0 0 0 .188-.517.75.75 0 0 0-.178-.497 1.4 1.4 0 0 0-.578-.368 4 4 0 0 0-.739-.191 6 6 0 0 0-1.064-.082 4.7 4.7 0 0 0-1.6.27c-.472.16-.894.441-1.222.816a2 2 0 0 0-.47 1.34c-.01.386.08.768.26 1.108"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialSkype;
