import { useEffect, useState } from "react";
import { useLiteSiteStore } from "../store";
import { HookLoading } from "../types";
import { PersistedForm } from "schemas";
import { apiRequest } from "../apiRequest";

async function getForms() {
  try {
    const forms = await apiRequest<PersistedForm[]>({ url: `forms` });
    useLiteSiteStore.setState(() => ({ formsList: forms }));
  } catch {
    // not found
  }
}

export function useForms(): HookLoading<{ forms: PersistedForm[] }> {
  const [loading, setLoading] = useState(true);
  const forms = useLiteSiteStore(({ formsList }) => formsList);

  useEffect(() => {
    getForms().then(() => setLoading(false));
  }, []);

  return { loading, forms };
}
