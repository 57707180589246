import { PropsWithClassName, SubmitWrapper } from "ui";
import { PropsWithChildren } from "react";
import { Button } from "../Button";
import { DisablingProps } from "./SharedProps";

interface Props extends PropsWithChildren, PropsWithClassName, DisablingProps {
  label?: string;
}

export function SubmitButton({
  disableClean = false,
  disableInvalid = false,
  disabled = false,
  className,
  label = "Submit",
  children,
}: Props) {
  return (
    <SubmitWrapper>
      {({ isSubmitting, isValid, isDirty }) => (
        <>
          <Button
            className={className}
            disabled={disabled || isSubmitting || (disableClean && !isDirty) || (disableInvalid && !isValid)}
            htmlButtonType="submit"
          >
            {label}
          </Button>
          {children}
        </>
      )}
    </SubmitWrapper>
  );
}
