import type { SVGProps } from "react";
const SvgSocialSnapchat = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <g clipPath="url(#SocialSnapchat_svg__a)">
      <path
        fill="#fff"
        d="M10.172.994c.825 0 3.622.23 4.941 3.184.441.995.336 2.683.25 4.04l-.003.05c-.01.15-.018.287-.025.425.063.037.17.075.334.075.25-.014.55-.1.861-.251a.8.8 0 0 1 .387-.087c.151 0 .299.024.424.075.375.124.611.4.611.698q.019.563-1.01.974c-.075.024-.175.062-.287.099-.375.112-.95.3-1.11.675-.076.187-.052.437.1.723l.012.013c.05.113 1.271 2.896 3.992 3.345a.416.416 0 0 1 .35.424.5.5 0 0 1-.037.187c-.2.475-1.061.824-2.622 1.06-.05.075-.1.312-.137.475q-.036.222-.111.46c-.064.226-.225.338-.463.338h-.025c-.112 0-.26-.026-.448-.062a5 5 0 0 0-1.061-.112c-.25 0-.5.012-.76.061-.5.087-.937.387-1.437.737-.71.5-1.521 1.073-2.745 1.073-.05 0-.099-.012-.15-.012H9.88c-1.223 0-2.022-.563-2.732-1.073-.5-.35-.923-.65-1.423-.737a5.5 5.5 0 0 0-.773-.062c-.45 0-.798.074-1.06.124a2.4 2.4 0 0 1-.45.062c-.312 0-.436-.187-.486-.35-.05-.16-.075-.324-.112-.472-.039-.151-.088-.412-.139-.475-1.598-.185-2.458-.535-2.657-1.022A.5.5 0 0 1 0 15.468a.413.413 0 0 1 .35-.424c2.72-.45 3.942-3.232 3.992-3.35l.014-.024c.15-.287.186-.537.099-.724-.163-.362-.737-.548-1.11-.674-.101-.024-.2-.062-.288-.1-.923-.362-1.048-.774-.998-1.06.075-.4.562-.661.973-.661.122 0 .225.024.32.062.35.161.657.25.92.25.195 0 .32-.05.387-.088l-.038-.474c-.082-1.355-.188-3.043.256-4.03C6.16 1.23 8.949 1.005 9.773 1.005l.349-.013z"
      />
    </g>
    <defs>
      <clipPath id="SocialSnapchat_svg__a">
        <path fill="#fff" d="M0 .333h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSocialSnapchat;
