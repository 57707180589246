"use client";

import { ReactElement } from "react";
import { FormState, useFormContext } from "react-hook-form";

interface Props {
  children: (formState: FormState<object>) => ReactElement;
}
export function SubmitWrapper({ children }: Props) {
  const { formState } = useFormContext();

  return children(formState);
}
