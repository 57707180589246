import classNames from "classnames";
import { Popover } from "react-tiny-popover";
import { useHover } from "react-use";
import { Icon } from "./Icon";
import { PropsWithClassNameAndChildren } from "./types";
import { Card } from "./Card";
import { iconStyles, paddingStyles, positionStyles } from "./styles";
import { Description } from "./typography";

interface Props extends PropsWithClassNameAndChildren {}
export function Help({ className, children }: Props) {
  const icon = () => (
    <div>
      <Icon iconType="Help" />
    </div>
  );
  const [hoverable, hovered] = useHover(icon);

  return (
    <div className={classNames(iconStyles.neutral300, className)}>
      <Popover
        reposition
        isOpen={hovered}
        parentElement={window.document.getElementById("inner-root") || undefined}
        containerClassName={positionStyles.zIndex5}
        content={
          <Card className={classNames(paddingStyles.p0875)}>
            <Description>{children}</Description>
          </Card>
        }
      >
        {hoverable}
      </Popover>
    </div>
  );
}
