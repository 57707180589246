import { SVGProps } from "react";

const SvgEventUpcoming = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="EventUpcoming_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#EventUpcoming_svg__a)">
      <path
        d="M12 18v-1.5h3.5V9h-11v4.5H3v-8c0-.403.146-.753.438-1.052A1.428 1.428 0 0 1 4.5 4H6V2h1.5v2h5V2H14v2h1.5c.403 0 .753.15 1.052.448.299.299.448.65.448 1.052v11c0 .403-.15.753-.448 1.052A1.44 1.44 0 0 1 15.5 18H12Zm-4.438 1L6.5 17.938l1.167-1.188H2v-1.5h5.667L6.5 14.062 7.562 13l2.938 3-2.938 3ZM4.5 7.5h11v-2h-11v2Z"
        fill="#EEDD7F"
      />
    </g>
  </svg>
);
export default SvgEventUpcoming;
