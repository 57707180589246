import { PropsWithChildren } from "react";
import { PropsWithClassName } from "../types";
import classNames from "classnames";
import { marginStyles, widthStyles } from "../styles";

interface Props extends PropsWithClassName, PropsWithChildren {}

export function ResponsiveWrapper({ className, children }: Props) {
  return (
    <div className={classNames(widthStyles.full, widthStyles.maxWidth4225, marginStyles.centered, className)}>
      {children}
    </div>
  );
}
