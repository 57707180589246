import { useNavigate } from "react-router-dom";
import { FormProvider, Loader, paddingStyles } from "ui";
import { CampaignPayload, CampaignSchema, PersistedCampaign } from "schemas";
import { updateCampaign, useCampaign } from "../../store";
import { InitialDetailsForm } from "./InitialDetailsForm";
import { Overlay } from "../ui";

export function Edit() {
  const navigate = useNavigate();
  const { campaign } = useCampaign();

  if (!campaign) {
    return <Loader />;
  }

  const onSubmit = async (payload: CampaignPayload) => {
    await updateCampaign(campaign.id, payload as unknown as PersistedCampaign);
    navigate("../..", { relative: "path" });
  };

  return (
    <FormProvider defaultValues={campaign} schema={CampaignSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: "../..", label: "Campaigns List" }}
        title="Edit a campaign"
        header="Campaign Name"
      >
        <InitialDetailsForm onSubmit={onSubmit} />
      </Overlay>
    </FormProvider>
  );
}
