import type { SVGProps } from "react";
const SvgSocialTinder = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#SocialTinder_svg__a)">
      <path
        fill="#fff"
        d="M7.764 7.876a.207.207 0 0 0 .308.052l.013-.01a9 9 0 0 0 .892-.847c2.675-2.903 1.113-6.466 1.1-6.5a.416.416 0 0 1 .125-.494.41.41 0 0 1 .513.027c9.062 8.428 6.662 14.849 6.487 15.281-.725 2.617-3.612 4.512-7.036 4.608-.115.007-.202.007-.303.007-4.043 0-7.48-2.49-7.48-5.672v-.05c0-4.415 3.995-8.769 4.174-8.954a.366.366 0 0 1 .437-.087c.15.063.248.212.243.375q-.054 1.295.525 2.25v.013z"
      />
    </g>
    <defs>
      <clipPath id="SocialTinder_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSocialTinder;
