import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Toggler } from "ui";

interface Args {
  name: string;
  id: string;
}
interface Return {
  toggle: Toggler;
}
export function useToggle({ name, id }: Args): Return {
  const { register, setValue } = useFormContext();

  const list = useWatch({ name });
  useEffect(() => {
    register(name);
  }, [register, name]);

  const toggle = async () => {
    if (list.includes(id)) {
      const newSelection = list.filter((item: string) => item !== id);
      setValue(name, newSelection, { shouldValidate: true, shouldDirty: true });
    } else {
      const newSelection = list ? [...list, id].sort() : [id];
      setValue(name, newSelection, { shouldValidate: true, shouldDirty: true });
    }
  };

  return { toggle };
}
