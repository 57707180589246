import classNames from "classnames";
import {
  bgStyles,
  borderStyles,
  heightStyles,
  opacityStyles,
  pointerStyles,
  positionStyles,
  widthStyles,
} from "../styles";
import { MouseEvent } from "react";

interface Props {
  type: "left" | "right";
  initResize: (event: MouseEvent) => void;
}
export function Handle({ type, initResize }: Props) {
  return (
    <div
      style={{ top: "calc(50% - 1.5625rem)" }}
      className={classNames(
        widthStyles.width037,
        heightStyles.height312,
        borderStyles.radius025,
        opacityStyles.opacity50,
        bgStyles.neutral0,
        positionStyles.absolute,
        pointerStyles.resizeX,
        {
          [positionStyles.right006]: type === "right",
          [positionStyles.left006]: type === "left",
        },
      )}
      onMouseDown={initResize}
    />
  );
}
