export type BuiltInGradients =
  | "Yellow"
  | "Lime"
  | "SeaGreen"
  | "Turquoise"
  | "Blue"
  | "NavyBlue"
  | "Purple"
  | "Pink"
  | "Orange"
  | "Black";

export const BuiltInGradientsValues: BuiltInGradients[] = [
  "Yellow",
  "Lime",
  "SeaGreen",
  "Turquoise",
  "Blue",
  "NavyBlue",
  "Purple",
  "Pink",
  "Orange",
  "Black",
];
