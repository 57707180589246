import {
  Animation,
  Card,
  Icon,
  SubheaderMain,
  TextMain,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  positionStyles,
} from "ui";
import classNames from "classnames";
import { Button } from "./Button";

interface Props {
  header: string;
  subheader: string;
  successTo: string;
}
export function SuccessCard({ header, subheader, successTo }: Props) {
  return (
    <Card
      className={classNames(flexStyles.vert100, iconStyles.successMedium, positionStyles.relative, paddingStyles.p125)}
    >
      <Animation
        className={classNames(
          positionStyles.absolute,
          positionStyles.left0,
          positionStyles.right0,
          positionStyles.topNegative2,
          positionStyles.bottom4,
        )}
        animationType="Tada"
      />
      <Icon className={flexStyles.alignSelfCenter} iconType="Success" />
      <SubheaderMain>{header}</SubheaderMain>
      <TextMain>{subheader}</TextMain>
      <div className={borderStyles.bottomSuccessLightDashed} />
      <Button to={successTo}>Done</Button>
    </Card>
  );
}
