import { string } from "yup";

export const LinkValidation = string()
  .min(3, "Link has to be at least 3 characters")
  .max(100, "Link can't be longer than 100 characters")
  .lowercase()
  .trim()
  .matches(/^[\w-]*$/, "Only letters, numbers, hyphen or underscore are allowed")
  .transform((value) => value || null)
  .nullable();
