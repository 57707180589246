import { useEffect } from "react";
import { useLiteSiteStore } from "../store";
import { getPage } from "./getPage";

export function usePage(id: string) {
  const page = useLiteSiteStore((state) => state.pages.get(id));

  useEffect(() => {
    if (!page) {
      getPage(id);
    }
  }, [id, page]);

  return page;
}
