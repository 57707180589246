export const ReturnPromptDaysOptions = [
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "1 week", value: 7 },
  { label: "2 weeks", value: 14 },
  { label: "3 weeks", value: 21 },
  { label: "1 month", value: 30 },
  { label: "2 months", value: 60 },
];
export const ReturnPromptDaysValues = ReturnPromptDaysOptions.map((option) => option.value);
