import type { SVGProps } from "react";
const SvgFileCsv = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" viewBox="0 0 40 40" {...props}>
    <g fill="#F0A056">
      <path d="M9.166 36.666c-.666 0-1.25-.25-1.75-.75s-.75-1.083-.75-1.75V5.833c0-.667.25-1.25.75-1.75s1.084-.75 1.75-.75h14c.334 0 .66.07.98.208.32.14.59.32.812.542l7.625 7.625a2.6 2.6 0 0 1 .542.813c.139.319.208.645.208.979v20.666c0 .667-.25 1.25-.75 1.75s-1.083.75-1.75.75H9.167Zm13.792-24.333v-6.5H9.167v28.333h21.666V13.583h-6.625c-.36 0-.66-.118-.896-.354a1.214 1.214 0 0 1-.354-.896Z" />
      <path d="M28.14 28.849v-6.76a1.611 1.611 0 0 0-1.62-1.62H8.76a1.611 1.611 0 0 0-1.62 1.62v6.76a1.611 1.611 0 0 0 1.62 1.62h17.76a1.611 1.611 0 0 0 1.62-1.62Zm-16.5-2.07a1.72 1.72 0 0 0 1.26.53c.255 0 .506-.05.74-.15.252-.126.481-.292.68-.49l.57.58a2.61 2.61 0 0 1-2 .92 2.68 2.68 0 0 1-2-.76 2.769 2.769 0 0 1 0-3.86 2.75 2.75 0 0 1 2-.78 2.6 2.6 0 0 1 2 .9l-.56.61a2.24 2.24 0 0 0-.7-.5 1.86 1.86 0 0 0-1.99.37 1.7 1.7 0 0 0-.54 1.3c-.012.5.184.98.54 1.33Zm7.43 1a2.06 2.06 0 0 1-1.35.42 3.18 3.18 0 0 1-2.17-.84l.55-.67a2.49 2.49 0 0 0 1.64.69c.24.013.478-.05.68-.18a.57.57 0 0 0 .25-.49.54.54 0 0 0-.24-.47 2.368 2.368 0 0 0-.81-.3 8.315 8.315 0 0 1-.87-.26 1.73 1.73 0 0 1-.53-.3 1.25 1.25 0 0 1-.46-1.07 1.29 1.29 0 0 1 .53-1.12 2.11 2.11 0 0 1 1.3-.39c.34-.002.678.052 1 .16.31.102.599.261.85.47l-.47.66a1.7 1.7 0 0 0-.63-.34 2.39 2.39 0 0 0-.78-.14 1.232 1.232 0 0 0-.63.16.52.52 0 0 0-.24.47.56.56 0 0 0 .24.49c.332.168.685.29 1.05.36.44.084.854.27 1.21.54.274.259.424.623.41 1a1.4 1.4 0 0 1-.54 1.12l.01.03Zm4 .36h-1l-2.09-5.21h1l1.61 3.89 1.6-3.89h1l-2.12 5.21Z" />
    </g>
  </svg>
);
export default SvgFileCsv;
