"use client";

import { RefObject, useEffect } from "react";

interface Args {
  ref: RefObject<HTMLElement>;
  trigger: boolean;
  offsetAdjustment?: number;
}
export function useHorizontalScrollIntoView({ ref, trigger, offsetAdjustment = 0 }: Args) {
  useEffect(() => {
    const parent = ref.current?.parentElement;
    const childOffset = ref.current?.offsetLeft;
    if (trigger && childOffset && parent) {
      if (parent.clientWidth < childOffset + ref.current?.clientWidth) {
        const offset = childOffset - parent.offsetLeft + offsetAdjustment;
        parent.scrollTo({
          left: offset,
          behavior: "smooth",
        });
      }
    }
  }, [ref, offsetAdjustment, trigger]);
}
