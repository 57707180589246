import type { SVGProps } from "react";
const SvgComponent1 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none" {...props}>
    <path
      fill="#465357"
      d="m15.938 17.5-4.98-4.979a5.208 5.208 0 0 1-1.375.719A4.8 4.8 0 0 1 8 13.5c-1.389 0-2.57-.486-3.542-1.458C3.486 11.069 3 9.889 3 8.5c0-1.389.486-2.57 1.458-3.542C5.431 3.986 6.611 3.5 8 3.5c1.389 0 2.57.486 3.542 1.458C12.514 5.931 13 7.111 13 8.5a4.8 4.8 0 0 1-.26 1.583c-.174.5-.414.958-.719 1.375L17 16.438 15.938 17.5ZM8 12c.972 0 1.798-.34 2.479-1.021.68-.68 1.021-1.507 1.021-2.479 0-.972-.34-1.798-1.021-2.479C9.799 5.341 8.972 5 8 5c-.972 0-1.798.34-2.479 1.021C4.841 6.701 4.5 7.528 4.5 8.5c0 .972.34 1.798 1.021 2.479C6.201 11.659 7.028 12 8 12Z"
    />
  </svg>
);
export default SvgComponent1;
