import { Drawer, Loader, flexStyles } from "ui";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { CandidateCard } from "./CandidateCard";
import { useContactImportCandidates } from "../../store";

export function CandidatesDrawer() {
  const navigate = useNavigate();
  const { candidates, loading } = useContactImportCandidates();

  const onClose = () => navigate("..");

  return (
    <Drawer title="Contacts to be imported" open onClose={onClose} className={classNames(flexStyles.vert125)}>
      {loading ? (
        <Loader />
      ) : (
        candidates.map((contact) => <CandidateCard key={`candidate-${contact.email}`} contact={contact} />)
      )}
    </Drawer>
  );
}
