import { BusinessPayload, BusinessSchema } from "schemas";
import { FormProvider, FormTag, HeaderMain, InlineTextInput, PropsWithClassName, textStyles } from "ui";
import { useState } from "react";
import { Menu } from "./Menu";
import { updateBusiness, useCurrentBusiness } from "../../../store";
import { EditWrapper } from "../../blocks";

interface Props extends PropsWithClassName {
  hasLabel?: boolean;
}
export function EditBusinessName({ hasLabel, className }: Props) {
  const business = useCurrentBusiness();
  const [open, setOpen] = useState(false);

  const onSubmit = async (payload: BusinessPayload) => {
    await updateBusiness(payload);
    setOpen(false);
  };

  const label = hasLabel ? "Edit Your Business Name" : undefined;

  const editor = open && (
    <FormProvider<BusinessPayload> schema={BusinessSchema} defaultValues={business}>
      <FormTag<BusinessPayload> onSubmit={onSubmit}>
        <InlineTextInput
          label={label}
          size="Header"
          name="businessName"
          placeholder="Your business name"
          onSubmit={onSubmit}
        />
        <Menu<BusinessPayload> onSubmit={onSubmit} onCancel={() => setOpen(false)} />
      </FormTag>
    </FormProvider>
  );

  return (
    <EditWrapper className={textStyles.center} open={open} onClick={async () => setOpen(true)} editElement={editor}>
      <HeaderMain className={className}>{business.businessName}</HeaderMain>
    </EditWrapper>
  );
}
