import { Asserts, object, string } from "yup";
import { CaptchaString, EmailValidation } from "./shared";

export const LoginSchema = object({
  email: EmailValidation.required("Email is required"),
  password: string().required("Password is required"),
  captchaResponse: CaptchaString,
});
export type LoginPayload = Asserts<typeof LoginSchema>;

export const EmptyLogin: LoginPayload = {
  email: "",
  password: "",
  captchaResponse: "",
};
