import type { SVGProps } from "react";
const SvgSocialLine = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 2.333c4.595 0 8.333 3.034 8.333 6.763 0 1.492-.578 2.836-1.786 4.16C14.8 15.27 10.89 17.72 10 18.096c-.865.364-.765-.206-.725-.431l.003-.018.12-.714c.027-.213.056-.543-.028-.753-.093-.232-.463-.353-.734-.411-4.005-.53-6.97-3.33-6.97-6.672 0-3.729 3.74-6.763 8.334-6.763m-1.69 4.96h-.59a.16.16 0 0 0-.16.16v3.637c0 .088.071.159.16.159h.59c.088 0 .16-.071.16-.16V7.454a.16.16 0 0 0-.16-.16M7.06 11.09a.16.16 0 0 1-.16.159H4.56a.16.16 0 0 1-.16-.16V7.454a.16.16 0 0 1 .16-.16h.59a.16.16 0 0 1 .16.16v2.887H6.9a.16.16 0 0 1 .16.159zm6.16.159h2.341a.16.16 0 0 0 .16-.16v-.59a.16.16 0 0 0-.16-.16H13.97v-.613h1.591a.16.16 0 0 0 .16-.159v-.591a.16.16 0 0 0-.16-.16H13.97v-.613h1.591a.16.16 0 0 0 .16-.159v-.59a.16.16 0 0 0-.16-.16H13.22a.16.16 0 0 0-.16.16v3.636c0 .088.072.159.16.159m-1.478-3.955h.591a.16.16 0 0 1 .16.159v3.637a.16.16 0 0 1-.16.159h-.587a.2.2 0 0 1-.042-.006h-.002l-.011-.004-.005-.002q-.004 0-.008-.003l-.008-.004h-.001l-.003-.002-.005-.003-.005-.003-.002-.002a.2.2 0 0 1-.041-.04L9.947 8.93v2.16a.16.16 0 0 1-.16.159h-.59a.16.16 0 0 1-.16-.16V7.454a.16.16 0 0 1 .16-.16h.587l.006.001h.008l.009.002h.007l.01.003.005.001.005.002.005.001.005.002.009.004.005.003.009.005.005.002.003.003.005.003.004.003.009.008.003.002.004.004.006.006v.002q.008.007.013.016l1.664 2.247v-2.16a.16.16 0 0 1 .16-.16"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialLine;
