import type { SVGProps } from "react";
const SvgSocialVk = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M10.401 15.996c.435 0 .613-.29.608-.654-.022-1.37.51-2.106 1.47-1.146 1.064 1.063 1.284 1.8 2.574 1.8h2.286c.577 0 .804-.186.804-.478 0-.616-1.015-1.704-1.875-2.502-1.204-1.118-1.26-1.145-.223-2.49 1.286-1.671 2.969-3.812 1.48-3.812h-2.843c-.552 0-.591.311-.788.774-.71 1.676-2.061 3.848-2.574 3.516-.537-.347-.29-1.719-.25-3.758.01-.539.008-.908-.815-1.1A5.8 5.8 0 0 0 8.963 6c-1.624 0-2.744.68-2.107.8 1.122.209 1.014 2.636.752 3.685C7.153 12.31 5.44 9.039 4.726 7.41c-.172-.391-.225-.696-.839-.696H1.562c-.351 0-.562.115-.562.369 0 .43 2.114 4.8 4.133 6.978 1.968 2.125 3.914 1.935 5.268 1.935"
    />
  </svg>
);
export default SvgSocialVk;
