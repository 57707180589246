import { useFormContext } from "react-hook-form";
import { PropsWithDirtyBlock } from "../../../helpers";
import { useEffect } from "react";

interface Props extends PropsWithDirtyBlock {}
export function DirtyBlockLock({ setDirtyBlock }: Props) {
  const {
    formState: { isDirty },
  } = useFormContext();

  useEffect(() => {
    setDirtyBlock(isDirty);
  }, [isDirty, setDirtyBlock]);
  return null;
}
