import { SVGProps } from "react";

const SvgPayments = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="payments_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#payments_svg__a)">
      <path
        d="M11.5 11a2.411 2.411 0 0 1-1.771-.729A2.411 2.411 0 0 1 9 8.5c0-.695.243-1.285.729-1.771A2.411 2.411 0 0 1 11.5 6c.695 0 1.285.243 1.771.729S14 7.805 14 8.5c0 .695-.243 1.285-.729 1.771A2.411 2.411 0 0 1 11.5 11Zm-6 2c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 4 11.5v-6c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 5.5 4h12c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v6c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 17.5 13h-12ZM7 11.5h9c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 17.5 10V7c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 16 5.5H7c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 5.5 7v3c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062Zm9.5 4.5h-14c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 1 14.5V6h1.5v8.5h14V16Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgPayments;
