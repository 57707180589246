import type { SVGProps } from "react";
const SvgSocialThumblr = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M14.524 18.333h-2.812c-2.53 0-4.414-1.29-4.414-4.374v-4.94H5V6.341c2.529-.65 3.587-2.807 3.708-4.675h2.626v4.242H14.4v3.11h-3.064v4.301c0 1.29.656 1.735 1.702 1.735h1.483z"
    />
  </svg>
);
export default SvgSocialThumblr;
