import { MouseEvent, PropsWithChildren } from "react";
import { OnClickHandler, PropsWithClassName } from "../types";
import classNames from "classnames";
import { buttonStyles } from "../styles";
import { ButtonSize } from "./ButtonSize";
import { ButtonType } from "./ButtonType";

interface Props extends PropsWithChildren, PropsWithClassName {
  onClick: OnClickHandler | ((e: MouseEvent<HTMLButtonElement>) => Promise<void>);
  disabled?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  themed?: boolean;
  stopPropagation?: boolean;
}

export function Button({
  children,
  className,
  onClick,
  disabled = false,
  type = "primary",
  size = "large",
  themed,
  stopPropagation,
}: Props) {
  const neuteredClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      if (stopPropagation) {
        e.stopPropagation();
      }
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <button
      type="button"
      className={classNames(buttonStyles.btn, className, {
        [buttonStyles.themed]: themed,
        [buttonStyles.primary]: type === "primary",
        [buttonStyles.secondary]: type === "secondary",
        [buttonStyles.tertiary]: type === "tertiary",
        [buttonStyles.small]: size === "small",
        [buttonStyles.medium]: size === "medium",
        [buttonStyles.large]: size === "large",
      })}
      disabled={disabled}
      onClick={neuteredClick}
    >
      {children}
    </button>
  );
}
