import { ThemePayload } from "../schemas/Theme";
import { BlackGradient, Gradients, GrayGradient } from "./gradients";

export function gradientForTheme(theme: ThemePayload) {
  const gradient = Gradients[theme.gradient];
  switch (theme.variation) {
    case "Primary":
      return gradient.Primary;
    case "Light":
      return gradient.Light;
    case "Dark":
      return gradient.Dark;
    case "Gray":
      return GrayGradient;
    case "Black":
      return BlackGradient;
  }
}
