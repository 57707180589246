import { useEffect, useState } from "react";
import { useLiteSiteStore } from "../store";
import { getNewItems } from "./getNewItems";

export function useNewItems() {
  const [loading, setLoading] = useState<boolean>(true);
  const newItems = useLiteSiteStore((state) => state.newItems);

  useEffect(() => {
    if (loading) {
      getNewItems().then(() => setLoading(false));
    }
  }, [setLoading, loading]);

  return { loading, newItems };
}
