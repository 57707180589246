import { useEffect } from "react";
import { useLiteSiteStore } from "../store";
import { getPage } from "./getPage";

export function useHomePage() {
  const homePage = useLiteSiteStore((state) => state.homePage);

  useEffect(() => {
    if (!homePage) {
      getPage();
    }
  }, [homePage]);

  return homePage;
}
