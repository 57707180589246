import { TextInput } from "ui";
import { BusinessForm } from "./BusinessForm";
import { useCurrentBusiness } from "../../store";

export function SenderDetailsForm() {
  const business = useCurrentBusiness();

  return (
    <BusinessForm header="Edit your sender details" closeUrl=".." submitText="Save">
      <TextInput
        name="emailSettings.fromName"
        label="Custom email from name"
        placeholder={business.emailSettings.resolvedFromName}
      />
      <TextInput
        name="emailSettings.replyToEmail"
        label="Custom reply-to email address"
        placeholder={business.emailSettings.resolvedReplyToEmail}
      />
    </BusinessForm>
  );
}
