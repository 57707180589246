import type { SVGProps } from "react";
const SvgContactRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#191B20"
      d="M13 9.5V8h5v1.5zM8 10a2.9 2.9 0 0 1-2.125-.875A2.9 2.9 0 0 1 5 7q0-1.25.875-2.125A2.9 2.9 0 0 1 8 4q1.25 0 2.125.875T11 7t-.875 2.125A2.9 2.9 0 0 1 8 10m-6 6v-1.917q0-.537.26-.987.261-.45.72-.721a10.3 10.3 0 0 1 2.416-1.02A9.7 9.7 0 0 1 8 11q1.334 0 2.604.354a10.3 10.3 0 0 1 2.417 1.021q.458.27.719.721t.26.987V16zm1.5-1.5h9v-.417a.48.48 0 0 0-.23-.416 9 9 0 0 0-2.062-.865A8.2 8.2 0 0 0 8 12.5q-1.125 0-2.208.302-1.084.303-2.063.865a.486.486 0 0 0-.23.417zm4.504-6q.621 0 1.059-.442T9.5 6.996t-.442-1.059A1.46 1.46 0 0 0 7.996 5.5q-.621 0-1.059.442A1.46 1.46 0 0 0 6.5 7.004q0 .621.442 1.059t1.062.437"
    />
  </svg>
);
export default SvgContactRemove;
