import type { SVGProps } from "react";
const SvgContactAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#465357"
      d="M9.375 9.938q.542-.625.833-1.378a4.28 4.28 0 0 0 0-3.106 5 5 0 0 0-.833-1.391q.166-.042.313-.053Q9.833 4 10 4q1.25 0 2.125.875T13 7t-.875 2.125A2.9 2.9 0 0 1 10 10q-.167 0-.323-.01a1.5 1.5 0 0 1-.302-.053M13.5 16v-1.917q0-.854-.396-1.593A3.75 3.75 0 0 0 12 11.23q1.416.332 2.708.916T16 14.083V16zm2.75-5V9.25H14.5v-1.5h1.75V6h1.5v1.75h1.75v1.5h-1.75V11zM6 10a2.9 2.9 0 0 1-2.125-.875A2.9 2.9 0 0 1 3 7q0-1.25.875-2.125A2.9 2.9 0 0 1 6 4q1.25 0 2.125.875T9 7t-.875 2.125A2.9 2.9 0 0 1 6 10m-6 6v-1.917q0-.53.26-.973.261-.442.72-.735a8.5 8.5 0 0 1 2.406-1.042A10.3 10.3 0 0 1 5.99 11q1.323 0 2.593.354t2.438 1.021q.437.292.708.735.27.444.271.973V16zm5.99-7.5q.614 0 1.062-.437.448-.438.448-1.053 0-.614-.448-1.062A1.45 1.45 0 0 0 5.99 5.5q-.614 0-1.053.448A1.47 1.47 0 0 0 4.5 7.01q0 .614.438 1.053.437.437 1.052.437m-4.49 6h9v-.417q0-.125-.062-.23a.7.7 0 0 0-.167-.186 10.5 10.5 0 0 0-2.073-.854 8.109 8.109 0 0 0-4.417-.01q-1.073.3-2.052.864a.7.7 0 0 0-.167.187.44.44 0 0 0-.062.23z"
    />
  </svg>
);
export default SvgContactAdd;
