import { SVGProps } from "react";

const SvgFinalSquiggleBottom = (props: SVGProps<SVGSVGElement>) => (
  <svg width={142} height={75} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.893 25.911c-3.66 6.92-5.985 15.9-4.32 23.954 1.562 7.55 5.104 16.907 12.168 20.225 10.278 4.828 23.879 2.371 33.763-1.688 4.827-1.982 8.359-9.556 9.842-14.508 2.127-7.103 5.338-13.022 5.164-20.76-.155-6.917-3.618-13.13-10.162-11.776-9.392 1.942-10.177 10.221-8.351 19.053 2.098 10.145 9.633 20.536 18.399 24.911 12.511 6.245 28.99 1.703 41.306-2.044 12.045-3.664 22.843-12.499 27.754-25.231 3.388-8.785 3.776-18.679-3.155-25.578-10.003-9.956-23.383-5.126-20.972 10.514 1.399 9.07 5.528 18.424 11.186 25.44 2.504 3.106 3.835 5.365 7.512 6.789 7.923 3.069 18.274-.17 26.124-1.793 5.549-1.147 11.967-2.441 16.405-6.44 7.706-6.945 12.592-18.513 13.454-29.253.412-5.13-3.629-21.242-9.868-12.839-1.712 2.307-4.187 4.446-4.765 7.563-1.27 6.854 3.357 17.305 6.859 22.846 5.021 7.945 12.972 13.762 22.176 11.858"
      stroke="#8FC9FA"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgFinalSquiggleBottom;
