import { useEffect } from "react";
import { useIdParam } from "../../hooks";
import { useLiteSiteStore } from "../store";
import { getTheme } from "./getTheme";

export function useTheme(passedId?: string) {
  const paramId = useIdParam("themeId");
  const id = passedId || paramId;
  const theme = useLiteSiteStore(({ themes }) => themes.get(id)!);

  useEffect(() => {
    if (id) {
      getTheme(id);
    }
  }, [id]);

  return theme;
}
