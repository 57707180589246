import { PagePayload } from "schemas";
import { FormTag, LinkInput, TextInput, VisualTextArea } from "ui";
import { Submit } from "../ui";
import { checkEntityLink, useCurrentBusiness } from "../../store";

const { REACT_APP_PUBLIC_DOMAIN } = import.meta.env;

interface Props {
  onSubmit: (payload: PagePayload) => Promise<void>;
  id?: string;
}
export function Form({ onSubmit, id }: Props) {
  const business = useCurrentBusiness();
  const base = `${REACT_APP_PUBLIC_DOMAIN}/${business!.link}/`;

  const checkLink = async (link: string, abortController: AbortController) => {
    const available = await checkEntityLink({ type: "page", link, id }, abortController);
    return available ? "" : `${link} is already taken`;
  };

  return (
    <FormTag<PagePayload> onSubmit={onSubmit}>
      <TextInput name="name" label="Page Name" />
      <VisualTextArea type="form" name="description" label="Page Description" />
      <LinkInput name="handle" label="Page Link" base={base} slugFrom="name" check={checkLink} />
      <Submit label="Save and continue" />
    </FormTag>
  );
}
