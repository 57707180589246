import type { SVGProps } from "react";
const SvgSocialTwitch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path fill="#fff" d="M10 5.417h1.25v3.75H10zm4.375 0v3.75h-1.25v-3.75z" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m2.5 4.792 3.125-3.125H17.5v8.75l-5.625 5.625h-2.5L6.25 19.167v-3.125H2.5zm13.75 5-2.5 2.5h-2.5l-2.5 2.5v-2.5h-2.5V2.917h10zM10 5.417h1.25v3.75H10zm4.375 0v3.75h-1.25v-3.75z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialTwitch;
