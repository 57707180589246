import { SVGProps } from "react";

const SvgBillingUpArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={38} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.583 2.834c-.828-.163-1.802-.04-2.655-.115-.889-.078-1.771-.149-2.655-.238-.551-.056-1.018-.256-1.554-.324-.07-.01-.755-.25-.735-.169.066.274.317.565.458.809.664 1.15 1.453 2.272 2.142 3.43.64 1.074 1.233 2.136 1.858 3.218.453.785.781 1.633 1.227 2.405.307.532-.558-1.094-.797-1.66-.446-1.059-.787-2.175-1.35-3.18-.417-.744-.722-1.53-1.148-2.268-.403-.698-1.907-2.576-1.303-2.038.935.831 1.782 1.787 2.66 2.676 1.653 1.673 3.16 3.52 4.95 5.054 3.538 3.03 7.41 5.757 11.67 7.741 2.975 1.386 5.902 1.76 9.194 1.74 1.806-.011 3.901-.11 5.442-1.224"
      stroke="#6ABB7B"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgBillingUpArrow;
