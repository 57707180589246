import { SVGProps } from "react";

const SvgFinalSquiggleLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={385} height={217} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M383 2c-2.514 9.36-2.043 19.09-4.424 28.546-2.808 11.151-12.285 22.602-19.466 30.743-18.053 20.465-43.879 36.318-68.575 44.771-17.671 6.049-42.608 6.672-58.841-4.27-16.855-11.36-37.329-49.803-27.872-71.244 13.47-30.54 60.361-40.873 72.667-3.171 7.886 24.161 9.451 46.921.995 70.756-9.111 25.682-31.093 39.907-52.869 50.993-32.105 16.343-66.821 31.991-102.419 35.012-28.755 2.439-62.28-18.308-71.228-48.676-8.007-27.173-11.829-61.122 7.963-82.467C68.499 42.675 81.35 36 92.333 48.113c13.89 15.32 13.271 49.242 11.835 69.049-1.365 18.824-10.266 37.285-17.807 53.92-12.519 27.615-48.87 35.269-73.884 39.282C-9.077 213.822-31.226 215-53 215"
      stroke="#8FC9FA"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgFinalSquiggleLeft;
