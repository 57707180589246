import classNames from "classnames";
import { RawIcon } from "../../Icon";
import { displayStyles } from "../../styles";
import { PropsWithClassName, PropsWithOptionalClick, PropsWithOptionalName } from "../../types";

interface Props extends PropsWithOptionalClick, PropsWithOptionalName, PropsWithClassName {
  on: boolean;
  interrim?: boolean;
}
export function RawCheckbox({ on, name, interrim, onClick, className }: Props) {
  const checkIconType = on ? "CheckboxChecked" : "CheckboxUnchecked";
  const iconType = interrim ? "CheckboxInterrim" : checkIconType;

  return (
    <label className={classNames(displayStyles.block, className)} htmlFor={name} onClick={onClick}>
      <RawIcon iconType={iconType} />
    </label>
  );
}
