import { FormProvider, Loader, ResponsiveWrapper, flexStyles, paddingStyles } from "ui";
import { getContacts, useContactGroup, useContacts } from "../../store";
import classNames from "classnames";
import { ContactsList } from "../contacts/ContactsList";
import { filterContacts } from "../../helpers/filterContacts";
import { AllCheckbox } from "./AllCheckbox";
import { SelectContactsSchema } from "schemas";
import { SelectBottomBar } from "./SelectBottomBar";
import { Outlet, useNavigate } from "react-router-dom";
import { LoadMore, Overlay } from "../ui";
import { usePagination } from "../../hooks";

export function Select() {
  const contacts = useContacts();
  const navigate = useNavigate();
  const contactGroup = useContactGroup();
  const { page, setPage } = usePagination();
  const onBack = () => navigate("..", { relative: "path" });

  if (!contactGroup) {
    return <Loader />;
  }

  const onMore = async () => {
    if (page.on) {
      const nextPage = page.page + 1;
      const contacts = await getContacts(contactGroup.id, nextPage);
      setPage({ page: nextPage, on: contacts.length > 0 });
    }
  };

  const contactsToRender = contactGroup ? filterContacts(contacts, contactGroup) : contacts;

  return (
    <FormProvider defaultValues={{ contacts: [] }} schema={SelectContactsSchema}>
      <Overlay full header={contactGroup.name} backTo={{ to: "..", label: `${contactGroup.name} List` }}>
        <ResponsiveWrapper className={classNames(flexStyles.vert050, flexStyles.grow, paddingStyles.p125)}>
          <AllCheckbox contactGroup={contactGroup} name="contacts" collection={contactsToRender} />
          <ContactsList newContactTo="contacts/new" hideInitials name="contacts" contacts={contactsToRender} />
          <LoadMore loadMore={onMore} />
        </ResponsiveWrapper>
        <SelectBottomBar name="contacts" onDone={onBack} />
        <Outlet />
      </Overlay>
    </FormProvider>
  );
}
