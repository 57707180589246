import classNames from "classnames";
import { OnClickHandler, PropsWithClassName } from "./types";
import { Icon, IconType } from "./Icon";
import { TextMain } from "./typography";
import { bgStyles, borderStyles, flexStyles, iconStyles, paddingStyles, pointerStyles } from "./styles";

interface Props extends PropsWithClassName {
  title: string;
  iconType: IconType;
  onClick: OnClickHandler;
}

export function ClickRow({ className, title, iconType, onClick }: Props) {
  return (
    <div
      className={classNames(
        bgStyles.neutral0,
        iconStyles.secondary300,
        flexStyles.horiz050,
        flexStyles.alignStart,
        borderStyles.bottomNeutral200,
        borderStyles.topFirstChildNeutral200,
        paddingStyles.p125,
        pointerStyles.cursor,
        className,
      )}
      onClick={onClick}
    >
      <Icon className={paddingStyles.pt012} iconType={iconType} />
      <TextMain>{title}</TextMain>
    </div>
  );
}
