import { ChangePasswordPayload } from "schemas";
import { apiRequest } from "../apiRequest";

interface Args {
  payload: ChangePasswordPayload;
  token: string;
}
export async function patchPasswordReset({ payload, token }: Args): Promise<boolean> {
  await apiRequest({ url: `passwordReset/${token}`, method: "PATCH", body: JSON.stringify(payload) });
  return true;
}
