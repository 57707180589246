import { AddForm, Connect, Edit, New } from "../components/appointments";
import { ReminderForm } from "../components/appointments/ReminderForm";
import { Preview } from "../components/forms";
import { PrivateOutlet } from "./PrivateOutlet";

export const AppointmentRoutes = {
  path: "appointments",
  Component: PrivateOutlet,
  children: [
    { path: "new/:destinationIndex", Component: New },
    { path: ":id", element: <Edit step="BasicDetails" /> },
    { path: ":id/schedule", element: <Edit step="Schedule" /> },
    { path: ":id/utilities", element: <Edit step="Utilities" /> },
    { path: ":id/utilities/preview/:formId", Component: Preview },
    { path: ":id/addForm", Component: AddForm },
    { path: ":id/automation", element: <Edit step="Automation" /> },
    { path: ":id/automation/:reminderName", Component: ReminderForm },
    { path: "connect", Component: Connect },
  ],
};
