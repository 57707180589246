import { SVGProps } from "react";

const SvgButtons = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Buttons_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Buttons_svg__a)">
      <mask
        id="Buttons_svg__b"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
      </mask>
      <g mask="url(#Buttons_svg__b)">
        <path
          d="M9 14H6c-1.111 0-2.056-.389-2.833-1.167C2.389 12.056 2 11.111 2 10c0-1.111.389-2.056 1.167-2.833C3.944 6.389 4.889 6 6 6h3v1.5H6c-.695 0-1.285.243-1.771.729A2.411 2.411 0 0 0 3.5 10c0 .695.243 1.285.729 1.771A2.411 2.411 0 0 0 6 12.5h3V14Zm-2-3.25v-1.5h6v1.5H7ZM11 14v-1.5h3c.695 0 1.285-.243 1.771-.729A2.411 2.411 0 0 0 16.5 10c0-.695-.243-1.285-.729-1.771A2.411 2.411 0 0 0 14 7.5h-3V6h3c1.111 0 2.056.389 2.833 1.167C17.611 7.944 18 8.889 18 10c0 1.111-.389 2.056-1.167 2.833C16.056 13.611 15.111 14 14 14h-3Z"
          fill="#F0A056"
        />
      </g>
    </g>
  </svg>
);
export default SvgButtons;
