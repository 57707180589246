import { useNavigate } from "react-router-dom";
import {
  Card,
  DescriptionAccent,
  Icon,
  IconButton,
  RawIcon,
  SmallTextAccent,
  SmallTextMain,
  TextMain,
  flexStyles,
  paddingStyles,
  textStyles,
  useConfirm,
} from "ui";
import classNames from "classnames";
import { Button, Overlay, SuperCard } from "../ui";
import { useUrlWithBack } from "../../hooks";
import { deleteCalendar, useCalendars, useCurrentBusiness } from "../../store";

export function Calendar() {
  const navigate = useNavigate();
  const business = useCurrentBusiness();
  const { calendars } = useCalendars();
  const discoveredCalendarsLink = useUrlWithBack("/billing/discovered/calendars");
  const connectAppointmentsLocation = useUrlWithBack("/appointments/connect");
  const { confirm, Confirm } = useConfirm();

  const handleAdd = () => {
    navigate(connectAppointmentsLocation);
  };

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap050)}
      header="Synced Calendars"
      backTo={{ to: "/settings/calendars", label: "Calendars" }}
    >
      <Confirm />
      {calendars.map((calendar) => {
        const onDeleteCalendar =
          !calendar.defaultCalendar &&
          (async () => {
            confirm({
              text: `Are you sure you want to remove ${calendar.email} calendar?`,
              label: "Delete Calendar",
              onConfirm: async () => deleteCalendar(calendar.id),
            });
          });
        return (
          <SuperCard
            key={calendar.id}
            left={<Icon iconType={calendar.defaultCalendar ? "CalendarPrimary" : "Calendar"} />}
            right={onDeleteCalendar && <IconButton iconType="Delete" onClick={onDeleteCalendar} />}
            title={<DescriptionAccent>{calendar.defaultCalendar ? "Primary Calendar" : ""}</DescriptionAccent>}
          >
            <TextMain>{calendar.email}</TextMain>
          </SuperCard>
        );
      })}
      <Card className={classNames(paddingStyles.p125, flexStyles.gap125, textStyles.center)}>
        <RawIcon className={flexStyles.alignSelfCenter} iconType="MultipleCalendars" />
        <div className={flexStyles.vert050}>
          <SmallTextAccent>Add multiple calendars for more accurate availability!</SmallTextAccent>
          <SmallTextMain>
            Your primary calendar is your work calendar where all your and client interactions are booked. Other
            calendars can be personal or family calendars.
          </SmallTextMain>
        </div>
        {business.paidFeatures.multipleCalendars ? (
          <Button onClick={handleAdd}>Add Calendar</Button>
        ) : (
          <Button to={discoveredCalendarsLink}>
            <Icon iconType="Diamond" />
            Add Calendar
          </Button>
        )}
      </Card>
    </Overlay>
  );
}
