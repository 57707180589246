import { ContactImportPayload, PersistedContactImport } from "schemas";
import { apiRequest } from "../apiRequest";
import { setContactImport } from "./setContactImport";

interface Args {
  payload: ContactImportPayload;
  contactImportId: string;
}
export async function updateContactImport({ contactImportId, payload }: Args) {
  const contactImport = await apiRequest<PersistedContactImport>({
    url: `contactImports/${contactImportId}`,
    method: "PATCH",
    body: JSON.stringify(payload),
  });
  setContactImport(contactImport);

  return contactImport;
}
