import classNames from "classnames";
import { Loader, ResponsiveWrapper, SearchInput, flexStyles, paddingStyles } from "ui";
import { SearchContacts } from "./SearchContacts";
import { useSearchQuery } from "../../hooks";
import { useContactGroup } from "../../store";
import { Outlet, useNavigate } from "react-router-dom";
import { Overlay } from "../ui";

export function Search() {
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery } = useSearchQuery();
  const contactGroup = useContactGroup();
  const onBack = () => navigate("..", { relative: "path" });

  if (!contactGroup) {
    return <Loader />;
  }

  return (
    <Overlay
      full
      header={contactGroup?.name || "All contacts"}
      backTo={{ to: "..", label: contactGroup ? `${contactGroup.name} List` : undefined }}
    >
      <ResponsiveWrapper className={classNames(flexStyles.vert050, paddingStyles.p125)}>
        <SearchInput
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          value={searchQuery}
          onCancel={onBack}
        />
        <SearchContacts query={searchQuery} contactGroup={contactGroup} hideInitials />
        <Outlet />
      </ResponsiveWrapper>
    </Overlay>
  );
}
