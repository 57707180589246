import { PagePayload, PersistedPage } from "schemas";
import { setPage } from "./setPage";
import { getPage } from "./getPage";
import { apiRequest } from "../apiRequest";

export async function addPage(
  destinationIndex: number,
  destinationPage: PersistedPage,
  payload: PagePayload,
): Promise<PersistedPage> {
  const page = await apiRequest<PersistedPage>({
    url: "pages",
    method: "POST",
    body: JSON.stringify({
      page: payload,
      destination: {
        destinationIndex,
        destinationId: destinationPage.id,
        destinationType: "Page",
      },
    }),
  });

  setPage(page);
  getPage(destinationPage.id);

  return page;
}
