"use client";

import { useCopyToClipboard } from "react-use";

type Hook = {
  onClick: () => Promise<void>;
};
export function useShareHandler(url: string, onCopy?: (text: string) => void): Hook {
  const [, copyToClipboard] = useCopyToClipboard();
  const onClick = async () => {
    if (navigator.share) {
      await navigator.share({
        url,
      });
    } else {
      copyToClipboard(url);
      onCopy && onCopy("Link copied to your clipboard");
    }
  };

  return { onClick };
}
