import { ContactPayload, PersistedContact } from "schemas";
import { setContact } from "./setContact";
import { apiRequest } from "../apiRequest";

export async function addContact(payload: ContactPayload): Promise<PersistedContact> {
  const contact = await apiRequest<PersistedContact>({
    url: "contacts",
    method: "POST",
    body: JSON.stringify(payload),
  });

  setContact(contact);
  return contact;
}
