import type { SVGProps } from "react";
const SvgSocialThreads = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M14.156 9.151a5 5 0 0 0-.212-.096c-.125-2.296-1.38-3.611-3.49-3.625h-.029c-1.262 0-2.31.538-2.956 1.518l1.16.795c.482-.732 1.24-.888 1.797-.888h.02c.694.005 1.218.207 1.557.6q.371.43.493 1.182a9 9 0 0 0-1.993-.096c-2.005.115-3.294 1.284-3.207 2.908a2.5 2.5 0 0 0 1.156 1.995c.594.391 1.357.582 2.151.54 1.05-.057 1.872-.457 2.445-1.189.436-.555.711-1.274.833-2.18.5.3.87.698 1.075 1.174.348.81.368 2.141-.72 3.228-.953.95-2.098 1.362-3.83 1.374-1.92-.014-3.372-.629-4.316-1.829-.884-1.123-1.34-2.745-1.358-4.82.017-2.077.474-3.699 1.358-4.822.944-1.2 2.396-1.815 4.316-1.83 1.935.015 3.411.634 4.392 1.839.48.59.843 1.334 1.082 2.2l1.359-.362c-.29-1.066-.746-1.986-1.366-2.748-1.257-1.545-3.094-2.336-5.463-2.352h-.01c-2.362.016-4.18.811-5.4 2.362-1.087 1.38-1.648 3.3-1.667 5.706v.012c.02 2.407.58 4.327 1.666 5.707 1.221 1.55 3.039 2.345 5.402 2.362h.01c2.1-.015 3.581-.565 4.801-1.783 1.597-1.593 1.549-3.59 1.023-4.817-.375-.88-1.095-1.593-2.08-2.065m-3.628 3.408c-.879.05-1.791-.345-1.837-1.188-.033-.626.446-1.325 1.89-1.408q.248-.014.488-.014a7 7 0 0 1 1.463.148c-.167 2.078-1.143 2.415-2.004 2.462"
    />
  </svg>
);
export default SvgSocialThreads;
