import { PasswordResetPayload, PasswordResetSchema } from "schemas";
import { FormProvider, FormTag, ReCaptcha, SubheaderMain, TextInput, textStyles } from "ui";
import { toast } from "react-toastify";
import { AuthScreen } from "./AuthScreen";
import { Link, Submit } from "../ui";
import { requestPasswordReset } from "../../store";

export function PasswordReset() {
  const onReset = async (payload: PasswordResetPayload) => {
    await requestPasswordReset(payload);
    toast.success("Check your email for a password reset link.");
  };

  return (
    <FormProvider<PasswordResetPayload> defaultValues={{ email: "" }} schema={PasswordResetSchema}>
      <AuthScreen>
        <SubheaderMain>Forgot your password?</SubheaderMain>
        <FormTag<PasswordResetPayload> onSubmit={onReset}>
          <TextInput type="email" name="email" label="Email" />
          <Submit label="Reset your password" disableClean disableInvalid />
          <ReCaptcha action="requestPasswordReset" />
          <Link className={textStyles.center} to="/auth/login">
            Back to login
          </Link>
        </FormTag>
      </AuthScreen>
    </FormProvider>
  );
}
