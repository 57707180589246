import { SVGProps } from "react";

const SvgShow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 13.823c.972 0 1.798-.34 2.479-1.02.68-.681 1.021-1.508 1.021-2.48s-.34-1.798-1.021-2.479c-.68-.68-1.507-1.02-2.479-1.02-.972 0-1.798.34-2.479 1.02-.68.681-1.021 1.507-1.021 2.48 0 .972.34 1.798 1.021 2.479.68.68 1.507 1.02 2.479 1.02Zm0-1.5a1.931 1.931 0 0 1-1.417-.582A1.931 1.931 0 0 1 8 10.323c0-.555.194-1.027.583-1.417.39-.388.862-.583 1.417-.583s1.028.195 1.417.583c.389.39.583.862.583 1.417 0 .556-.194 1.028-.583 1.418-.39.388-.862.582-1.417.582Zm0 4c-1.986 0-3.792-.545-5.417-1.635A9.64 9.64 0 0 1 1 10.323a9.639 9.639 0 0 1 3.583-4.365C6.208 4.868 8.014 4.323 10 4.323c1.986 0 3.792.545 5.417 1.635A9.639 9.639 0 0 1 19 10.323a9.64 9.64 0 0 1-3.583 4.365c-1.625 1.09-3.431 1.635-5.417 1.635Zm0-1.5a8.123 8.123 0 0 0 4.312-1.207 8.084 8.084 0 0 0 3.042-3.293 8.085 8.085 0 0 0-3.042-3.292A8.123 8.123 0 0 0 10 5.823a8.123 8.123 0 0 0-4.312 1.208 8.085 8.085 0 0 0-3.042 3.292 8.084 8.084 0 0 0 3.042 3.293A8.123 8.123 0 0 0 10 14.823Z"
      fill="#191B20"
    />
  </svg>
);
export default SvgShow;
