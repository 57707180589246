import { SVGProps } from "react";

const SvgFinalSquiggleTop = (props: SVGProps<SVGSVGElement>) => (
  <svg width={211} height={109} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M209.14 90.177c-4.136 2.9-7.198 6.95-11.313 9.933-4.852 3.518-12.481 4.92-18.083 5.768-14.083 2.132-29.744-.186-42.479-5.085-9.113-3.505-19.328-11.617-22.187-21.435-2.969-10.194 1.658-32.433 12.617-37.837 15.61-7.698 37.88 3.89 30.222 23.094-4.907 12.307-11.884 21.934-23.24 28.631-12.237 7.217-25.812 5.536-38.255 2.669-18.345-4.228-37.505-9.609-52.8-20.336-12.355-8.665-18.878-28.202-12.323-43.347C37.165 18.68 46.974 3.822 62.048 1.921c7.287-.92 14.675.72 15.035 9.247.456 10.783-11.126 24.143-18.32 31.582-6.837 7.07-16.577 11.47-25.162 15.594-14.25 6.848-31.395-2.279-42.775-9.06C-18.98 43.44-28.262 36.486-37 29.185"
      stroke="#8FC9FA"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgFinalSquiggleTop;
