import { PersistedCampaign, PersistedPrompt, UpdatePromptPayload } from "schemas";
import { setCampaign } from "./setCampaign";
import { setPrompt } from "../prompts/setPrompt";
import { apiRequest } from "../apiRequest";
import { getCampaignElements } from "./getCampaignElements";

export async function regenerateCampaign(
  campaignId: string,
  promptId: string,
  payload: UpdatePromptPayload,
): Promise<{ campaign: PersistedCampaign; prompt: PersistedPrompt }> {
  const { campaign, prompt } = await apiRequest<{ campaign: PersistedCampaign; prompt: PersistedPrompt }>({
    url: `campaigns/${campaignId}/regenerate/${promptId}`,
    method: "PUT",
    body: JSON.stringify({ prompt: payload }),
  });

  setCampaign(campaign);
  getCampaignElements(campaign.id);
  setPrompt(prompt);

  return { campaign, prompt };
}
