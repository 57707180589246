import { PagePayload, PersistedPage } from "schemas";
import { setPage } from "./setPage";
import { getPage } from "./getPage";
import { apiRequest } from "../apiRequest";

export async function updatePage(id: string, payload: PagePayload) {
  // @ts-expect-error state
  setPage({ ...payload, _id: id });
  const page = await apiRequest<PersistedPage>({ url: `pages/${id}`, method: "PATCH", body: JSON.stringify(payload) });

  setPage(page);
  getPage();

  return page;
}
