import { PropsWithChildren } from "react";
import { Icon, IconType, RawIcon, TextAccent, TextMain } from "ui";
import { SuperCard } from "../ui";

interface Props extends PropsWithChildren {
  iconType: IconType;
  to: string;
  title: string;
  contentIconType?: IconType;
}
export function AddQuestionCard({ iconType, to, title, contentIconType, children }: Props) {
  return (
    <SuperCard
      left={<RawIcon iconType={iconType} />}
      title={<TextAccent>{title}</TextAccent>}
      to={to}
      right={<Icon iconType="ChevronRight" />}
    >
      <TextMain>Your question here</TextMain>
      {contentIconType && <RawIcon iconType={contentIconType} />}
      {children}
    </SuperCard>
  );
}
