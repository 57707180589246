import { SVGProps } from "react";

const SvgTime = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Time_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Time_svg__a)">
      <path
        d="m12.792 13.542 1.062-1.063-3.104-3.104V5h-1.5v5l3.542 3.542ZM10 18a7.794 7.794 0 0 1-3.104-.625 8.067 8.067 0 0 1-2.552-1.719 8.065 8.065 0 0 1-1.719-2.552A7.795 7.795 0 0 1 2 10c0-1.111.208-2.153.625-3.125.417-.972.99-1.82 1.719-2.542a8.159 8.159 0 0 1 2.552-1.708A7.795 7.795 0 0 1 10 2c1.111 0 2.153.208 3.125.625.972.417 1.82.986 2.542 1.708a8.039 8.039 0 0 1 1.708 2.542C17.792 7.847 18 8.889 18 10a7.794 7.794 0 0 1-.625 3.104 8.159 8.159 0 0 1-1.708 2.552 7.95 7.95 0 0 1-2.542 1.719A7.846 7.846 0 0 1 10 18Zm0-1.5c1.805 0 3.34-.635 4.604-1.906C15.868 13.324 16.5 11.792 16.5 10c0-1.805-.632-3.34-1.896-4.604C13.34 4.132 11.805 3.5 10 3.5c-1.792 0-3.323.632-4.594 1.896C4.136 6.66 3.5 8.195 3.5 10c0 1.792.635 3.323 1.906 4.594C6.676 15.864 8.208 16.5 10 16.5Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgTime;
