import type { SVGProps } from "react";
const SvgSocialGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M17.98 10.854c0-.548-.05-1.07-.133-1.576H10v3.132h4.493a3.87 3.87 0 0 1-1.667 2.486v2.083h2.681c1.57-1.451 2.472-3.59 2.472-6.125M10 5.632c1.23 0 2.326.423 3.194 1.25l2.375-2.375C14.133 3.16 12.25 2.333 10 2.333A8.32 8.32 0 0 0 2.563 6.93l2.763 2.146c.66-1.979 2.5-3.444 4.674-3.444"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 19a8.32 8.32 0 0 1-7.437-4.597l2.763-2.146c.66 1.979 2.5 3.444 4.674 3.444 1.125 0 2.076-.305 2.826-.805l2.681 2.083C14.132 18.25 12.25 19 10 19M5.326 9.076V6.93H2.563z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M2.563 12.257h2.763a4.8 4.8 0 0 1-.263-1.59c0-.556.097-1.09.263-1.59L2.563 6.93a8.24 8.24 0 0 0 0 7.473z"
    />
    <path fill="#fff" d="M5.326 12.257H2.563v2.146z" />
  </svg>
);
export default SvgSocialGoogle;
