import { ContactPayload, EmptyContact } from "schemas";
import { useNavigate } from "react-router-dom";
import { paddingStyles } from "ui";
import { Form } from "./Form";
import { addContact, useContactGroup, useOverContactsLimit } from "../../store";
import { Overlay } from "../ui";

export function New() {
  const navigate = useNavigate();
  const overLimit = useOverContactsLimit(1);
  const contactGroup = useContactGroup();

  const emptyContact =
    contactGroup && !contactGroup.autogeneratedType
      ? { ...EmptyContact, contactGroups: [+contactGroup.id] }
      : EmptyContact;

  const onSubmit = async (values: ContactPayload) => {
    const { id } = await addContact(values);
    navigate(`../${id}`, { relative: "path" });
  };

  return (
    <Overlay
      className={paddingStyles.p125}
      header="Add a contact"
      backTo={{ to: "../..", label: contactGroup ? `${contactGroup.name} List` : undefined }}
    >
      <Form overLimit={overLimit} values={emptyContact} onSubmit={onSubmit} />
    </Overlay>
  );
}
