import classNames from "classnames";
import {
  bgStyles,
  borderStyles,
  displayStyles,
  flexStyles,
  outlineStyles,
  textStyles,
  widthStyles,
} from "../../styles";

interface Props {
  value: string;
  placeholder?: string;
  onChange: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}
export function NakedInput({ value, onChange, placeholder, onFocus, onBlur }: Props) {
  return (
    <input
      placeholder={placeholder}
      className={classNames(
        flexStyles.grow,
        displayStyles.block,
        textStyles.fontPoppins,
        textStyles.size087,
        textStyles.neutral600,
        widthStyles.min750,
        bgStyles.none,
        borderStyles.none,
        outlineStyles.none,
      )}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
