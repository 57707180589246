import { paddingStyles } from "ui";
import { FormQuestionPayload } from "schemas";
import { useFormContext, useWatch } from "react-hook-form";
import { QuestionForm } from "./QuestionForm";
import { ReadableFieldTypes } from "./fieldTypes";
import { Overlay } from "../ui";
import { useNavigate, useParams } from "react-router-dom";

export function EditQuestion() {
  const navigate = useNavigate();
  const questions = useWatch({ name: "questions" });
  const { index } = useParams() as { index: string };
  const { setValue } = useFormContext();

  const question = questions[Number(index)] as FormQuestionPayload;

  const goBack = () => navigate("../..", { relative: "path" });

  const onSubmit = async (values: FormQuestionPayload) => {
    const newQuestions = [...questions];
    newQuestions[+index] = values;
    setValue("questions", newQuestions, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    goBack();
  };

  return (
    <Overlay
      className={paddingStyles.p125}
      header={`Edit ${ReadableFieldTypes[question.questionType]}`}
      backTo={{ to: "../..", label: "Form" }}
    >
      <QuestionForm type="Update" question={question} onSubmit={onSubmit} cancelTo="../.." />
    </Overlay>
  );
}
