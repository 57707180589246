import { SVGProps } from "react";

const SvgTimezone = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Timezone_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Timezone_svg__a)">
      <path
        d="M10 18a7.794 7.794 0 0 1-3.104-.625 8.067 8.067 0 0 1-2.552-1.719 8.065 8.065 0 0 1-1.719-2.552A7.795 7.795 0 0 1 2 10c0-1.111.208-2.15.625-3.115a8.066 8.066 0 0 1 4.271-4.26A7.795 7.795 0 0 1 10 2c1.111 0 2.15.208 3.115.625a8.1 8.1 0 0 1 4.26 4.26C17.792 7.85 18 8.889 18 10a7.794 7.794 0 0 1-.625 3.104 8.066 8.066 0 0 1-4.26 4.271A7.775 7.775 0 0 1 10 18Zm-1-1.583V15a.962.962 0 0 1-.708-.292A.962.962 0 0 1 8 14v-1L3.646 8.646A6.394 6.394 0 0 0 3.5 10c0 1.597.517 3.007 1.552 4.229C6.087 15.451 7.402 16.18 9 16.417Zm6-2.271c.25-.292.469-.598.656-.917.188-.32.344-.656.469-1.01A6.816 6.816 0 0 0 16.5 10c0-1.32-.361-2.52-1.083-3.604A6.418 6.418 0 0 0 12.5 4v.5c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 11 6H9v1a.962.962 0 0 1-.292.708A.962.962 0 0 1 8 8H7v2h5c.278 0 .514.097.708.292.195.194.292.43.292.708v2h.854c.32 0 .59.111.813.333.222.223.333.494.333.813Z"
        fill="#DF349B"
      />
    </g>
  </svg>
);
export default SvgTimezone;
