import type { SVGProps } from "react";
const SvgSocialDiscord = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M15.745 3.62A14.5 14.5 0 0 0 12.11 2.5c-.16.276-.338.649-.462.942a13.9 13.9 0 0 0-4.028 0 12 12 0 0 0-.462-.942c-1.28.213-2.498.596-3.637 1.12C1.22 7.016.597 10.331.908 13.602a14.8 14.8 0 0 0 4.454 2.231c.356-.48.676-.995.951-1.537a9.3 9.3 0 0 1-1.502-.72 6 6 0 0 0 .365-.285c2.897 1.325 6.036 1.325 8.898 0 .125.098.24.196.365.284a9.3 9.3 0 0 1-1.503.72c.276.543.596 1.058.951 1.538a14.7 14.7 0 0 0 4.455-2.23c.382-3.787-.606-7.076-2.597-9.983m-9.032 7.964c-.87 0-1.582-.79-1.582-1.76 0-.968.693-1.76 1.582-1.76.88 0 1.6.792 1.582 1.76 0 .97-.702 1.76-1.582 1.76m5.841 0c-.871 0-1.583-.79-1.583-1.76 0-.968.694-1.76 1.583-1.76.88 0 1.6.792 1.582 1.76 0 .97-.693 1.76-1.582 1.76"
    />
  </svg>
);
export default SvgSocialDiscord;
