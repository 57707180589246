import classNames from "classnames";
import { PropsWithClassNameAndChildren } from "../types";
import { textStyles } from "../styles";

export function SmallTextMain({ children, className }: PropsWithClassNameAndChildren) {
  return <div className={classNames(textStyles.size087, textStyles.lsColor, className)}>{children}</div>;
}

export function SmallTextAccent({ children, className }: PropsWithClassNameAndChildren) {
  return <SmallTextMain className={classNames(textStyles.weight600, className)}>{children}</SmallTextMain>;
}
