import { SVGProps } from "react";

const SvgFinalSquiggleRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={472} height={167} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M26.82 2C13.196 18.57 2 41.41 2 63.987c0 21.162 4.302 48.279 21.373 61.125 24.837 18.69 62.602 19.647 91.303 14.231 14.018-2.646 27.664-21.04 34.375-33.528 9.624-17.907 21.494-32.034 25.312-52.931 3.413-18.677-2.418-37.3-20.683-37.3-26.213 0-32.897 21.817-32.897 46.57 0 28.436 14.418 60.557 35.458 77.189 30.03 23.739 76.652 20.69 111.691 17.464 34.266-3.155 68.058-20.899 88.25-52.393 13.931-21.729 20.446-48.107 5.713-70.504-21.262-32.323-59.751-26.778-61.952 16.602-1.277 25.159 4.599 52.596 15.857 74.6 4.984 9.741 7.297 16.552 16.35 22.424 19.508 12.653 49.005 9.702 70.915 9.702 15.488 0 33.383.09 47.474-8.193 24.469-14.383 43.949-42.762 52.201-71.15 3.943-13.562 2.045-59.115-19.304-39.996-5.861 5.248-13.669 9.623-16.941 17.68-7.194 17.715-.594 48.38 5.713 65.221C481.249 144.947 499.311 165 525 165"
      stroke="#8FC9FA"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgFinalSquiggleRight;
