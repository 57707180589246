import classNames from "classnames";
import { Icon, IconType } from "./Icon";
import { OnClickHandler, PropsWithClassName, PropsWithLabel, PropsWithOptionalIconType } from "./types";
import { Description } from "./typography";
import { IconButton } from "./IconButton";
import styles from "./Tag.module.css";
import { borderStyles, flexStyles, heightStyles, paddingStyles } from "./styles";

type TagType = "Square" | "Round";
interface Props extends PropsWithClassName, PropsWithOptionalIconType, PropsWithLabel {
  type?: TagType;
  button?: {
    onClick: OnClickHandler;
    iconType: IconType;
  };
}
export function Tag({ className, iconType, label, type = "Round", button }: Props) {
  return (
    <div
      className={classNames(
        styles.wrapper,
        flexStyles.horiz025,
        flexStyles.alignCenter,
        heightStyles.height175,
        paddingStyles.px050,
        { [borderStyles.radius087]: type == "Round", [borderStyles.radius025]: type === "Square" },
        className,
      )}
    >
      {iconType && <Icon iconType={iconType} />}
      <Description>{label}</Description>
      {button && <IconButton iconType={button.iconType} onClick={button.onClick} />}
    </div>
  );
}
