"use client";

import { Player } from "@lottiefiles/react-lottie-player";
import { Suspense, useEffect, useRef } from "react";
import { AnimationProps } from "./Animation";

interface Props extends AnimationProps {
  src: string;
}
export default function AnimationPlayer({
  className,
  src,
  loop,
  noAutoplay,
  keepLastFrame,
  togglePlay,
  oneWay,
}: Props) {
  const player = useRef<Player>(null);

  useEffect(() => {
    if (noAutoplay && player.current) {
      if (togglePlay) {
        player.current.setPlayerDirection(1);
        player.current.play();
      } else {
        if (!oneWay) {
          player.current.setPlayerDirection(-1);
          player.current.play();
        }
      }
    }
  }, [noAutoplay, oneWay, togglePlay]);

  return (
    <Suspense>
      <Player
        ref={player}
        autoplay={!noAutoplay || togglePlay}
        loop={loop}
        className={className}
        src={src}
        keepLastFrame={keepLastFrame}
      />
    </Suspense>
  );
}
