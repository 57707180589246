import { BlockKind } from "schemas";
import { useDestinationIndex } from "./useDestinationIndex";
import { useBlockKindParam } from "./useBlockKindParam";
import { useLocation } from "react-router-dom";

type Hook =
  | {
      destinationIndex: number;
      kind: BlockKind;
    }
  | undefined;
export function useNewBlock(): Hook {
  const destinationIndex = useDestinationIndex();
  const kind = useBlockKindParam();
  const { pathname } = useLocation();
  const newInlineFragment = pathname.indexOf("newInline") > -1;

  if (newInlineFragment && destinationIndex !== undefined && destinationIndex !== null && kind) {
    return { destinationIndex: +destinationIndex, kind };
  }
  return undefined;
}
