import { Asserts, object, string } from "yup";
import { CaptchaString } from "./shared";

export const PasswordString = string()
  .min(8, "Password has to be at least 8 characters long")
  .required("Password is required");

export const JustPasswordSchema = object({
  password: PasswordString,
});

export const PasswordSchema = object({
  password: PasswordString,
  passwordConfirmation: string().test("passwords-match", "Passwords must match", function matchPasswords(value) {
    return this.parent.password === value;
  }),
});

export const ChangePasswordSchema = PasswordSchema.shape({
  captchaResponse: CaptchaString,
});
export type ChangePasswordPayload = Asserts<typeof ChangePasswordSchema>;
