import type { SVGProps } from "react";
const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} fill="none" {...props}>
    <g fill="#F0A056">
      <path d="m10.49 3.5 6.639 6.705a.415.415 0 0 1 .09.454.4.4 0 0 1-.09.135L10.49 17.5 9.39 16.388l5.829-5.889-5.83-5.887z" />
      <path d="M2.25 11.413H16V9.587H2.25z" />
    </g>
  </svg>
);
export default ArrowRight;
