import { Loader, Offsetino, Progress, flexStyles, paddingStyles } from "ui";
import { useCampaign, useTheme } from "../../store";
import { Button, Overlay } from "../ui";
import classNames from "classnames";
import { Home } from "../design";

export function Design() {
  const { campaign } = useCampaign();
  const theme = useTheme(campaign?.themeId);

  if (!campaign || !theme) {
    return <Loader />;
  }

  return (
    <Overlay
      className={paddingStyles.p125}
      backTo={{ to: "../..", label: "Campaigns List" }}
      title="Create a campaign"
      header="Design"
      subHeaderElement={<Progress current={1} total={5} />}
    >
      <div className={classNames(flexStyles.vert050, flexStyles.grow)}>
        <Home theme={theme} />
        <Offsetino />
        <Button to="../content">Continue</Button>
      </div>
    </Overlay>
  );
}
