import { SVGProps } from "react";
const SvgPaymentsStarLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.288 1.71c.351 2.05.008 4.343.072 6.429.038 1.21.166 2.38.305 3.58.174 1.507.898 5.998.694 4.496-.235-1.719-.493-3.639-.405-5.372.072-1.417-.17-2.852-.062-4.24.1-1.288-.417-2.494-.35-3.748.023-.415.074-.8-.034-1.203-.116-.434.197.878.36 1.296.912 2.322 1.568 4.751 2.786 6.926a71.636 71.636 0 0 1 1.663 3.127c.338.678.611 1.853 1.172 2.368.412.38 2.876-1.003 3.51-1.173 1.964-.526 3.923-1.635 5.793-2.429.673-.285 1.906-.564 2.483-1 .023-.018-.86 1.126-1.27 1.423-2 1.45-4.21 2.66-6.168 4.206-1.11.878-2.537 2.44-2.26 3.933.226 1.21.204 2.439.515 3.68.494 1.975 1.053 3.93 1.583 5.907l1.16 4.331c.155.576-.544-.531-.665-.673a25.083 25.083 0 0 1-3.06-4.428c-.779-1.451-1.309-3.126-2.36-4.41-.687-.84-1.63-1.996-1.986-3.014-.743-2.121-3.689.24-5.136.628-1.213.325-2.34.86-3.572 1.19-.505.134-2.647.805-2.338.51 1.692-1.617 3.696-3.037 5.485-4.604.653-.572 1.54-.949 2.046-1.605.296-.386 1.09-.956 1.171-1.346"
      stroke="#FFCA28"
      strokeWidth={2.612}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgPaymentsStarLeft;
