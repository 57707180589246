import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_CRITICAL, KEY_ENTER_COMMAND } from "lexical";
import { useEffect } from "react";

interface Props {
  onSubmit: () => Promise<void>;
  onlyMeta?: boolean;
}
export function SubmitPlugin({ onSubmit, onlyMeta = false }: Props) {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          KEY_ENTER_COMMAND,
          (event: KeyboardEvent) => {
            if (onlyMeta && !event.metaKey) {
              return false;
            }
            onSubmit();
            editor.setEditable(false);
            return true;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
      ),
    [editor, onSubmit, onlyMeta],
  );

  return null;
}
