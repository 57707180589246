const Day = 1;
export const Month = Day * 30;
export const DaysAheadAllowedOptions = [
  { label: "1 week", value: 7 * Day },
  { label: "2 weeks", value: 14 * Day },
  { label: "3 weeks", value: 21 * Day },
  { label: "1 month", value: Month },
  { label: "2 months", value: 2 * Month },
  { label: "3 months", value: 3 * Month },
  { label: "4 months", value: 4 * Month },
  { label: "5 months", value: 5 * Month },
  { label: "6 months", value: 6 * Month },
  { label: "7 months", value: 7 * Month },
  { label: "8 months", value: 8 * Month },
  { label: "9 months", value: 9 * Month },
  { label: "10 months", value: 10 * Month },
  { label: "11 months", value: 11 * Month },
  { label: "1 year", value: 12 * Month },
  { label: "2 years", value: 24 * Month },
];
export const DaysAheadAllowedValues = DaysAheadAllowedOptions.map((option) => option.value);
