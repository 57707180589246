import { useState } from "react";
import { BusinessPayload, BusinessSchema } from "schemas";
import { FormProvider, FormTag, Markdown, TextMain, VisualTextArea, opacityStyles, textStyles } from "ui";
import { Menu } from "./Menu";
import { updateBusiness, useCurrentBusiness } from "../../../store";
import { EditWrapper } from "../../blocks";

export function EditBusinessDescription() {
  const business = useCurrentBusiness();
  const [open, setOpen] = useState(false);

  const onSubmit = async (payload: BusinessPayload) => {
    await updateBusiness(payload);
    setOpen(false);
  };

  const editor = open && (
    <FormProvider<BusinessPayload> schema={BusinessSchema} defaultValues={business}>
      <FormTag<BusinessPayload> onSubmit={onSubmit}>
        <VisualTextArea<BusinessPayload>
          autoFocus
          onSubmit={onSubmit}
          type="inline"
          name="description"
          placeholder="Add business description"
        />
        <Menu<BusinessPayload> onSubmit={onSubmit} onCancel={() => setOpen(false)} />
      </FormTag>
    </FormProvider>
  );
  return (
    <EditWrapper className={textStyles.center} open={open} onClick={async () => setOpen(true)} editElement={editor}>
      {business.description ? (
        <Markdown>{business.description}</Markdown>
      ) : (
        <TextMain className={opacityStyles.opacity50}>Add business description</TextMain>
      )}
    </EditWrapper>
  );
}
