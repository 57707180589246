import { MouseEvent as ReactMouseEvent, RefObject, useEffect, useState } from "react";

type Options = {
  maxWidth: number | null;
};
type Direction = "positive" | "negative";
export function useResize(ref: RefObject<HTMLElement>, options: Options) {
  const { maxWidth } = options;
  const [handleX, setHandleX] = useState(Infinity);
  const [startingWidth, setStartingWidth] = useState(Infinity);
  const [width, setWidth] = useState(Infinity);
  const [direction, setDirection] = useState<Direction>("positive");
  const [dragging, setDragging] = useState(false);

  const initResize = (event: ReactMouseEvent, direction: Direction) => {
    if (!ref.current) {
      return;
    }

    event.stopPropagation();
    setDragging(true);
    setDirection(direction);
    setHandleX(event.clientX);
    const { width } = window.getComputedStyle(ref.current);
    setStartingWidth(parseInt(width, 10));
    setWidth(parseInt(width, 10));
  };

  useEffect(() => {
    const doDrag = (event: MouseEvent) => {
      if (!ref.current || !dragging) {
        return;
      }

      // Calculate the box size
      const newWidth =
        direction === "positive" ? startingWidth + event.clientX - handleX : startingWidth + handleX - event.clientX;

      setWidth(maxWidth ? Math.min(newWidth, maxWidth) : newWidth);
    };

    const stopDrag = () => {
      setDragging(false);
      document.removeEventListener("mousemove", doDrag, false);
      document.removeEventListener("mouseup", stopDrag, false);
    };

    document.addEventListener("mousemove", doDrag, false);
    document.addEventListener("mouseup", stopDrag, false);
  }, [startingWidth, handleX, maxWidth, direction, ref, dragging]);

  return { initResize, width };
}
