import { useEffect, useState } from "react";
import { HookLoading } from "../types";
import { apiRequest } from "../apiRequest";

interface Args {
  id: string;
}

export function useGenerateSubjects({ id }: Args): HookLoading<{ subjects: string[] }> {
  const [loading, setLoading] = useState<boolean>(true);
  const [subjects, setSubjects] = useState<string[]>([]);

  useEffect(() => {
    if (loading && id) {
      apiRequest<{ subjects: [] }>({ url: `prompts/${id}/campaignSubjects`, method: "PUT" }).then(({ subjects }) => {
        setSubjects(subjects);
        setLoading(false);
      });
    }
  }, [id, loading]);

  return { loading, subjects };
}
