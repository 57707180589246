import { ReactNode, useCallback, useEffect, useState } from "react";
import { Icon, IconType } from "../../Icon";
import { StandaloneSelect } from "../Select";
import { flexStyles } from "../../styles";
import { HeaderMain, SubheaderMain, TextAccent, TextMain } from "../../typography";
import { mergeRegister } from "@lexical/utils";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getSelection, $isParagraphNode, $isRangeSelection } from "lexical";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import { SelectOption } from "../Select/SelectOption";
import { $setBlocksType } from "@lexical/selection";

type Value = "h1" | "h2" | "h3" | "p";
const Examples: Record<Value, ReactNode> = {
  h1: <HeaderMain>Main Title</HeaderMain>,
  h2: <SubheaderMain>Heading</SubheaderMain>,
  h3: <TextAccent>Subheading</TextAccent>,
  p: <TextMain>Text</TextMain>,
};
const Icons: Record<Value, IconType> = {
  h1: "FormatHeadingH1",
  h2: "FormatHeadingH2",
  h3: "FormatHeadingH3",
  p: "TextFields",
};

interface LabelProps {
  type: Value;
}
function Label({ type }: LabelProps) {
  return (
    <div className={flexStyles.horiz025}>
      <Icon iconType={Icons[type]} />
      <div style={{ display: "var(--heading-label-display, block)" }}>{Examples[type]}</div>
    </div>
  );
}

const options: SelectOption<Value>[] = [
  {
    label: <Label type="h1" />,
    value: "h1",
  },
  {
    label: <Label type="h2" />,
    value: "h2",
  },
  {
    label: <Label type="h3" />,
    value: "h3",
  },
  {
    label: <Label type="p" />,
    value: "p",
  },
];
export function ToolbarHeadingSelect() {
  const [value, setValue] = useState<Value>("p");
  const [editor] = useLexicalComposerContext();
  const findHeading = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      if ($isHeadingNode(element)) {
        setValue(element.getTag() as Value);
      } else if ($isParagraphNode(element)) {
        setValue("p");
      }
    }
  }, []);

  const onChange = (selected: SelectOption<Value> | null) => {
    if (selected && selected.value) {
      const value = selected.value;
      editor.update(() => {
        const selection = $getSelection();
        if (value === "p") {
          $setBlocksType(selection, () => $createParagraphNode());
        } else if (["h1", "h2", "h3"].includes(value)) {
          $setBlocksType(selection, () => $createHeadingNode(value));
        }
      });
    }
  };

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            findHeading();
          });
        }),
      ),
    [editor, findHeading],
  );

  return <StandaloneSelect onChange={onChange} raw options={options} value={value} />;
}
