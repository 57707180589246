import { Description, DescriptionAccent, DotMenu, DotMenuLink, Icon, TextAccent, flexStyles } from "ui";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Button, SuperCard } from "../ui";
import { useUrlWithBack } from "../../hooks";
import { updateAppointment, useAppointment, useForm } from "../../store";

export function FormsSection() {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const appointment = useAppointment(id);
  const { reset } = useFormContext();
  const { form } = useForm(appointment?.form);
  const addFormPath = useUrlWithBack(`/appointments/${id}/addForm`);
  const editFromPath = useUrlWithBack(`/forms/${appointment?.form}`);

  const formLinks: DotMenuLink[] = [];
  if (appointment?.form) {
    formLinks.push(
      {
        label: "Edit Form",
        iconType: "Edit",
        onClick: () => navigate(editFromPath),
      },
      {
        label: "Preview Form",
        iconType: "OpenInNew",
        onClick: () => navigate(`preview/${appointment?.form}`),
      },
      {
        label: "Remove Form",
        iconType: "Delete",
        onClick: async () => {
          const updatedAppointment = await updateAppointment(id, { ...appointment, form: null });
          reset(updatedAppointment);
        },
      },
    );
  }
  return (
    <div className={flexStyles.vert100}>
      <TextAccent>Forms</TextAccent>
      {form ? (
        <SuperCard
          title={<DescriptionAccent>{form.name}</DescriptionAccent>}
          right={formLinks && <DotMenu links={formLinks} />}
        >
          <Description>{form.description}</Description>
        </SuperCard>
      ) : (
        <Button buttonType="secondary" to={addFormPath}>
          <Icon iconType="Plus" />
          Add form
        </Button>
      )}
    </div>
  );
}
