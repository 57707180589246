import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Icon } from "./Icon";
import { bgStyles, borderStyles, flexStyles, iconStyles, paddingStyles } from "./styles";

interface Props extends PropsWithChildren {}
export function InfoCard({ children }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.horiz050,
        flexStyles.alignStart,
        borderStyles.radius025,
        bgStyles.infoLight,
        borderStyles.infoMedium,
        iconStyles.infoDark,
        paddingStyles.p075,
      )}
    >
      <Icon iconType="Info" />
      <div>{children}</div>
    </div>
  );
}
