import { ThemePayload, businessThemeObject } from "schemas";
import { PropsWithClassNameAndChildren } from "../types";
import { CSSProperties } from "react";

interface Props extends PropsWithClassNameAndChildren {
  theme: ThemePayload;
}
export function RawThemedWrapper({ theme, children, className }: Props) {
  return (
    <div className={className} style={businessThemeObject(theme) as CSSProperties}>
      {children}
    </div>
  );
}
