import type { SVGProps } from "react";
const SvgSyncCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <mask
      id="SyncCalendar_svg__a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g fill="#F0A056" mask="url(#SyncCalendar_svg__a)">
      <path d="M4.5 18a1.44 1.44 0 0 1-1.052-.448A1.44 1.44 0 0 1 3 16.5v-11c0-.412.15-.766.448-1.06.299-.293.65-.44 1.052-.44H6V2h1.5v2h5V2H14v2h1.5c.403 0 .753.147 1.052.44.299.294.448.647.448 1.06v5h-1.5V9h-11v7.5h6V18h-6Zm0-10.5h11v-2h-11v2Z" />
      <path
        stroke="#F0A056"
        strokeWidth={0.4}
        d="M13.044 18.7c.757.667 1.642 1 2.656 1 1.107 0 2.05-.389 2.83-1.166.78-.778 1.17-1.721 1.17-2.83 0-1.107-.39-2.052-1.17-2.832-.78-.781-1.723-1.172-2.83-1.172-.585 0-1.133.118-1.647.354a4.14 4.14 0 0 0-1.353 1V11.7h-1v3h3v-1h-1.25c.306-.305.65-.548 1.031-.729.382-.18.789-.27 1.219-.27.829 0 1.536.29 2.121.874.586.584.879 1.29.879 2.12 0 .83-.293 1.538-.879 2.125a2.888 2.888 0 0 1-2.121.88c-.722 0-1.35-.219-1.885-.656-.535-.438-.893-1.052-1.073-1.844H11.72c.125 1 .566 1.834 1.323 2.5Z"
      />
    </g>
  </svg>
);
export default SvgSyncCalendar;
