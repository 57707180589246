import type { SVGProps } from "react";
const SvgSocialTelegram = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14.256 16.186c.215.152.492.19.738.097a.77.77 0 0 0 .483-.56c.58-2.723 1.985-9.614 2.512-12.091a.52.52 0 0 0-.173-.505.53.53 0 0 0-.532-.094C14.49 4.068 5.88 7.298 2.362 8.6A.55.55 0 0 0 2 9.133c.008.236.167.44.396.509 1.578.472 3.649 1.129 3.649 1.129s.968 2.923 1.473 4.41c.063.186.209.333.402.384a.58.58 0 0 0 .54-.138l2.064-1.949s2.382 1.746 3.732 2.708m-7.34-5.785 1.12 3.692.248-2.338 6.79-6.124a.185.185 0 0 0 .022-.251.19.19 0 0 0-.25-.043z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialTelegram;
