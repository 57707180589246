import { PropsWithChildren } from "react";
import { DefaultValues } from "react-hook-form";
import { Schema } from "schemas";
import { FormProvider, FormTag, OnClickHandler, stopPropagation } from "ui";
import { Menu } from "./Menu";

interface Props<T> extends PropsWithChildren {
  schema: Schema;
  defaultValues: DefaultValues<T>;
  onSubmit: (payload: T) => Promise<void>;
  onDelete?: OnClickHandler;
  onCancel: OnClickHandler;
}

export function Form<T extends object>({ children, schema, defaultValues, onSubmit, onCancel, onDelete }: Props<T>) {
  return (
    <FormProvider<T> schema={schema} defaultValues={defaultValues}>
      <FormTag<T> onSubmit={onSubmit} onClick={stopPropagation}>
        {children}
        <Menu<T> onDelete={onDelete} onSubmit={onSubmit} onCancel={onCancel} />
      </FormTag>
    </FormProvider>
  );
}
