"use client";

import { useFormContext, useWatch } from "react-hook-form";
import classNames from "classnames";
import { Slide } from "../Slide";
import { displayStyles, flexStyles } from "../../styles";

interface Props {
  name: string;
}

export function Checkbox({ name }: Props) {
  const { register } = useFormContext();
  const value = useWatch({ name });

  return (
    <div className={classNames(flexStyles.horiz050, flexStyles.alignCenter)}>
      <input {...register(name)} className={displayStyles.none} id={name} type="checkbox" />
      <Slide name={name} on={value} />
    </div>
  );
}
