import { Callback, Setup } from "../components/zoom";
import { PrivateOutlet } from "./PrivateOutlet";

export const ZoomRoutes = {
  path: "zoom",
  Component: PrivateOutlet,
  children: [
    { path: "setup", Component: Setup },
    { path: "callback", Component: Callback },
  ],
};
