"use client";

import { PropsWithChildren, useState } from "react";
import classNames from "classnames";
import { ButtonTextSmall } from "../typography/ButtonTextSmall";
import { OnClickHandler } from "../types";
import { Icon, IconType } from "../Icon";
import { Loader } from "../Loader";
import { borderStyles, loaderStyles } from "../styles";

interface Props extends PropsWithChildren {
  hasDivider: boolean;
  onClick: OnClickHandler;
  iconType?: IconType;
  loading?: boolean;
}
export function Item({ hasDivider, children, onClick, iconType, loading = false }: Props) {
  const [spinning, setSpinning] = useState<boolean>(false);

  const wrapClick: OnClickHandler = async () => {
    if (!spinning) {
      setSpinning(true);
      await onClick();
      setSpinning(false);
    }
  };
  return (
    <ButtonTextSmall className={classNames({ [borderStyles.leftNeutral500]: hasDivider })} onClick={wrapClick}>
      {spinning || loading ? (
        <Loader fitted className={classNames(loaderStyles.size150)} />
      ) : (
        <>
          {iconType && <Icon iconType={iconType} />}
          {children}
        </>
      )}
    </ButtonTextSmall>
  );
}
