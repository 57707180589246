import { useNavigate } from "react-router-dom";
import { PersistedContactImport } from "schemas";
import { BottomWrapper, Button } from "../ui";
import { runContactImport, useOverContactsLimit } from "../../store";
import { ExceededLimit } from "../contacts";
import { Offsetino, OnClickHandler } from "ui";

interface Props {
  contactImport: PersistedContactImport;
  goBack: OnClickHandler;
}
export function RunImport({ contactImport, goBack }: Props) {
  const navigate = useNavigate();

  const goToSuccess = () => navigate(`/contactImports/${contactImport.id}/success`, { replace: false });
  const overLimit = useOverContactsLimit(contactImport.candidateCount);

  if (contactImport.status === "completed") {
    goToSuccess();
  }

  const runImport = async () => {
    await runContactImport(contactImport.id);
    goToSuccess();
  };

  return (
    <>
      <Offsetino />
      <BottomWrapper>
        {overLimit && <ExceededLimit />}
        <Button disabled={overLimit} onClick={runImport}>
          Import Contacts
        </Button>
        <Button buttonType="tertiary" onClick={goBack}>
          Cancel
        </Button>
      </BottomWrapper>
    </>
  );
}
