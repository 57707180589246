import { SVGProps } from "react";

const SvgCustomersUpperRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={38} height={39} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 37.182h32.217c3.373 0 1.432-26 1.432-30.427 0-1.545-.716-2.982-.716-4.653"
      stroke="#F5A343"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgCustomersUpperRight;
