import classNames from "classnames";
import { bgStyles } from "../../styles";
import formStyles from "../forms.module.css";
interface Props {
  onChange: (val: string) => void;
  placeholder?: string;
}
export function RawTextInput({ onChange, placeholder }: Props) {
  return (
    <input
      dir="auto"
      autoComplete="off"
      className={classNames(bgStyles.neutral0, formStyles.input)}
      type="text"
      placeholder={placeholder}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  );
}
