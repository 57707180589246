import {
  Icon,
  PropsWithIconType,
  PropsWithTo,
  SmallTextMain,
  bgStyles,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  squareStyles,
  textStyles,
  transformStyles,
} from "ui";
import { RawLink } from "../ui";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { BlockConsumer, ExtendedBlockKind, supportsBlock } from "./AvailableBlocks";

interface Props extends PropsWithTo, PropsWithIconType, PropsWithChildren {
  rotate?: boolean;
  unthemed?: boolean;
  consumer: BlockConsumer;
  kind?: ExtendedBlockKind;
}
export function AddCard({ to, iconType, children, rotate, unthemed, consumer, kind }: Props) {
  if (kind && !supportsBlock(consumer, kind)) {
    return null;
  }

  return (
    <RawLink
      to={to}
      preventScrollReset
      className={classNames(
        flexStyles.vert025,
        flexStyles.alignCenter,
        borderStyles.radius050,
        iconStyles.editorInterfaceText,
        textStyles.decorationNone,
        paddingStyles.py625,
        {
          [bgStyles.neutral0]: unthemed,
          [bgStyles.editorInterface]: !unthemed,
          [textStyles.neutral600]: unthemed,
          [textStyles.editorInterfaceText]: !unthemed,
          [iconStyles.neutral600]: unthemed,
          [iconStyles.editorInterfaceText]: !unthemed,
          [borderStyles.neutral200]: unthemed,
        },
      )}
    >
      <Icon
        className={classNames(squareStyles.square125, { [transformStyles.rotate90]: rotate })}
        iconType={iconType}
      />
      <SmallTextMain>{children}</SmallTextMain>
    </RawLink>
  );
}
