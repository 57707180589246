import type { SVGProps } from "react";
const SvgSocialDribbble = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.732 8.333 8.333 8.333 4.591 0 8.333-3.732 8.333-8.333 0-4.6-3.742-8.333-8.333-8.333m5.504 3.84a7.1 7.1 0 0 1 1.61 4.43c-.236-.045-2.586-.525-4.954-.226-.054-.118-.1-.244-.153-.37a21 21 0 0 0-.47-1.031c2.62-1.067 3.814-2.603 3.967-2.802M10 2.897c1.808 0 3.462.678 4.718 1.79-.127.18-1.202 1.617-3.733 2.566C9.82 5.11 8.527 3.357 8.328 3.086A7.2 7.2 0 0 1 10 2.896m-3.028.669c.19.253 1.455 2.015 2.64 4.112-3.327.886-6.264.868-6.58.868a7.15 7.15 0 0 1 3.94-4.98m-4.094 6.444v-.217c.307.01 3.76.054 7.312-1.012.208.397.398.804.578 1.21-.09.028-.19.055-.28.082-3.67 1.184-5.622 4.42-5.784 4.691a7.1 7.1 0 0 1-1.826-4.754M10 17.122a7.07 7.07 0 0 1-4.366-1.5c.127-.262 1.573-3.046 5.586-4.447.018-.01.027-.01.045-.018a29.4 29.4 0 0 1 1.519 5.396 7 7 0 0 1-2.784.57m3.968-1.22c-.072-.434-.452-2.513-1.383-5.07 2.232-.353 4.185.225 4.429.307a7.06 7.06 0 0 1-3.046 4.763"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialDribbble;
