import { paddingStyles } from "ui";
import { FormQuestionPayload, FormQuestionType, getEmptyQuestion } from "schemas";
import { useFormContext, useWatch } from "react-hook-form";
import { QuestionForm } from "./QuestionForm";
import { ReadableFieldTypes } from "./fieldTypes";
import { Overlay } from "../ui";
import { useNavigate, useParams } from "react-router-dom";

export function NewQuestion() {
  const navigate = useNavigate();
  const questions = useWatch({ name: "questions" });
  const { setValue } = useFormContext();
  const { questionType } = useParams() as { questionType: FormQuestionType };
  const question = getEmptyQuestion(questionType);

  const onSubmit = async (values: FormQuestionPayload) => {
    setValue("questions", [...questions, values], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    navigate("../..", { relative: "path" });
  };

  return (
    <Overlay
      className={paddingStyles.p125}
      header={`Add a ${ReadableFieldTypes[questionType]}`}
      backTo={{ to: "..", label: "Form" }}
    >
      <QuestionForm type="Add" question={question} onSubmit={onSubmit} cancelTo=".." />
    </Overlay>
  );
}
