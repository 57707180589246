import { Design, Home } from "../components/content";
import { OutletBlockRoutes, StandaloneBlockRoutes } from "./Blocks";
import { PrivateOutlet } from "./PrivateOutlet";
import { OutletThemeRoutes } from "./Theme";

export const ContentRoutes = {
  path: "content",
  Component: PrivateOutlet,
  children: [
    { path: "", Component: Home, children: [OutletBlockRoutes] },
    { path: "", children: [StandaloneBlockRoutes] },
    { path: "design", Component: Design, children: OutletThemeRoutes },
  ],
};
