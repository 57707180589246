import classNames from "classnames";
import { textStyles } from "ui";
import styles from "./Link.module.css";
import { RawLink } from "./RawLink";
import type { Props } from "./RawLink";

export function Link({
  href,
  target,
  disabled = false,
  to,
  children,
  onClick,
  className,
  htmlButtonType = "button",
}: Props) {
  return (
    <RawLink
      className={classNames(textStyles.size100, styles.link, className)}
      to={to}
      href={href}
      htmlButtonType={htmlButtonType}
      onClick={onClick}
      disabled={disabled}
      target={target}
    >
      {children}
    </RawLink>
  );
}
