import { SVGProps } from "react";

const SvgChevronSmallRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m12.157 11.28-3.152 2.975a.9.9 0 0 1-.452.228.932.932 0 0 1-.51-.048.875.875 0 0 1-.396-.306.801.801 0 0 1-.149-.463v-6.33a.8.8 0 0 1 .149-.464.873.873 0 0 1 .396-.307.931.931 0 0 1 .51-.047.9.9 0 0 1 .452.229l3.152 2.975c.219.207.341.487.341.779 0 .292-.122.572-.34.779Z"
      fill="#465357"
    />
  </svg>
);
export default SvgChevronSmallRight;
