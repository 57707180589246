import { PersisitedContactImportCandidate } from "schemas";
import { ContactInitials, Description, TextMain, textStyles } from "ui";
import { SuperCard } from "../ui";

interface Props {
  contact: PersisitedContactImportCandidate;
}
export function CandidateCard({ contact }: Props) {
  return (
    <SuperCard
      key={contact.email}
      left={<ContactInitials contact={contact} />}
      title={
        <div className={textStyles.breakWord}>
          <TextMain>{contact.name}</TextMain>
          <Description>{contact.email}</Description>
        </div>
      }
    />
  );
}
