import { apiRequest } from "../apiRequest";
import { unstoreContact } from "./unstoreContact";

export async function batchDeleteContacts(ids: string[]) {
  try {
    if (ids.length > 0) {
      await apiRequest<{ deletedIds: string[] }>({
        url: "contacts",
        body: JSON.stringify({ ids }),
        method: "DELETE",
      });
      ids.map(unstoreContact);
    }
  } catch {
    // not found
  }
}
