"use client";

import classNames from "classnames";
import { IconButton } from "./IconButton";
import { PropsWithClassName } from "./types";
import { Description } from "./typography";
import { useShareHandler } from "../hooks";
import {
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  overflowStyles,
  paddingStyles,
  pointerStyles,
  positionStyles,
  textStyles,
} from "./styles";
import { CSSProperties, useEffect, useRef } from "react";
import { useWindowSize } from "react-use";

interface Props extends PropsWithClassName {
  host: string;
  relativeUrl: string;
  onCopy?: (text: string) => void;
}
export function ShareLink({ className, host, relativeUrl, onCopy }: Props) {
  const url = `${host}${relativeUrl}`;
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    // all the way right
    ref.current?.scrollTo(1000, 0);
  }, [width]);

  const { onClick } = useShareHandler(url, onCopy);

  return (
    <div
      className={classNames(
        pointerStyles.cursor,
        flexStyles.horiz050,
        flexStyles.alignCenter,
        borderStyles.neutral200,
        borderStyles.radius125,
        bgStyles.neutral100,
        heightStyles.height250,
        paddingStyles.ps075,
        paddingStyles.pe025,
        overflowStyles.hidden,
        className,
      )}
      onClick={onClick}
    >
      <div ref={ref} className={classNames(overflowStyles.hidden, positionStyles.relative)}>
        <div className={classNames(flexStyles.horiz)}>
          <Description className={textStyles.neutral400}>{host.replace(/http[s]?:\/\//, "")}/</Description>
          <Description className={classNames(textStyles.primary, textStyles.nowrap)}>
            {relativeUrl.slice(1)}
          </Description>
        </div>
      </div>
      <IconButton
        className={classNames(iconStyles.primary, bgStyles.neutral200)}
        style={{ "--icon-button-dimensions": "2rem" } as CSSProperties}
        iconType="Share"
        onClick={onClick}
      />
    </div>
  );
}
