import { SelectContactsPayload } from "schemas";
import { apiRequest } from "../apiRequest";
import { getContactGroup } from "../contactGroups/useContactGroup";

interface Args {
  contactGroupId: string;
  payload: SelectContactsPayload;
}
export async function addContactGroupMemberships({ contactGroupId, payload }: Args) {
  await apiRequest({
    url: `contactGroupMemberships/${contactGroupId}`,
    method: "POST",
    body: JSON.stringify(payload),
  });
  await getContactGroup(contactGroupId);
}
