import { PersistedBooking, PersistedFormAnswer } from "schemas";
import { useLiteSiteStore } from "../store";
import { setBooking } from "./setBooking";
import { useEffect } from "react";
import { apiRequest } from "../apiRequest";

async function getBooking(id: string): Promise<void> {
  try {
    const { booking, formAnswer } = await apiRequest<{ booking: PersistedBooking; formAnswer: PersistedFormAnswer }>({
      url: `bookings/${id}`,
    });
    setBooking({ ...booking, formAnswer });
  } catch {
    // no booking
  }
}

export function useBooking(id: string) {
  const booking = useLiteSiteStore(({ bookings }) => bookings.get(id));

  useEffect(() => {
    getBooking(id);
  }, [id]);

  return booking;
}
