import type { SVGProps } from "react";
const SvgSocialMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M11.174 10.873c0 2.875-2.314 5.206-5.17 5.206s-5.17-2.33-5.17-5.206 2.314-5.206 5.17-5.206 5.17 2.33 5.17 5.206m5.673 0c0 2.706-1.158 4.9-2.586 4.9s-2.585-2.194-2.585-4.9 1.158-4.901 2.585-4.901 2.586 2.195 2.586 4.9m2.32 0c0 2.425-.407 4.39-.91 4.39-.502 0-.909-1.966-.909-4.39 0-2.425.407-4.391.91-4.391.502 0 .909 1.966.909 4.39"
    />
  </svg>
);
export default SvgSocialMedium;
