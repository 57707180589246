import classNames from "classnames";
import { borderStyles, displayStyles, marginStyles, squareStyles, widthStyles } from "../../styles";

interface Props {
  isRound: boolean;
  src: string;
}
export function ImagePreview({ isRound, src }: Props) {
  return (
    <div
      className={classNames({
        [squareStyles.square1000]: isRound,
        [borderStyles.radiusRound]: isRound,
        [widthStyles.full]: !isRound,
      })}
    >
      <img
        className={classNames(displayStyles.block, widthStyles.maxWidthFull, marginStyles.centered, {
          [squareStyles.square1000]: isRound,
          [borderStyles.radiusRound]: isRound,
        })}
        src={src}
        alt=""
      />
    </div>
  );
}
