import { SVGProps } from "react";

const SvgDrag = (props: SVGProps<SVGSVGElement>) => (
  <svg width={10} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#Drag_svg__a)" fill="#191B20">
      <path d="M3.75 0H0v3.692h3.75V0ZM3.75 6.154H0v3.692h3.75V6.154ZM3.75 12.308H0V16h3.75v-3.692ZM10 0H6.25v3.692H10V0ZM10 6.154H6.25v3.692H10V6.154ZM10 12.308H6.25V16H10v-3.692Z" />
    </g>
    <defs>
      <clipPath id="Drag_svg__a">
        <path fill="#fff" d="M0 0h10v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDrag;
