import type { SVGProps } from "react";
const SvgFormatItalic = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <mask
      id="FormatItalic_svg__a"
      width={21}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M.5 0h20v20H.5z" />
    </mask>
    <g mask="url(#FormatItalic_svg__a)">
      <path fill="#191B20" d="M5 16v-2h3.333l2.584-8H7.5V4H16v2h-3.083l-2.584 8H13.5v2H5Z" />
    </g>
  </svg>
);
export default SvgFormatItalic;
