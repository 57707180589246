import { ErrorPage } from "../components/ui/ErrorPage";

export function NotFound() {
  return (
    <ErrorPage>
      Oops, that&apos;s a 404! We looked everywhere, but it appears that this path does not exist on PopSend&apos;s
      books yet.
    </ErrorPage>
  );
}
