import { ContactGroupPayload, PersistedContactGroup } from "schemas";
import { setContactGroup } from "./setContactGroup";
import { apiRequest } from "../apiRequest";

export async function updateContactGroup(id: string, payload: ContactGroupPayload) {
  const updatedContactGroup = await apiRequest<PersistedContactGroup>({
    url: `contactGroups/${id}`,
    method: "PATCH",
    body: JSON.stringify(payload),
  });

  setContactGroup(updatedContactGroup);
}
