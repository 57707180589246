import { PhoneBlockPayload } from "schemas";
import { InlineTextInput } from "ui";
import { BlockPreview } from "./BlockPreview";

interface Props {
  onSubmit: (payload: PhoneBlockPayload) => Promise<void>;
}
export function PhoneBlockEditor({ onSubmit }: Props) {
  return (
    <>
      <BlockPreview />
      <InlineTextInput
        autofocus
        size="Text"
        label="Button label"
        name="label"
        placeholder="Button Label"
        onSubmit={onSubmit}
      />
      <InlineTextInput size="Text" label="Phone number" name="number" placeholder="+48 XXX" onSubmit={onSubmit} />
    </>
  );
}
