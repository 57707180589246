import { useNavigate } from "react-router-dom";
import { AppointmentPayload, AppointmentSchema, EmptyAppointment } from "schemas";
import { Divider, FormProvider, FormTag, Loader, Progress, TextMain, paddingStyles } from "ui";
import { useEffect } from "react";
import { Overlay, Submit } from "../ui";
import { BasicDetails } from "./BasicDetails";
import { useDestinationIndex, useUrlWithBack } from "../../hooks";
import { FormStep, FormSteps, stepPath } from "./steps";
import { addAppointment, checkCalendarAccess, useHomePage } from "../../store";

export function New() {
  const navigate = useNavigate();
  const destinationIndex = useDestinationIndex();
  const homePage = useHomePage();
  const connectCalenderLocation = useUrlWithBack("/appointments/connect");

  const step = FormSteps.get("BasicDetails") as FormStep;

  useEffect(() => {
    checkCalendarAccess().then((hasCalendarAccess) => {
      if (!hasCalendarAccess) {
        navigate(connectCalenderLocation);
      }
    });
  }, [navigate, connectCalenderLocation]);

  if (!homePage) {
    return <Loader />;
  }

  const onSubmit = async (payload: AppointmentPayload) => {
    const { _id: id } = await addAppointment(destinationIndex, homePage, payload);
    navigate(stepPath(id, step.next));
  };

  return (
    <FormProvider defaultValues={EmptyAppointment} schema={AppointmentSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: `/content/add/${destinationIndex}`, label: "Block selection" }}
        header="Create an appointment"
        title={step.title}
        subtitle={<TextMain>{step.description}</TextMain>}
        subHeaderElement={<Progress current={1} total={FormSteps.size} />}
      >
        <FormTag onSubmit={onSubmit}>
          <BasicDetails />
          <Divider />
          <Submit cancelTo="/content" label="Save and continue" />
        </FormTag>
      </Overlay>
    </FormProvider>
  );
}
