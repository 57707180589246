import { EditableBlockKinds } from "schemas";
import { AppointmentLinkForm } from "./AppointmentLinkForm";
import { SocialLinksForm } from "./SocialLinksForm";
import { FC } from "react";
import { FormLinkForm } from "./FormLinkForm";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BlockFormComponents: Record<EditableBlockKinds, FC<any>> = {
  AppointmentLinkBlock: AppointmentLinkForm,
  FormLinkBlock: FormLinkForm,
  SocialLinksBlock: SocialLinksForm,
};
