import type { SVGProps } from "react";
const SvgSocialWeChat = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M9.84 6.55c-.381.006-.704.331-.697.702a.697.697 0 0 0 .716.681.684.684 0 0 0 .688-.702.69.69 0 0 0-.706-.682m-3.714.713c.014-.37-.306-.702-.687-.714a.693.693 0 0 0-.72.67c-.012.39.285.7.678.71a.704.704 0 0 0 .729-.666m7.12.63c-1.547.08-2.892.55-3.985 1.609-1.103 1.07-1.607 2.382-1.47 4.008-.604-.075-1.155-.157-1.709-.204-.19-.016-.418.007-.58.099-.538.303-1.054.646-1.665 1.028.112-.507.185-.951.313-1.379.095-.314.051-.488-.238-.693-1.857-1.311-2.64-3.273-2.054-5.294C2.4 5.198 3.73 4.065 5.54 3.474c2.468-.806 5.243.016 6.744 1.976a4.6 4.6 0 0 1 .962 2.442"
    />
    <path
      fill="#fff"
      d="M14.74 10.987a.577.577 0 0 0-.572.551.57.57 0 0 0 .565.597.56.56 0 0 0 .572-.547.573.573 0 0 0-.565-.601m-3.594 1.152a.58.58 0 0 0 .58-.552.58.58 0 0 0-.576-.601.587.587 0 0 0-.583.597c.01.307.269.555.579.556m5.576 5.399c-.49-.218-.94-.546-1.418-.596-.476-.05-.977.225-1.476.276-1.519.156-2.88-.267-4.002-1.305-2.134-1.974-1.829-5 .64-6.618 2.195-1.437 5.413-.958 6.96 1.037 1.35 1.74 1.19 4.05-.457 5.513-.477.423-.649.771-.343 1.33.057.102.063.233.096.363"
    />
  </svg>
);
export default SvgSocialWeChat;
