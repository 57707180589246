import type { SVGProps } from "react";
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#191B20"
      d="M4.5 18c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 3 16.5V5h1.5v11.5H14V18H4.5Zm3-3c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 6 13.5v-10c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 7.5 2h8c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v10c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 15.5 15h-8Zm0-1.5h8v-10h-8v10Z"
    />
  </svg>
);
export default SvgCopy;
