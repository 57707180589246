import type { SVGProps } from "react";
const SvgCalendarPrimary = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <mask
      id="CalendarPrimary_svg__a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g fill="#F0A056" mask="url(#CalendarPrimary_svg__a)">
      <path d="M4.5 18c-.412 0-.766-.15-1.06-.448A1.452 1.452 0 0 1 3 16.5v-11c0-.403.147-.753.44-1.052A1.433 1.433 0 0 1 4.5 4H6V2h1.5v2h5V2H14v2h1.5c.412 0 .766.15 1.06.448.293.299.44.65.44 1.052v11c0 .403-.147.753-.44 1.052A1.433 1.433 0 0 1 15.5 18h-11Zm0-1.5h11V9h-11v7.5Zm0-9h11v-2h-11v2Z" />
      <path d="m8.142 15.388 1.855-1.417 1.854 1.417-.709-2.274 1.855-1.476h-2.28l-.72-2.25-.72 2.25h-2.28l1.854 1.476-.709 2.274Z" />
    </g>
  </svg>
);
export default SvgCalendarPrimary;
