import { RefObject, useEffect } from "react";
import { useChillIntersection } from "../../hooks";
import { useLiteSiteStore } from "../store";
import { without } from "es-toolkit";

function addIntersection(dateString: string) {
  useLiteSiteStore.setState(({ upcomingIntersectingDays }) => {
    if (upcomingIntersectingDays.indexOf(dateString) < 0) {
      return { upcomingIntersectingDays: [...upcomingIntersectingDays, dateString].sort() };
    }

    return { upcomingIntersectingDays };
  });
}

function removeIntersection(dateString: string) {
  useLiteSiteStore.setState(({ upcomingIntersectingDays }) => {
    if (upcomingIntersectingDays.indexOf(dateString) >= 0) {
      return { upcomingIntersectingDays: without(upcomingIntersectingDays, dateString) };
    }

    return { upcomingIntersectingDays };
  });
}

export function useBookingIntersection(ref: RefObject<HTMLDivElement>, offset: number, dateString: string) {
  const intersects = useChillIntersection(ref, offset);

  useEffect(() => {
    intersects ? addIntersection(dateString) : removeIntersection(dateString);
  }, [dateString, intersects]);
}
