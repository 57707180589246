import { DateTime } from "luxon";
import { PersistedBooking } from "schemas";
import {
  Card,
  Description,
  DescriptionAccent,
  Icon,
  IconType,
  SmallTextMain,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  textStyles,
} from "ui";
import classNames from "classnames";
import { useUrlWithBack } from "../../hooks";
import { RawLink } from "../ui";
import { cancelBooking } from "../../store";
import { toast } from "react-toastify";

type CardType = "upcoming" | "canceled" | "past";

const Icons: Record<CardType, IconType> = {
  upcoming: "EventUpcoming",
  canceled: "EventCanceled",
  past: "EventCheck",
};

interface Props {
  booking: PersistedBooking;
}

export function SimpleBookingCard({ booking }: Props) {
  const { event, date } = booking;
  const start = DateTime.fromISO(date);
  const end = start.plus({ minutes: event.sessionDuration });
  const bookingUrl = useUrlWithBack(`/bookings/${booking._id}`);
  const type: CardType = booking.canceled
    ? "canceled"
    : DateTime.fromISO(booking.date).diffNow().milliseconds < 0
      ? "past"
      : "upcoming";

  const year = start.year < DateTime.now().year ? "numeric" : undefined;

  const onCancel = async () => {
    await cancelBooking(booking);
    toast("Booking cancelled");
  };

  return (
    <Card>
      <div
        className={classNames(
          paddingStyles.p125,
          flexStyles.horiz075,
          flexStyles.alignStretch,
          { [iconStyles.successMedium]: type === "past" },
          { [iconStyles.warningMedium]: type === "upcoming" },
          { [iconStyles.errorDark]: type === "canceled" },
        )}
      >
        <Icon className={flexStyles.alignSelfStart} iconType={Icons[type]} />
        <div className={flexStyles.vert025}>
          <DescriptionAccent>
            {start.toLocaleString({ month: "long", day: "numeric", weekday: "long", year })}
          </DescriptionAccent>
          <Description>
            {start.toLocaleString({ hour: "2-digit", minute: "2-digit" })} -{" "}
            {end.toLocaleString({ hour: "2-digit", minute: "2-digit" })}
          </Description>
          <Description>{event.eventName}</Description>
        </div>
      </div>
      {type === "upcoming" && (
        <div
          className={classNames(
            flexStyles.horiz,
            flexStyles.alignCenter,
            flexStyles.justifyEvenly,
            borderStyles.topNeutral200,
          )}
        >
          <RawLink
            className={classNames(
              textStyles.decorationNone,
              flexStyles.basisHalf,
              flexStyles.horiz050,
              flexStyles.alignCenter,
              flexStyles.justifyCenter,
              paddingStyles.px125,
              paddingStyles.py050,
            )}
            onClick={onCancel}
          >
            <Icon iconType="Remove" className={iconStyles.errorDark} />
            <SmallTextMain>Cancel</SmallTextMain>
          </RawLink>
          <RawLink
            className={classNames(
              textStyles.decorationNone,
              flexStyles.basisHalf,
              flexStyles.horiz050,
              flexStyles.alignCenter,
              flexStyles.justifyCenter,
              borderStyles.leftNeutral200,
              paddingStyles.px125,
              paddingStyles.py050,
            )}
            to={bookingUrl}
          >
            <Icon iconType="ArrowRight" className={iconStyles.secondary500} />
            <SmallTextMain>View</SmallTextMain>
          </RawLink>
        </div>
      )}
    </Card>
  );
}
