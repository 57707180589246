import { DaySelector } from "ui";
import { Days } from "./Days";

interface Props {
  name: string;
}
export function ScheduleInput({ name }: Props) {
  return (
    <>
      <DaySelector name={name} label="Available days" />
      <Days name={name} />
    </>
  );
}
