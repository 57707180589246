import type { SVGProps } from "react";
const SvgSocialMessenger = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10.001 2.333c-4.694 0-8.334 3.439-8.334 8.085 0 2.43.996 4.528 2.616 5.978a.67.67 0 0 1 .224.474l.046 1.484a.665.665 0 0 0 .935.588l1.654-.73a.67.67 0 0 1 .445-.033c.76.209 1.569.322 2.412.322 4.694 0 8.334-3.439 8.334-8.084s-3.64-8.084-8.332-8.084m4.376 6.316-2.087 3.31a1.066 1.066 0 0 1-1.541.284L9.089 11a.43.43 0 0 0-.512 0l-2.24 1.7c-.297.228-.69-.13-.49-.446l2.086-3.31a1.066 1.066 0 0 1 1.541-.285l1.66 1.244a.43.43 0 0 0 .513 0l2.24-1.7c.3-.23.693.128.49.447"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialMessenger;
