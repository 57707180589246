import { FontFamilyValues } from "schemas";
import { Select } from "../Select";
import { flexStyles } from "../../styles";

export function ToolbarFontFamilySelect() {
  const options = FontFamilyValues.map((value) => ({ label: value, value }));
  const optionsWithEmpty = [{ label: "Select font", value: null }, ...options];

  return (
    <Select className={flexStyles.shrink1} placeholder="Select font" raw name="fontFamily" options={optionsWithEmpty} />
  );
}
