import { PropsWithChildren } from "react";
import { OnClickHandler } from "../../types";
import classNames from "classnames";
import { bgStyles, borderStyles, flexStyles, paddingStyles, pointerStyles } from "../../styles";

interface Props extends PropsWithChildren {
  onClick?: OnClickHandler;
}

export function Row({ onClick, children }: Props) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        flexStyles.horiz025,
        paddingStyles.py150,
        paddingStyles.px125,
        bgStyles.hoverNeutral200,
        borderStyles.topNeutral200,
        borderStyles.noneFirstChild,
        pointerStyles.cursor,
      )}
    >
      {children}
    </div>
  );
}
