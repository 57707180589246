import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

export async function deleteForm(id: string) {
  await apiRequest({ url: `forms/${id}`, method: "DELETE" });
  useLiteSiteStore.setState(({ formsList, forms }) => {
    const updatedFormsList = formsList.filter((form) => form._id !== id);
    forms.delete(id);
    return { formsList: updatedFormsList, forms: new Map(forms) };
  });
}
