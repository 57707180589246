import { TextMain, flexStyles } from "ui";
import { CampaignStatus } from "schemas";
import { useNavigate } from "react-router-dom";
import { RadioCard, SwitcherScaffold } from "../ui";
import { ReactNode } from "react";

interface Props {
  label: CampaignStatus | "All";
  extra: ReactNode;
}
export function Switcher({ label, extra }: Props) {
  const navigate = useNavigate();

  return (
    <SwitcherScaffold label={label} drawerTitle="Campaign Status" extra={extra}>
      <div className={flexStyles.vert125}>
        <RadioCard
          title={<TextMain>All Campaigns</TextMain>}
          checked={label === "All"}
          onClick={async () => navigate("/campaigns")}
        />
        <RadioCard
          title={<TextMain>Draft Campaigns</TextMain>}
          checked={label === "Draft"}
          onClick={async () => navigate("/campaigns/draft")}
        />
        <RadioCard
          title={<TextMain>Sent Campaigns</TextMain>}
          checked={label === "Sent" || label === "Sending"}
          onClick={async () => navigate("/campaigns/sent")}
        />
      </div>
    </SwitcherScaffold>
  );
}
