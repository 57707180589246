import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

export async function deleteContactGroup(id: string) {
  await apiRequest({ url: `contactGroups/${id}`, method: "DELETE" });

  useLiteSiteStore.setState(({ contactGroups }) => {
    contactGroups.delete(id);

    return { contactGroups: new Map(contactGroups) };
  });
}
