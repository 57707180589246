import type { SVGProps } from "react";
const SvgSocialYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M17.163 4.383c.789.212 1.41.832 1.62 1.621.382 1.43.384 4.413.384 4.413s0 2.984-.384 4.413a2.3 2.3 0 0 1-1.62 1.62c-1.43.384-7.163.384-7.163.384s-5.733 0-7.162-.383a2.3 2.3 0 0 1-1.621-1.621C.833 13.4.833 10.417.833 10.417s0-2.984.384-4.413c.211-.79.831-1.41 1.62-1.62C4.268 4 10 4 10 4s5.733 0 7.163.383m-4.235 6.034-4.763 2.75v-5.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialYoutube;
