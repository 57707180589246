import classNames from "classnames";
import { TextMain, borderStyles, flexStyles } from "ui";

export function Or() {
  return (
    <div className={classNames(flexStyles.horiz125, flexStyles.alignCenter)}>
      <div className={classNames(flexStyles.grow, borderStyles.topNeutral300)} />
      <TextMain>Or</TextMain>
      <div className={classNames(flexStyles.grow, borderStyles.topNeutral300)} />
    </div>
  );
}
