import type { SVGProps } from "react";
const SvgUpdateTwirl2 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={159} height={143} fill="none" {...props}>
    <path
      stroke="#9BC7F5"
      strokeLinecap="round"
      strokeWidth={3}
      d="M7.958 150.876c8.482 6.176 17.525 8.489 28.38 10.499 18.374 3.401 25.767-30.555 21.248-39.762-2.66-5.42-3.897-13.836-9.888-16.718-7.091-3.411-9.944.622-7.167 7.903 6.03 15.808 25.513 34.488 50.02 24.232 19.578-8.193 34.466-48.046 24.572-59.315-2.361-2.689-1.501-11.548-6.321-11.443-6.958.152 10.921 16.682 13.149 19.22 10.533 11.996 20.482 21.493 44.123 12.06 13.835-5.52 17.548-13.976 27.762-22.944"
    />
  </svg>
);
export default SvgUpdateTwirl2;
