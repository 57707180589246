import { ThemePayload } from "../schemas/Theme";
import { Colors } from "./colors";
import { gradientCss } from "./gradientCss";
import { gradientForTheme } from "./gradientForTheme";
import { isGradientTheme } from "./helpers/isGradientTheme";
import { themePrimary } from "./themePrimary";
import { themePrimaryDark } from "./themePrimaryDark";
import { themePrimaryLight } from "./themePrimaryLight";

export function themeBackground(theme: ThemePayload) {
  if (isGradientTheme(theme)) {
    return gradientCss(gradientForTheme(theme));
  } else {
    switch (theme.variation) {
      case "Primary":
        return themePrimary(theme);
      case "Light":
        return themePrimaryLight(theme);
      case "Dark":
        return themePrimaryDark(theme);
      case "Gray":
        return Colors.Neutral200;
      case "Black":
        return Colors.Neutral600;
    }
  }
}
