import classNames from "classnames";
import { PropsWithClassName } from "../types";
import { textStyles } from "../styles";
import { PropsWithChildren } from "react";

interface Props extends PropsWithClassName, PropsWithChildren {}
export function HeaderMain({ children, className }: Props) {
  return (
    <h1 className={classNames(textStyles.lsColor, textStyles.size175, textStyles.weight500, className)}>{children}</h1>
  );
}

export function HeaderAccent({ children, className }: Props) {
  return <HeaderMain className={classNames(textStyles.weight600, className)}>{children}</HeaderMain>;
}
