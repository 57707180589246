import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";
import { getCampaignElements } from "./getCampaignElements";

interface Args {
  campaignId: string;
  campaignElementId: string;
}
export async function removeCampaignElement({ campaignElementId, campaignId }: Args) {
  await apiRequest({ url: `campaignElements/${campaignId}/elements/${campaignElementId}`, method: "DELETE" });

  getCampaignElements(campaignId);
  useLiteSiteStore.setState(({ campaignElements }) => {
    campaignElements.delete(campaignElementId);
    return { campaignElements };
  });
}
