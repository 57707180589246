import { SVGProps } from "react";

const SvgUploadCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={29} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#UploadCloud_svg__a)" fill="#0FD6CA">
      <path d="M38.471 29h-5.23a1.554 1.554 0 0 1-1.553-1.551c0-.856.697-1.552 1.553-1.552h5.23c3.434 0 6.25-2.588 6.415-5.894a6.223 6.223 0 0 0-4.76-6.36 2.15 2.15 0 0 1-1.642-1.805c-.678-4.981-4.995-8.735-10.034-8.735-3.869 0-7.453 2.256-9.13 5.744a1.696 1.696 0 0 1-1.344.953 1.687 1.687 0 0 1-1.522-.639c-.772-.984-1.872-1.52-3.048-1.507-2.502.058-3.824 1.348-3.934 3.834a2.383 2.383 0 0 1-1.77 2.199c-2.809.753-4.738 3.408-4.592 6.316.164 3.306 2.981 5.894 6.415 5.894h5.731c.857 0 1.553.696 1.553 1.552 0 .855-.696 1.551-1.553 1.551H9.525C4.44 29 .257 25.113.013 20.153c-.208-4.184 2.458-8.022 6.402-9.32.315-3.059 2.271-6.18 6.93-6.281a6.737 6.737 0 0 1 4.13 1.303C19.904 2.247 24.04 0 28.454 0c6.37 0 11.857 4.596 13.006 10.792a9.33 9.33 0 0 1 6.526 9.361c-.249 4.96-4.428 8.843-9.516 8.843V29Z" />
      <path d="m31.156 19.67-6.717-6.71a.624.624 0 0 0-.883 0l-6.716 6.71a.623.623 0 0 0 .444 1.064h3.065v7.477c0 .346.28.625.625.625h6.052c.346 0 .625-.279.625-.625v-7.477h3.066c.554 0 .834-.673.443-1.063h-.004Z" />
    </g>
    <defs>
      <clipPath id="UploadCloud_svg__a">
        <path fill="#fff" d="M0 0h48v29H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUploadCloud;
