import classNames from "classnames";
import {
  ShareLink,
  bgStyles,
  flexStyles,
  heightStyles,
  imageStyles,
  overflowStyles,
  paddingStyles,
  widthStyles,
} from "ui";
import { DesktopMenu } from "./DesktopMenu";
import { User } from "./User";
import { useCurrentBusiness } from "../../store";
import { toast } from "react-toastify";
import logo from "../../images/PopSendLogoDark.png";

const { REACT_APP_PUBLIC_DOMAIN } = import.meta.env;

export function Sidebar() {
  const business = useCurrentBusiness();
  return (
    <aside
      className={classNames(
        paddingStyles.ps075,
        paddingStyles.pt325,
        paddingStyles.pb250,
        bgStyles.neutral0,
        overflowStyles.autoY,
      )}
    >
      <div
        className={classNames(
          paddingStyles.ps200,
          flexStyles.vert,
          flexStyles.gap450,
          flexStyles.justifyStretch,
          widthStyles.min2000,
          heightStyles.full,
        )}
      >
        <img className={classNames(widthStyles.width950, imageStyles.fitContain)} src={logo} />
        <DesktopMenu className={flexStyles.grow} />
        <div className={classNames(flexStyles.vert100, flexStyles.justifyStretch)}>
          {business && (
            <ShareLink
              className={classNames(flexStyles.alignSelfStart, widthStyles.maxWidth1500)}
              relativeUrl={`${business.relativeUrl}/subscribe`}
              host={REACT_APP_PUBLIC_DOMAIN}
              onCopy={toast}
            />
          )}
          <User />
        </div>
      </div>
    </aside>
  );
}
