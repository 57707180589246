import { SVGProps } from "react";

const SvgWhatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={41} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#Whatsapp_svg__a)" fill="#3D4351">
      <path d="M18.968 3h3.838c.143.05.299.123.442.147 9.48 1.326 16.092 10.763 14.274 20.36C35.36 34.932 23.5 40.994 13.302 35.9a1.663 1.663 0 0 0-1.064-.11c-2.344.601-4.663 1.24-6.994 1.865-.442.123-.885.221-1.423.356.718-2.7 1.363-5.289 2.104-7.842.228-.773.168-1.399-.19-2.123-4.663-9.584.298-21.316 10.304-24.323.969-.294 1.949-.49 2.93-.724ZM7.958 33.84c1.638-.442 3.132-.872 4.638-1.215.37-.086.85-.037 1.172.147 2.857 1.645 5.906 2.356 9.157 1.829 7.76-1.264 12.864-8.345 11.836-16.384-.992-7.731-8.452-13.388-15.972-12.112C9.117 7.749 3.977 18.34 8.615 27.188c.55 1.056.706 1.94.311 3.044-.406 1.117-.634 2.307-.968 3.608Z" />
      <path d="M25.16 28.71c-1.59-.049-3.167-.724-4.662-1.546-3.323-1.829-5.786-4.59-7.531-7.99-1.184-2.319-.85-4.54.74-6.258.515-.552 1.782-.847 2.44-.503.203.11.394.356.49.589a63.347 63.347 0 0 1 1.35 3.362c.096.246.049.565-.023.835-.072.245-.251.466-.407.675-1.016 1.338-1.016 1.338-.035 2.712 1.267 1.792 2.905 3.093 4.89 3.964.537.233.884.135 1.207-.32.322-.453.705-.846 1.016-1.312.287-.442.621-.577 1.076-.344 1.135.552 2.271 1.08 3.371 1.681.215.123.37.553.37.835 0 2.209-1.685 3.289-3.43 3.596-.228.037-.467.024-.873.049l.012-.025Z" />
    </g>
    <defs>
      <clipPath id="Whatsapp_svg__a">
        <path fill="#fff" transform="translate(3.833 3)" d="M0 0h34v35H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWhatsapp;
