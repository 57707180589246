import { ContactImportPayload, PersistedContactImport } from "schemas";
import { apiRequest } from "../apiRequest";
import { setContactImport } from "./setContactImport";

export async function addContactImport(payload: ContactImportPayload) {
  const contactImport = await apiRequest<PersistedContactImport>({
    url: "contactImports",
    method: "POST",
    body: JSON.stringify(payload),
  });
  setContactImport(contactImport);

  return contactImport;
}
