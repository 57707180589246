import { SVGProps } from "react";

const SvgOutlookCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#OutlookCalendar_svg__a)">
      <path d="M11.667 5.417h6.055c.338 0 .611.273.611.61v7.945a.611.611 0 0 1-.61.611h-6.056V5.417Z" fill="#1976D2" />
      <path d="M17.5 7.483h-5.833v6.475H17.5V7.483Z" fill="#fff" />
      <path d="m11.25 18.333-9.583-1.875V3.542l9.583-1.875v16.666Z" fill="#1976D2" />
      <path
        d="M6.354 6.875c-1.323 0-2.396 1.4-2.396 3.125 0 1.726 1.073 3.125 2.396 3.125S8.75 11.725 8.75 10c0-1.726-1.073-3.125-2.396-3.125Zm-.104 5C5.56 11.875 5 11.035 5 10c0-1.035.56-1.875 1.25-1.875S7.5 8.965 7.5 10c0 1.035-.56 1.875-1.25 1.875Z"
        fill="#fff"
      />
      <path
        d="M12.811 12.39h-1.125V13.6h1.125V12.39ZM14.228 12.39h-1.125V13.6h1.125V12.39ZM15.645 12.39H14.52V13.6h1.125V12.39ZM12.811 10.9h-1.125v1.208h1.125V10.9ZM14.228 10.9h-1.125v1.208h1.125V10.9ZM15.645 10.9H14.52v1.208h1.125V10.9ZM17.063 10.9h-1.125v1.208h1.124V10.9ZM12.811 9.46h-1.125v1.21h1.125V9.46ZM14.228 9.46h-1.125v1.21h1.125V9.46ZM15.645 9.46H14.52v1.21h1.125V9.46ZM17.063 9.46h-1.125v1.21h1.124V9.46ZM14.228 7.963h-1.125v1.209h1.125V7.963ZM15.645 7.963H14.52v1.209h1.125V7.963ZM17.063 7.963h-1.125v1.209h1.124V7.963Z"
        fill="#1976D2"
      />
    </g>
    <defs>
      <clipPath id="OutlookCalendar_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgOutlookCalendar;
