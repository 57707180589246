import type { SVGProps } from "react";
const SvgSocialTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20" {...props}>
    <path
      fill="#fff"
      d="M18.833 4.937a6.9 6.9 0 0 1-1.963.538 3.43 3.43 0 0 0 1.503-1.892 6.8 6.8 0 0 1-2.171.83 3.42 3.42 0 0 0-5.827 3.12 9.7 9.7 0 0 1-7.048-3.574 3.42 3.42 0 0 0 1.058 4.566 3.4 3.4 0 0 1-1.548-.429v.044a3.42 3.42 0 0 0 2.743 3.353 3.45 3.45 0 0 1-1.545.06 3.42 3.42 0 0 0 3.195 2.373 6.86 6.86 0 0 1-5.063 1.417 9.67 9.67 0 0 0 5.24 1.536c6.291 0 9.73-5.21 9.73-9.729a10 10 0 0 0-.01-.442 7 7 0 0 0 1.705-1.769z"
    />
  </svg>
);
export default SvgSocialTwitter;
