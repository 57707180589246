import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";
import { getPageElements } from "./getPageElements";

interface Args {
  pageId: string;
  pageElementId: string;
}
export async function removePageElement({ pageId, pageElementId }: Args) {
  await apiRequest({ url: `pageElements/${pageId}/elements/${pageElementId}`, method: "DELETE" });

  getPageElements(pageId);
  useLiteSiteStore.setState(({ pageElements }) => {
    pageElements.delete(pageElementId);
    return { pageElements };
  });
}
