import { AddCard } from "./AddCard";
import { BlockKind, EditableBlockKinds, InlineEditableBlockKinds } from "schemas";
import { AddGrid } from "./AddGrid";
import { useBlockConsumer } from "./AvailableBlocks";

interface Props {
  open: boolean;
  destinationIndex: number;
  currentKind: BlockKind;
  blockId: string;
}
export function AddMenu({ currentKind, blockId, open, destinationIndex }: Props) {
  const consumer = useBlockConsumer();

  const inlineTo = (kind: InlineEditableBlockKinds | "HeadingBlock") => `blocks/newInline/${destinationIndex}/${kind}`;
  const to = (kind: EditableBlockKinds) => `blocks/new/${destinationIndex}/${kind}`;

  return (
    <AddGrid open={open}>
      <AddCard kind="HeadingBlock" consumer={consumer} to={inlineTo("HeadingBlock")} iconType="FormatHeadingH1">
        Heading
      </AddCard>
      <AddCard kind="TextBlock" consumer={consumer} to={inlineTo("TextBlock")} iconType="TextFields">
        Text
      </AddCard>
      <AddCard kind="ImageBlock" consumer={consumer} to={inlineTo("ImageBlock")} iconType="Image">
        Image
      </AddCard>
      <AddCard kind="LinkBlock" consumer={consumer} to={inlineTo("LinkBlock")} iconType="Link">
        Button
      </AddCard>
      <AddCard kind="SocialLinksBlock" consumer={consumer} to={to("SocialLinksBlock")} iconType="SocialTiktok">
        Social Media
      </AddCard>
      <AddCard
        consumer={consumer}
        to={`blocks/${currentKind}/${blockId}/${destinationIndex}/new`}
        iconType="ThreeDots"
        rotate
      >
        More
      </AddCard>
    </AddGrid>
  );
}
