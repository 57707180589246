import { SVGProps } from "react";

const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Image_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
      viewBox="0 0 20 20"
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Image_svg__a)">
      <path
        d="M4.875 17.396c-.625 0-1.16-.222-1.604-.667a2.187 2.187 0 0 1-.667-1.604V4.875c0-.639.222-1.177.667-1.615.445-.437.98-.656 1.604-.656h10.25c.639 0 1.177.219 1.615.656.437.438.656.976.656 1.615v10.25c0 .625-.219 1.16-.656 1.604a2.178 2.178 0 0 1-1.615.667H4.875Zm0-1.542h10.25c.222 0 .399-.07.531-.208a.726.726 0 0 0 .198-.521V4.875c0-.222-.066-.399-.198-.531s-.309-.198-.531-.198H4.875a.726.726 0 0 0-.521.198.699.699 0 0 0-.208.531v10.25c0 .209.07.382.208.521a.707.707 0 0 0 .521.208Zm.917-1.958 2.812-2.813 1.229 1.25 1.563-1.958 2.792 3.521H5.792Zm1.291-5.229a1.53 1.53 0 0 1-1.125-.459A1.53 1.53 0 0 1 5.5 7.083c0-.444.156-.819.469-1.125A1.565 1.565 0 0 1 7.104 5.5c.445 0 .816.156 1.115.469.299.313.448.691.448 1.135 0 .445-.153.816-.459 1.115-.305.299-.68.448-1.125.448Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgImage;
