import type { SVGProps } from "react";
const SvgSocialBandcamp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#191B20"
      d="M13.118 8.442c-.835 0-1.26.653-1.26 1.637 0 .93.46 1.628 1.26 1.628.904 0 1.244-.823 1.244-1.628-.001-.84-.427-1.637-1.244-1.637M10.901 6h.99v2.361h.018c.27-.447.835-.724 1.34-.724 1.417 0 2.104 1.11 2.104 2.46 0 1.243-.609 2.415-1.94 2.415-.608 0-1.26-.152-1.556-.76h-.017v.635h-.94zM19.009 9.31c-.079-.564-.478-.868-1.026-.868-.514 0-1.235.277-1.235 1.682 0 .769.33 1.583 1.191 1.583.574 0 .974-.394 1.07-1.056H20c-.183 1.199-.904 1.86-2.06 1.86-1.41 0-2.184-1.028-2.184-2.387 0-1.396.74-2.487 2.218-2.487 1.043 0 1.93.537 2.026 1.673z"
    />
    <path fill="#191B20" d="M7.435 12.373H0L3.472 6h7.435z" />
  </svg>
);
export default SvgSocialBandcamp;
