"use client";

import classNames from "classnames";
import { Checkbox } from "./Checkbox";
import { Label } from "../Label";
import { textStyles } from "../../styles";
import { PropsWithClassName } from "../../types";
import { PropsWithChildren } from "react";

interface Props extends PropsWithClassName, PropsWithChildren {
  name: string;
}

export function CheckboxWithLabel({ className, name, children }: Props) {
  return (
    <Label className={classNames(textStyles.size087, className)} name={name} extra={<Checkbox name={name} />}>
      {children}
    </Label>
  );
}
