import { AnimationType } from "ui";
import { useCurrentUser, useNewItems } from "../../store";
import { BottomBar } from "./BottomBar";
import { Item } from "./Item";

export function Menu() {
  const currentUser = useCurrentUser();

  const { newItems } = useNewItems();

  if (!currentUser) {
    return null;
  }

  const hasDot = newItems && (newItems.bookings.length > 0 || newItems.formAnswers.length > 0);

  const items: { href: string; label: string; animation: AnimationType; hasDot?: boolean }[] = [
    {
      href: "/campaigns",
      label: "Email",
      animation: "MenuCampaigns",
    },
    {
      href: "/contacts",
      label: "Contacts",
      animation: "MenuContacts",
    },
    {
      href: "/content",
      label: "Link in bio",
      animation: "MenuLink",
    },
    {
      href: "/more",
      label: "More",
      animation: "MenuMore",
      hasDot,
    },
  ];

  return (
    <BottomBar>
      {items.map(({ href, label, animation, hasDot }) => (
        <Item key={label} href={href} label={label} animation={animation} hasDot={hasDot} />
      ))}
    </BottomBar>
  );
}
