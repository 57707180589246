import { SVGProps } from "react";

const SvgFunkyWrap = (props: SVGProps<SVGSVGElement>) => (
  <svg width={78} height={43} viewBox="0 0 78 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M49.121 6.873c-4.629-.06-9.407-.993-13.983-.87-4.195.112-8.335.957-12.387 1.945-5.725 1.396-13.389 3.6-17.7 8.025-3.739 3.835-4.64 7.221-2.969 12.696 1.569 5.139 6.322 8.406 11.148 9.826 9.112 2.681 17.982 3.323 27.418 2.493 6.577-.578 13.179-1.781 19.716-2.679 3.395-.466 7.688-1.611 9.977-4.264.778-.902 1.856-2.272 2.665-3.008.927-.844 1.032-2.116 1.621-3.188 1.577-2.869 2.093-7.327 1.797-10.58-.313-3.42-1.056-8.586-4.182-10.677C68.52 4.1 64.32 3.686 60.137 2.859c-4.599-.908-9.79-1.207-14.466-1.119-4.225.08-8.4 1.216-12.602 1.608a72.406 72.406 0 0 0-10.374 1.729c-3.554.867-8.182 1.387-11.132 3.539"
      stroke="#FEFFF4"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgFunkyWrap;
