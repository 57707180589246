import classNames from "classnames";
import { PropsWithClassNameAndChildren } from "../types";
import { textStyles } from "../styles";

export function Huge({ children, className }: PropsWithClassNameAndChildren) {
  return (
    <div className={classNames(textStyles.lsColor, textStyles.size250, textStyles.weight500, className)}>
      {children}
    </div>
  );
}

export function HugeAccent({ children, className }: PropsWithClassNameAndChildren) {
  return <div className={classNames(textStyles.weight700, className)}>{children}</div>;
}
