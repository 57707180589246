import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IconType,
  Loader,
  SubheaderMain,
  Tag,
  Icon,
  RawIcon,
  IconButton,
  DescriptionAccent,
  paddingStyles,
  flexStyles,
  bgStyles,
  imageStyles,
  iconStyles,
  useConfirm,
} from "ui";
import classNames from "classnames";
import { useBackLocation, useIdParam } from "../../hooks";
import { cancelBooking, resendBookingConfirmation, useBooking } from "../../store";
import { FormAnswer } from "../forms";
import { PersistedFormAnswer } from "schemas";
import { ContactCard } from "./ContactCard";
import { IconLink, Overlay, SuperCard } from "../ui";

export function Show() {
  const navigate = useNavigate();
  const id = useIdParam("bookingId");
  const booking = useBooking(id);

  const backUrl = useBackLocation("/dashboard");
  const goBack = () => navigate(backUrl);
  const { confirm, Confirm } = useConfirm();

  if (!booking) {
    return <Loader full />;
  }

  const { event, contact, calendarLinks, formAnswer, conferenceType, conferenceLink } = booking;

  const resetConfirmation = async () => {
    await resendBookingConfirmation(id);
    toast("Booking confirmation resent");
  };

  const onCancelBooking = () => {
    confirm({
      text: "Are you sure you want to cancel this booking?",
      label: "Cancel Booking",
      onConfirm: async () => {
        await cancelBooking(booking);
        goBack();
      },
    });
  };

  return (
    <Overlay className={paddingStyles.p125} header="Appointment Detail" backTo={{ to: backUrl }}>
      <Confirm />
      <div className={classNames(flexStyles.vert125, bgStyles.neutral0)}>
        {event.image && <img className={imageStyles.cover1300} alt={event.eventName} src={`${event.image}/feed`} />}
        <SubheaderMain className={paddingStyles.px125}>{event.eventName}</SubheaderMain>
        <div
          style={
            {
              "--tag-background-color": "var(--litesite-secondary-100)",
              "--tag-border-color": "var(--litesite-secondary-light)",
            } as CSSProperties
          }
          className={classNames(flexStyles.horiz050, flexStyles.wrap, paddingStyles.px125, iconStyles.secondary300)}
        >
          {booking.bookingTags.map(({ label, iconType }) => (
            <Tag key={label} label={label} iconType={iconType as IconType} />
          ))}
        </div>
        <ContactCard className={paddingStyles.px125} contact={contact} />
      </div>
      <div className={classNames(flexStyles.vert050, paddingStyles.py125)}>
        {formAnswer?.answers && formAnswer.answers.length > 0 && (
          <SuperCard left={<Icon iconType="FormsSymbol" />} title={<DescriptionAccent>Form</DescriptionAccent>}>
            <FormAnswer formAnswer={formAnswer as PersistedFormAnswer} />
          </SuperCard>
        )}
        {calendarLinks?.google && (
          <SuperCard
            to={calendarLinks.google}
            right={<IconLink iconType="ChevronRight" to={calendarLinks.google} />}
            left={<Icon iconType="Calendar" />}
            title={<DescriptionAccent>View on Google Calendar</DescriptionAccent>}
          />
        )}
        {conferenceType === "Zoom" && (
          <SuperCard
            to={conferenceLink}
            right={<IconLink iconType="ChevronRight" to={conferenceLink} />}
            left={<Icon iconType="Zoom" />}
            title={<DescriptionAccent>Open Zoom Meeting</DescriptionAccent>}
          />
        )}
        <SuperCard
          onClick={resetConfirmation}
          right={<IconButton iconType="ChevronRight" onClick={resetConfirmation} />}
          left={<Icon iconType="Refresh" />}
          title={<DescriptionAccent>Resend Confirmation</DescriptionAccent>}
        />
        <SuperCard
          onClick={onCancelBooking}
          right={<IconButton iconType="ChevronRight" onClick={onCancelBooking} />}
          left={<RawIcon iconType="Remove" />}
          title={<DescriptionAccent>Cancel booking</DescriptionAccent>}
        />
      </div>
    </Overlay>
  );
}
