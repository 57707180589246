import { useEffect } from "react";
import { Loader } from "ui";
import { postConnectAccountLink } from "../../store";

export function Retry() {
  useEffect(() => {
    postConnectAccountLink().then((url) => {
      window.location.href = url;
    });
  }, []);

  return <Loader full />;
}
