import { Animation, Icon, TextMain, flexStyles, iconStyles, textStyles } from "ui";
import classNames from "classnames";
import { Button } from "../ui";

interface Props {
  newContactTo: string;
  query?: string;
}
export function NoResults({ newContactTo, query }: Props) {
  const message = query ? (
    <>
      No contacts found for “{query}”<br />
      Try a different search or add this contact.
    </>
  ) : (
    <>
      You don’t have any contacts.
      <br />
      You can import your contacts or add one by one manually.
    </>
  );

  return (
    <div
      className={classNames(
        flexStyles.justifyCenter,
        flexStyles.vert100,
        flexStyles.alignCenter,
        flexStyles.grow,
        textStyles.center,
      )}
    >
      <Animation className={iconStyles.width625} animationType="AvatarCircle" />
      <TextMain>{message}</TextMain>
      <Button to={newContactTo} className={flexStyles.alignSelfStretch}>
        <Icon iconType="Plus" />
        Add contact
      </Button>
    </div>
  );
}
