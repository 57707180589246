import type { SVGProps } from "react";
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={8} viewBox="0 0 16 8" fill="none" {...props}>
    <path
      fill="#465357"
      d="M7 8H4Q2.333 8 1.167 6.833 0 5.667 0 4t1.167-2.833T4 0h3v1.5H4q-1.042 0-1.771.729A2.4 2.4 0 0 0 1.5 4q0 1.042.729 1.771A2.4 2.4 0 0 0 4 6.5h3zM5 4.75v-1.5h6v1.5zM9 8V6.5h3q1.042 0 1.771-.729A2.4 2.4 0 0 0 14.5 4q0-1.042-.729-1.771A2.4 2.4 0 0 0 12 1.5H9V0h3q1.667 0 2.833 1.167Q16 2.333 16 4t-1.167 2.833T12 8z"
    />
  </svg>
);
export default SvgIcon;
