import type { SVGProps } from "react";
const SvgSocialWhatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="m1.667 18.333 1.176-4.275a8.24 8.24 0 0 1-1.1-4.125c-.009-4.562 3.696-8.266 8.249-8.266 2.21 0 4.284.856 5.846 2.42a8.2 8.2 0 0 1 2.42 5.846c0 4.553-3.705 8.258-8.258 8.258a8.2 8.2 0 0 1-3.948-1.008zm4.578-2.637.252.15a6.87 6.87 0 0 0 3.495.959c3.78 0 6.863-3.083 6.863-6.864a6.83 6.83 0 0 0-2.008-4.855A6.84 6.84 0 0 0 9.992 3.07c-3.79 0-6.871 3.084-6.871 6.864a6.85 6.85 0 0 0 1.05 3.655l.16.26-.698 2.537z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7.933 6.472c-.15-.345-.319-.353-.461-.353q-.19-.01-.404-.008c-.134 0-.361.05-.554.26s-.722.706-.722 1.722.739 2 .84 2.134 1.428 2.284 3.528 3.117c1.747.689 2.1.554 2.478.512s1.218-.496 1.395-.983c.167-.479.167-.899.117-.983s-.193-.134-.395-.244c-.21-.1-1.218-.604-1.41-.672-.194-.067-.329-.1-.463.101-.135.21-.53.672-.655.807-.118.134-.244.15-.446.05-.21-.1-.873-.319-1.663-1.025-.613-.546-1.025-1.226-1.151-1.427-.117-.21-.017-.32.093-.421.092-.092.21-.243.31-.36.101-.119.135-.211.21-.346.067-.134.034-.26-.016-.36-.051-.093-.445-1.11-.63-1.521"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialWhatsapp;
