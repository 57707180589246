import { ThemePayload } from "../schemas/Theme";

export function themeButtonBorderRadius(theme: ThemePayload) {
  switch (theme.buttonBorderRadius) {
    case "Square":
      return "0";
    case "Smooth":
      return "0.25rem";
    case "Rounded":
      return "5rem";
  }
}
