import classNames from "classnames";
import { PropsWithChildren, useEffect } from "react";
import useTransition from "react-transition-state";
import { flexStyles, gridStyles, transitionStyles } from "ui";

interface Props extends PropsWithChildren {
  open: boolean;
}

export function AddGrid({ children, open }: Props) {
  const [{ status }, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    preExit: true,
    mountOnEnter: true,
    unmountOnExit: true,
  });

  useEffect(() => {
    toggle(open);
  }, [open]);

  return (
    <div
      className={classNames(
        gridStyles.grid3rows,
        flexStyles.gap050,
        transitionStyles.all200,
        transitionStyles.fadeIn,
        status,
      )}
    >
      {children}
    </div>
  );
}
