import { SVGProps } from "react";
const SvgAuthUnderline = (props: SVGProps<SVGSVGElement>) => (
  <svg width={133} height={7} viewBox="0 0 133 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 5.253c4.554-1.681 9.576-.89 14.28-1.686C25.35 1.863 36.378 2.483 46.633 1.85c17.821-1.098 35.777.21 53.626.602 10.409.229 20.728 1.604 31.107 1.832"
      stroke="#F5A343"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgAuthUnderline;
