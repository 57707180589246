import type { SVGProps } from "react";
const SvgUpdateTwirl = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={151} height={159} fill="none" {...props}>
    <path
      stroke="#9BC7F5"
      strokeLinecap="round"
      strokeWidth={3}
      d="M72.21-20.8c-10.48.486-18.982 4.337-28.71 9.559-16.463 8.838-.993 39.956 8.292 44.313 5.466 2.565 11.695 8.359 18.172 6.86 7.666-1.775 7.37-6.706.65-10.65-14.593-8.562-41.479-10.95-54.186 12.38-10.15 18.639 3.161 59.046 17.929 61.65 3.523.621 8.394 8.071 12.089 4.974 5.334-4.47-18.955-6.185-22.282-6.772-15.72-2.772-29.424-3.958-41.97 18.189-7.341 12.96-4.95 21.88-7.31 35.266"
    />
  </svg>
);
export default SvgUpdateTwirl;
