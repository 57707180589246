import { PersistedContact } from "schemas";
import { apiRequest } from "../apiRequest";
import { setContact } from "./setContact";

export async function getContacts(contactGroupId: string, page: number) {
  const params = new URLSearchParams({ page: `${page}`, contactGroupId });
  const contacts = await apiRequest<PersistedContact[]>({ url: `contacts?${params.toString()}` });
  contacts.map(setContact);

  return contacts;
}
