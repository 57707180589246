import type { SVGProps } from "react";
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#465357"
      d="M9.25 14h1.5V9h-1.5zm.746-6.5q.316 0 .535-.214a.71.71 0 0 0 .219-.532.74.74 0 0 0-.214-.535.71.71 0 0 0-.532-.219.74.74 0 0 0-.535.214.71.71 0 0 0-.219.532q0 .316.214.535t.532.219m.01 10.5a7.8 7.8 0 0 1-3.11-.625 8.1 8.1 0 0 1-2.552-1.719 8.1 8.1 0 0 1-1.719-2.551A7.8 7.8 0 0 1 2 9.99q0-1.657.625-3.105a8.066 8.066 0 0 1 4.27-4.26A7.8 7.8 0 0 1 10.009 2a7.75 7.75 0 0 1 3.106.625q1.447.625 2.541 1.719a8.1 8.1 0 0 1 1.719 2.546A7.8 7.8 0 0 1 18 9.994a7.8 7.8 0 0 1-.625 3.11 8.1 8.1 0 0 1-1.719 2.552 8.1 8.1 0 0 1-2.546 1.719 7.8 7.8 0 0 1-3.104.625M10 16.5q2.709 0 4.604-1.896T16.5 10t-1.896-4.604T10 3.5q-2.709 0-4.604 1.896T3.5 10t1.896 4.604T10 16.5"
    />
  </svg>
);
export default SvgInfo;
