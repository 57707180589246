import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { PersistedCampaign } from "schemas";
import { Description, DotMenu, DotMenuLink, SmallTextMain, TextAccent, flexStyles, textStyles, useConfirm } from "ui";
import { CampaignStatusLabel } from "./CampaignStatusLabel";
import { cloneCampaign, deleteCampaign } from "../../store";
import { SuperCard } from "../ui";
import classNames from "classnames";

interface Props {
  campaign: PersistedCampaign;
}

export function CampaignCard({ campaign }: Props) {
  const navigate = useNavigate();
  const { confirm, Confirm } = useConfirm();
  const to = campaign.status === "Sent" ? `${campaign.id}/stats` : `${campaign.id}/content`;

  const links: DotMenuLink[] = [
    {
      label: "Rename Campaign",
      iconType: "Edit",
      onClick: () => {
        navigate(`${campaign.id}/edit`);
      },
    },
    {
      label: "Clone Campaign",
      iconType: "Copy",
      onClick: async () => {
        const clone = await cloneCampaign(campaign.id);

        navigate(`/campaigns/${clone.id}/edit`);
      },
    },
    {
      label: "Delete Campaign",
      iconType: "Delete",
      onClick: () => {
        confirm({
          text: `"${campaign.name}" campaign will be permanently deleted. This action cannot be undone. Are you sure you want to delete this campaign?`,
          animationType: "Thrash",
          label: "Delete Campaign",
          onConfirm: async () => deleteCampaign(campaign.id),
        });
      },
    },
  ];
  return (
    <SuperCard
      to={to}
      title={
        <div className={classNames(flexStyles.horiz050, flexStyles.wrap)}>
          <TextAccent>{campaign.name}</TextAccent>
          <TextAccent>•</TextAccent>
          <CampaignStatusLabel campaign={campaign} />
        </div>
      }
      right={<DotMenu links={links} />}
    >
      <Confirm />
      <SmallTextMain>{campaign.subject}</SmallTextMain>
      <Description className={textStyles.neutral400}>
        {campaign.sentAt ? (
          <>Sent: {DateTime.fromISO(campaign.sentAt).toRelative()}</>
        ) : (
          <>Created: {DateTime.fromISO(campaign.insertedAt).toRelative()}</>
        )}
      </Description>
    </SuperCard>
  );
}
