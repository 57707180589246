"use client";

import classNames from "classnames";

import { Label, LabelWithCount } from "../Label";
import { bgStyles, flexStyles } from "../../styles";
import { InputWithErrors, Props as InputProps } from "./InputWithErrors";
import formStyles from "../forms.module.css";

interface Props extends InputProps {
  label?: string;
  maxCharacterCount?: number;
}

export function TextInput({
  disabled,
  name,
  type = "text",
  label,
  placeholder,
  onChange,
  className,
  disableAutoComplete,
  autoFocus,
  maxCharacterCount = 0,
}: Props) {
  const hasCharacterCount = maxCharacterCount > 0;

  return (
    <div className={classNames(flexStyles.vert025, className)}>
      {label &&
        (hasCharacterCount ? (
          <LabelWithCount name={name} maxCharacterCount={maxCharacterCount}>
            {label}
          </LabelWithCount>
        ) : (
          <Label name={name}>{label}</Label>
        ))}
      <InputWithErrors
        disableAutoComplete={disableAutoComplete}
        name={name}
        className={classNames(bgStyles.neutral0, formStyles.input)}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
      />
    </div>
  );
}
