import {
  DotMenuLink,
  Loader,
  flexStyles,
  paddingStyles,
  widthStyles,
  marginStyles,
  ResponsiveWrapper,
  useConfirm,
} from "ui";
import { toast } from "react-toastify";
import classNames from "classnames";
import { LinkItem, Overlay, Tabs } from "../ui";
import {
  batchBlockContacts,
  deleteContact,
  unblockContact,
  unsubscribeContact,
  useContact,
  useContactGroups,
  useCurrentBusiness,
} from "../../store";
import { Bookings } from "./tabs/Bookings";
import { Forms } from "./tabs/Forms";
import { compact } from "es-toolkit";
import { ConfirmCallout } from "./ConfirmCallout";
import { BigContactCard } from "./BigContactCard";
import { useNavigate, useParams } from "react-router-dom";

export function Show() {
  const navigate = useNavigate();
  const contact = useContact();
  const business = useCurrentBusiness();
  const { contactGroups } = useContactGroups();
  const goBack = () => navigate("../..", { relative: "path" });
  const { formId } = useParams();
  const { confirm, Confirm } = useConfirm();

  if (!contact) {
    return <Loader full />;
  }

  const { id } = contact;

  const { blocked, name, confirmed, sendable } = contact;
  const contactGroupsForContact = contactGroups.filter((group) => group.contacts.includes(id));

  const links: (DotMenuLink | null)[] = [
    {
      label: "Schedule",
      onClick: () => {
        window.open(`${business.url}/schedule/?contact=${id}`);
      },
      iconType: "Calendar",
    },
    sendable
      ? {
          label: "Unsubscribe contact",
          onClick: async () => {
            if (
              await confirm({
                text: `Are you sure you want to unsubscribe ${name} from emails?`,
                label: `Unsubscribe`,
                onConfirm: async () => unsubscribeContact(id),
              })
            ) {
              toast(`${name} is unsubscribed. They won'r receive emails from you.`);
            }
          },
          iconType: "EmailUnsubscribe",
        }
      : null,
    {
      label: blocked ? "Unblock contact" : "Block contact",
      onClick: async () => {
        const isBlocking = !blocked;
        if (
          await confirm({
            text: `Are you sure you want to ${isBlocking ? "block" : "unblock"} ${name}?${isBlocking ? " They will not be able to book appointments from now on." : ""}`,
            label: isBlocking ? "block" : "unblock",
            onConfirm: async () => {
              await (isBlocking ? batchBlockContacts([id]) : unblockContact(id));
            },
          })
        ) {
          toast(
            isBlocking
              ? `${name} is blocked.You won’t receive any more appointments from them.`
              : `${name} is unblocked.They can now book appointments with you.`,
          );
          if (isBlocking) {
            goBack();
          }
        }
      },
      iconType: "Remove",
    },
    {
      label: "Delete contact",
      onClick: async () => {
        if (
          await confirm({
            text: `Are you sure you want to delete ${contact.name}?`,
            label: "Delete contact",
            onConfirm: async () => deleteContact(id),
          })
        ) {
          goBack();
        }
      },
      iconType: "Delete",
    },
  ];

  const tabItems: LinkItem[] = [
    {
      label: "APPOINTMENTS",
      iconType: "Calendar",
      content: <Bookings id={id} />,
    },
    {
      label: "FORMS",
      iconType: "FormsSymbol",
      content: <Forms contactId={contact.id} />,
    },
  ];

  return (
    <Overlay
      transparent
      full
      className={classNames(flexStyles.gap050)}
      backTo={{ to: "../..", label: formId ? "Form Responses" : "List" }}
      header="Contact Detail"
    >
      {!confirmed && <ConfirmCallout contacts={[contact]} />}
      <Confirm />
      <ResponsiveWrapper
        className={classNames(flexStyles.vert100, widthStyles.full, paddingStyles.p125, marginStyles.centered)}
      >
        <BigContactCard links={compact(links)} contact={contact} contactGroups={contactGroupsForContact} />
      </ResponsiveWrapper>
      <Tabs items={tabItems} />
    </Overlay>
  );
}
