import { LinkForm, LogoForm } from "../components/business";
import { PrivateOutlet } from "./PrivateOutlet";

export const BusinessRoutes = {
  path: "business",
  Component: PrivateOutlet,
  children: [
    {
      path: "link",
      Component: LinkForm,
    },
    {
      path: "logo",
      Component: LogoForm,
    },
  ],
};
