"use client";

import classNames from "classnames";
import { bgStyles, flexStyles } from "../../styles";
import { TextAccent } from "../../typography";
import { InputWithErrors, Props as InputProps } from "./InputWithErrors";
import formStyles from "../forms.module.css";

interface Props extends InputProps {
  label?: string;
}

export function TextField({
  disabled,
  name,
  type = "text",
  label,
  placeholder,
  disableAutoComplete,
  onChange,
  className,
  autoFocus,
}: Props) {
  return (
    <div className={classNames(flexStyles.vert025, className)}>
      {label && <TextAccent>{label}</TextAccent>}
      <InputWithErrors
        disableAutoComplete={disableAutoComplete}
        className={classNames(bgStyles.neutral0, formStyles.input)}
        name={name}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
      />
    </div>
  );
}
