import { PersistedBusiness, PersistedTheme, PersistedUser } from "schemas";
import { setBusiness } from "./setBusiness";
import { apiRequest } from "../apiRequest";
import { setTheme } from "../themes/setTheme";

export async function getBusiness() {
  try {
    const { business, theme } = await apiRequest<{
      user: PersistedUser;
      business: PersistedBusiness;
      theme: PersistedTheme;
    }>({
      url: "business",
    });
    setBusiness(business);
    setTheme(theme);
  } catch {
    // no business
  }
}
