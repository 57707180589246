import { FormQuestionPayload, FormQuestionType } from "schemas";
import { DescriptionAccent, IconButton, IconType, RawIcon, TextMain } from "ui";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useFormContext, useWatch } from "react-hook-form";
import { ReadableFieldTypes } from "./fieldTypes";
import { SuperCard } from "../ui";

const TypeIconTypes: Record<FormQuestionType, IconType> = {
  TextField: "FormTextField",
  NumberField: "FormNumberField",
  DateField: "FormDateField",
  SingleChoice: "FormSingleChoiceField",
  MultipleChoice: "FormMultipleChoiceField",
  FileField: "FormFileField",
};

interface Props {
  question: FormQuestionPayload;
  index: number;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
}
export function QuestionCard({ question, dragHandleProps, index }: Props) {
  const questions = useWatch({ name: "questions" });
  const { setValue } = useFormContext();

  const deleteQuestion = async () => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setValue("questions", newQuestions, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };

  return (
    <SuperCard
      dragHandleProps={dragHandleProps}
      to={`edit/${index}`}
      left={<RawIcon iconType={TypeIconTypes[question.questionType]} />}
      title={<DescriptionAccent>{ReadableFieldTypes[question.questionType]}</DescriptionAccent>}
      right={<IconButton onClick={deleteQuestion} iconType="Delete" />}
    >
      <TextMain>
        {question.question}
        {question.required && "*"}
      </TextMain>
    </SuperCard>
  );
}
