import classNames from "classnames";
import { displayStyles, marginStyles, widthStyles } from "../../styles";
import { Resizable } from "../../Resizable";
import { PropsWithName } from "../../types";

interface Props extends PropsWithName {
  src: string;
  maxWidth: number | null;
}
export function ResizableImagePreview({ name, src, maxWidth }: Props) {
  return (
    <Resizable maxWidth={maxWidth} name={name} className={classNames({})}>
      <img
        className={classNames(displayStyles.block, widthStyles.maxWidthFull, marginStyles.centered, {})}
        src={src}
        alt=""
      />
    </Resizable>
  );
}
