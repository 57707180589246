import { SVGProps } from "react";

const SvgAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Account_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Account_svg__a)">
      <path
        d="M10 10a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 7 7c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 10 4c.833 0 1.542.292 2.125.875S13 6.167 13 7s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 10 10Zm-6 6v-2c0-.32.087-.621.26-.906a2.22 2.22 0 0 1 .72-.719 10.242 10.242 0 0 1 2.422-1.02 9.662 9.662 0 0 1 5.192 0c.854.235 1.663.576 2.427 1.02.305.18.545.417.719.708.173.292.26.598.26.917v2H4Zm1.5-1.5h9V14a.33.33 0 0 0-.063-.196.422.422 0 0 0-.166-.137 7.812 7.812 0 0 0-2.042-.875A8.535 8.535 0 0 0 10 12.5c-.764 0-1.507.097-2.23.292a7.812 7.812 0 0 0-2.04.875c-.07.055-.126.109-.168.16A.268.268 0 0 0 5.5 14v.5Zm4.504-6c.414 0 .767-.147 1.059-.442.291-.295.437-.649.437-1.062 0-.414-.147-.767-.442-1.059A1.456 1.456 0 0 0 9.996 5.5c-.414 0-.767.147-1.059.442A1.456 1.456 0 0 0 8.5 7.004c0 .414.147.767.442 1.059.295.291.649.437 1.062.437Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgAccount;
