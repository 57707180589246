import classNames from "classnames";
import { PropsWithChildren } from "react";
import { PropsWithClassName, bgStyles, flexStyles, paddingStyles, widthStyles } from "ui";

interface Props extends PropsWithClassName, PropsWithChildren {
  custom?: boolean;
}
export function Box({ children, className, custom = false }: Props) {
  return (
    <div
      style={{ minHeight: "1.25rem" }}
      className={classNames(
        flexStyles.vert050,
        widthStyles.full,
        paddingStyles.p050,
        { [bgStyles.neutral0]: !custom, [bgStyles.customButton]: custom },
        className,
      )}
    >
      {children}
    </div>
  );
}
