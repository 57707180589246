import type { SVGProps } from "react";
const SvgSocialFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M18.333 10a8.333 8.333 0 1 0-9.635 8.232V12.41H6.582V10h2.116V8.164c0-2.088 1.244-3.242 3.147-3.242.912 0 1.866.163 1.866.163v2.05h-1.05c-1.036 0-1.359.643-1.359 1.302V10h2.311l-.37 2.409h-1.941v5.823A8.335 8.335 0 0 0 18.333 10"
    />
  </svg>
);
export default SvgSocialFacebook;
