import {
  Icon,
  Offsetino,
  ResponsiveWrapper,
  Strong,
  SubheaderMain,
  TextMain,
  flexStyles,
  iconStyles,
  paddingStyles,
  textStyles,
} from "ui";
import classNames from "classnames";
import { Button } from "../ui";
import { useAfterImportRedirect } from "../../hooks";
import { useContactImport } from "../../store";

export function ImportSuccessful() {
  const { contactImport } = useContactImport();
  const { afterImportRedirect } = useAfterImportRedirect();

  const doneTo = afterImportRedirect || "/campaigns";

  return (
    <ResponsiveWrapper
      className={classNames(flexStyles.grow, paddingStyles.p125, flexStyles.vert200, flexStyles.alignCenter)}
    >
      <Offsetino />
      <div className={classNames(flexStyles.vert050, flexStyles.alignCenter)}>
        <Icon className={iconStyles.successMedium} iconType="Success" />
        <SubheaderMain className={classNames(textStyles.center)}>Contacts imported successfully</SubheaderMain>
      </div>
      <TextMain className={classNames(textStyles.center)}>
        {contactImport?.candidateCount} contacts will appear in your account soon.
      </TextMain>
      <TextMain className={classNames(textStyles.center)}>
        For <Strong>spam prevention reasons</Strong> we have to verify your import before you can send your email
        campaigns.
      </TextMain>
      <Offsetino />
      <Button className={classNames(flexStyles.alignSelfStretch)} buttonType="secondary" to={doneTo}>
        Done
      </Button>
    </ResponsiveWrapper>
  );
}
