"use client";

import { MouseEvent, useRef, useState } from "react";
import { useClickAway } from "react-use";
import classNames from "classnames";
import { getBottomOffset } from "../helpers";
import { Icon, IconType } from "./Icon";
import { OnClickHandler, PropsWithClassName } from "./types";
import { TextMain } from "./typography";
import { Card } from "./Card";
import {
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  paddingStyles,
  pointerStyles,
  positionStyles,
  textStyles,
  transformStyles,
} from "./styles";
import { IconButton } from "./IconButton";

export type DotMenuLink = {
  label: string;
  onClick: OnClickHandler;
  iconType: IconType;
};

interface Props extends PropsWithClassName {
  links: DotMenuLink[];
  vertical?: boolean;
  dark?: boolean;
  disabled?: boolean;
}
export function DotMenu({ className, links, vertical, dark, disabled }: Props) {
  const ref = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<"bottom" | "top">("bottom");
  useClickAway(ref, () => setOpen(false));

  if (links.length === 0) {
    return null;
  }

  const onClick = () => {
    if (!open) {
      if (ref.current) {
        const bottomOffset = getBottomOffset(ref.current);
        if (links.length * 48 > bottomOffset) {
          setPosition("top");
        } else {
          setPosition("bottom");
        }
      }

      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const closeAndCall = (callback: OnClickHandler): ((e: MouseEvent<HTMLButtonElement>) => void) => {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      setOpen(false);
      callback();
    };
  };

  return (
    <div ref={ref} className={classNames(positionStyles.relative, className)}>
      <IconButton
        square
        className={classNames({
          [transformStyles.rotate90]: vertical,
          [bgStyles.neutral300]: open && !dark,
          [iconStyles.primary]: !disabled && !dark,
          [iconStyles.neutral0]: !disabled && dark,
          [iconStyles.neutral300]: disabled,
        })}
        iconType="ThreeDots"
        onClick={disabled ? undefined : onClick}
      />
      {open && (
        <Card
          className={classNames(
            flexStyles.gap0,
            positionStyles.absolute,
            positionStyles.right0,
            positionStyles.zIndex47,
            paddingStyles.px125,
            {
              [positionStyles.top150]: position === "bottom",
              [positionStyles.bottom200]: position === "top",
            },
          )}
        >
          {links.map(({ label, onClick, iconType }) => (
            <button
              className={classNames(
                flexStyles.horiz150,
                flexStyles.alignCenter,
                flexStyles.justifyBetween,
                pointerStyles.cursor,
                iconStyles.textColor,
                textStyles.nowrap,
                textStyles.primary,
                textStyles.neutral400Hover,
                borderStyles.initialNone,
                borderStyles.bottomNeutral300,
                borderStyles.noneLastChild,
                bgStyles.none,
                heightStyles.height300,
              )}
              type="button"
              key={label}
              onClick={closeAndCall(onClick)}
            >
              <TextMain>{label}</TextMain>
              <Icon iconType={iconType} />
            </button>
          ))}
        </Card>
      )}
    </div>
  );
}
