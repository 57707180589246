import { flexStyles, paddingStyles } from "ui";

import classNames from "classnames";
import { SharedProps } from "./SharedProps";
import { Cancel } from "./Cancel";
import { SubmitButton } from "./SubmitButton";

interface Props extends SharedProps {}

export function Submit({
  disableClean = false,
  disableInvalid = false,
  disabled = false,
  cancelTo,
  className,
  label = "Submit",
  cancelType = "link",
}: Props) {
  return (
    <div className={classNames(flexStyles.vert125, paddingStyles.py125, className)}>
      <SubmitButton disabled={disabled} disableClean={disableClean} disableInvalid={disableInvalid} label={label}>
        <Cancel to={cancelTo} cancelType={cancelType} />
      </SubmitButton>
    </div>
  );
}
