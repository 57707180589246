export const Colors = {
  Neutral100: "#FAFAFA",
  Neutral200: "#F5F5F5",
  Neutral300: "#D1D4D5",
  Neutral400: "#848C8F",
  Neutral500: "#465357",
  Neutral600: "#232A2C",
  Neutral0: "#FFFFFF",
  Neutral0Alpha08: "rgba(255, 255, 255, 0.08)",
  Neutral0Alpha16: "rgba(255, 255, 255, 0.16)",
  Primary: "#191B20",
  PrimaryAlpha08: "rgba(25, 27, 32, 0.08)",
  PrimaryAlpha16: "rgba(25, 27, 32, 0.16)",
  Secondary100: "#FEF7EC",
  Secondary200: "#FCE6C9",
  Secondary300: "#F9CC8E",
  Secondary400: "#F5A343",
  Secondary500: "#F38F2C",
  Secondary600: "#ED6B13",
  SuccessLight: "#DAF1DB",
  SuccessMedium: "#B7E3BC",
  SuccessDark: "#237839",
  WarningLight: "#F7F4CE",
  WarningMedium: "#EEE899",
  WarningDark: "#BE8921",
  ErrorLight: "#FAEAE9",
  ErrorMedium: "#F4D7D7",
  ErrorDark: "#B7424F",
  InfoLight: "#E3F1FB",
  InfoMedium: "#C0E4F7",
  InfoDark: "#1380B6",
};

export function litesiteThemeObject() {
  return {
    "--litesite-neutral-0": Colors.Neutral0,
    "--litesite-neutral-100": Colors.Neutral100,
    "--litesite-neutral-200": Colors.Neutral200,
    "--litesite-neutral-300": Colors.Neutral300,
    "--litesite-neutral-400": Colors.Neutral400,
    "--litesite-neutral-500": Colors.Neutral500,
    "--litesite-neutral-600": Colors.Neutral600,
    "--litesite-neutral-600-a20": "rgba(35, 42, 44, 0.2)",
    "--litesite-primary": Colors.Primary,
    "--litesite-secondary-100": Colors.Secondary100,
    "--litesite-secondary-200": Colors.Secondary200,
    "--litesite-secondary-300": Colors.Secondary300,
    "--litesite-secondary-400": Colors.Secondary400,
    "--litesite-secondary-500": Colors.Secondary500,
    "--litesite-secondary-600": Colors.Secondary600,
    "--litesite-success-light": Colors.SuccessLight,
    "--litesite-success-medium": Colors.SuccessMedium,
    "--litesite-success-dark": Colors.SuccessDark,
    "--litesite-warning-light": Colors.WarningLight,
    "--litesite-warning-medium": Colors.WarningMedium,
    "--litesite-warning-dark": Colors.WarningDark,
    "--litesite-error-light": Colors.ErrorLight,
    "--litesite-error-medium": Colors.ErrorMedium,
    "--litesite-error-dark": Colors.ErrorDark,
    "--litesite-info-light": Colors.InfoLight,
    "--litesite-info-medium": Colors.InfoMedium,
    "--litesite-info-dark": Colors.InfoDark,

    "--max-content-width": "30rem",
    "--litesite-progress-color": "var(--litesite-secondary-300)",
    "--litesite-progress-background": "var(--litesite-neutral-200)",
    "--litesite-text-color": "var(--litesite-neutral-600)",
  };
}

export interface ThemeSolidColor {
  name: string;
  color: string;
}

export const ThemeSolids: ThemeSolidColor[] = [
  {
    name: "Bittersweet",
    color: "#B55B55",
  },
  {
    name: "Cardinal",
    color: "#B22D3B",
  },
  {
    name: "Fuschia",
    color: "#C01E6B",
  },
  {
    name: "Beet",
    color: "#8E1D59",
  },
  {
    name: "Old Rose",
    color: "#CE7B81",
  },
  {
    name: "Beeswax",
    color: "#F9C072",
  },
  {
    name: "Pumpkin",
    color: "#EE7E3B",
  },
  {
    name: "Salmon",
    color: "#F38CA0",
  },
  {
    name: "Coral",
    color: "#F27079",
  },
  {
    name: "Peony",
    color: "#F6CCC0",
  },
  {
    name: "Lemon",
    color: "#F6EC9C",
  },
  {
    name: "Maize",
    color: "#FCEC60",
  },
  {
    name: "Citron",
    color: "#D6D57F",
  },
  {
    name: "Pistachio",
    color: "#B4D472",
  },
  {
    name: "Celadon",
    color: "#92CEAE",
  },
  {
    name: "Blueberry",
    color: "#3D5F90",
  },
  {
    name: "Dusk",
    color: "#7AAECE",
  },
  {
    name: "Moonstone",
    color: "#54B9D1",
  },
  {
    name: "Aqua",
    color: "#A4D9E7",
  },
  {
    name: "Teal",
    color: "#0D7179",
  },
  {
    name: "Payne’s Grey",
    color: "#5E7684",
  },
  {
    name: "Silver",
    color: "#BFC0BC",
  },
  {
    name: "Adore",
    color: "#A1A2CA",
  },
  {
    name: "Grape",
    color: "#8A65A9",
  },
  {
    name: "Plum",
    color: "#57377C",
  },
  {
    name: "Seasalt",
    color: "#F9F9F9",
  },
  {
    name: "Sand",
    color: "#D6CFC2",
  },
  {
    name: "Khaki",
    color: "#ADA49A",
  },
  {
    name: "Chocolate",
    color: "#4E2C16",
  },
  {
    name: "Rich Black",
    color: "#071F20",
  },
];
