import { Strong, TextMain, flexStyles } from "ui";
import { useEffect } from "react";
import classNames from "classnames";
import { LogoHeader, Primer } from "../ui";
import { useZoomUrl } from "../../store";
import { useBackLocation, useZoomSetupRedirect } from "../../hooks";

const tableRows = [
  "Excellent video and audio quality",
  "Wide range of features, including screen sharing, virtual backgrounds, chat functionality, and recording options",
  "Compatible with various devices and operating systems",
  "Protects meetings, including encryption, meeting passwords, waiting rooms, and host controls.",
];

export function Setup() {
  const backUrl = useBackLocation("/");
  const zoomUrl = useZoomUrl();
  const { setZoomSetupRedirect } = useZoomSetupRedirect();

  useEffect(() => {
    if (backUrl) {
      setZoomSetupRedirect(backUrl);
    }
  }, [backUrl, setZoomSetupRedirect]);

  return (
    <div className={classNames(flexStyles.vert, flexStyles.grow)}>
      <LogoHeader logo="ZoomLogo" />
      <Primer
        header="Connect your Zoom account"
        description={
          <TextMain>
            PopSend uses <Strong>Zoom</Strong> to provide a reliable and efficient video conferencing experience.
          </TextMain>
        }
        why="Why should I setup Zoom?"
        tableRows={tableRows}
        minutes={2}
        continueTo={zoomUrl}
        cta="Setup Zoom"
        cancelTo={backUrl}
      />
    </div>
  );
}
