import { LinkNode } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { $getSelection, $isRangeSelection } from "lexical";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { IconButton } from "../../IconButton";
import styles from "./Toolbar.module.css";
import { Flagger } from "../../types";

interface Props {
  setShowLinkInput: Flagger;
  setLinkValue: (value: string | null) => void;
}
export function LinkButton({ setShowLinkInput, setLinkValue }: Props) {
  const [active, setActive] = useState<boolean>(false);
  const [editor] = useLexicalComposerContext();

  const updateButton = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();

      const nearestLink = $getNearestNodeOfType(anchorNode, LinkNode);
      setLinkValue(nearestLink ? nearestLink.getURL() : null);
      setActive(!!nearestLink);
    }
  }, [setLinkValue]);

  const onClick = async () => {
    setShowLinkInput(true);
  };
  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateButton();
          });
        }),
      ),
    [editor, updateButton],
  );

  return (
    <IconButton
      className={classNames(styles.button, { [styles.active]: active })}
      square
      onClick={onClick}
      iconType="Link"
    />
  );
}
