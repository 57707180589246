import type { SVGProps } from "react";
const SvgSocialQq = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m15.586 8.54-.157.049-.004.001-.017.005-.072.021q-.096.028-.283.077c-.248.064-.612.15-1.068.238-.911.175-2.186.355-3.64.372h-.013a20 20 0 0 1-3.637-.365 17 17 0 0 1-1.35-.32l-.07-.022-.018-.005-.003-.001h-.001l-.107-.035-.003-.145C5.143 5.214 6.646 2 10.366 2c3.703 0 5.222 3.214 5.222 6.41q0 .058-.002.13m-1.22 3.219c.713-.14 1.412-.311 2.058-.505l.022-.006c.154.419.296.833.417 1.197.716 2.27.472 3.213.35 3.248-.385.053-1.468-1.711-1.468-1.711 0 1.781-1.624 4.54-5.327 4.558h-.105c-3.702-.017-5.327-2.76-5.327-4.558 0 0-1.1 1.764-1.467 1.711-.174-.017-.419-.96.297-3.248.121-.364.263-.778.417-1.197l.023.007c.647.193 1.349.364 2.065.505a10.6 10.6 0 0 0-.042 1.994c.157 2.55 1.676 4.157 4.017 4.175h.105c2.34-.018 3.86-1.625 4.017-4.175.04-.76.01-1.423-.052-1.995m-4.018-4.642c1.694 0 3.04.332 3.179.63 0 .017 0 .034-.017.034 0 .018 0 .035-.018.053-.105.157-1.624.978-3.161.978h-.018c-1.537 0-3.056-.821-3.161-.978-.017-.018-.017-.035-.017-.053 0 0 .017-.017.017-.035.14-.297 1.502-.629 3.179-.629zm-1.327-.401c-.507.017-.943-.56-.96-1.275-.036-.734.366-1.345.855-1.363.507-.017.926.56.96 1.275.036.734-.349 1.345-.855 1.363m3.598-1.275c-.035.733-.454 1.31-.96 1.275-.507-.018-.892-.629-.857-1.363.035-.733.455-1.31.961-1.275.507.018.89.63.856 1.363"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10.348 9.72c2.97-.035 5.205-.734 5.205-.734l.751 1.87c-1.869.558-4.192.925-5.956.908h-.017q-.466.004-.978-.026v2.244c-1.153.157-2.306-.07-2.306-.07v-2.443a23 23 0 0 1-2.672-.614l.751-1.869c0 .018 2.236.717 5.205.734z"
      clipRule="evenodd"
    />
    <path
      stroke="#fff"
      strokeWidth={0.833}
      d="M10.33 18.47c-1.449 0-2.776-.489-3.65-1.205-.436.122-.995.332-1.344.594-.297.227-.262.454-.21.541.245.402 4.087.262 5.205.14zM10.33 18.47c1.45 0 2.778-.489 3.651-1.205.437.122.996.332 1.345.594.297.227.262.454.21.541-.245.402-4.087.262-5.205.14z"
    />
    <path
      fill="#fff"
      d="M9.632 5.458c.018.297-.14.542-.35.577s-.418-.175-.436-.472c-.017-.297.14-.541.35-.576.227-.035.419.174.436.471m1.398.087c.052-.087.349-.506.978-.349a.6.6 0 0 1 .262.122.16.16 0 0 1 0 .158c-.053.122-.14.122-.21.104-.035-.017-.472-.314-.873.123-.035.035-.07.035-.123 0a.114.114 0 0 1-.034-.158"
    />
  </svg>
);
export default SvgSocialQq;
