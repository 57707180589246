import type { SVGProps } from "react";
const SvgSocialReddit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M7.365 10.704a.833.833 0 1 0 1.386.926.833.833 0 0 0-1.386-.926M10.008 14.1a3.2 3.2 0 0 0 2.059-.642v.034a.234.234 0 0 0 .004-.33.23.23 0 0 0-.164-.07.23.23 0 0 0-.165.066 2.73 2.73 0 0 1-1.742.509 2.73 2.73 0 0 1-1.733-.525.224.224 0 0 0-.368.163.23.23 0 0 0 .051.153 3.2 3.2 0 0 0 2.058.642M11.454 11.893c.137.091.298.14.463.14l-.009.034a.835.835 0 0 0 .842-.867.834.834 0 1 0-1.296.693"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M18.333 10.333a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0M15.34 9.68c.134.193.21.42.22.654a1.22 1.22 0 0 1-.676 1.109q.015.183 0 .366c0 1.867-2.175 3.384-4.858 3.384s-4.858-1.517-4.858-3.384a2.4 2.4 0 0 1 0-.366A1.218 1.218 0 1 1 6.508 9.45a5.95 5.95 0 0 1 3.25-1.025l.617-2.892a.26.26 0 0 1 .308-.2l2.042.409a.833.833 0 1 1-.108.508l-1.784-.375-.541 2.6c1.146.025 2.26.38 3.208 1.025a1.217 1.217 0 0 1 1.839.18"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSocialReddit;
