export const SoonestBookingHoursOptions = [
  { value: 0, label: "Immediately" },
  { value: 1, label: "1 hour later" },
  { value: 2, label: "2 hours later" },
  { value: 3, label: "3 hours later" },
  { value: 4, label: "4 hours later" },
  { value: 6, label: "6 hours later" },
  { value: 8, label: "8 hours later" },
  { value: 12, label: "12 hours later" },
  { value: 24, label: "1 day later" },
  { value: 48, label: "2 days later" },
];

export const SoonestBookingHoursValues = SoonestBookingHoursOptions.map((option) => option.value);
