import { Asserts, object, string } from "yup";

export const UserUploadSchema = object({
  key: string().required(),
  bucket: string().required(),
  fileName: string().required(),
});
export type UserUploadPayload = Asserts<typeof UserUploadSchema>;
export type PersistedUserUpload = UserUploadPayload & {
  id: number;
};
