import { SVGProps } from "react";

const SvgCutOutLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={44} viewBox="0 0 20 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#CutOutLeft_svg__a)">
      <mask id="CutOutLeft_svg__b" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0-208a4 4 0 0 1 4-4h327a4 4 0 0 1 4 4V6c0 2.21-1.84 3.935-3.928 4.658C326.374 12.284 323 16.748 323 22c0 5.252 3.374 9.716 8.072 11.343C333.16 34.065 335 35.79 335 38v142a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V38c0-2.21 1.84-3.935 3.928-4.657C8.626 31.716 12 27.252 12 22s-3.374-9.716-8.072-11.342C1.84 9.934 0 8.209 0 6v-214Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0-208a4 4 0 0 1 4-4h327a4 4 0 0 1 4 4V6c0 2.21-1.84 3.935-3.928 4.658C326.374 12.284 323 16.748 323 22c0 5.252 3.374 9.716 8.072 11.343C333.16 34.065 335 35.79 335 38v142a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V38c0-2.21 1.84-3.935 3.928-4.657C8.626 31.716 12 27.252 12 22s-3.374-9.716-8.072-11.342C1.84 9.934 0 8.209 0 6v-214Z"
        fill="#FFF1FC"
      />
      <path
        d="m3.928 10.658.327-.945-.327.945Zm0 22.685-.328-.945.328.944Zm327.144 0 .328-.946-.328.946Zm0-22.686.328.945-.328-.944ZM4-213a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3v-2Zm327 0H4v2h327v-2Zm5 5a5 5 0 0 0-5-5v2a3 3 0 0 1 3 3h2Zm0 214v-214h-2V6h2Zm-12 16c0-4.813 3.091-8.906 7.4-10.398l-.655-1.89C325.656 11.475 322 16.31 322 22h2Zm7.4 10.398C327.091 30.905 324 26.813 324 22h-2c0 5.691 3.656 10.526 8.745 12.288l.655-1.89ZM336 180V38h-2v142h2Zm-5 5a5 5 0 0 0 5-5h-2a3 3 0 0 1-3 3v2ZM4 185h327v-2H4v2Zm-5-5a5 5 0 0 0 5 5v-2a3 3 0 0 1-3-3h-2Zm0-142v142h2V38h-2Zm12-16c0 4.813-3.091 8.906-7.4 10.398l.655 1.89C9.344 32.526 13 27.69 13 22h-2ZM3.6 11.602C7.91 13.095 11 17.188 11 22h2c0-5.691-3.656-10.526-8.745-12.287l-.655 1.89ZM-1-208V6h2v-214h-2ZM4.255 9.713C2.376 9.063 1 7.609 1 6h-2c0 2.81 2.304 4.807 4.6 5.602l.655-1.89ZM1 38c0-1.609 1.376-3.062 3.255-3.712l-.655-1.89C1.304 33.193-1 35.19-1 38h2Zm329.745-3.712C332.624 34.938 334 36.39 334 38h2c0-2.81-2.304-4.807-4.6-5.602l-.655 1.89ZM334 6c0 1.609-1.376 3.062-3.255 3.713l.655 1.89c2.296-.796 4.6-2.794 4.6-5.603h-2Z"
        fill="#DF349B"
        mask="url(#CutOutLeft_svg__b)"
      />
    </g>
    <defs>
      <clipPath id="CutOutLeft_svg__a">
        <path fill="#fff" d="M0 0h20v44H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCutOutLeft;
