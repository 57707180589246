import { BusinessPayload, PersistedBusiness } from "schemas";
import { setBusiness } from "./setBusiness";
import { apiRequest } from "../apiRequest";

export async function updateBusiness(payload: BusinessPayload) {
  const business = await apiRequest<PersistedBusiness>({
    url: `business`,
    method: "PATCH",
    body: JSON.stringify(payload),
  });
  setBusiness(business);
}
