import { SVGProps } from "react";

const SvgThreeDots = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="ThreeDots_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#ThreeDots_svg__a)">
      <path
        d="M4 12a1.926 1.926 0 0 1-1.416-.584A1.925 1.925 0 0 1 2 10c0-.556.195-1.028.584-1.416C2.972 8.194 3.444 8 4 8c.556 0 1.028.195 1.416.584.39.388.584.86.584 1.416 0 .556-.195 1.028-.584 1.416-.388.39-.86.584-1.416.584Zm6 0a1.926 1.926 0 0 1-1.416-.584A1.925 1.925 0 0 1 8 10c0-.556.195-1.028.584-1.416C8.972 8.194 9.444 8 10 8c.556 0 1.028.195 1.416.584.39.388.584.86.584 1.416 0 .556-.195 1.028-.584 1.416-.388.39-.86.584-1.416.584Zm6 0a1.925 1.925 0 0 1-1.416-.584A1.925 1.925 0 0 1 14 10c0-.556.195-1.028.584-1.416.388-.39.86-.584 1.416-.584.556 0 1.028.195 1.416.584.39.388.584.86.584 1.416 0 .556-.195 1.028-.584 1.416-.388.39-.86.584-1.416.584Z"
        fill="#191B20"
      />
    </g>
  </svg>
);
export default SvgThreeDots;
