import { ThemePayload } from "../schemas/Theme";
import { Colors } from "./colors";
import { gradientForTheme } from "./gradientForTheme";
import { findContrastColor } from "./helpers/findContrastColor";
import { themeButtonBackground } from "./themeButtonBackground";

export function themeButtonText(theme: ThemePayload) {
  switch (theme.type) {
    case "SolidColor":
      return findContrastColor(themeButtonBackground(theme));
    case "Gradient":
      switch (theme.variation) {
        case "Dark":
        case "Light":
        case "Primary":
          return Colors.Neutral0;
        case "Black":
        case "Gray":
          return gradientForTheme({ ...theme, variation: "Primary" }).fontColor;
      }
  }
}
