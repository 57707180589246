import { FormQuestionType } from "schemas";

export const ReadableFieldTypes: Record<FormQuestionType, string> = {
  TextField: "Text Field",
  NumberField: "Number Field",
  DateField: "Date Picker",
  SingleChoice: "Single Choice",
  MultipleChoice: "Multiple Choice",
  FileField: "File Upload",
};
