import type { SVGProps } from "react";
const GoogleContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="url(#icons8-google-contacts_svg__a)"
    baseProfile="basic"
    {...props}
  >
    <linearGradient
      id="icons8-google-contacts_svg__a"
      x1={-207.274}
      x2={-188.659}
      y1={79.108}
      y2={97.723}
      gradientTransform="translate(222.127 -52.643)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#55adfd" />
      <stop offset={1} stopColor="#438ffd" />
    </linearGradient>
    <path d="M6.999 41.499v-5.5c0-6.624 5.376-12 12-12h2.009c.819 0 2.013 2 2.991 2 1.063 0 1.917-2 3.003-2h5.997c4.416 0 8 3.584 8 8v4c0 4.416-3.584 8-8 8h-5.992c-1.628 0-3.187-3-5.008-3-2.181 0-4.629 3-6.551 3H9.499a2.5 2.5 0 0 1-2.5-2.5z" />
    <linearGradient
      id="icons8-google-contacts_svg__b"
      x1={-203.901}
      x2={-187.985}
      y1={75.663}
      y2={91.579}
      gradientTransform="translate(218.61 -49.343)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#427fdb" />
      <stop offset={1} stopColor="#0c52bb" />
    </linearGradient>
    <path
      fill="url(#icons8-google-contacts_svg__b)"
      d="M12.999 41.499v-9.5c0-4.416 3.584-8 8-8h6c4.416 0 8 3.584 8 8v4c0 4.416-3.584 8-8 8h-11.5a2.5 2.5 0 0 1-2.5-2.5z"
    />
    <linearGradient
      id="icons8-google-contacts_svg__c"
      x1={-202.589}
      x2={-191.884}
      y1={70.278}
      y2={80.983}
      gradientTransform="translate(220.932 -63.935)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#427fdb" />
      <stop offset={1} stopColor="#0c52bb" />
    </linearGradient>
    <path
      fill="url(#icons8-google-contacts_svg__c)"
      d="M23.999 3.998A8.005 8.005 0 0 1 32 11.999C32 16.415 28.415 20 23.999 20s-8.001-3.585-8.001-8.001a8.005 8.005 0 0 1 8.001-8.001z"
    />
  </svg>
);
export default GoogleContacts;
