import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

export async function deleteCampaign(id: string) {
  await apiRequest({ url: `campaigns/${id}`, method: "DELETE" });
  useLiteSiteStore.setState(({ campaigns }) => {
    campaigns.delete(id);
    return { campaigns: new Map(campaigns) };
  });
}
