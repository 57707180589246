import { PersistedBooking } from "schemas";
import { useLiteSiteStore } from "../store";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { apiRequest } from "../apiRequest";

function setDate(date: string, bookings: PersistedBooking[]) {
  useLiteSiteStore.setState((state) => {
    const map = state.upcomingBookings || new Map();
    map.set(date, bookings);
    return { upcomingBookings: map };
  });
}

async function fetchUpcomingBookings(): Promise<PersistedBooking[]> {
  try {
    const bookings = await apiRequest<PersistedBooking[]>({ url: "bookings/upcoming" });

    return bookings;
  } catch {
    return [];
  }
}

async function getUpcomingBookings() {
  const bookings = await fetchUpcomingBookings();
  const map = new Map<string, PersistedBooking[]>();
  bookings.forEach((booking: PersistedBooking) => {
    const date = DateTime.fromISO(booking.date).toISODate()!;

    const setBookings = map.get(date);
    if (setBookings) {
      setBookings.push(booking);
    } else {
      map.set(date, [booking]);
    }
  });
  setDate(DateTime.now().toISODate()!, []);
  map.forEach((values: PersistedBooking[], key: string) => {
    setDate(key, values);
  });
}

export function useUpcomingBookings() {
  useEffect(() => {
    getUpcomingBookings();
  }, []);

  return useLiteSiteStore(({ upcomingBookings }) => upcomingBookings);
}
