import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormProvider, FormTag, Loader, PasswordInput, ReCaptcha, SubheaderMain, textStyles } from "ui";
import { ChangePasswordPayload, ChangePasswordSchema } from "schemas";
import { AuthScreen } from "./AuthScreen";
import { Link, Submit } from "../ui";
import { getPasswordReset, patchPasswordReset } from "../../store";

export function ChangePassword() {
  const { token } = useParams() as { token: string };
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getPasswordReset({ token })
      .then((response) => {
        setReady(response);
      })
      .catch((error) => {
        toast.error((error as { response: { data: string } }).response.data);
      });
  }, [token]);

  const onChangePassword = async (payload: ChangePasswordPayload) => {
    try {
      await patchPasswordReset({ token, payload });
      toast.success("Password changed successfully, you can now login");
      navigate("/auth/login");
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  return (
    <FormProvider<ChangePasswordPayload>
      defaultValues={{ password: "", passwordConfirmation: "" }}
      schema={ChangePasswordSchema}
    >
      <AuthScreen>
        <SubheaderMain>Set your new password</SubheaderMain>
        {ready ? (
          <FormTag<ChangePasswordPayload> onSubmit={onChangePassword}>
            <PasswordInput name="password" label="Password" />
            <PasswordInput name="passwordConfirmation" label="Confirm Password" />
            <ReCaptcha action="changePassword" />
            <Submit label="Reset your password" disableClean disableInvalid />
          </FormTag>
        ) : (
          <Loader />
        )}
        <Link className={textStyles.center} to="/auth/login">
          Back to login
        </Link>
      </AuthScreen>
    </FormProvider>
  );
}
