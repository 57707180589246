import type { SVGProps } from "react";
const SvgSocialBehance = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M16.944 6.389h-4.86V5h4.86zm1.199 6.944c-.307.9-1.41 2.084-3.542 2.084-2.135 0-3.864-1.201-3.864-3.941 0-2.716 1.614-4.111 3.796-4.111 2.14 0 3.447 1.237 3.732 3.073.054.351.076.825.066 1.486h-5.574c.09 2.23 2.419 2.3 3.186 1.41zm-5.337-2.777h3.447c-.073-1.075-.789-1.541-1.72-1.541-1.018 0-1.581.533-1.727 1.54m-6.65 4.852h-4.49V5.015h4.83c3.802.056 3.874 3.78 1.888 4.795 2.403.875 2.484 5.598-2.227 5.598M3.75 9.167h2.489c1.742 0 2.018-2.084-.217-2.084H3.75zm2.355 2.083H3.75v2.094h2.32c2.122 0 1.992-2.094.035-2.094"
    />
  </svg>
);
export default SvgSocialBehance;
