"use client";

import { LegacyRef, useRef, useState } from "react";
import { useClickAway } from "react-use";

type Hook = {
  editMode: boolean;
  toggleEditMode: () => void;
  ref: LegacyRef<HTMLDivElement>;
};

export function useEditMode(editable: boolean = true, open: boolean = false): Hook {
  const ref = useRef(null);
  const [editMode, setEditMode] = useState(open);

  useClickAway(ref, () => {
    editable && setEditMode(false);
  });

  const toggleEditMode = editable ? () => setEditMode(!editMode) : () => {};

  return { editMode, toggleEditMode, ref };
}
