import { PropsWithChildren } from "react";
import annieAvatar from "../../images/annie-avatar.png";
import styles from "./ErrorPage.module.css";
import classNames from "classnames";
import {
  TextMain,
  bgStyles,
  borderStyles,
  displayStyles,
  flexStyles,
  marginStyles,
  paddingStyles,
  positionStyles,
  textStyles,
  widthStyles,
} from "ui";

export function ErrorPage({ children }: PropsWithChildren) {
  return (
    <div
      className={classNames(
        flexStyles.vert100,
        flexStyles.alignCenter,
        widthStyles.full,
        marginStyles.mt250,
        styles.wrapper,
      )}
    >
      <img
        alt="annie"
        className={classNames(displayStyles.block, borderStyles.radiusRound, bgStyles.blueGrad, styles.logo)}
        src={annieAvatar}
      />
      <div className={classNames(flexStyles.vert, flexStyles.alignCenter)}>
        <span className={classNames(displayStyles.block, styles.triangle)} />
        <TextMain
          className={classNames(
            bgStyles.neutral200,
            positionStyles.relative,
            paddingStyles.p200,
            borderStyles.radius125,
            textStyles.center,
            widthStyles.maxWidth2500,
            styles.tooltip,
          )}
        >
          {children}
        </TextMain>
      </div>
    </div>
  );
}
