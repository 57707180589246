import { SVGProps } from "react";

const SvgHide = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Hide_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Hide_svg__a)">
      <path
        d="m13.271 11.146-1.292-1.292A1.619 1.619 0 0 0 11.5 8.49 1.578 1.578 0 0 0 10.146 8L8.854 6.708c.18-.07.368-.121.563-.156.194-.035.388-.052.583-.052.972 0 1.798.34 2.479 1.021.68.68 1.021 1.507 1.021 2.479 0 .195-.017.389-.052.583a2.306 2.306 0 0 1-.177.563Zm2.771 2.771-1.084-1.084c.5-.389.955-.816 1.365-1.281.41-.465.754-.983 1.031-1.552a7.695 7.695 0 0 0-3.01-3.302A8.268 8.268 0 0 0 10 5.5c-.361 0-.715.02-1.062.062a6.36 6.36 0 0 0-1.021.209L6.708 4.562a7.444 7.444 0 0 1 1.615-.437A11.153 11.153 0 0 1 10 4c1.986 0 3.802.538 5.448 1.615C17.094 6.691 18.278 8.153 19 10a8.29 8.29 0 0 1-1.219 2.156 10.965 10.965 0 0 1-1.739 1.761ZM16 18.125l-2.708-2.708A9.467 9.467 0 0 1 10 16c-1.986 0-3.802-.538-5.448-1.615C2.906 13.309 1.722 11.847 1 10a8.67 8.67 0 0 1 1.208-2.167 10.06 10.06 0 0 1 1.75-1.771L1.875 3.979l1.063-1.062 14.124 14.145L16 18.125ZM5.021 7.146c-.486.389-.937.816-1.354 1.281-.417.465-.757.99-1.021 1.573a7.695 7.695 0 0 0 3.01 3.302A8.268 8.268 0 0 0 10 14.5c.361 0 .715-.024 1.062-.073.347-.049.695-.115 1.042-.198l-.937-.937a3.65 3.65 0 0 1-.584.156A3.305 3.305 0 0 1 10 13.5c-.972 0-1.798-.34-2.479-1.021C6.841 11.799 6.5 10.972 6.5 10c0-.195.024-.389.073-.583.049-.195.094-.39.135-.584L5.021 7.146Z"
        fill="#191B20"
      />
    </g>
  </svg>
);
export default SvgHide;
