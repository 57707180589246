import { SVGProps } from "react";

const SvgSmsMarketing = (props: SVGProps<SVGSVGElement>) => (
  <svg width={80} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.682 25.516c-8.146 0-14.75 6.604-14.75 14.75 0 5.255 2.747 9.867 6.884 12.48v7.515l6.171-5.34c.557.063 1.122.096 1.695.096h20.323c8.146 0 14.75-6.604 14.75-14.75 0-8.147-6.604-14.75-14.75-14.75H26.682Z"
      fill="#FF6B00"
    />
    <path
      d="M61.998 21.255c1.852 0 3.737.072 5.596.072.374 0 .741-.072 1.109-.072.363 0 .708.072 1.07.072.769 0 1.53.072 2.298.072.328 0 .39 1.71.39 2.09 0 .324-.023.924.059 1.226.09.336-.01.968-.01 1.353 0 .842-.032 1.697 0 2.538.003.107.137.781 0 .793-.34.03-.673.072-1.02.072-.798 0-1.644.018-2.436.148-.198.033-.38.068-.584.068-.081 0-.189.028-.267-.008-.088-.04-.125-.184-.22-.204-.378-.08-.837-.076-1.216-.076-.83 0-1.657.072-2.485.072-.432 0-.856.072-1.286.072-.205 0-.417-.072-.633-.072-.087 0-.206.032-.29-.004-.052-.022-.022-.317-.05-.392-.104-.274-.025-.837-.025-1.153 0-.626-.004-1.411-.095-2.018-.042-.285-.002-.644-.002-.937 0-.38-.049-.763-.049-1.153 0-.315-.049-.643-.049-.937 0-.18.056-.752-.048-.829"
      stroke="#F5A343"
      strokeWidth={1.714}
      strokeLinecap="round"
    />
    <path
      d="M62.282 22.26c.174.03.39.2.555.286.334.172.66.272 1.007.372.536.154 1.106.374 1.593.732.344.253.757.373 1.085.641.207.169.577.372.8.392.5.043.932-.471 1.357-.784a5.357 5.357 0 0 1 1.31-.712 4.262 4.262 0 0 0 1.088-.602c.173-.136.615-.28.703-.538"
      stroke="#F5A343"
      strokeWidth={1.714}
      strokeLinecap="round"
    />
    <path fill="#FFE1BE" d="M24.13 31.013h19.601v2.95H24.13zM24.13 38.224h25.239v2.95H24.13z" />
    <path fill="#F5A343" d="M24.13 45.436h25.239v2.95H24.13z" />
    <path
      d="M7.118 29.143c0-1.203-.26-2.408-.26-3.637v-1.938c0-.587-.057-.935.519-.389.477.453 1.1.683 1.611 1.102.359.294.955.553 1.238.908.244.306.518.322.518-.094 0-.722.13-1.44.13-2.175 0-.403.138-2.348.582-2.002.359.279 2.412 2.28 2.626 1.584.263-.854.35-1.773.54-2.629.262-1.18 1.356.317 1.95.713"
      stroke="#F5A343"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgSmsMarketing;
