import { PersistedContact } from "schemas";
import {
  ContactInitials,
  PropsWithClassName,
  TextMain,
  bgStyles,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  pointerStyles,
  squareStyles,
} from "ui";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { IconLink } from "../ui";

interface Props extends PropsWithClassName {
  contact: PersistedContact;
}
export function ContactCard({ className, contact }: Props) {
  const navigate = useNavigate();
  const onClick = () => navigate(`contacts/${contact.id}`);

  return (
    <div
      className={classNames(
        flexStyles.horiz050,
        flexStyles.alignCenter,
        paddingStyles.p125,
        borderStyles.bottomNeutral200,
        borderStyles.topNeutral200,
        className,
      )}
    >
      <ContactInitials contact={contact} onClick={onClick} />
      <TextMain className={pointerStyles.cursor} onClick={onClick}>
        {contact.name}
      </TextMain>
      <div className={flexStyles.grow} />
      <IconLink
        className={classNames(iconStyles.neutral0, bgStyles.primary, squareStyles.square275)}
        iconType="PhoneEnabled"
        to={`tel:${contact.phone}`}
      />
      <IconLink
        className={classNames(iconStyles.neutral0, bgStyles.primary, squareStyles.square275)}
        iconType="Mail"
        to={`mailto:${contact.email}`}
      />
    </div>
  );
}
