import { useWatch } from "react-hook-form";
import { PersistedBlock } from "schemas";
import { BlockCard } from "ui";

export function BlockPreview() {
  const block = useWatch();

  return (
    <BlockCard lazy={false} editable={false} navigate={async (_path: string) => {}} block={block as PersistedBlock} />
  );
}
