import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ReminderPayload, ReminderSchema, ReturnPromptDaysOptions, TemplatePair } from "schemas";
import {
  Divider,
  Select,
  TextArea,
  TextMain,
  Icon,
  FormTag,
  Loader,
  FormProvider,
  flexStyles,
  paddingStyles,
  heightStyles,
} from "ui";
import classNames from "classnames";
import { Link, Overlay, Submit } from "../ui";
import { updateAppointment, useAppointment } from "../../store";

function AutomationRefreshLink({
  method,
  defaultTemplates,
}: {
  method: "sms" | "email";
  defaultTemplates: TemplatePair;
}) {
  const { setValue } = useFormContext();
  const resetToDefault = () => {
    setValue(`templates.${method}`, defaultTemplates[method], { shouldDirty: true, shouldValidate: true });
  };

  return (
    <Link className={classNames(flexStyles.horiz075, flexStyles.alignCenter)} onClick={() => resetToDefault()}>
      <Icon iconType="Refresh" /> Reset to default
    </Link>
  );
}

const Labels = {
  UpcomingBooking: "Reminder",
  ThankYou: "Thanks",
  ReturnPrompt: "Follow up",
};

type Params = {
  id: string;
  reminderName: "UpcomingBooking" | "ThankYou" | "ReturnPrompt";
};

export function ReminderForm() {
  const navigate = useNavigate();
  const { id, reminderName } = useParams() as Params;
  const appointment = useAppointment(id);
  const onClose = () => navigate("..", { relative: "path" });

  if (!appointment) {
    return <Loader />;
  }

  const defaultTemplates: TemplatePair = appointment.defaultTemplates[reminderName];
  const reminder: ReminderPayload & { days?: number } = appointment.reminders[reminderName];

  const hasDays = reminderName === "ReturnPrompt";

  const schema = ReminderSchema[reminderName];

  const onSubmit = async (payload: ReminderPayload) => {
    const appointmentPayload = {
      ...appointment,
      ...{ reminders: { ...appointment.reminders, [reminderName]: payload } },
    };
    await updateAppointment(id, appointmentPayload);
    onClose();
  };

  const defaultValues: { templates: TemplatePair; days?: number } = {
    templates: {
      sms: reminder?.templates?.sms || defaultTemplates.sms,
      email: reminder?.templates?.email || defaultTemplates.email,
    },
  };

  if (hasDays) {
    defaultValues.days = reminder?.days || 7;
  }

  const label = Labels[reminderName];

  return (
    <FormProvider schema={schema} defaultValues={defaultValues}>
      <Overlay
        className={paddingStyles.p125}
        header={`${label} reminder details`}
        backTo={{ to: "..", label: "Automation" }}
      >
        <FormTag onSubmit={onSubmit}>
          {hasDays && (
            <>
              <TextMain>Select the duration you want to send the reminder before the event</TextMain>
              <Select name="days" label={`Send ${label} after`} options={ReturnPromptDaysOptions} />
              <Divider />
            </>
          )}
          <TextMain>Email message template</TextMain>
          <TextArea
            className={heightStyles.height1000}
            name="templates.email"
            label={`${label} email template`}
            extra={<AutomationRefreshLink defaultTemplates={defaultTemplates} method="email" />}
            placeholder={defaultTemplates.email}
          />
          <Divider />
          <TextMain className={flexStyles.horiz050}>
            SMS message template
            <Icon iconType="Diamond" />
          </TextMain>
          <TextArea
            className={heightStyles.height1000}
            name="templates.sms"
            label={`${label} SMS template`}
            extra={<AutomationRefreshLink defaultTemplates={defaultTemplates} method="sms" />}
            placeholder={defaultTemplates.sms}
          />
          <Submit disableClean label="Apply changes" cancelTo=".." />
        </FormTag>
      </Overlay>
    </FormProvider>
  );
}
