import classNames from "classnames";
import { IconButton } from "../IconButton";
import { bgStyles, borderStyles, flexStyles, iconStyles, paddingStyles } from "../styles";
import { iconForSocialNetwork } from "../../helpers/iconForSocialNetwork";
import { PersistedSocialLinksBlock } from "schemas";

interface Props {
  block: PersistedSocialLinksBlock;
}
export function SocialLinksCard({ block }: Props) {
  return (
    <div className={classNames(flexStyles.horiz100, flexStyles.justifyCenter, flexStyles.wrap)}>
      {block.links.filter(Boolean).map((link) => (
        <IconButton
          wide
          className={classNames(
            iconStyles.businessButtonText,
            iconStyles.horizStretch,
            iconStyles.buttonDimensions375,
            paddingStyles.p0875,
            borderStyles.neutral200,
            bgStyles.businessButton,
          )}
          iconType={iconForSocialNetwork(link!)}
          onClick={async () => {
            window.open(link || "", "_blank");
          }}
          key={link}
        />
      ))}
    </div>
  );
}
