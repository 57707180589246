import { useLocalStorage } from "react-use";

export function useAfterImportRedirect() {
  const [afterImportRedirect, setAfterImportRedirect, removeAfterImportRedirect] =
    useLocalStorage<string>("after-import-redirect");

  return {
    afterImportRedirect,
    setAfterImportRedirect,
    removeAfterImportRedirect,
  };
}
