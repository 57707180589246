import { TimeZones } from "schemas";
import { Select } from "ui";
import { BusinessForm } from "./BusinessForm";

export function TimezoneForm() {
  return (
    <BusinessForm header="Edit Time Zone" closeUrl="/settings/business" submitText="Continue">
      <Select name="timeZone" label="Time Zone" options={TimeZones} />
    </BusinessForm>
  );
}
