import { DateTime } from "luxon";
import classNames from "classnames";
import { CarouselCard } from "./CarouselCard";
import { PropsWithClassName } from "../types";
import { flexStyles, overflowStyles, paddingStyles } from "../styles";
import { CSSProperties } from "react";

interface Props extends PropsWithClassName {
  dates: DateTime[];
  selectedDate?: DateTime;
  onClick: (day: DateTime) => void;
  timeZone: string;
  style?: CSSProperties;
}

export function DayCarousel({ style, className, timeZone, dates, selectedDate, onClick }: Props) {
  return (
    <div
      style={style}
      className={classNames(flexStyles.horiz050, overflowStyles.scrollX, paddingStyles.p050, className)}
    >
      {dates.map((day) => (
        <CarouselCard
          timeZone={timeZone}
          key={`carouse-elem-${day.toISODate()}`}
          onClick={onClick}
          active={!!selectedDate && day.hasSame(selectedDate, "day")}
          day={day}
        />
      ))}
    </div>
  );
}
