import { FormPayload, PersistedForm } from "schemas";
import { setForm } from "./setForm";
import { apiRequest } from "../apiRequest";

export async function createForm(payload: FormPayload) {
  const form = await apiRequest<PersistedForm>({ url: "forms", method: "POST", body: JSON.stringify(payload) });
  setForm(form);

  return form;
}
