import { ThemePayload } from "../schemas/Theme";
import { themeBorderColor } from "./themeBorderColor";
import { themeLightColor } from "./themeLightColor";
import { themeDarkColor } from "./themeDarkColor";
import { themeButtonBackground } from "./themeButtonBackground";
import { themeButtonText } from "./themeButtonText";
import { themeTagBorderColor } from "./themeTagBorderColor";
import { themeTagBackgroundColor } from "./themeTagBackgroundColor";
import { themeEditorBackground } from "./themeEditorBackground";
import { themeEditorBorder } from "./themeEditorBorder";
import { themeEditorActive } from "./themeEditorActive";
import { themeEditorInterface } from "./themeEditorInterface";
import { themeEditorInterfaceText } from "./themeEditorInterfaceText";
import { themeCalloutBackground } from "./themeCalloutBackground";
import { themeBackground } from "./themeBackground";
import { themeTextColor } from "./themeTextColor";
import { transparentize } from "polished";
import { themeCalloutIcon } from "./themeCalloutIcon";
import { themeCalloutText } from "./themeCalloutText";
import { fontFamilyCSS } from "./fontFamilyCSS";
import { themeButtonBorderRadius } from "./themeButtonBorderRadius";
import { themeButtonAlignment } from "./themeButtonAlignment";
import { findContrastColor } from "./helpers/findContrastColor";

export function businessThemeObject(theme: ThemePayload) {
  const customButtonVariables = theme.buttonHexColor
    ? {
        "--litesite-custom-button-background": theme.buttonHexColor,
        "--litesite-custom-button-text": findContrastColor(theme.buttonHexColor),
      }
    : {};
  return {
    "--litesite-business-background": themeBackground(theme),
    "--litesite-business-dark": themeDarkColor(theme),
    "--litesite-business-light": themeLightColor(theme),
    "--litesite-business-text-color": themeTextColor(theme),
    "--litesite-business-border-color": themeBorderColor(theme),

    "--litesite-button-background": themeButtonBackground(theme),
    "--litesite-button-text": themeButtonText(theme),

    ...customButtonVariables,

    "--litesite-editor-background": themeEditorBackground(theme),
    "--litesite-editor-border": themeEditorBorder(theme),
    "--litesite-editor-active": themeEditorActive(theme),
    "--litesite-editor-interface": themeEditorInterface(theme),
    "--litesite-editor-interface-text": themeEditorInterfaceText(theme),

    "--litesite-callout-background": themeCalloutBackground(theme),
    "--litesite-callout-text": themeCalloutText(theme),
    "--litesite-callout-icon": transparentize(0.1, themeCalloutIcon(theme)),

    "--litesite-text-color": themeTextColor(theme),
    "--icon-color": themeTextColor(theme),

    "--litesite-button-border-radius": themeButtonBorderRadius(theme),
    "--litesite-button-flex-justify": themeButtonAlignment(theme),

    ...fontFamilyCSS(theme.fontFamily),

    "--tag-background-color": themeTagBackgroundColor(theme),
    "--tag-border-color": themeTagBorderColor(theme),
    "--litesite-progress-color": "var(--litesite-business-border-color)",
    "--litesite-progress-background": "var(--litesite-business-light-border-color)",
  };
}
