import classNames from "classnames";
import {
  Icon,
  IconType,
  RawIcon,
  bgStyles,
  borderStyles,
  flexStyles,
  iconStyles,
  imageStyles,
  paddingStyles,
  positionStyles,
  widthStyles,
} from "ui";
import styles from "./Logo.module.css";
import logoImage from "../../images/PopSendLogoDark.png";

interface Props {
  logo: IconType;
  tall?: boolean;
}
export function LogoHeader({ logo, tall = false }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.horiz050,
        flexStyles.justifyCenter,
        bgStyles.secondary200,
        positionStyles.relative,
        paddingStyles.py300,
      )}
    >
      <RawIcon
        className={classNames(bgStyles.neutral0, borderStyles.radius025, paddingStyles.px075, styles.logo, {
          [styles.tall]: tall,
        })}
        iconType={logo}
      />
      <Icon className={iconStyles.secondary400} iconType="Plus" />
      <img
        className={classNames(
          bgStyles.neutral0,
          borderStyles.radius025,
          paddingStyles.px075,
          widthStyles.width500,
          imageStyles.fitContain,
        )}
        src={logoImage}
      />
      <RawIcon
        className={classNames(positionStyles.absolute, positionStyles.left300, positionStyles.bottom050)}
        iconType="PaymentsStarLeft"
      />
      <RawIcon
        className={classNames(positionStyles.absolute, positionStyles.bottom050, positionStyles.right300)}
        iconType="PaymentsStarRight"
      />
      <RawIcon
        className={classNames(positionStyles.absolute, positionStyles.top050, positionStyles.right600)}
        iconType="PaymentsStarTopRight"
      />
    </div>
  );
}
