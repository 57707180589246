"use client";

import classNames from "classnames";
import {
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  paddingStyles,
  squareStyles,
  transitionStyles,
  widthStyles,
} from "../styles";

interface Props {
  name: string;
  on: boolean;
}
export function Slide({ name, on }: Props) {
  return (
    <label
      className={classNames(
        transitionStyles.all200,
        flexStyles.vert,
        flexStyles.justifyCenter,
        borderStyles.radius060,
        widthStyles.width200,
        heightStyles.height120,
        paddingStyles.px020,
        {
          [paddingStyles.ps020]: !on,
          [bgStyles.neutral500]: !on,
          [paddingStyles.ps100]: on,
          [bgStyles.secondary300]: on,
        },
      )}
      htmlFor={name}
    >
      <div className={classNames(squareStyles.square080, borderStyles.radiusRound, bgStyles.neutral0)} />
    </label>
  );
}
