import { BlockKind, BlockPayload, PersistedBlock } from "schemas";
import { setBlock } from "./setBlock";
import { apiRequest } from "../apiRequest";
import { withHttp } from "ui";

interface Args {
  id: string;
  kind: BlockKind;
  payload: BlockPayload;
}
export async function updateBlock({ id, kind, payload }: Args) {
  const extendedPayload = payload.kind === "LinkBlock" ? { ...payload, url: withHttp(payload.url) } : payload;
  const block = await apiRequest<PersistedBlock>({
    url: `blocks/${kind}/${id}`,
    method: "PATCH",
    body: JSON.stringify(extendedPayload),
  });
  setBlock(block);
}
