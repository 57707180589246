import { SVGProps } from "react";
const SvgPaymentsStarRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.278 1.997c-.55 1.157-1.545 2.186-2.256 3.258-.412.62-.764 1.256-1.12 1.909-.447.82-1.674 3.337-1.244 2.508.492-.948 1.043-2.006 1.707-2.846.543-.686.93-1.494 1.48-2.153.512-.612.674-1.403 1.156-2.01.16-.2.324-.375.412-.617.094-.259-.212.512-.276.781-.355 1.494-.882 2.95-1.027 4.48-.07.736-.146 1.424-.253 2.167-.067.462-.345 1.15-.238 1.61.079.339 1.849.526 2.238.668 1.206.439 2.617.583 3.87.854.45.097 1.19.399 1.644.386.019 0-.848.259-1.165.261-1.555.013-3.133-.17-4.699-.095-.89.043-2.186.318-2.575 1.168-.315.689-.764 1.299-1.046 2.033-.449 1.17-.856 2.353-1.287 3.537l-.944 2.593c-.126.345-.092-.462-.105-.576-.118-1.11-.13-2.22-.006-3.322.114-1.009.437-2.04.35-3.063-.056-.668-.133-1.587.046-2.226.371-1.333-1.998-1.2-2.887-1.524-.745-.271-1.52-.406-2.276-.682-.31-.113-1.66-.543-1.394-.58 1.454-.207 3-.203 4.486-.35.543-.053 1.137.075 1.633-.074.291-.088.906-.09 1.088-.257"
      stroke="#FFCA28"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgPaymentsStarRight;
