import { ScheduleItemPayload } from "schemas";
import { Label, widthStyles } from "ui";
import { Times } from "./Times";

interface Props {
  index?: number;
  name: string;
  label: string;
  item: ScheduleItemPayload;
}
export function Day({ index = undefined, label, name, item }: Props) {
  return (
    <div className={widthStyles.full}>
      <Label name={name}>{label}</Label>
      <Times times={item.times!} name={name} index={index} />
    </div>
  );
}
