"use client";

import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ReCaptchaProvider, ReCaptchaV3 } from "react-recaptcha-x";
import { useInterval } from "react-use";

function SiteKey() {
  return import.meta.env?.REACT_APP_CAPTCHA_SITE_KEY;
}

type Action = "login" | "signup" | "requestPasswordReset" | "changePassword";
interface Props {
  action: Action;
}

export function ReCaptcha({ action }: Props) {
  const siteKey = SiteKey();
  const [refresh, setRefresh] = useState<() => void | undefined>();

  const {
    register,
    setValue,
    formState: { submitCount },
  } = useFormContext();

  useEffect(() => {
    register("captchaResponse");
  }, [register]);

  useInterval(() => {
    if (refresh) {
      refresh();
    }
  }, 1000 * 90);

  useEffect(() => {
    if (refresh) {
      refresh();
    }
  }, [submitCount, refresh]);

  const onSuccess = (token: string | void, refreshToken: () => void) => {
    if (typeof token !== "string") {
      return;
    }
    setRefresh(() => refreshToken);
    setValue("captchaResponse", token, { shouldValidate: true });
  };

  return (
    <ReCaptchaProvider siteKeyV3={siteKey}>
      <ReCaptchaV3 action={action} callback={onSuccess} />
    </ReCaptchaProvider>
  );
}
