import { PersistedContactImport, UserUploadPayload } from "schemas";
import { setContactImport } from "./setContactImport";
import { apiRequest } from "../apiRequest";

interface Args {
  contactImport: PersistedContactImport;
  payload: UserUploadPayload;
}
export async function addCsvToContactImport({ contactImport, payload }: Args) {
  const updatedImport = await apiRequest<PersistedContactImport>({
    url: `contactImports/${contactImport.id}/csv`,
    method: "POST",
    body: JSON.stringify(payload),
  });
  setContactImport(updatedImport);

  return updatedImport;
}
