import { DateTime } from "luxon";
import { PersistedBooking } from "schemas";
import { ContactInitials, Description, DescriptionAccent, flexStyles } from "ui";
import { useUrlWithBack } from "../../hooks";
import { SuperCard } from "./SuperCard";

interface Props {
  booking: PersistedBooking;
}

export function BookingCard({ booking }: Props) {
  const { contact, event, date } = booking;
  const start = DateTime.fromISO(date);
  const end = start.plus({ minutes: event.sessionDuration });
  const bookingUrl = useUrlWithBack(`/bookings/${booking._id}`);

  return (
    <SuperCard
      to={bookingUrl}
      left={<ContactInitials contact={contact} />}
      title={
        <div className={flexStyles.vert025}>
          <DescriptionAccent>{contact.name}</DescriptionAccent>
          <Description>{event.eventName}</Description>
          <Description>
            {start.toLocaleString({ hour: "2-digit", minute: "2-digit" })} -{" "}
            {end.toLocaleString({ hour: "2-digit", minute: "2-digit" })}
          </Description>
        </div>
      }
    />
  );
}
