import classNames from "classnames";
import { SmallTextAccent } from "./typography";
import { PropsWithClassName } from "./types";
import { borderStyles, flexStyles, heightStyles, paddingStyles } from "./styles";
import { Icon, IconType } from "./Icon";

interface Props extends PropsWithClassName {
  iconType: IconType;
  label: string;
}

export function Summary({ label, iconType, className }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.horiz025,
        flexStyles.alignCenter,
        heightStyles.height175,
        paddingStyles.px050,
        borderStyles.radius025,
        className,
      )}
    >
      <Icon iconType={iconType} />
      <SmallTextAccent>{label}</SmallTextAccent>
    </div>
  );
}
