import classNames from "classnames";
import { Props, RawInitials } from "./RawInitials";
import { squareStyles, textStyles } from "../styles";

export function SmallContactInitials({ contact, onClick, className }: Props) {
  return (
    <RawInitials
      className={classNames(squareStyles.square150, textStyles.size087, className)}
      contact={contact}
      onClick={onClick}
    />
  );
}
