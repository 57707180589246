import { Connect, Exit, Retry, Success } from "../components/payments";
import { PrivateOutlet } from "./PrivateOutlet";

export const PaymentsRoutes = {
  path: "payments",
  Component: PrivateOutlet,
  children: [
    {
      path: "retry",
      Component: Retry,
    },
    {
      path: "success",
      Component: Success,
    },
    {
      path: "exit",
      Component: Exit,
    },
    {
      path: "connect",
      Component: Connect,
    },
  ],
};
