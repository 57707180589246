import classNames from "classnames";
import { PersistedBooking } from "schemas";
import { SubheaderMain, flexStyles, paddingStyles } from "ui";
import { SimpleBookingCard } from "../SimpleBookingCard";

interface Props {
  label: string;
  bookings: PersistedBooking[];
}
export function BookingsList({ label, bookings }: Props) {
  const count = bookings.length;
  if (count === 0) {
    return null;
  }

  return (
    <div className={classNames(paddingStyles.p125, flexStyles.vert050)}>
      {count > 0 && (
        <SubheaderMain>
          {label} ({count})
        </SubheaderMain>
      )}
      {bookings.map((booking) => (
        <SimpleBookingCard key={booking._id} booking={booking} />
      ))}
    </div>
  );
}
