"use client";

import { useFormContext } from "react-hook-form";
import getSymbolFromCurrency from "currency-symbol-map";
import classNames from "classnames";
import { Label } from "./Label";
import { Error } from "./Error";
import {
  bgStyles,
  borderStyles,
  displayStyles,
  flexStyles,
  heightStyles,
  outlineStyles,
  paddingStyles,
  placeholderStyles,
  textStyles,
  widthStyles,
} from "../styles";

interface Props {
  name: string;
  label: string;
  currency: string;
  placeholder?: string;
}
export function PriceInput({ name, label, placeholder, currency }: Props) {
  const { register } = useFormContext();

  return (
    <div className={flexStyles.vert025}>
      <Label name={name}>{label}</Label>
      <div
        className={classNames(
          flexStyles.horiz050,
          flexStyles.alignCenter,
          textStyles.size087,
          heightStyles.height312,
          paddingStyles.p0875,
          widthStyles.full,
          bgStyles.neutral0,
          borderStyles.neutral400,
          borderStyles.radius025,
        )}
      >
        <div className={classNames(textStyles.neutral600, textStyles.weight600)}>{getSymbolFromCurrency(currency)}</div>
        <input
          className={classNames(
            paddingStyles.p0875,
            displayStyles.block,
            outlineStyles.none,
            borderStyles.none,
            heightStyles.height137,
            textStyles.fontPoppins,
            textStyles.size087,
            textStyles.neutral600,
            flexStyles.grow,
            placeholderStyles.neutral300,
          )}
          placeholder={placeholder}
          type="text"
          {...register(name)}
        />
      </div>
      <Error name={name} />
    </div>
  );
}
