import type { SVGProps } from "react";
const SvgGetYoursStars = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={30} fill="none" {...props}>
    <g stroke="#54B9D1" strokeLinecap="round" opacity={0.1}>
      <path
        strokeWidth={2.612}
        d="M7.327 11.39c.18 1.052.004 2.228.037 3.297.02.62.085 1.221.157 1.837.089.772.46 3.075.355 2.305-.12-.881-.253-1.866-.207-2.755.037-.727-.087-1.462-.032-2.174.052-.66-.214-1.279-.18-1.922.012-.213.039-.41-.017-.617-.06-.223.1.45.185.664.468 1.191.804 2.437 1.429 3.552.3.537.574 1.045.852 1.604.174.348.314.95.601 1.214.212.195 1.475-.514 1.8-.601 1.007-.27 2.012-.839 2.971-1.246.345-.146.977-.29 1.273-.513.012-.009-.44.578-.65.73-1.026.744-2.16 1.364-3.164 2.157-.57.45-1.301 1.252-1.159 2.017.116.62.105 1.25.264 1.887.253 1.013.54 2.015.812 3.029l.595 2.221c.08.296-.279-.272-.341-.345a12.867 12.867 0 0 1-1.57-2.27c-.399-.745-.67-1.604-1.21-2.262-.352-.431-.835-1.024-1.018-1.546-.381-1.088-1.892.123-2.634.322-.622.167-1.2.44-1.832.61-.259.07-1.357.413-1.199.262.868-.829 1.896-1.558 2.813-2.36.335-.294.79-.488 1.049-.824.152-.198.559-.49.601-.69"
      />
      <path
        strokeWidth={3}
        d="M32.674 3.75c-.344.722-.966 1.365-1.41 2.035-.258.389-.478.785-.7 1.193-.28.513-1.047 2.086-.778 1.568.307-.592.652-1.254 1.067-1.778.34-.43.58-.934.925-1.346.32-.383.421-.877.723-1.256.1-.126.202-.235.257-.386.06-.162-.132.32-.172.488-.222.934-.552 1.844-.642 2.8a27 27 0 0 1-.159 1.354c-.041.29-.215.72-.148 1.007.049.211 1.155.329 1.399.417.753.274 1.636.365 2.419.534.281.06.743.249 1.027.241.012 0-.53.162-.728.163-.972.008-1.959-.107-2.937-.059-.556.027-1.366.199-1.61.73-.197.43-.477.812-.653 1.27-.28.732-.535 1.471-.805 2.211l-.59 1.62c-.078.216-.057-.288-.065-.36a9.484 9.484 0 0 1-.004-2.076c.071-.63.273-1.275.219-1.914-.036-.417-.083-.992.028-1.391.233-.833-1.248-.75-1.804-.953-.466-.17-.95-.254-1.422-.426-.194-.07-1.038-.34-.872-.363.909-.129 1.875-.126 2.804-.218.34-.034.71.047 1.02-.047.183-.054.567-.056.68-.16"
      />
    </g>
  </svg>
);
export default SvgGetYoursStars;
