import type { SVGProps } from "react";
const SvgSocialMySpace = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21" {...props}>
    <path
      fill="#fff"
      d="M4.15 11.757c1.163 0 2.106-.939 2.106-2.097A2.1 2.1 0 0 0 4.15 7.562c-1.163 0-2.106.94-2.106 2.098s.943 2.097 2.106 2.097M4.15 12.237c-1.335 0-2.314 1.17-2.314 2.382v.815c0 .115.094.21.21.21h4.207a.21.21 0 0 0 .211-.21v-.815c0-1.211-.979-2.383-2.314-2.383M9.624 11.325a2.335 2.335 0 0 0 2.34-2.33 2.335 2.335 0 0 0-2.34-2.331 2.335 2.335 0 0 0-2.34 2.33 2.335 2.335 0 0 0 2.34 2.331M9.624 11.857c-1.484 0-2.571 1.302-2.571 2.648v.905c0 .129.105.234.234.234h4.674c.13 0 .234-.105.234-.234v-.905c0-1.346-1.088-2.648-2.571-2.648M15.646 10.846a2.594 2.594 0 0 0 2.598-2.59c0-1.43-1.163-2.59-2.598-2.59a2.594 2.594 0 0 0-2.6 2.59c0 1.43 1.164 2.59 2.6 2.59M15.646 11.437c-1.65 0-2.857 1.447-2.857 2.941v1.007a.26.26 0 0 0 .26.259h5.194a.26.26 0 0 0 .26-.26v-1.006c0-1.495-1.21-2.941-2.857-2.941"
    />
  </svg>
);
export default SvgSocialMySpace;
