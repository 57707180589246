import { SVGProps } from "react";

const SvgChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="ChevronRight_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#ChevronRight_svg__a)">
      <path d="M7.487 17 6 15.513 11.512 10 6 4.487 7.487 3l7 7-7 7Z" fill="#465357" />
    </g>
  </svg>
);
export default SvgChevronRight;
